<template>
	<div>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">Role</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item">
                                <a href="#">User & Role Management</a>
                            </li>

                            <li class="breadcrumb-item active">Role</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-12 col-sm-6 mb-1">
                                            <div class="btn-responsive">                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="w-long" width="85%">Role</th>
                                                    <th>Set Permission</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Pengawas</td>
                                                    <td>
                                                        <div class="btn-action-block">
                                                            <router-link to="role/pengawas" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                                <i class="fa fa-pencil"></i>
                                                            </router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Non Pengawas</td>
                                                    <td>
                                                        <div class="btn-action-block">
                                                            <router-link to="role/non-pengawas" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                                <i class="fa fa-pencil"></i>
                                                            </router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Kontraktor</td>
                                                    <td>
                                                        <div class="btn-action-block">
                                                            <button type="button" @click="redirect()" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                                                                <i class="fa fa-pencil"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>           
                                        </table>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>
<script>
    export default {
        data() {
            return {                
                role_id: localStorage.getItem('role_id')
            }
        },
        methods: {
            redirect(){
                localStorage.setItem('role', '2')
                localStorage.setItem('type', 'kontraktor')
                this.$router.push('/akses')
            }
        }
    }
</script>