<template>
<div class="table-responsive tableFixHead" style="max-height: 500px">
    <table class="table mb-0">
        <slot name="header"></slot>
        <tbody>
            <tr v-for="(list,idx) in options.list" :key="idx">
                <td v-if="!noNumber">{{ idx+ 1 }}</td>
                <td v-for="(column, idxCol) in options.columns" :key="idxCol">      
                    <span v-if="['document_kontraktual', 'dok_pendukung', 'document'].includes(column)">
                        <p v-if="list[column]" class="m-0 text-primary">&bull; File 1 <a :href="list['document_url']">[Lihat Detail]</a></p>
                        <p v-else class="m-0 text-primary">-</p>
                    </span>  
                    <span v-else-if="column === 'nomor'">
                        <!-- {{ (idx+1) * page }} -->
                        <!-- {{ (page - 1) * 10 + (idx + 1) }} -->
                        {{ list[column] }}
                    </span>
                    <span v-else-if="column === 'nomor_mtu'">
                        <!-- {{ (idx+1) * page }} -->
                        {{ (page - 1) * 10 + (idx + 1) }}
                    </span>
                    <span v-else-if="column === 'col_prk'">
                        <span style="font-size: 15px">{{ list[column].toString() }}</span>
                    </span>
                    <!-- <span v-else-if="column === 'dok_pendukung'">
                        <p v-if="list[column]" class="m-0 text-primary">&bull; File 1 <a :href="list['document_url']">[Lihat Detail]</a></p>
                        <p v-else class="m-0 text-primary">-</p>
                    </span>                                                   -->
                    <span v-else-if="['col_value', 'col_value_paid', 'nilai_kontrak'].includes(column)" style="font-size: 15px">
                        <p>{{ list[column] | currency }}</p>
                    </span>
                    <span v-else-if="column === 'name_resume'">
                        <span style="font-size: 15px">{{ list[column] }}</span>
                        <p class="m-0 text-primary" v-if="list['relokasi']"><small>Relokasi dari {{ list['relokasi'] }}</small></p>
                    </span>
                    <span v-else-if="['judul_kontrak'].includes(column)" style="font-size: 15px">
                        <strong>
                            <router-link :to="'/kontrak-mtu/detail/'+list['id']" class="m-0 text-primary">
                                {{ list[column] }}
                            </router-link>
                        </strong>
                    </span>
                    <span v-else-if="column === 'perihal_parse'" style="font-size: 15px">
                        <p class="m-0">{{list[column]}}</p>
                    </span>
                    <span v-else-if="column === 'col_perihal'" style="font-size: 15px">
                        <ol class="p-0">
                            <li v-for="(i, index) in list[column]" :key="index">
                                <p class="mb-0"><b><u>{{i.location}}</u></b></p>
                                <ul>
                                    <li v-for="(z, zz) in i.perihals" :key="zz">
                                        <b>{{ z.perihal_name }}</b><br>
                                        {{ z.perihal_notes }}
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </span>
                    <span v-else-if="column == 'material' || column == 'jumlah' || column == 'satuan'" style="font-size: 15px">
                        <p v-for="(data, i) in (list[column])" :key="i" class="mb-0">• {{ data }}</p>
                    </span>
                    <span v-else-if="column == 'status' && changeableStatus === true">
                        <multiselect 
                            :options="statusOptions" 
                            :multiple="false" 
                            :close-on-select="false" 
                            :clear-on-select="false" 
                            v-model="list[column]"
                            :preserve-search="true" 
                            placeholder="Pilih Progress" 
                            label="name" 
                            track-by="name"
                            :preselect-first="false"
                            :searchable="true"
                            @select="onSelect($event)"
                            @remove="onRemove($event)"
                            @input="onInput(list)"
                            :allow-empty="false"
                            deselectLabel="Remove"
                            selectLabel="Select">
                            <template 
                                slot="selection" 
                                slot-scope="{ values, isOpen }">
                                <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                                    {{ values.map(x => x.name).join(', ') }}
                                </span>
                            </template>
                            <template v-slot:noResult>
                                Tidak ada data.
                            </template>
                            <template v-slot:noOptions>
                                Tidak ada data.
                            </template>                                                  
                        </multiselect>
                    </span>
                    <span v-else style="font-size: 15px" v-html="list[column]"></span>
                </td>
                <td v-if="noAction !== true">
                    <!-- <div class="d-flex align-items-center" style="gap: 10px">
                    </div> -->
                    <slot name="action" v-bind:data="list"></slot>
                </td>
            </tr>
            <tr v-if="!options.list.length">
                <td :colspan="noAction ? options.columns.length : options.columns.length + 1">
                    <div class="col text-center mt-1">
                        <p>Tidak ada data.</p>
                    </div>  
                </td>
            </tr>
            <slot name="customeColSpan" />
        </tbody>           
    </table>                                                                         
</div>
</template>

<script>
export default {
    name: 'TableResponsiveV2',
    props: {
        options: {
            type: Object,
            default () {
                return {
                    list: [],
                    columns: []
                }
            }
        },
        noAction : {
            type: Boolean,
            default: false
        },
        noNumber : {
            type: Boolean,
            default: false
        },
        page : {
            type: Number,
        },
        changeableStatus : {
            type: Boolean,
            default: false
        },
        statusOptions: [],
    },
    data() {
        return {
            huaaa: [],
        }
    },
    methods: {
        onInput(list) {
            const data = {
                id_kontrak_mtu_lokasi: list.id,
                type: 1,
                progress_type: list.status,
                progress_value: 0,
            };
            this.$emit('onStatusChange', data);
        },
        onSelect (opt) {
            console.log(opt)
        },
        onRemove (opt) {
            console.log(opt)
        },
    }
}
</script>

<style>

</style>