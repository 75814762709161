<template>
	<section>		
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">Generate Laporan Project</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item">
                                <a href="#">Laporan</a>
                            </li>

                            <li class="breadcrumb-item active">Generate Laporan Project</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body card-dashboard">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-12 col-sm-7">
                                    <div class="form-group">
                                        <label for="filter_by">Nama Kontrak</label>                                        
                                        <multiselect 
                                            v-model="search" 
                                            :options="model" 
                                            placeholder="-- Pilih Nama Kontrak --" 
                                            label="judul_kontrak"
                                            :searchable="true">
                                            <template v-slot:noResult>
                                                Tidak ada data.
                                            </template>
                                            <template v-slot:noOptions>
                                                Tidak ada data.
                                            </template>
                                        </multiselect>                                        
                                    </div>
                                </div>

                                <div class="col-12 col-sm-5">
                                    <div class="btn-responsive">
                                        <div class="no-flex-mobile justify-content-end">
                                            <button v-can="'laporan/project/generate'" @click="cetak_excel" class="btn btn-outline-primary mr-1" :disabled="search.id == null">
                                                <i class="fa fa-file-excel-o fa-l" aria-hidden="true"></i> Cetak
                                                Excel
                                            </button>

                                            <button v-can="'laporan/project/generate'" @click="cetak_pdf" class="btn btn-outline-primary" :disabled="search.id == null">
                                                <i class="fa fa-file-pdf-o fa-l" aria-hidden="true"></i> Cetak PDF
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</section>
</template>

<script>
export default {
	name: 'LaporanProjectIndex',
	data() {
		return {			
            model: [],
            search: {},
            role_id: localStorage.getItem('role_id')
		}
	},
    methods: {
        get() {
            let _ = this
            _.axios.get('/kontrak/all')
                .then(resp=>{
                    _.model = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        cetak_excel() {
			let _ = this
            _.axios.get('kontrak/export-project-excel?id=' + _.search.id, {responseType: 'arraybuffer'})
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LaporanProject-' + _.search.no_kontrak +'.xlsx'
                    link.click()
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        cetak_pdf() {
			let _ = this
            _.axios.get('kontrak/export-project-pdf?id=' + _.search.id, {responseType: 'arraybuffer'})
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LaporanProject-' + _.search.no_kontrak +'.pdf'
                    link.click()
                })
                .catch(err => {
                    console.log(err.response)
                })
		}
    },
    created(){
        let _ = this
        _.get()
    }
}
</script>