<template>
    <div>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">Progress List</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item">
                                <router-link :to="'/approval'">
                                Approval Progress
                                </router-link>
                            </li>

                            <li class="breadcrumb-item active">Progress List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-1">
                                            <div class="btn-responsive">
                                                <div>
                                                    <button type="button" data-toggle="modal" data-target="#Modal_filter_progress" class="btn btn-primary d-inline">
                                                        Filter Kontrak
                                                    </button>  
                                                    &emsp;
                                                    <button v-can="'approval_progress/export_excell'" @click="exportExcel" type="button" class="btn btn-outline-primary waves-effect waves-light" data-toggle="modal" data-target="#Modal_Import">
                                                        Cetak Excel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-4 mb-1">
                                            <div class="input-group form-search-rounded">
                                                <input v-model="payload.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari">
                                                <span class="input-group-append">
                                                    <div class="input-group-text bg-transparent">
                                                        <i class="fa fa-search"></i>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <el-table v-if="!loadStateList" empty-text="Tidak ada data" stripe :data="lists" style="width: 100%" class="table-ipm-theme">
                                                <el-table-column
                                                    width="160"
                                                    fixed
                                                    >
                                                    <template #header>
                                                        <span class="text-primary text-center d-block"><strong>No</strong></span>
                                                    </template>
                                                    <template slot-scope="e">
                                                        <a style="display: block; text-align: center; cursor: normal">{{ e.row.number }}</a>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    width="365"
                                                    fixed
                                                    >
                                                    <template #header>
                                                        <span style="display: inline" class="text-primary"><strong>Judul Kontrak</strong></span>
                                                    </template>
                                                    <template slot-scope="e">
                                                        <router-link :to="'/kontrak/'+e.row.id_kontrak+'/detail-progress/'+ e.row.id" style="display: block;">{{ e.row.judul_kontrak }}</router-link>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    width="160"
                                                    >
                                                    <template #header>
                                                        <span style="display: inline" class="text-primary"><strong>Periode Progress</strong></span>
                                                    </template>
                                                    <template slot-scope="e">
                                                        <a style="display: block; color: black !important;" class="text-capitalize">{{ e.row.name }}</a>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column
                                                    width="160"
                                                    >
                                                    <template #header>
                                                        <span style="display: inline" class="text-primary"><strong>Tipe Progress</strong></span>
                                                    </template>
                                                    <template>
                                                        <a style="display: block; color: black !important;">Mingguan</a>
                                                    </template>
                                                </el-table-column>


                                                <el-table-column
                                                    width="160"
                                                    >
                                                    <template #header>
                                                        <span style="display: inline" class="text-primary"><strong>Tanggal</strong></span>
                                                    </template>
                                                    <template slot-scope="e">
                                                        <a style="display: block; color: black !important;">{{ moment(e.row.date).format('DD MMMM YYYY')  }}</a>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    width="200"
                                                    >
                                                    <template #header>
                                                        <span style="display: inline" class="text-primary"><strong>Status</strong></span>
                                                    </template>
                                                    <template slot-scope="e">
                                                        <!-- <a style="display: block; color: black !important;">
                                                            <span v-html="e.row.status"></span>
                                                        </a> -->
                                                        <a v-if="e.row.col_status == 'Approved'">
                                                            <span class="statusbg approve">Approved</span>
                                                        </a>
                                                        <a v-if="e.row.col_status == 'Waiting For Approval'">
                                                            <span class="statusbg waiting">Waiting For Approval</span>
                                                        </a>

                                                        <a v-if="e.row.col_status == 'Not Approved'">
                                                            <span class="statusbg not_approve">Not Approved</span>
                                                        </a>

                                                        <a v-if="e.row.col_status == 'Return To Conceptor'">
                                                            <span class="statusbg conceptor">Return To Conceptor</span>
                                                        </a>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column
                                                    width="160"
                                                    >
                                                    <template #header>
                                                        <span style="display: inline" class="text-primary"><strong>Aksi</strong></span>
                                                    </template>
                                                    <template slot-scope="e">
                                                        <div class="btn-action-block">
                                                            <router-link v-can="'approval_progress/detail'" :to="'/kontrak/'+e.row.id_kontrak+'/detail-progress/'+ e.row.id" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                                                                <i class="fa fa-eye"></i>
                                                            </router-link>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <div class="mx-auto text-center" style="width:100%" v-else>
                                                <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="modal fade text-left" id="Modal_filter_progress" aye="mdaman" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title">Filter</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                   <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(loadList)">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col col-md-12">
                                    <ValidationProvider name="Status" rules="" v-slot="{ errors }">
                                        <div class="form-group">
                                            <label for="">Status</label>
                                            <select class="form-control" v-model="payload.status">
                                                <option value=1>Approved</option>
                                                <option value=0>Waiting for approval</option>
                                                <option value=-1>Not Approved</option>
                                            </select>
                                            <small>{{ errors[0] }}</small>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                    
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Tanggal Mulai" :rules="payload.end_date !== null ? 'required' : ''" v-slot="{ errors }">
                                            <label for="">Tanggal Mulai</label>
                                            <date-picker
                                                format="DD/MM/YYYY"
                                                type="date"
                                                v-model="payload.start_date"
                                                placeholder="Tanggal Mulai"
                                                input-class="form-control"
                                                value-type="YYYY-MM-DD"
                                                style="width: 100%;"
                                                >
                                            </date-picker>
                                            <small>{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <ValidationProvider name="Tanggal Berakhir" :rules="payload.start_date !== null ? 'required' : ''" v-slot="{ errors }">
                                            <label for="">Tanggal Berakhir</label>
                                            <date-picker
                                                format="DD/MM/YYYY"
                                                type="date"
                                                v-model="payload.end_date"
                                                placeholder="Tanggal Mulai"
                                                input-class="form-control"
                                                :disabled-date="notAfterToday"
                                                value-type="YYYY-MM-DD"
                                                style="width: 100%;"
                                                >
                                            </date-picker>
                                            <small>{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button type="reset" @click="resetFilter()" class="btn btn-outline-primary" data-dismiss="modal">Reset</button>
                            <button type="submit" class="btn btn-primary">
                                <span>Filter</span>
                            </button>
                        </div>
                    </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ld from 'lodash'
import moment from 'moment'
export default {
    name: 'ApprovalProgressIndex',
    data () {
        return {
            role_id: localStorage.getItem('role_id'),
            lists: [],
            loadStateList: true,
            moment : moment,
            payload: {
                judul_kontrak: null,
                name: null,
                search: null,
                progress_type: null,
                start_date: null,
                end_date: null,
                status: null,
            }
        }
    },
    watch: {
        'payload.search' : ld.debounce (function (val) {
            if (val === '') {
                this.payload.search = null
            }
            this.loadList ()
        }, 300)
    },
    mounted () {
        this.loadList ()
    },
    methods : {
        notAfterToday(date) {
            let d = this.payload.start_date;
            if (d) {
                let res = d.split("-");
                return date < new Date(res[res.length - 3],res[res.length - 2] - 1,res[res.length - 1],0,0,0,0)
            } else {
                return date < new Date(new Date().setHours(0, 0, 0, 0) - 1);
            }
        },
        resetFilter()
        {
            this.payload.status = null,
            this.payload.start_date = null,
            this.payload.end_date = null,
            this.loadList()
        },
        async loadList () {
            this.loadStateList = true
            try {
                const datas = (await this.axios.get('/v2/kontrak/curva-s', { params :this.payload })).data
                this.lists = datas.data.map((e, i) => {
                    e.number = i + 1
                    return e
                })
            } catch (error) {
                console.log(error)
            } finally { 
                this.loadStateList = false
                window.$('#Modal_filter_progress').modal('hide')
            }
        },
        async exportExcel () {
            try {
                const datas = (await this.axios.get('/v2/kontrak/curva-s/export', { responseType: 'blob' })).data
                const url = window.URL.createObjectURL(new Blob([datas]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'approval-progress-list.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style>

</style>