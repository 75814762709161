import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import VueAxios from 'vue-axios'
import validate from './plugins/validate.js'
import VModal from 'vue-js-modal'
import { ValidationObserver } from 'vee-validate'
import { localize } from 'vee-validate'
import id from 'vee-validate/dist/locale/id.json'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'
import VueToast from 'vue-toast-notification'
import VueCurrencyFilter from 'vue-currency-filter'
import VueFormWizard from 'vue-form-wizard'
import Datepicker from 'vuejs-datepicker'
import vue2Dropzone from 'vue2-dropzone'
import DatePicker from 'vue2-datepicker'
import VueQuillEditor from 'vue-quill-editor'
import VueQrcode from 'vue-qrcode'

import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);

import 'vue2-datepicker/index.css'
// import './assets/css/tailwind.css'
import 'dropzone/dist/dropzone.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL

// import Echo from 'laravel-echo'
// window.io = require('socket.io-client')
// export var echo_instance = new Echo({
//   broadcaster: "socket.io",
//   host: 'https://mon-api.demoapp.xyz:6003',
//   auth: {headers: {Authorization: "Bearer " + localStorage.getItem('token')}}
// })
// Vue.prototype.$echo = echo_instance

// import ModalV2 from './components/ModalV2.vue'
import moment from 'moment'
// Vue.component('ModalV2', ModalV2)
Vue.use(ElementUI)
Vue.use(VueAxios, axios)
Vue.use(VModal)

Vue.use(VueSweetalert2)
Vue.use(VueToast, {
  position: 'top'
});
Vue.use(VueFormWizard)
Vue.use(VueQuillEditor)

Vue.use(VueCurrencyFilter,[
	{
		name: 'numFormat',
		symbol: '',
		thousandsSeparator: '.',
		fractionCount: 0,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
		symbol: 'Rp',
		thousandsSeparator: '.',
		fractionCount: 2,
		fractionSeparator: ',',
		symbolPosition: 'front',
		symbolSpacing: true
	},
	{
        name: 'globalCurrency',
        symbol: '',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true
    }
]
)

Vue.component('DatePicker', DatePicker)
Vue.component('Datepicker', Datepicker)
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver)
localize('id',id);
Vue.component('multiselect', Multiselect)
Vue.component('vueDropzone', vue2Dropzone)
Vue.component('vue-qrcode', VueQrcode)

Vue.config.productionTip = false

Vue.directive('can', {
	inserted(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (store.state.permissions.indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = 'inline-block';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (store.state.permissions.indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = 'inline-block';
			}
		}
	},
	update(el, binding) {
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (store.state.permissions.indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = 'inline-block';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (store.state.permissions.indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = 'inline-block';
			}
		}
	},
	bind(el, binding) {				
		let b = binding.value.toString().split(',');
		if(b.length > 1){
			let isShow = 0
			b.forEach(elm => {				
				if (store.state.permissions.indexOf(elm) != -1 ) {					
					isShow = 1
				}
			});
			if (isShow == 1){
				el.style.display = 'inline-block';
			} else {
				el.style.cssText = 'display:none !important';
			}
		} else {
			if (store.state.permissions.indexOf(binding.value) == -1 ) {
				el.style.cssText = 'display:none !important';
			}
			else {
				el.style.display = 'inline-block';
			}
		}
	}
});

Vue.filter('terbilang', function (value) {
	function terbilang(a){if(a<12)return["","satu","dua","tiga","empat","lima","enam","tujuh","delapan","sembilan","sepuluh","sebelas"][a];if(a<20)return terbilang(a-10)+" belas";if(a<100)return terbilang(Math.floor(parseInt(a)/10))+" puluh "+terbilang(parseInt(a)%10);if(a<200)return"seratus "+terbilang(parseInt(a)-100);if(a<1e3)return terbilang(Math.floor(parseInt(a)/100))+" ratus "+terbilang(parseInt(a)%100);if(a<2e3)return"seribu "+terbilang(parseInt(a)-1e3);if(a<1e6)return terbilang(Math.floor(parseInt(a)/1e3))+" ribu "+terbilang(parseInt(a)%1e3);else if(a<1e9)return terbilang(Math.floor(parseInt(a)/1e6))+" juta "+terbilang(parseInt(a)%1e6);else if(a<1e12)return terbilang(Math.floor(parseInt(a)/1e9))+" milyar "+terbilang(parseInt(a)%1e9);else if(a<1e15)return terbilang(Math.floor(parseInt(a)/1e12))+" trilyun "+terbilang(parseInt(a)%1e12)}
	return terbilang(value);
});
Vue.filter('dateParse', function (value) {
	return moment(value).format('D MMMM YYYY')
})

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
  	if ( localStorage.getItem('token') ) {
  		this.$store.dispatch('tryAutoLogin')
  		// this.$router.push('/')
  	} else {
  		this.$router.push('/login')
  	}
  }
}).$mount('#app')
