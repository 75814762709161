<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
					<button v-can="'bidang/create'" type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahBidang">
		                <i class="fa fa-plus fa-l" aria-hidden="true"></i> Tambah Bidang
		            </button>

                    <button v-can="'bidang/create'" type="button" class="btn btn-primary" style="margin-left: 4px;" data-toggle="modal" data-target="#Modal_Import">
                        <i class="fa fa-cloud-download fa-l" aria-hidden="true"></i> Import Bidang
                    </button>
				</div>
			</template>

			<template v-slot:search>
				<div class="input-group form-search-rounded">
                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari bidang disini..">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                        </div>
                    </span>
                </div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th @click="sortBy('name')">Nama 
                            <i class="fa sort" :class="currentSort == 'name' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th @click="sortBy('status')">Status
                            <i class="fa sort" :class="currentSort == 'status' ? currentSortDir == 'asc' ? 'fa-sort-down' : currentSortDir == 'desc' ? 'fa-sort-up' : 'fa-sort' : 'fa-sort'"></i>
                        </th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button v-can="'bidang/detail'" type="button" data-toggle="modal" data-target="#Modal_DataUnit" @click="detail = data" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                        <i class="fa fa-eye"></i>
                    </button>
                    <router-link v-can="'bidang/update'" :to="'bidang/edit/' + data.id" type="button" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                        <i class="fa fa-pencil"></i>
                    </router-link>
                    <button v-can="'bidang/delete'" type="button" @click="destroy(data)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>

        <!-- Modal -->
        <div class="modal fade text-left" id="Modal_TambahBidang" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Tambah Bidang</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver v-slot="{ invalid }" ref="observer">
                        <div class="modal-body">
                            <ValidationProvider name="Unit" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Unit</label>
                                    <select v-model="model.unit" class="custom-select form-control">
                                        <option value="" selected>Pilih Unit</option>
                                        <option value="1">UIP</option>
                                        <option value="2">UPP</option>
                                    </select>
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nama Bidang</label>
                                    <input type="text" v-model="model.name" class="form-control" placeholder="Masukan nama bidang" aria-describedby="helpId" required="">
                                </div>
                            </ValidationProvider>

                            <ValidationProvider name="model.status" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Status</label>
                                    <div class="custom-control custom-switch custom-control-inline mt-1">
                                        <input type="checkbox" v-model="model.status" class="custom-control-input" id="customSwitch1">
                                        <label class="custom-control-label" for="customSwitch1">
                                        </label>
                                        <span class="switch-label">{{ model.status ? 'Aktif' : 'Tidak Aktif' }}</span>
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="save()" class="btn btn-primary" data-dismiss="modal">Tambah Bidang</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>

        <ModalDetail v-if="detail != null" :detail="detail"/>
        <ModalImport
            title="Bidang"
            url="bidang"
            @success="getList()"/>
	</section>
</template>

<script>
import List from '@/components/List.vue'
import ModalDetail from './ModalDetail.vue'
import ModalImport from '@/components/ModalImport.vue'

export default {
	name: 'BidangIndex',
	components: {
		List,
        ModalDetail,
        ModalImport
	},
	data() {
		return {
			table: {
				title: "Bidang",
				breadcumb: "Master Data",
                dataTable: {
                    lists: [],
                    columns: ['name', 'status_text']
                }
			},
            search: null,
            detail: [],
            model: {
                unit: '',
                status: false,
                name: ''
            },
            currentSort: 'id',
            currentSortDir: 'desc'
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/bidang?key=' + _.search + '&page=' + page + '&orderby=' + _.currentSort + '&sort=' + _.currentSortDir)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.axios.post('/bidang/store', _.model)
                .then(resp => {
                    if (resp.status) {
                        _.getList()
                        _.$toast.success('Data Berhasil Disimpan')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Disimpan')
                    }
                    _.model = {
                        status: false,
                        name: null
                    }
                    _.$refs.observer.reset();
                })
                .catch(err=>{
                    console.log(err.response)
                    _.model = {
                        status: false,
                        name: null
                    }
                    _.$refs.observer.reset();
                    _.$toast.warning('Data Bidang Sudah Digunakan')
                })
        },
        destroy(x) {
            let _ = this
            if ( parseInt(x.users_count) == 0 ) {
                this.$swal.fire({
                    title: 'Hapus Data Ini?',
                    text: "Anda mungkin tidak akan mengembalikannya lagi!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#44A8BD',
                    cancelButtonColor: '#D95E6A',
                    confirmButtonText: 'Ya, Hapus!',
                    cancelButtonText: 'Batal'
                    }).then((result) => {
                        if (result.value) {
                            _.axios.delete('/bidang/delete', { data: { id: x.id }})
                                .then(resp => {
                                    if (resp.status) {
                                        _.getList()
                                        this.$swal.fire({
                                            title: 'Terhapus!',
                                            text: "Data berhasil dihapus",
                                            icon: 'success',
                                            confirmButtonColor: '#37BA71',
                                            confirmButtonText: 'OK'
                                        })
                                    }else{
                                        console.log("Failed")
                                    }
                                })
                                .catch(err=>{
                                    console.log(err.response)
                                })                        
                        }
                })
            } else {
                this.$swal.fire({
                    title: 'Tidak Dapat Menghapus',
                    text: "Data tidak dapat dihapus karena dipakai oleh data lain",
                    icon: 'warning',
                    cancelButtonColor: '#D95E6A',
                    cancelButtonText: 'OK'
                    })
            }
        },
        sortBy(x) {
            if (x === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortDir = 'asc'
            }
            this.currentSort = x;
            this.getList()
        }
    },
    watch: {
        search: function() {
            this.getList()
        }
    }
}
</script>