<template>
	<div>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ table.title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in table.breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col-12 col-sm-6 mb-1">
                                            <div class="btn-responsive">                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="w-long" width="85%">Akses</th>
                                                    <th>Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data, key) in modules" :key="key">
                                                    <td v-for="(column, kcolumn) in table.dataTable.columns" :key="kcolumn" v-html="data[column]"></td>
                                                    <td>
                                                        <div class="btn-action-block">
                                                            <router-link :to="'akses/' + data.link" class="btn btn-icon-small btn-warning-trans waves-effect waves-light">
                                                                <i class="fa fa-pencil"></i>
                                                            </router-link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>         
                                        </table>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>

export default {
	name: 'UserIndex',
	data() {
		return {
			table: {
				title: "Daftar Akses",
				breadcumb: [
                    {
                        name: 'User & Role Management', 
                        link: '/role',
                        active: false
                    },
                    {
                        name: localStorage.getItem('type'),
                        link: '/role',
                        active: false
                    },    
                    {
                        name: 'Akses', 
                        link: '',
                        active: true
                    }                
                ],
                dataTable: {
                    lists: {
                        data: [
                            { 
                                name: 'Home',
                                link: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'home' : 'dashboard'
                            },
                            { 
                                name: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'CCTV' : 'Monitoring',
                                link: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'cctv' : 'monitoring'
                            },
                            { 
                                name: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'Live Monitoring' : 'Setting',
                                link: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'live_monitoring' : 'setting'
                            },
                            { 
                                name: 'Laporan',
                                link: 'laporan'
                            },
                            //phase 2 
                            { 
                                name: 'Monitoring Kontrak MTU',
                                link: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'kontrak-mtu' : 'kontrak-mtu'
                            },

                            { 
                                name: 'Approval Progress',
                                link: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'approval_progress' : 'approval_progress'
                            },

                            { 
                                name: 'Upload AI & AKI',
                                link: localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor' ? 'upload_aki' : 'upload_aki'
                            },
                        ]
                    },
                    columns: ['name']
                }
			},
            role_id: localStorage.getItem('role_id')
		}
	},
    computed: {
        modules() {
            var accesses = [];
            if (localStorage.getItem('type') == 'pengawas' || localStorage.getItem('type') == 'kontraktor') {
                accesses = [
                    { name: 'Home', link: 'home' },
                    { name: 'CCTV', link: 'cctv' },
                    { name: 'Live Monitoring', link: 'live_monitoring' },
                    { name: 'Laporan', link: 'laporan' },
                    { name: 'Monitoring Kontrak MTU', link: 'kontrak-mtu' },
                    { name: 'Approval Progress', link: 'approval_progress' },
                    { name: 'Upload AI & AKI', link: 'upload_aki' },
                    { name: 'Setting', link: 'setting' }, // Issue "Akun "Pengawas" muncul modul role management. Hal ini disebabkan belum adanya item "setting" pada pengaturan role pengawas."
                ];
            } else {
                accesses = [
                    { name: 'Home', link: 'dashboard' },
                    { name: 'Monitoring', link: 'monitoring' },
                    { name: 'Setting', link: 'setting' },
                    { name: 'Laporan', link: 'laporan' },
                    { name: 'Monitoring Kontrak MTU', link: 'kontrak-mtu' },
                    { name: 'Approval Progress', link: 'approval_progress' },
                    { name: 'Upload AI & AKI', link: 'upload_aki' },
                ];
            }
            return accesses;
        }
    }
}
</script>
