<template>
	<div>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }} </h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" v-if="breadcumb">
                                <a href="#">{{ breadcumb }}</a>
                            </li>

                            <li class="breadcrumb-item active">{{ title }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12 mb-2">
                        <slot name="warning" />
                    </div>
                    <div class="col-12">
                        <slot name="leaderboard" />
                    </div>
                    <div class="col-12">
                        <div class="card">
                            <div class="card-content">
                                <div class="card-body card-dashboard">
                                    <div class="row align-items-center justify-content-between">
                                        <div class="col mb-1">
                                            <div class="btn-responsive">
                                                <slot name="button"></slot>
                                            </div>
                                        </div>

                                        <div class="col-12 col-sm-4 mb-1">
                                            <slot name="search"></slot>
                                        </div>
                                    </div>

                                    <div class="table-responsive">
                                        <table class="table mb-0">
                                            <slot name="columnHeader"></slot>
                                            <tbody>
                                                <tr v-for="(data, idx) in dataTable.lists" :key="idx">
                                                    <td>{{ idx + 1 }}</td>
                                                    <td v-for="(column, kcolumn) in dataTable.columns" :key="kcolumn">                                                        
                                                        <span v-if="column == 'no_kontrak'">
                                                            <router-link :to="'kontrak/' + data.id" v-html="data[column]"></router-link>
                                                        </span>
                                                        <span v-else-if="column == 'no_kontrak_lap'">
                                                            <router-link :to="'kontrak/' + data.id_kontrak" v-html="data[column]"></router-link>
                                                        </span>
                                                        <span v-else-if="column == 'nilai_kontrak'">
                                                            {{data[column] | currency}}
                                                        </span>
                                                        <span v-else-if="column == 'idr'">
                                                            {{data[column] | currency}}
                                                        </span>
                                                        <span v-else-if="column == 'nilai'">
                                                            {{data[column] | currency}}
                                                        </span>
                                                        <span v-else v-html="data[column]"></span>
                                                    </td>
                                                    <td>
                                                        <slot name="action" v-bind:data="data"></slot>
                                                    </td>
                                                </tr>
                                            </tbody>           
                                        </table>
                                        <slot name="loadingState"></slot>
                                        <div class="col text-center mt-1">
                                            <p v-if="dataTable.lists.length == 0">Tidak ada data.</p>
                                        </div>                                                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
export default {
	name: 'ListMonitoringProject',
    props: ['title', 'breadcumb', 'dataTable',  'isBreadcrumb'],
    data() {
        return {
            role_id: localStorage.getItem('role_id'),
        }
    },
}
</script>