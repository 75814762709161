<template>
  <div class="col-12 mt-2 mb-2" v-can="'kontrak_mtu/detail/resume/read'">
    <h4 class="text-black"><strong>Resume</strong></h4>
    <div class="table-responsive tableFixHead" style="max-height: 500px">
      <table class="table mb-0">
        <thead>
          <tr>
            <th>No.</th>
            <th>Lokasi</th>
            <th>No. PRK</th>
            <th>Nilai Kontrak Exclude PPN</th>
            <th>Nilai Kontrak Include PPN</th>
            <th>Ready At Site</th>
            <th>Status</th>
            <th>Progress Bayar Per Lokasi</th>
            <th>Material</th>
            <th>Jumlah</th>
            <th>Satuan</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data) in finalResumes" :key="data.id">
            <td v-if="!data.relokasi" class="text-center">
              {{ !data.relokasi ? getNumber(data) : '' }}
            </td>
            <td v-if="data.relokasi"></td>
            <td v-if="!data.relokasi">
              <span style="font-size: 15px">{{ data.name }}</span>
            </td>
            <td v-if="!data.relokasi">{{ data.no_prk }}</td>
            <td v-else colspan="4">
              <div class="row align-items-center pl-1">
                <img src="/assets/img/arrow-turn-down-right.png" alt="" width="15" style="height: fit-content;">
                <div class="col">
                  <p class="mb-0">{{ data.name }}</p>
                  <p class="mb-0 text-primary"><small>Relokasi dari {{ data.relokasi }}</small></p>
                </div>
              </div>
            </td>
            <td v-if="!data.relokasi" class="text-nowrap">{{ data.col_nilai_equivalent_exlude_ppn | currency }}</td>
            <td v-if="!data.relokasi" class="text-nowrap">{{ data.col_nilai_equivalent_include_ppn | currency }}</td>
            <td class="text-nowrap">{{ data.col_ready_at }}</td>
            <td class="text-nowrap">
              <multiselect 
                :options="statusOptions" 
                :multiple="false" 
                :close-on-select="true" 
                :clear-on-select="false" 
                v-model="data.status"
                :preserve-search="true" 
                placeholder="Pilih Progress" 
                label="name" 
                track-by="name"
                :preselect-first="false"
                :searchable="true"
                @input="onInput(data)"
                :allow-empty="false"
                deselectLabel="Remove"
                selectLabel="Select">
                <template 
                    slot="selection" 
                    slot-scope="{ values, isOpen }">
                    <span class="multiselect__single" v-if="values.length" v-show="!isOpen">
                        {{ values.map(x => x.name).join(', ') }}
                    </span>
                </template>
                <template v-slot:noResult>
                    Tidak ada data.
                </template>
                <template v-slot:noOptions>
                    Tidak ada data.
                </template>                                                  
            </multiselect>
            </td>
            <td>{{ data.relokasi ? '-' : `${data.progress_bayar}%` }}</td>
            <td>
              <p v-for="(material, idx) in data.material" :key="idx" class="mb-0">• {{ material }}</p>
            </td>
            <td>
              <p v-for="(jumlah, idx) in data.jumlah" :key="idx" class="mb-0">• {{ jumlah }}</p>
            </td>
            <td>
              <p v-for="(satuan, idx) in data.satuan" :key="idx" class="mb-0">• {{ satuan }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    kontrakMTU: {
      type: Object,
      default() {
        return {
          jenis_pekerjaan: null,
        }
      },
    },
    statusOptions: {
      type: Array,
      default: null,
    }
  },
  data() {
    return {
      resumes: [],
    }
  },
  computed: {
    tableData: function() {
      return this.resumes.map((e) => {
        e.status = this.getStatusV2(e.progress_mtu_status_id);

        // if (e.detail.jenis_pekerjaan == 'AIS') {
          e.material = this.isJsonString(e.konfigurasi) ? JSON.parse(e.konfigurasi) : null;
          e.jumlah = this.isJsonString(e.jumlah) ? JSON.parse(e.jumlah) : null;
          e.satuan = this.isJsonString(e.satuan) ? JSON.parse(e.satuan) : null;
        // }

        return e;
      })
    },
    parentResumes: function() {
      return this.tableData
        .filter(x => !x.parent)
        .map((x) => {
          x.childrens = this.tableData
            .filter(z => z.parent === x.id)
          return x;
        });
    },
    finalResumes: function() {
      const resumeData = [];
      for (let i = 0; i < this.parentResumes.length; i++) {
        const resume = this.parentResumes[i];
        resumeData.push(resume);
        for (let z = 0; z < resume.childrens.length; z++) {
          const children = resume.childrens[z];
          resumeData.push(children);
        }
      }
      return resumeData;
    }
  },
  methods: {
    async getResume() {
      this.$emit('loadStateResume', false);
      await this.axios.get(`/v2/kontrak-mtu/resume?id_kontrak_mtu=${this.kontrakMTU.id}`)
        .then(res => this.resumes = res.data.data)
        .catch(err => this.$toast.warning(err?.response?.data?.message??err.message))
        .finally(() => this.$emit('loadStateResume', false));
    },
    async onInput(kontrakMTULokasi) {
      const data = {
        id: kontrakMTULokasi.id,
        progress_mtu_status_id: kontrakMTULokasi.status.id
      };

      await this.axios.post('/v2/kontrak-mtu/resume/status', data)
        .then(() => this.$emit('onResumeStatusChange'))
        .catch(err => this.$toast.warning(err?.response?.data?.message??err.message));
    },
    getStatusV2(progress_mtu_status_id) {
      return this.statusOptions
        .filter(x => x.id === progress_mtu_status_id);
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    getNumber(data) {
      let numbering = 1;
      for (let i = 0; i < this.finalResumes.length; i++) {
        const resume = this.finalResumes[i];
        if (resume.id === data.id && resume.childrens) {
          return numbering;
        } else if (resume.childrens) {
          numbering++;
        }
      }
      return numbering;
    }
  },
  async mounted() {
    this.$root.$on("getResume", () => this.getResume());
  },
  beforeDestroy(){
    this.$root.$off("getResume");
  },
}
</script>