<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
					<button v-can="'anggaran_status/create'" type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahAnggaranStatus">
                    <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#Modal_TambahAnggaranStatus"> -->
		                <i class="fa fa-plus fa-l" aria-hidden="true"></i> Tambah Anggaran Status
		            </button>
				</div>
			</template>

            <template v-slot:search>
				<div class="input-group form-search-rounded">
                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari status anggaran disini..">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                        </div>
                    </span>
                </div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Nama</th>
                        <th>Warna</th>
                        <th width="10%">Aksi</th>
                    </tr>
                </thead>
			</template>

			<template v-slot:action="{ data }">
                <div class="btn-action-block">
                    <button v-can="'anggaran_status/detail'" type="button" data-toggle="modal" data-target="#Modal_DataAnggaranStatus" @click="detail = JSON.parse(JSON.stringify(data))" class="btn btn-icon-small btn-success-trans waves-effect waves-light">
                    <!-- <button type="button" data-toggle="modal" data-target="#Modal_DataAnggaranStatus" @click="detail = data" class="btn btn-icon-small btn-success-trans waves-effect waves-light"> -->
                        <i class="fa fa-eye"></i>
                    </button>
                    <button v-can="'anggaran_status/update'" type="button" data-toggle="modal" data-target="#Modal_EditAnggaranStatus" @click="detail = JSON.parse(JSON.stringify(data))" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;">
                    <!-- <button type="button" data-toggle="modal" data-target="#Modal_EditAnggaranStatus" @click="detail = data" class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left: 2px;"> -->
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button v-can="'anggaran_status/delete'" type="button" @click="destroy(data)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;">
                    <!-- <button type="button" @click="destroy(data)" class="btn btn-icon-small btn-danger-trans waves-effect waves-light" id="confirm-text" style="margin-left: 2px;"> -->
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </template>
		</List>

        <!-- Modal -->
        <div class="modal fade text-left" id="Modal_TambahAnggaranStatus" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Tambah Anggaran Status</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver v-slot="{ invalid }" ref="observer">
                        <div class="modal-body">
                            <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nama Status</label>
                                    <input type="text" v-model="model.name" class="form-control" placeholder="Masukan nama status" aria-describedby="helpId" required="">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="model.color" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Warna Status</label>
                                    <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                        <input type="text" class="form-control border-right-0 bg-white" v-model="model.color" placeholder="Masukkan atau pilih warna status">
                                        <span class="input-group-append pointer">
                                            <div class="input-group-text pl-1 pr-1 pt-0 pb-0">
                                                <InputColorPicker v-model="model.color"/>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="save()" class="btn btn-primary" data-dismiss="modal">Tambah Status</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left" id="Modal_EditAnggaranStatus" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel160" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Edit Anggaran Status</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <ValidationObserver v-slot="{ invalid }" ref="observerEdit">
                        <div class="modal-body">
                            <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Nama Status</label>
                                    <input type="text" v-model="detail.name" class="form-control" placeholder="Masukan nama status" aria-describedby="helpId" required="">
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="model.color" v-slot="{ errors }" rules="required">
                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                    <label for="">Warna Status</label>
                                    <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                        <input type="text" class="form-control border-right-0 bg-white" v-model="detail.color" placeholder="Masukkan atau pilih warna status">
                                        <span class="input-group-append pointer">
                                            <div class="input-group-text pl-1 pr-1 pt-0 pb-0">
                                                <InputColorPicker v-model="detail.color"/>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button type="button" :disabled="invalid" @click="update()" class="btn btn-primary" data-dismiss="modal">Edit Status</button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <div class="modal fade text-left" id="Modal_DataAnggaranStatus" tabindex="-1" role="dialog"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Data Anggaran Status</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">Nama Status</label>
                            <input type="text" name="" id="" class="form-control" :value="detail.name" readonly>
                        </div>

                        <div class="form-group">
                            <label for="">Warna Status</label>
                            <div class="form-group input-group mb-0">
                                <input type="text" class="form-control border-right-0" v-model="detail.color" readonly placeholder="Masukkan atau pilih warna status">
                                <span class="input-group-append pointer">
                                    <div class="input-group-text pl-1 pr-1 pt-0 pb-0">
                                        <InputColorPicker v-model="detail.color" disabled/>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import List from '@/components/List.vue'
import InputColorPicker from 'vue-native-color-picker'

export default {
	components: {
		List,
        InputColorPicker,
	},
	data() {
		return {
			table: {
				title: "Anggaran Status",
				breadcumb: "Master Data",
                dataTable: {
                    lists: [],
                    columns: ['name', 'color']
                }
			},
            search: null,
            detail: {
                color: null,
                name: null
            },
            model: {
                color: null,
                name: null
            },
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    beforeDestroy(){
        this.$root.$off("paginate");
    },
    methods: {
        getList(page = 1) {
            let _ = this
            _.axios.get('/v2/anggaran-status?key=' + _.search + '&page=' + page + '&per_page=10')
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.axios.post('/v2/anggaran-status', _.model)
                .then(resp => {
                    if (resp.status) {
                        _.getList()
                        _.$toast.success('Data Berhasil Disimpan')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Disimpan')
                    }
                    _.model = {
                        color: null,
                        name: null
                    }
                    _.$refs.observer.reset();
                })
                .catch(err=>{
                    console.log(err.response)
                    _.model = {
                        color: null,
                        name: null
                    }
                    _.$refs.observer.reset();
                    _.$toast.warning('Data Anggaran Status Sudah Digunakan')
                })
        },
        update() {
            let _ = this
            _.axios.put('/v2/anggaran-status/' + _.detail.id, _.detail)
                .then(resp => {
                    if (resp.status) {
                        _.getList()
                        _.$toast.success('Data Berhasil Diubah')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Diubah')
                    }
                    _.$refs.observerEdit.reset();
                })
                .catch(err=>{
                    console.log(err.response)
                    _.$refs.observerEdit.reset();
                    _.$toast.warning(err.response.data.message)
                })
        },
        destroy(x) {
            let _ = this
            this.$swal.fire({
                title: 'Hapus Data Ini?',
                text: "Anda mungkin tidak akan mengembalikannya lagi!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#44A8BD',
                cancelButtonColor: '#D95E6A',
                confirmButtonText: 'Ya, Hapus!',
                cancelButtonText: 'Batal'
                }).then((result) => {
                    if (result.value) {
                        _.axios.delete('/v2/anggaran-status/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    this.$swal.fire({
                                        title: 'Terhapus!',
                                        text: "Data berhasil dihapus",
                                        icon: 'success',
                                        confirmButtonColor: '#37BA71',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                                _.$toast.warning(err.response.data.message)
                            })                        
                    }
            })
            
        },
    },
    watch: {
        search: function() {
            this.getList()
        }
    }
}
</script>