<template>
	<div class="row">
		<div class="col-sm-12 col-md-5"></div>
	    <div class="col-sm-12 col-md-7 mt-1">
	        <div class="dataTables_paginate paging_simple_numbers pull-right">
	            <ul class="pagination">
	                <li class="paginate_button page-item previous" :class="{ disabled: data.current_page <= 1 }">
	                    <button type="button" class="page-link" @click.prevent="changePage(data.current_page - 1)">Previous</button>
	                </li>

					<li class="paginate_button page-item" v-for="(page, key) in pages" :key="key" :class="isCurrentPage(page) ? 'active' : ''">
						<button type="button" class="page-link" @click.prevent="changePage(page)">{{ page }}</button>
					</li>

	                <li class="paginate_button page-item next" :class="{ disabled: data.current_page >= data.last_page }">
	                    <button type="button" class="page-link" @click.prevent="changePage(data.current_page + 1)">Next</button>
	                </li>
	            </ul>
	        </div>
	    </div>
	</div>
</template>

<script>
export default {
	name: 'PaginateList',
	props:['data'],
	methods: {
		isCurrentPage(page){
			return this.data.current_page === page
		},
		changePage(page) {
			if (page > this.data.last_page) {
				page = this.data.last_page;
			}
			this.data.current_page = page;
			this.$root.$emit('paginate', page);
		}
	},
	computed: {
		pages() {
			let current         = this.data.current_page
			let last            = this.data.last_page
			let delta           = 2
			let left            = current - delta
			let right           = current + delta + 1
			let range           = []
			let rangeWithDots   = []  
			let l               = 0              

			for (let i = 1; i <= last; i++) {
				if (i == 1 || i == last || i >= left && i < right) {
					range.push(i);
				}
			}

			for (let i of range) {
				if (l) {
					if (i - l === 2) {
						rangeWithDots.push(l + 1);
					} else if (i - l !== 1) {
						rangeWithDots.push('...');
					}
				}
				rangeWithDots.push(i);
				l = i;
			}

			return rangeWithDots;
		}
	}
}
</script>