import { ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('alphaandnumeric', {
  validate: value => {
  	var Valid = value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])/) !== null;
    if ( !Valid ) {
    	return 'Password harus terdiri dari huruf dan angka'
    }
    return true
  }
});

extend('custom', {
  validate: value => {
  	var Valid = value > 0;
    if ( !Valid ) {
    	return 'Cuaca harus dipilih'
    }
    return true
  }
});

export default ValidationProvider