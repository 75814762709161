<template>
  <div>
    <div class="content-header">
      <div class="content-header-left mb-2">
        <div class="breadcrumbs-top">
          <h2 class="content-header-title mb-0">{{ title }}</h2>
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                  <i class="feather icon-home"></i>
                </router-link>
              </li>

              <li
                class="breadcrumb-item"
                :class="data.active == true ? 'active' : ''"
                style="text-transform: capitalize"
                v-for="(data, index) in breadcumb"
                :key="index"
              >
                <router-link :to="data.link">{{ data.name }}</router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <section id="card-actions">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title text-primary">Informasi Kontrak</h4>
          </div>
          <hr />
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Judul Kontrak</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.judul_kontrak }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Nama Pekerjaan</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.nama_pekerjaan }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">No. PRK</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.no_prk }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">No. Kontrak</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.no_kontrak }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Tgl. Awal Kontrak</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.col_tgl_awal }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Tgl. Akhir Kontrak</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <div
                      class="d-flex flex-wrap justify-content-between align-items-center"
                    >
                      <p class="text-black mb-1">
                        {{ model.col_tgl_akhir }}
                        {{
                          model.col_amandemen_waktu == null
                            ? ""
                            : "(Amandemen ke-" + model.col_amandemen_waktu + ")"
                        }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Durasi Kontrak</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <!-- <p class="text-black" v-if="model.tgl_akhir && model.tgl_spmk">{{ getAge(model.tgl_akhir, model.tgl_spmk) }}</p> -->
                    <p class="text-black" v-if="model.tgl_akhir && model.tgl_spmk">{{ model.col_duration }} Hari</p>
                    <p class="text-black" v-else>Belum Efektif</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Nilai Kontrak Original</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <div
                      class="d-flex flex-wrap justify-content-between align-items-center"
                    >
                      <p class="text-black mb-1">
                        {{ (model.original_nilai || 0) | currency }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Nilai Kontrak Terakhir</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <div
                      class="d-flex flex-wrap justify-content-between align-items-center"
                    >
                      <p class="text-black mb-1">
                        {{ (model.nilai || 0) | currency }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">SPMK</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <div
                      class="d-flex flex-wrap justify-content-between align-items-center"
                    >
                      <p class="text-black mb-1">
                        {{ model.col_tgl_spmk }} - {{ model.no_spmk }}
                      </p>
                      <button
                        type="submit"
                        class="btn btn-primary btn-sm mb-1"
                        v-can="'kontrak/detail/informasi/update_spmk'"
                        data-toggle="modal"
                        data-target="#Modal_UpdateSPMK"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Jenis Pekerjaan</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.jenis_pekerjaan.name }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Sumber Dana</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.sumber_dana }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">UPP</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.unit.name }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">GI</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.col_gi }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Lingkup</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black" v-html="model.lingkup"></p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Kapasitas</p>
                  </div>

                  <div
                    class="col-12 col-sm-7 subtitle-inline-text"
                    v-if="model.kapasitas != null"
                  >
                    <p class="text-black">
                      {{ model.kapasitas }}
                      <span v-if="model.id_pekerjaan < 5">MVA</span>
                      <span v-else>KMS</span>
                    </p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text" v-else>
                    <p class="text-black">-</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Project Code PMO</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.project_code_pmo ? model.project_code_pmo :'-' }}</p>
                  </div>
                </div>


                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Contract Code PMO</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.contact_code_pmo ? model.contact_code_pmo :'-' }}</p>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Lokasi Project</p>
                  </div>
                  <div
                    class="col-12 col-sm-7 subtitle-inline-text"
                    v-if="model.id_pekerjaan > 4"
                  >
                    <div v-if="model.id_pekerjaan <= 6" class="scrollable">
                      <p
                        v-for="(val, ix) in model.lokasi"
                        :key="ix"
                        class="text-black"
                      >
                        <b>• Tower {{ val.name }} :</b> <br />
                        {{ val.address }}
                      </p>
                    </div>
                    <div v-else-if="model.id_pekerjaan <= 8" class="scrollable">
                      <p
                        v-for="(val, ix) in model.lokasi"
                        :key="ix"
                        class="text-black"
                      >
                        <b>• JP {{ val.name }} :</b> <br />
                        {{ val.address }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-12 col-sm-7 subtitle-inline-text"
                    v-if="model.id_pekerjaan < 5"
                  >
                    <p
                      v-for="(val, ix) in model.lokasi"
                      :key="ix"
                      class="text-black"
                    >
                      {{ val.address }}
                    </p>
                  </div>
                </div>

                <div
                  v-if="model.id_pekerjaan <= 4"
                  class="w-100"
                  id="map"
                  ref="map"
                  style="height: 150px; width: 600px"
                ></div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600"><br />Kontraktor</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black"><br />{{ model.kontraktor.name }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Pengawas</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.col_penugasan }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">PIC Kontraktual</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <p class="text-black">{{ model.col_pic }}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <span
                          class="statusbg pusmanpro mr-1"
                          v-if="model.is_supervised == true"
                          >Supervised by Pusmanpro</span
                        >
                  </div>
                </div>
                <hr />

                <div class="row">
                  <div class="col-12 col-sm-5 title-inline-text">
                    <p class="text-black font-600">Progress Fisik</p>
                  </div>

                  <div class="col-12 col-sm-7 subtitle-inline-text">
                    <h5 class="text-blue font-600">
                      {{ model.col_kontrak_progress_num }}%
                    </h5>
                    <div class="progress progress-bar-primary">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :aria-valuenow="model.col_kontrak_progress_num"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{ width: model.col_kontrak_progress_num + '%' }"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col mt-1"
                    v-if="model.no_spmk != null"
                    v-can="'kontrak/detail/informasi/update_dokumen'"
                  >
                    <router-link
                      :to="'/udr/' + $route.params.id"
                      class="btn btn-primary btn-block btn-lg mb-1 waves-effect waves-light"
                    >
                      Update Dokumen
                      {{ role_id == 3 ? "Permohonan" : "Rekomendasi" }}
                    </router-link>
                  </div>
                  <div
                    class="col mt-1"
                    v-if="model.no_spmk != null"
                    v-can="'kontrak/detail/informasi/read_rekomendasi'"
                  >
                    <router-link
                      :to="'/rklist/' + $route.params.id"
                      class="btn btn-primary btn-block btn-lg mb-1 waves-effect waves-light"
                    >
                      Lihat Dokumen Rekomendasi
                    </router-link>
                  </div>
                  <div
                    class="col mt-1"
                    v-if="model.no_spmk != null"
                    v-can="'kontrak/detail/informasi/read_permohonan'"
                  >
                    <router-link
                      :to="'/pmhlist/' + $route.params.id"
                      class="btn btn-primary btn-block btn-lg mb-1 waves-effect waves-light"
                    >
                      Lihat Dokumen Permohonan
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <!-- // commented Cuase New Progress From Curva S -->
            <button v-if="model.no_spmk != null" type="#" class="btn btn-primary waves-effect waves-light btn-icon-small float-right mb-1"
                @click="modalProgress('add')" v-can="'kontrak/detail/progress/create'">
                <i class="fa fa-plus"></i>
            </button>
            <div class="table-responsive no-sort tableFixHead" v-if="model.progress.length > 0">
              <table class="table tabel-status-document w-100">
                <thead>
                  <tr>
                    <th class="w10">No.</th>
                    <th>Tanggal</th>
                    <th>Periode Progress</th>
                    <th>Tipe Progress</th>
                    <th class="text-center">Status</th>
                    <th class="text-center ">Aksi</th>
                  </tr>
                </thead>
                <tbody v-if="model.progress.length > 0">
                  <tr v-for="(data, index) in model.progress" :key="index">
                    <td>{{index + 1}}</td>
                    <td>{{data.col_created_at}}</td>
                    <td>{{data.name}}</td>
                    <td>{{data.type == 1 ? 'Harian' : 'Mingguan'}}</td>
                    <td class="text-center">
                      <div class="status waiting" v-if="data.approved_by == null">Waiting For Approval</div>
                      <div class="status not_approve" v-else-if="data.approved_by == 0">Not Approved</div>
                      <div class="status approve" v-else>Approved</div>
                    </td>
                    <td class="text-center">
                      <div class="btn-action-block">                                                
                        <button type="button" class="btn btn-primary" data-toggle="modal" style="margin-left:5px;" v-can="'kontrak/detail/progress/approve'"
                          data-target="#Modal_Approve" v-if="isShowApprove(data)" @click="progress_id = data.id">
                          Approve
                        </button>
                        <button type="button" data-toggle="modal" v-can="'kontrak/detail/progress/read'"
                          data-target="#Modal_DetailProgress" @click="progress_detail = data, detailFoto = ''"
                          class="btn btn-icon-small btn-success-trans waves-effect waves-light" style="margin-left:5px;">
                          <i class="fa fa-eye"></i>
                        </button>
                        <button type="button" v-if="data.approved_by == null || data.approved_by == 0" @click="progress_detail = data; modalProgress('edit')" v-can="'kontrak/detail/progress/update'"
                          class="btn btn-icon-small btn-warning-trans waves-effect waves-light" style="margin-left:5px;">
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button type="button" v-can="'kontrak/detail/progress/delete'"
                          class="btn btn-icon-small btn-danger-trans waves-effect waves-light" style="margin-left:5px;"
                          id="confirm-text" @click="delete_progress(data.id)"
                          v-if="data.type == '1' && role_id == '3' && (data.approved_by == null || data.approved_by == 0)">
                          <i class="fa fa-trash"></i>
                        </button>
                        <button type="button" v-can="'kontrak/detail/progress/delete'"
                          class="btn btn-icon-small btn-danger-trans waves-effect waves-light" style="margin-left:5px;"
                          id="confirm-text" @click="delete_progress(data.id)"
                          v-else-if="data.type == '2' && role_id == '1' && (data.approved_by == null || data.approved_by == 0)">
                          <i class="fa fa-trash"></i>
                        </button>
                        <!-- <button type="button" data-toggle="modal" v-can="'kontrak/detail/progress/read'"
                          class="btn btn-icon-small btn-success-trans waves-effect waves-light" style="margin-left:5px;">
                          <i class="fa fa-eye"></i>
                        </button> -->
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">Tidak ada data.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // commented Cuase New Progress From Curva S -->

            <h4 v-if="model.line_diagram.length" class="card-title mt-2 mb-1">
              Single Line Diagram
            </h4>
            <div v-if="model.line_diagram.length" class="row">
              <div class="col-12">
                <img
                  :src="model.line_diagram[0].url"
                  class="img-fluid"
                  style="max-width: 100%; height: auto"
                  alt="diagram"
                />
              </div>
            </div>
            <h4 class="card-title mt-2 mb-2">
              Progress Detail
              <!-- <button :disabled="isGenerating" v-if="model.no_spmk != null" v-can="'kontrak/detail/progress/generate'" @click="cetak()" type="button" data-toggle="modal" data-target="" class="btn btn-primary btn-block-mobile waves-effect waves-light">                                
                                <i v-if="isGenerating" class="fa fa-spinner fa-spin"></i> 
                                <span v-if="!isGenerating">Generate Progress</span>
                            </button> -->

              <div class="btn-group">
                <button
                  class="ml-1 btn btn-primary waves-effect waves-light dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="text-capitalize">{{ filter_curva_s }}</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" @click="getChartAmandemen('monthly')"
                    >Monthly</a
                  >
                  <a class="dropdown-item" @click="getChartAmandemen('weekly')"
                    >Weekly</a
                  >
                </div>
              </div>

              <div class="btn-group" v-if="amandemenHistory.length > 0">
                <button
                  class="ml-1 btn btn-primary waves-effect waves-light dropdown-toggle"
                  type="button"
                  id="dropdownMenuButtonAman"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="text-capitalize">{{
                    filter_aman_name == null
                      ? "Pilih Amandemen"
                      : filter_aman_name
                  }}</span>
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButtonAman"
                >
                  <a
                    class="dropdown-item"
                    v-for="(i, index) in amandemenHistory"
                    :key="index"
                    @click="getFilterChartAmandemen(i)"
                    >{{ i.amandemen_name }}</a
                  >
                </div>
              </div>

              <div class="btn-group" v-if="amandemenHistory.length > 0">
                <button
                  @click="resetFilterAman()"
                  type="button"
                  class="btn btn-outline-primary ml-1"
                >
                  Reset Filter
                </button>
              </div>

              <!-- Customized Link Here -->
              <button
                v-if="amandemen_chart_list"
                type="#"
                @click="
                  $router.push(
                    '/kontrak/' + $route.params.id + '/tambah-progress'
                  )
                "
                class="btn btn-primary waves-effect waves-light btn-icon-small float-right mb-1"
                v-can="'kontrak/detail/progress/create'"
              >
                <i class="fa fa-plus"></i>
              </button>
              <!-- Customized Link Here -->
            </h4>
            <div class="row mx-0 mt-2">
              <div
                class="col-12 text-center text-primary font-600 font-medium-1 py-1"
                style="background-color: #eaf5f8"
              >
                Live S Curve
              </div>
            </div>
            <div class="row mx-0 mt-0">
              <div class="col-12">
                <!-- <apexchart
                  ref="chartAmandemen"
                  type="line"
                  height="350"
                  :options="lineChartOpt"
                  :series="lineChartSeries"
                ></apexchart> -->
                <apexchart
                  id="Chart_Live_S_Curve"
                  ref="chartAmandemen"
                  type="line"
                  height="350"
                  :options="lievCurvaOPt"
                  :series="liveSeriesData"
                ></apexchart>
              </div>
            </div>

            <div class="table-responsive no-sort tableFixHead">
              <table class="table tabel-status-document w-100">
                <thead>
                  <tr>
                    <th class="w10">No.</th>
                    <th>Tanggal</th>
                    <th>Periode Progress</th>
                    <th>Tipe Progress</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Aksi</th>
                  </tr>
                </thead>
                <tbody v-if="progresCurva.length > 0">
                  <tr v-for="(data, index) in progresCurva" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        moment(data.created_at)
                          .locale("id")
                          .format("DD MMMM YYYY")
                      }}
                    </td>
                    <td class="text-capitalize">{{ data.name }}</td>
                    <td>Mingguan</td>
                    <td class="text-center">
                      <span
                        class="statusbg approve mr-1"
                        v-if="data.col_status == 'Approved'"
                        >Approved</span
                      >
                      <span
                        class="statusbg waiting mr-1"
                        v-if="data.col_status == 'Waiting For Approval'"
                        >Waiting For Approval</span
                      >

                      <span
                        class="statusbg conceptor mr-1"
                        v-if="data.col_status == 'Return To Conceptor'"
                        >Return To Conceptor</span
                      >

                      <span
                        class="statusbg not_approve mr-1"
                        v-if="data.col_status == 'Not Approved'"
                        >Not Approved</span
                      >
                    </td>
                    <td class="text-center">
                      <div class="btn-action-block">
                        <button
                          type="button"
                          data-toggle="modal"
                          v-can="'kontrak/detail/progress/read'"
                          data-target="#Modal_DetailProgress"
                          @click="
                            $router.push(
                              '/kontrak/' +
                                $route.params.id +
                                '/detail-progress/' +
                                data.id
                            )
                          "
                          class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                          style="margin-left: 5px"
                        >
                          <i class="fa fa-eye"></i>
                        </button>

                        <button
                          type="button"
                          data-toggle="modal"
                          v-can="'kontrak/detail/progress/update'"
                          v-if="(role_id == 'null' && data.col_status == 'Approved') 
                          || ( isSuperAdmin && data.col_status == 'Approved')"
                          data-target="#Modal_DetailProgress"
                          @click="
                            $router.push(
                              '/kontrak/' +
                                $route.params.id +
                                '/edit-progress/' +
                                data.id
                            )
                          "
                          class="btn btn-icon-small btn-warning-trans waves-effect ml-1 waves-light"
                          style="margin-left: 5px"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>

                        <button
                          type="button"
                          data-toggle="modal"
                          v-can="'kontrak/detail/progress/update'"
                          v-if="
                            role_id == 1 &&
                            isSuperAdmin == false &&
                            data.col_status == 'Return To Conceptor'
                          "
                          data-target="#Modal_DetailProgress"
                          @click="
                            $router.push(
                              '/kontrak/' +
                                $route.params.id +
                                '/edit-progress/' +
                                data.id
                            )
                          "
                          class="btn btn-icon-small btn-warning-trans waves-effect ml-1 waves-light"
                          style="margin-left: 5px"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>

                        <button
                          type="button"
                          data-toggle="modal"
                          v-can="'kontrak/detail/progress/update'"
                          v-if="
                            role_id == 2 &&
                            isSuperAdmin == false &&
                            data.col_status == 'Waiting For Approval'
                          "
                          data-target="#Modal_DetailProgress"
                          @click="
                            $router.push(
                              '/kontrak/' +
                                $route.params.id +
                                '/edit-progress/' +
                                data.id
                            )
                          "
                          class="btn btn-icon-small btn-warning-trans waves-effect ml-1 waves-light"
                          style="margin-left: 5px"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">Tidak ada data.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />

            <h4 class="card-title mt-2 mb-1">
              Kendala
              <button
                v-if="model.no_spmk != null"
                type="#"
                class="btn btn-primary waves-effect waves-light btn-icon-small float-right mb-1"
                @click="modalKendala('add')"
                v-can="'kontrak/detail/kendala/create'"
              >
                <i class="fa fa-plus"></i>
              </button>
            </h4>
            <div class="table-responsive no-sort tableFixHead">
              <table class="table tabel-status-document w-100">
                <thead>
                  <tr>
                    <th class="w10">No.</th>
                    <th>Tanggal</th>
                    <th>Kendala</th>
                    <th>Uraian</th>
                    <th class="text-center shrink30">Aksi</th>
                  </tr>
                </thead>
                <tbody v-if="model.kendala.length > 0">
                  <tr v-for="(data, index) in model.kendala" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.col_created_at }}</td>
                    <td>{{ data.name }}</td>
                    <td v-html="data.uraian"></td>
                    <td class="text-center">
                      <div class="btn-action-block">
                        <button
                          type="button"
                          class="btn btn-primary waves-effect waves-light"
                          v-can="'kontrak/detail/solusi/create'"
                          v-if="data.solusi == null"
                          @click="modalSolusi('add', data.id)"
                        >
                          <i class="fa fa-plus"></i> Solusi
                        </button>
                        <button
                          type="button"
                          data-toggle="modal"
                          v-can="'kontrak/detail/kendala/read'"
                          data-target="#Modal_DetailKendala"
                          @click="(kendala_detail = data), (detailFoto = '')"
                          class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                          style="margin-left: 5px"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                          v-can="'kontrak/detail/kendala/update'"
                          style="margin-left: 5px"
                          @click="
                            kendala_detail = data;
                            modalKendala('edit');
                          "
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                          v-can="'kontrak/detail/kendala/delete'"
                          style="margin-left: 5px"
                          @click="kendalaDelete(data.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">Tidak ada data.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />

            <h4 class="card-title mt-2 mb-1">Solusi</h4>
            <div class="table-responsive no-sort tableFixHead">
              <table class="table tabel-status-document w-100">
                <thead>
                  <tr>
                    <th class="w10">No.</th>
                    <th>Tanggal</th>
                    <th>Solusi</th>
                    <th>Uraian</th>
                    <th class="text-center shrink30">Aksi</th>
                  </tr>
                </thead>
                <tbody v-if="model.solusi.length > 0">
                  <tr v-for="(data, index) in model.solusi" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.col_created_at }}</td>
                    <td>{{ data.name }}</td>
                    <td v-html="data.uraian"></td>
                    <td class="text-center">
                      <div class="btn-action-block">
                        <button
                          type="button"
                          data-toggle="modal"
                          v-can="'kontrak/detail/solusi/read'"
                          data-target="#Modal_DetailSolusi"
                          @click="(solusi_detail = data), (detailFoto = '')"
                          class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                          style="margin-left: 5px"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                          v-can="'kontrak/detail/solusi/update'"
                          @click="
                            solusi_detail = data;
                            modalSolusi('edit');
                          "
                          style="margin-left: 5px"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                          v-can="'kontrak/detail/solusi/delete'"
                          style="margin-left: 5px"
                          @click="solusiDelete(data.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="6" class="text-center">Tidak ada data.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h4 class="card-title text-primary">Informasi Tambahan</h4>
          </div>
          <hr />
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-12 col-lg-6 mb-2">
                <p>
                  Nilai Kontrak
                  {{
                    model.col_amandemen == null
                      ? ""
                      : "(Amandemen ke-" + model.col_amandemen + ")"
                  }}
                </p>
                <div class="card-nominal mb-3">
                  <div class="ic-nominal">
                    <img
                      src="../../../public/assets/img/icon/ic-money-2.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>

                  <div class="text-nominal">
                    <div class="title">{{ model.nilai | currency }}</div>
                    <p class="subtitle" v-if="model.nilai >= 0">
                      Terbilang: {{ parseInt(model.nilai) | terbilang }} Rupiah
                    </p>
                    <p class="subtitle" v-else>
                      Terbilang: Minus
                      {{ parseInt(model.nilai * -1) | terbilang }} Rupiah
                    </p>
                  </div>
                </div>

                <!-- <div class="row">
                                    <div class="col-12 col-sm-4 col-lg-4">
                                        <p class="text-black font-600">Nilai BAP</p>
                                    </div>
                                    <div class="col-12 col-sm-8 col-lg-8 align-self-center">
                                        <p class="text-black" v-if="model.col_nilai_bap >= 0">{{model.col_nilai_bap | currency}} <b><i>({{parseInt(model.col_nilai_bap) | terbilang}}{{model.col_nilai_bap == 0 ? 'Nol' : ''}} Rupiah)</i></b></p>
                                        <p class="text-black" v-else>{{model.col_nilai_bap | currency}} <b><i>(Minus {{parseInt(model.col_nilai_bap*-1) | terbilang}}{{model.col_nilai_bap == 0 ? 'Nol' : ''}} Rupiah)</i></b></p>
                                    </div>
                                </div> -->
                <div class="row">
                  <div class="col-12 col-sm-4 col-lg-4">
                    <p class="text-black font-600">
                      Nilai Kontrak Terbayar (Include PPN)
                    </p>
                  </div>
                  <div class="col-12 col-sm-8 col-lg-8 align-self-center">
                    <p
                      class="text-black"
                      v-if="model.col_kontrak_terbayar >= 0"
                    >
                      {{ model.col_kontrak_terbayar | currency }}
                      <b
                        ><i
                          >({{ parseInt(model.col_kontrak_terbayar) | terbilang
                          }}{{
                            model.col_kontrak_terbayar == 0 ? "Nol" : ""
                          }}
                          Rupiah)</i
                        ></b
                      >
                    </p>
                    <p class="text-black" v-else>
                      {{ model.col_kontrak_terbayar | currency }}
                      <b
                        ><i
                          >(Minus
                          {{
                            parseInt(model.col_kontrak_terbayar * -1)
                              | terbilang
                          }}{{
                            model.col_kontrak_terbayar == 0 ? "Nol" : ""
                          }}
                          Rupiah)</i
                        ></b
                      >
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-4 col-lg-4">
                    <p class="text-black font-600">
                      % Progress Pembayaran (Include PPN)
                    </p>
                  </div>
                  <div class="col-12 col-sm-8 col-lg-8 align-self-center">
                    <h5 class="text-blue font-600">
                      {{ model.col_kontrak_progress_pembayaran_num }}%
                    </h5>
                    <div class="progress progress-bar-primary">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        :aria-valuenow="
                          model.col_kontrak_progress_pembayaran_num
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="{
                          width:
                            model.col_kontrak_progress_pembayaran_num + '%',
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-4 col-lg-4">
                    <p class="text-black font-600">Nilai BAP Berjalan</p>
                  </div>
                  <div class="col-12 col-sm-8 col-lg-8 align-self-center">
                    <p
                      class="text-black"
                      v-if="model.col_nilai_outstanding >= 0"
                    >
                      {{ model.col_nilai_outstanding | currency }}
                      <b
                        ><i
                          >({{
                            parseInt(model.col_nilai_outstanding) | terbilang
                          }}{{
                            model.col_nilai_outstanding == 0 ? "Nol" : ""
                          }}
                          Rupiah)</i
                        ></b
                      >
                    </p>
                    <p class="text-black" v-else>
                      {{ model.col_nilai_outstanding | currency }}
                      <b
                        ><i
                          >(Minus
                          {{
                            parseInt(model.col_nilai_outstanding * -1)
                              | terbilang
                          }}{{
                            model.col_nilai_outstanding == 0 ? "Nol" : ""
                          }}
                          Rupiah)</i
                        ></b
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-1">
              <div class="col-12 col-sm-12 col-lg-12" v-can="'kontrak/detail/kontrak_mtu/read'">
                <h4 class="card-title" style="margin-bottom: 8px">
                  Kontrak MTU
                </h4>
              </div>
              <div class="col-12 col-sm-12 col-lg-12" v-can="'kontrak/detail/kontrak_mtu/read'">
                <div class="table-responsive no-sort tableFixHead">
                  <table class="table tabel-status-document w-100">
                    <thead>
                      <tr>
                        <th>Lokasi</th>
                        <th>No. PRK</th>
                        <th>Nilai Kontrak</th>
                        <th>Ready At Site</th>
                        <th>Status</th>
                        <th>Progress Bayar</th>
                        <th>Material</th>
                        <th>Jumlah</th>
                        <th>Satuan</th>
                      </tr>
                    </thead>
                    <tbody v-if="mtu_list.length > 0">
                      <tr v-for="data in mtu_list" :key="data.id">
                        <td>{{ data.name_resume }}</td>
                        <td>{{ data.no_prk }}</td>
                        <td>{{ data.nilai_kontrak | currency }}</td>
                        <td>{{ data.col_ready_at }}</td>
                        <td>{{ data.progress_mtu_status?.name }}</td>
                        <td>{{ data.progress_bayar }}</td>
                        <td>
                          <ul class="pl-0">
                            <li v-for="(dt, idx) in data.material" :key="idx">{{dt}}</li>
                          </ul>
                        </td>
                        <!-- <td>{{ data.jumlah }}</td> -->
                        <td>
                          <ul class="pl-0">
                            <li v-for="(dt, idx) in data.jumlah" :key="idx">{{dt}}</li>
                          </ul>
                        </td>
                        <td>
                          <ul class="pl-0">
                            <li v-for="(dt, idx) in data.satuan" :key="idx">{{dt}}</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="8" class="text-center">Tidak ada data.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12 col-sm-12 col-lg-12" v-can="'kontrak/other_project/read'">
                <h4 class="card-title" style="margin-bottom: 8px">
                  Project Lain Terkait
                </h4>
              </div>
              <div class="col-12 col-sm-12 col-lg-12" v-can="'kontrak/other_project/read'">
                <div class="table-responsive no-sort tableFixHead">
                  <table class="table tabel-status-document w-100">
                    <thead>
                      <tr>
                        <th>Jenis Pekerjaan</th>
                        <th>Judul Kontrak</th>
                        <th>Status Pekerjaan</th>
                        <th>Progress</th>
                        <th>Status Serah Terima</th>
                        <th>Status Operasi</th>
                      </tr>
                    </thead>
                    <tbody v-if="project_lain_list.length > 0">
                      <tr v-for="data in project_lain_list" :key="data.id">
                        <td
                          @click="pushTodetailing(data.id)"
                          style="cursor: pointer"
                        >
                          {{ data.jenis_pekerjaan.name }}
                        </td>
                        <td>{{ data.judul_kontrak }}</td>
                        <td v-html="data.col_status_kontraktual_html"></td>
                        <td v-html="data.col_kontrak_progress"></td>
                        <td v-html="data.col_status_serahterima_html"></td>
                        <td v-html="data.col_status_operasi_html"></td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="8" class="text-center">Tidak ada data.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <hr />
            <div class="row mb-1">
              <div class="col-12 col-sm-12 col-lg-12">
                <h4 class="card-title" style="margin-bottom: 8px">
                  Data Anggaran
                  <div
                    class="ml-1 badge badge-warning badge-md"
                    v-if="master_ai.status_text != ''"
                  >
                    {{ master_ai.status_text }} &nbsp;
                  </div>
                  <span
                    class="ml-1 font-italic subtitle font-weight-light"
                    style="font-size:14px; color:#626262; !important"
                    v-if="master_ai.nomor_nodin"
                    >Pada tanggal &nbsp;
                    {{ master_ai.tanggal_perubahan_format }} &nbsp; -
                    {{ master_ai.nomor_nodin }}</span
                  >
                  <button
                    type="button"
                    v-if="master_ai.status_text != ''"
                    class="btn btn-primary float-right btn-sm btn-block-mobile"
                    @click="masterAiForce"
                  >
                    Action
                  </button>
                </h4>
                <p class="font-italic subtitle mt-font-weight-light">
                  {{ master_ai.no_ski }} &nbsp; - {{ master_ai.tgl }}
                </p>
              </div>
              <div class="col-12 col-sm-12 col-lg-12 mb-1">
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6 col-md-4">
                        <h5>
                          <strong>Nilai AI &nbsp; {{ this.date_now }}</strong>
                        </h5>
                        <h2 class="text-primary">
                          <strong>{{ master_ai.ai_thn | currency }}</strong>
                        </h2>
                        <div
                          v-if="master_ai.potensi_ai_kurang == true"
                          class="card"
                          style="background: #e32323"
                        >
                          <div class="card-content">
                            <div class="card-body">
                              <div class="row">
                                <div class="col-12">
                                  <div class="row">
                                    <div class="col-6">
                                      <p class="text-white m-0">
                                        <small
                                          >Kebutuhan AI &nbsp;
                                          {{ date_now }}</small
                                        >
                                      </p>
                                    </div>
                                    <div class="col-6">
                                      <p class="text-white m-0">
                                        <small
                                          ><strong>{{
                                            master_ai.kebutuhan_ai | currency
                                          }}</strong></small
                                        >
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-6">
                                      <p class="text-white m-0">
                                        <small>Selisih dari AI aktual</small>
                                      </p>
                                    </div>
                                    <div class="col-6">
                                      <p class="text-white m-0">
                                        <small
                                          ><strong>{{
                                            master_ai.selisih_ai | currency
                                          }}</strong></small
                                        >
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h5>
                          <strong>Nilai AKI &nbsp; {{ this.date_now }}</strong>
                        </h5>
                        <h2 class="text-primary">
                          <strong>{{ master_ai.aki_thn | currency }}</strong>
                        </h2>
                        <p>
                          <small
                            >Realisasi
                            <strong>{{
                              master_ai.nilai_pose_kontrak | currency
                            }}</strong></small
                          >
                        </p>
                      </div>
                      <div class="col-6 col-md-8" v-if="loadStateChart">
                        <apexchart
                          type="bar"
                          height="300"
                          :options="chartOpt"
                          :series="chartSeries"
                        ></apexchart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-can="[
          'kontrak/detail/bg_uang_muka/read',
          'kontrak/detail/bg_pelaksanaan/read',
          'kontrak/detail/bg_pemeliharaan/read']">
          <div class="card-header">
            <h4 class="card-title text-primary">Bank Garansi</h4>
          </div>
          <hr />
          <div class="card-body pt-1">
            <section
              class="d-block"
              name="BankGaransiUangMuka"
            >
              <div v-can="'kontrak/detail/bg_uang_muka/read'" class="w-100">
                <div class="row align-items-center justify-content-between">
                  <div class="col-12 col-sm-9 col-lg-9 mb-1">
                    <h4 class="card-title mb-0">
                      Jaminan Uang Muka
                      <div
                        class="ml-1 badge badge-warning badge-md"
                        v-if="bg_uang_muka.rows.length > 0"
                        @click="bgUangukaForce"
                        v-can="'kontrak/detail/bg_uang_muka/force_update'"
                        style="cursor: pointer"
                      >
                        {{ bg_uang_muka.status_table }} &nbsp;
                        <i class="fa fa-angle-down"></i>
                      </div>
                      <button
                        type="button"
                        v-can="'kontrak/detail/bg_uang_muka/create'"
                        class="btn btn-primary btn-sm btn-block-mobile"
                        @click="modalBgUangMuka('add')"
                      >
                        Tambah BG Original
                      </button>
                      <button
                        v-if="bg_uang_muka.rows.length > 0"
                        type="button"
                        v-can="'kontrak/detail/bg_uang_muka/create'"
                        @click="modalBgUangMuka('add_perubahan')"
                        class="btn btn-primary btn-sm btn-block-mobile"
                      >
                        Tambah BG Perubahan
                      </button>
                    </h4>
                  </div>

                  <div class="col-12 col-sm-3 mb-1">
                    <div class="input-group form-search-rounded">
                      <input
                        class="form-control py-2 border-right-0 border"
                        type="search"
                        v-model="bg_uang_muka_sq"
                        @input="bgUangMukaSearch"
                        placeholder="Cari.."
                      />
                      <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                          <a href="#" class="text-blue"
                            ><i class="fa fa-search"></i
                          ></a>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="table-responsive no-sort tableFixHead">
                  <table class="table tabel-status-document w-120">
                    <thead>
                      <tr>
                        <th>Nomor Seri</th>
                        <th>Tanggal Berlaku</th>
                        <th>Tanggal Berakhir</th>
                        <th>IDR</th>
                        <th>CNY</th>
                        <th>JPY</th>
                        <th>EUR</th>
                        <th>USD</th>
                        <th>Status</th>
                        <th class="w10">Aksi</th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="bg_uang_muka.rows.length > 0 && bg_uang_muka_sq == ''"
                    >
                      <tr v-for="data in bg_uang_muka.rows" :key="data.id">
                        <td class="text-left">
                          {{ data.nomor }}
                        </td>
                        <td>{{ data.tgl_terbit }}</td>
                        <td>{{ data.tgl_berakhir }}</td>
                        <td style="white-space: nowrap">
                          {{ data.nilai | currency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#x5143;{{ data.cny | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#xa5;{{ data.jpy | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &euro;{{ data.eur | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          ${{ data.usd | globalCurrency }}
                        </td>
                        <td class="text-left" style="white-space: nowrap">
                          <span
                            class="statusbg mr-5"
                            v-if="data.status_text == '-'"
                          >
                            -
                          </span>
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Aktif'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Penyesuaian Nilai'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Akan Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg not_approve mr-1"
                            v-if="data.status_text == 'Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Selesai'"
                            >-</span
                          >
                          
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Lengkapi file keabsahan"
                          >
                            <img
                              v-if="data.file_keabsahan.length == 0"
                              src="/assets/img/Vector.svg"
                              class="img-fluid"
                              alt=""
                            />
                          </span>
                        </td>
                        <td>
                          <div class="btn-action-block">
                            <button
                              type="button"
                              @click="modalBGUangMukaDetail(data.id)"
                              class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_uang_muka/detail'"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <!-- &nbsp;&nbsp; -->
                            <button
                              type="button"
                              class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_uang_muka/update'"
                              @click="
                                bg_uang_muka_detail = data;
                                modalBgUangMuka('edit');
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <!-- &emsp;&emsp; -->
                            <button
                              type="button"
                              class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_uang_muka/delete'"
                              @click="delete_bg_uang_muka(data.id)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      v-else-if="
                        bg_uang_muka.rows.length > 0 && bg_uang_muka_sq != ''
                      "
                    >
                      <tr v-for="data in bg_uang_muka_sq_res" :key="data.id">
                        <td class="text-left">
                          {{ data.nomor }}
                        </td>
                        <td>{{ data.tgl_terbit }}</td>
                        <td>{{ data.tgl_berakhir }}</td>
                        <td style="white-space: nowrap">
                          {{ data.nilai | currency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#x5143;{{ data.cny | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#xa5;{{ data.jpy | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &euro;{{ data.eur | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          ${{ data.usd | globalCurrency }}
                        </td>
                        <td class="text-left" style="white-space: nowrap">
                          <span
                            class="statusbg mr-5"
                            v-if="data.status_text == '-'"
                          >
                            -
                          </span>
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Aktif'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Penyesuaian Nilai'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Akan Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg not_approve mr-1"
                            v-if="data.status_text == 'Berakhir'"
                            >{{ data.status_text }}</span
                          >

                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Selesai'"
                            >-</span
                          >
                          
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Lengkapi file keabsahan"
                          >
                            <img
                              v-if="data.file_keabsahan.length == 0"
                              src="/assets/img/Vector.svg"
                              class="img-fluid"
                              alt=""
                            />
                          </span>
                        </td>

                        <td>
                          <div class="btn-action-block">
                            <button
                              type="button"
                              @click="modalBGUangMukaDetail(data.id)"
                              class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_uang_muka/detail'"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <!-- &nbsp;&nbsp; -->
                            <button
                              type="button"
                              class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_uang_muka/update'"
                              @click="
                                bg_uang_muka_detail = data;
                                modalBgUangMuka('edit');
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <!-- &emsp;&emsp; -->
                            <button
                              type="button"
                              class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_uang_muka/delete'"
                              @click="delete_bg_uang_muka(data.id)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="10" class="text-center">Tidak ada data.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-can="'kontrak/detail/bg_pelaksanaan/read'" class="w-100">
                <div class="row align-items-center justify-content-between">
                  <div class="col-12 col-sm-9 col-lg-9 mb-1">
                    <h4 class="card-title mb-0">
                      Jaminan Pelaksanaan
                      <div
                        class="ml-1 badge badge-warning badge-md"
                        v-if="bg_pelaksanaan.rows.length > 0"
                        @click="bgPelaksanaanForce"
                        v-can="'kontrak/detail/bg_pelaksanaan/force_update'"
                        style="cursor: pointer"
                      >
                        {{ bg_pelaksanaan.status_table }} &nbsp;
                        <i class="fa fa-angle-down"></i>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-block-mobile"
                        v-can="'kontrak/detail/bg_pelaksanaan/create'"
                        @click="modalBgPelaksanaan('add')"
                      >
                        Tambah BG Original
                      </button>
                      <button
                        v-if="bg_pelaksanaan.rows.length > 0"
                        type="button"
                        v-can="'kontrak/detail/bg_pelaksanaan/create'"
                        @click="modalBgPelaksanaan('add_perubahan')"
                        class="btn btn-primary btn-sm btn-block-mobile"
                      >
                        Tambah BG Perubahan
                      </button>
                    </h4>
                  </div>

                  <div class="col-12 col-sm-3 mb-1">
                    <div class="input-group form-search-rounded">
                      <input
                        class="form-control py-2 border-right-0 border"
                        type="search"
                        v-model="bg_pelaksanaan_sq"
                        @input="bgPelaksanaanSearch"
                        placeholder="Cari.."
                      />
                      <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                          <a href="#" class="text-blue"
                            ><i class="fa fa-search"></i
                          ></a>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="table-responsive no-sort tableFixHead">
                  <table class="table tabel-status-document w-100">
                    <thead>
                      <tr>
                        <th>Nomor Seri</th>
                        <th>Tanggal Berlaku</th>
                        <th>Tanggal Berakhir</th>
                        <th>IDR</th>
                        <th>CNY</th>
                        <th>JPY</th>
                        <th>EUR</th>
                        <th>USD</th>
                        <th>Status</th>
                        <th class="w10">Aksi</th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="
                        bg_pelaksanaan.rows.length > 0 && bg_pelaksanaan_sq == ''
                      "
                    >
                      <tr v-for="data in bg_pelaksanaan.rows" :key="data.id">
                        <td class="text-left">
                          {{ data.nomor }}
                        </td>
                        <td>{{ data.tgl_terbit }}</td>
                        <td>{{ data.tgl_berakhir }}</td>
                        <td style="white-space: nowrap">
                          {{ data.nilai | currency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#x5143;{{ data.cny | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#xa5;{{ data.jpy | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &euro;{{ data.eur | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          ${{ data.usd | globalCurrency }}
                        </td>
                        <td class="text-left" style="white-space: nowrap">
                          <span
                            class="statusbg mr-5"
                            v-if="data.status_text == '-'"
                          >
                            -
                          </span>
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Aktif'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Penyesuaian Nilai'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Akan Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg not_approve mr-1"
                            v-if="data.status_text == 'Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Selesai'"
                            >-</span
                          >
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Lengkapi file keabsahan"
                          >
                            <img
                              v-if="data.file_keabsahan.length == 0"
                              src="/assets/img/Vector.svg"
                              class="img-fluid"
                              alt=""
                            />
                          </span>
                        </td>

                        <td>
                          <div class="btn-action-block">
                            <button
                              type="button"
                              @click="modalBGPelaksanaanDetail(data.id)"
                              v-can="'kontrak/detail/bg_pelaksanaan/detail'"
                              class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pelaksanaan/update'"
                              @click="
                                bg_pelaksanaan_detail = data;
                                modalBgPelaksanaan('edit');
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pelaksanaan/delete'"
                              @click="delete_bg_pelaksanaan(data.id)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      v-else-if="
                        bg_pelaksanaan.rows.length > 0 && bg_pelaksanaan_sq != ''
                      "
                    >
                      <tr v-for="data in bg_pelaksanaan_sq_res" :key="data.id">
                        <td class="text-left">
                          {{ data.nomor }}
                        </td>
                        <td>{{ data.tgl_terbit }}</td>
                        <td>{{ data.tgl_berakhir }}</td>
                        <td style="white-space: nowrap">
                          {{ data.nilai | currency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#x5143;{{ data.cny | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#xa5;{{ data.jpy | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &euro;{{ data.eur | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          ${{ data.usd | globalCurrency }}
                        </td>
                        <td class="text-left" style="white-space: nowrap">
                          <span
                            class="statusbg mr-5"
                            v-if="data.status_text == '-'"
                          >
                            -
                          </span>
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Aktif'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Penyesuaian Nilai'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Akan Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg not_approve mr-1"
                            v-if="data.status_text == 'Berakhir'"
                            >{{ data.status_text }}</span
                          >

                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Selesai'"
                            >-</span
                          >
                          
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Lengkapi file keabsahan"
                          >
                            <img
                              v-if="data.file_keabsahan.length == 0"
                              src="/assets/img/Vector.svg"
                              class="img-fluid"
                              alt=""
                            />
                          </span>
                        </td>

                        <td>
                          <div class="btn-action-block">
                            <button
                              type="button"
                              @click="modalBGPelaksanaanDetail(data.id)"
                              v-can="'kontrak/detail/bg_pelaksanaan/detail'"
                              class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pelaksanaan/update'"
                              @click="
                                bg_pelaksanaan_detail = data;
                                modalBgPelaksanaan('edit');
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pelaksanaan/delete'"
                              @click="delete_bg_pelaksanaan(data.id)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="10" class="text-center">Tidak ada data.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-can="'kontrak/detail/bg_pemeliharaan/read'" class="w-100">
                <div class="row align-items-center justify-content-between">
                  <div class="col-12 col-sm-9 col-lg-9 mb-1">
                    <h4 class="card-title mb-0">
                      Jaminan Pemeliharaan
                      <div
                        class="ml-1 badge badge-warning badge-md"
                        v-if="bg_pemeliharaan.rows.length > 0"
                        @click="bgPemeliharaanForce"
                        style="cursor: pointer"
                        v-can="'kontrak/detail/bg_pemeliharaan/force_update'"
                      >
                        {{ bg_pemeliharaan.status_table }} &nbsp;
                        <i class="fa fa-angle-down"></i>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm btn-block-mobile"
                        @click="modalBgPemeliharaan('add')"
                        v-can="'kontrak/detail/bg_pemeliharaan/create'"
                      >
                        Tambah BG Original
                      </button>
                      <button
                        v-if="bg_pemeliharaan.rows.length > 0"
                        type="button"
                        @click="modalBgPemeliharaan('add_perubahan')"
                        v-can="'kontrak/detail/bg_pemeliharaan/create'"
                        class="btn btn-primary btn-sm btn-block-mobile"
                      >
                        Tambah BG Perubahan
                      </button>
                    </h4>
                  </div>

                  <div class="col-12 col-sm-3 mb-1">
                    <div class="input-group form-search-rounded">
                      <input
                        class="form-control py-2 border-right-0 border"
                        type="search"
                        v-model="bg_pemeliharaan_sq"
                        @input="bgPemeliharaanSearch"
                        placeholder="Cari.."
                      />
                      <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                          <a href="#" class="text-blue"
                            ><i class="fa fa-search"></i
                          ></a>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="table-responsive no-sort tableFixHead">
                  <table class="table tabel-status-document w-100">
                    <thead>
                      <tr>
                        <th>Nomor Seri</th>
                        <th>Tanggal Berlaku</th>
                        <th>Tanggal Berakhir</th>
                        <th>IDR</th>
                        <th>CNY</th>
                        <th>JPY</th>
                        <th>EUR</th>
                        <th>USD</th>
                        <th>Status</th>
                        <th class="w10">Aksi</th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="
                        bg_pemeliharaan.rows.length > 0 &&
                        bg_pemeliharaan_sq == ''
                      "
                    >
                      <tr v-for="data in bg_pemeliharaan.rows" :key="data.id">
                        <td class="text-left">
                          {{ data.nomor }}
                        </td>
                        <td>{{ data.tgl_terbit }}</td>
                        <td>{{ data.tgl_berakhir }}</td>
                        <td style="white-space: nowrap">
                          {{ data.nilai | currency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#x5143;{{ data.cny | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#xa5;{{ data.jpy | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &euro;{{ data.eur | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          ${{ data.usd | globalCurrency }}
                        </td>
                        <td class="text-left" style="white-space: nowrap">
                          <span
                            class="statusbg mr-5"
                            v-if="data.status_text == '-'"
                          >
                            -
                          </span>
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Aktif'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Penyesuaian Nilai'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Akan Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg not_approve mr-1"
                            v-if="data.status_text == 'Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Lengkapi file keabsahan"
                          >
                            <img
                              v-if="data.file_keabsahan.length == 0"
                              src="/assets/img/Vector.svg"
                              class="img-fluid"
                              alt=""
                            />
                          </span>
                        </td>

                        <td>
                          <div class="btn-action-block">
                            <button
                              type="button"
                              @click="modalBGPemeliharaanDetail(data.id)"
                              v-can="'kontrak/detail/bg_pemeliharaan/detail'"
                              class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <button
                              type="button"
                              class="btn ml-1 mr-1 btn-icon-small btn-warning-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pemeliharaan/update'"
                              @click="
                                bg_pemeliharaan_detail = data;
                                modalBgPemeliharaan('edit');
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pemeliharaan/delete'"
                              @click="delete_bg_pemeliharaan(data.id)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      v-else-if="
                        bg_pemeliharaan.rows.length > 0 &&
                        bg_pemeliharaan_sq != ''
                      "
                    >
                      <tr v-for="data in bg_pemeliharaan_sq_res" :key="data.id">
                        <td class="text-left">
                          {{ data.nomor }}
                        </td>
                        <td>{{ data.tgl_terbit }}</td>
                        <td>{{ data.tgl_berakhir }}</td>
                        <td style="white-space: nowrap">
                          {{ data.nilai | currency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#x5143;{{ data.cny | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &#xa5;{{ data.jpy | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          &euro;{{ data.eur | globalCurrency }}
                        </td>
                        <td style="white-space: nowrap">
                          ${{ data.usd | globalCurrency }}
                        </td>
                        <td class="text-left" style="white-space: nowrap">
                          <span
                            class="statusbg mr-5"
                            v-if="data.status_text == '-'"
                          >
                            -
                          </span>
                          <span
                            class="statusbg approve mr-1"
                            v-if="data.status_text == 'Aktif'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Penyesuaian Nilai'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg waiting mr-1"
                            v-if="data.status_text == 'Akan Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            class="statusbg not_approve mr-1"
                            v-if="data.status_text == 'Berakhir'"
                            >{{ data.status_text }}</span
                          >
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Lengkapi file keabsahan"
                          >
                            <img
                              v-if="data.file_keabsahan.length == 0"
                              src="/assets/img/Vector.svg"
                              class="img-fluid"
                              alt=""
                            />
                          </span>
                        </td>

                        <td>
                          <div class="btn-action-block">
                            <button
                              type="button"
                              @click="modalBGPemeliharaanDetail(data.id)"
                              v-can="'kontrak/detail/bg_pemeliharaan/detail'"
                              class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <button
                              type="button"
                              class="btn ml-1 mr-1 btn-icon-small btn-warning-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pemeliharaan/update'"
                              @click="
                                bg_pemeliharaan_detail = data;
                                modalBgPemeliharaan('edit');
                              "
                            >
                              <i class="fa fa-pencil"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                              v-can="'kontrak/detail/bg_pemeliharaan/delete'"
                              @click="delete_bg_pemeliharaan(data.id)"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="10" class="text-center">Tidak ada data.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h4 class="card-title text-primary">Dokumen</h4>
          </div>
          <hr />
          <div class="card-body pt-1">
            <DokumenKontrak 
              @showDetail="(res) => pdfViewer(res)"/>

            <section
              class="d-block"
              name="DokumenAmandemen"
              v-can="'kontrak/detail/amandemen/read'"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-12 col-sm-8 col-lg-6 mb-1">
                  <h4 class="card-title mb-0">
                    Dokumen Amandemen
                    <button
                      v-if="model.no_spmk != null"
                      type="button"
                      @click="modalAmandemen('add')"
                      v-can="'kontrak/detail/amandemen/create'"
                      class="btn btn-primary btn-block-mobile"
                    >
                      Tambah Amandemen
                    </button>
                  </h4>
                </div>

                <div class="col-12 col-sm-4 mb-1">
                  <div class="input-group form-search-rounded">
                    <input
                      class="form-control py-2 border-right-0 border"
                      type="search"
                      v-model="amandemenSQ"
                      @input="amandemenSearch"
                      placeholder="Cari dokumen amandemen.."
                    />
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent">
                        <a href="#" class="text-blue"
                          ><i class="fa fa-search"></i
                        ></a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div class="table-responsive no-sort w-100">
                <table class="table tabel-status-document w-100">
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Nomor</th>
                      <th v-can="'kontrak/detail/amandemen/detail'">
                        <i class="feather icon-download-cloud fa-l"></i> File
                      </th>
                      <th>Tanggal</th>
                      <th style="width: 20%">Perihal</th>
                      <th class="w10">Aksi</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      model.dokumen_amandemen.length > 0 && amandemenSQ == ''
                    "
                  >
                    <tr v-for="data in model.dokumen_amandemen" :key="data.id">
                      <td>{{ data.name }}</td>
                      <td>{{ data.nomor }}</td>
                      <td v-can="'kontrak/detail/amandemen/detail'">
                        <div v-if="data.dokumen == null">-</div>
                        <div v-else>
                          <p
                            v-for="(item, index) in data.dokumen"
                            :key="item.name"
                          >
                            <a @click="pdfViewer(item.url)" href="javascript:;"
                              >• File {{ index + 1 }} [Lihat Detail]</a
                            >
                          </p>
                        </div>
                      </td>
                      <td>{{ data.col_date }}</td>
                      <td v-html="data.col_perihal"></td>
                      <td>
                        <div class="btn-action-block">
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="'kontrak/detail/amandemen/update'"
                            @click="
                              amandemen_detail = data;
                              modalAmandemen('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="'kontrak/detail/amandemen/delete'"
                            @click="delete_amandemen(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    v-else-if="
                      model.dokumen_amandemen.length > 0 && amandemenSQ != ''
                    "
                  >
                    <tr v-for="data in amandemenSQRes" :key="data.id">
                      <td>{{ data.name }}</td>
                      <td>{{ data.nomor }}</td>
                      <td v-can="'kontrak/detail/amandemen/detail'">
                        <div v-if="data.dokumen == null">-</div>
                        <div v-else>
                          <p
                            v-for="(item, index) in data.dokumen"
                            :key="item.name"
                          >
                            <a :href="item.url" target="_blank"
                              >• File {{ index + 1 }} [Lihat Detail]</a
                            >
                          </p>
                        </div>
                      </td>
                      <td>{{ data.col_date }}</td>
                      <td>{{ data.col_perihal }}</td>
                      <td>
                        <div class="btn-action-block">
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="'kontrak/detail/amandemen/update'"
                            @click="
                              amandemen_detail = data;
                              modalAmandemen('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="'kontrak/detail/amandemen/delete'"
                            @click="delete_amandemen(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6" class="text-center">Tidak ada data.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr class="my-2" />
            </section>

            <section
              class="d-block"
              name="DokumenBAP"
              v-can="'kontrak/detail/bap/read'"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-12 col-sm-8 col-lg-6 mb-1">
                  <h4 class="card-title mb-0">
                    Dokumen Berita Acara Pembayaran
                    <button
                      v-if="model.no_spmk != null"
                      type="button"
                      @click="modalBAP('add')"
                      v-can="'kontrak/detail/bap/create'"
                      class="btn btn-primary btn-block-mobile"
                    >
                      Tambah BAP
                    </button>
                  </h4>
                </div>

                <div class="col-12 col-sm-4 mb-1">
                  <div class="input-group form-search-rounded">
                    <input
                      class="form-control py-2 border-right-0 border"
                      type="search"
                      id="example-search-input"
                      placeholder="Cari dokumen BAP.."
                      v-model="bapSQ"
                      @input="bapSearch"
                    />
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent">
                        <a href="#" class="text-blue"
                          ><i class="fa fa-search"></i
                        ></a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div class="table-responsive no-sort w-100">
                <table class="table tabel-status-document w-100">
                  <thead>
                    <tr>
                      <th>Nama</th>
                      <th>Nomor</th>
                      <th>
                        <i class="feather icon-download-cloud fa-l"></i> File
                      </th>
                      <th>Tanggal</th>
                      <th>Nilai BAP</th>
                      <th class="w10">Aksi</th>
                    </tr>
                  </thead>
                  <tbody v-if="model.dokumen_bap.length > 0 && bapSQ == ''">
                    <tr v-for="(data, index) in model.dokumen_bap" :key="index">
                      <td>{{ data.name == null ? "-" : data.name }}</td>
                      <td>{{ data.nomor == null ? "-" : data.nomor }}</td>
                      <td>
                        <div v-if="data.document == null">-</div>
                        <div v-else>
                          <p class="mb-0"><a :href="data.document_url" target="_blank">• File [Lihat Detail]</a></p>
                        </div>
                      </td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>
                        {{ data.nilai == null ? "-" : data.nilai | currency }}
                      </td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="'kontrak/detail/bap/update'"
                            @click="
                              bap_detail = data;
                              modalBAP('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="'kontrak/detail/bap/delete'"
                            @click="delete_bap(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    v-else-if="model.dokumen_bap.length > 0 && bapSQ != ''"
                  >
                    <tr v-for="(data, index) in bapSQRes" :key="index">
                      <td>{{ data.name == null ? "-" : data.name }}</td>
                      <td>{{ data.nomor == null ? "-" : data.nomor }}</td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>
                        {{ data.nilai == null ? "-" : data.nilai | currency }}
                      </td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="'kontrak/detail/bap/update'"
                            @click="
                              bap_detail = data;
                              modalBAP('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="'kontrak/detail/bap/delete'"
                            @click="delete_bap(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" class="text-center">Tidak ada data.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr class="my-2" />
            </section>

            <section
              class="d-block"
              name="DokumenPOSE"
              v-can="'kontrak/detail/pose/read'"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-12 col-sm-8 col-lg-6 mb-1">
                  <h4 class="card-title mb-0">
                    Dokumen PO SE
                    <button
                      v-if="model.no_spmk != null"
                      type="button"
                      @click="modalPOSE('add')"
                      v-can="'kontrak/detail/pose/create'"
                      class="btn btn-primary btn-block-mobile"
                    >
                      Tambah PO SE
                    </button>
                  </h4>
                </div>

                <div class="col-12 col-sm-4 mb-1">
                  <div class="input-group form-search-rounded">
                    <input
                      class="form-control py-2 border-right-0 border"
                      type="search"
                      id="example-search-input"
                      placeholder="Cari dokumen PO SE.."
                      v-model="poseSQ"
                      @input="poseSearch"
                    />
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent">
                        <a href="#" class="text-blue"
                          ><i class="fa fa-search"></i
                        ></a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div class="table-responsive no-sort w-100">
                <table class="table tabel-status-document w-100">
                  <thead>
                    <tr>
                      <th>No. PO</th>
                      <th>No. SE</th>
                      <th>Tanggal</th>
                      <th>Nilai Terbayar</th>
                      <th>Dokumen BAP</th>
                      <th class="w10">Aksi</th>
                    </tr>
                  </thead>
                  <tbody v-if="model.pose.length > 0 && poseSQ == ''">
                    <tr v-for="(data, index) in model.pose" :key="index">
                      <td>{{ data.no_po == null ? "-" : data.no_po }}</td>
                      <td>{{ data.no_se == null ? "-" : data.no_se }}</td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>
                        {{
                          data.nilai_terbayar == null
                            ? "-"
                            : data.nilai_terbayar | currency
                        }}
                      </td>
                      <td>{{ data.bap.name == null ? "-" : data.bap.name }}</td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="'kontrak/detail/pose/update'"
                            @click="
                              pose_detail = data;
                              modalPOSE('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="'kontrak/detail/pose/delete'"
                            @click="delete_pose(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="model.pose.length > 0 && poseSQ != ''">
                    <tr v-for="(data, index) in poseSQRes" :key="index">
                      <td>{{ data.no_po == null ? "-" : data.no_po }}</td>
                      <td>{{ data.no_se == null ? "-" : data.no_se }}</td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>
                        {{
                          data.nilai_terbayar == null
                            ? "-"
                            : data.nilai_terbayar | currency
                        }}
                      </td>
                      <td>{{ data.bap.name == null ? "-" : data.bap.name }}</td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="'kontrak/detail/pose/update'"
                            @click="
                              pose_detail = data;
                              modalPOSE('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="'kontrak/detail/pose/delete'"
                            @click="delete_pose(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" class="text-center">Tidak ada data.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr class="my-2" />
            </section>

            <section
              class="d-block"
              name="StatusKontraktual"
              v-can="'kontrak/detail/status_kontraktual/read'"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-12 col-sm-8 col-lg-6 mb-1">
                  <h4 class="card-title mb-0">
                    Status Kontraktual
                    <span v-html="model.col_status_kontraktual_html"></span>
                  </h4>
                </div>

                <div class="col-12 col-sm-4 mb-1">
                  <div class="input-group form-search-rounded">
                    <input
                      class="form-control py-2 border-right-0 border"
                      type="search"
                      id="example-search-input"
                      placeholder="Cari status kontraktual.."
                      v-model="kontraktualSQ"
                      @input="kontraktualSearch"
                    />
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent">
                        <a href="#" class="text-blue"
                          ><i class="fa fa-search"></i
                        ></a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div class="table-responsive no-sort w-100">
                <table class="table tabel-status-document w-100">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Tanggal</th>
                      <th>Nama</th>
                      <th>Nomor</th>
                      <th v-can="'kontrak/detail/status_kontraktual/detail'">
                        <i class="feather icon-download-cloud fa-l"></i> File
                      </th>
                      <th class="w10">Aksi</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      model.status_kontraktual.length > 0 &&
                      kontraktualSQ == '' &&
                      kontraktualBounce == false
                    "
                  >
                    <tr
                      v-for="(data, index) in model.status_kontraktual"
                      :key="index"
                    >
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            disabled
                            :checked="data.date != null"
                            name="customCheck"
                            id="UserRoleManagement1"
                          />
                          <label
                            class="custom-control-label"
                            for="UserRoleManagement1"
                          ></label>
                        </div>
                      </td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>{{ data.name == null ? "-" : data.name }}</td>
                      <td>{{ data.nomor == null ? "-" : data.nomor }}</td>
                      <td v-can="'kontrak/detail/status_kontraktual/detail'">
                        <div v-if="data.dokumen == null">-</div>
                        <div v-else>
                          <p
                            v-for="(item, index) in data.dokumen"
                            :key="item.name"
                          >
                            <a href="javascript:;" @click="pdfViewer(item.url)"
                              >• File {{ index + 1 }} [Lihat Detail]</a
                            >
                          </p>
                        </div>
                      </td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="data.permissions"
                            @click="
                              kontraktual_detail = data;
                              modalKontraktual('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            v-if="data.date != null"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="'kontrak/detail/status_kontraktual/delete'"
                            @click="delete_kontraktual(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody
                    v-else-if="
                      model.status_kontraktual.length > 0 && kontraktualSQ != ''
                    "
                  >
                    <tr v-for="(data, index) in kontraktualSQRes" :key="index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            disabled
                            :checked="data.date != null"
                            name="customCheck"
                            id="UserRoleManagement1"
                          />
                          <label
                            class="custom-control-label"
                            for="UserRoleManagement1"
                          ></label>
                        </div>
                      </td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>{{ data.name == null ? "-" : data.name }}</td>
                      <td>{{ data.nomor == null ? "-" : data.nomor }}</td>
                      <td v-can="'kontrak/detail/status_kontraktual/detail'">
                        <div v-if="data.dokumen == null">-</div>
                        <div v-else>
                          <p
                            v-for="(item, index) in data.dokumen"
                            :key="item.name"
                          >
                            <a :href="item.url" target="_blank"
                              >• File {{ index + 1 }} [Lihat Detail]</a
                            >
                          </p>
                        </div>
                      </td>
                      <td>
                        {{
                          data.tgl_operasi == null
                            ? "-"
                            : moment(data.tgl_operasi).format("DD MMMM YYYY")
                        }}
                      </td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="data.permissions"
                            @click="
                              kontraktual_detail = data;
                              modalKontraktual('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                          &emsp;
                          <button
                            type="button"
                            v-if="data.date != null"
                            class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                            v-can="data.permissions"
                            @click="delete_kontraktual(data.id)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6" class="text-center">Tidak ada data.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr class="my-2" />
            </section>

            <section
              class="d-block"
              name="ScopeProyek"
              v-can="'kontrak/detail/scope/read'"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-12 col-sm-8 col-lg-6 mb-1">
                  <h4 class="card-title mb-0">
                    Status Operasi
                    <span v-html="model.col_status_operasi_html"></span>
                    <button
                      class="btn btn-primary btn-block-mobile"
                      @click="modalScope('add')"
                      v-can="'kontrak/detail/scope/create'"
                    >
                      Tambah Scope
                    </button>
                  </h4>
                </div>

                <div class="col-12 col-sm-4 mb-1">
                  <div class="input-group form-search-rounded">
                    <input
                      class="form-control py-2 border-right-0 border"
                      type="search"
                      v-model="scopeSQ"
                      @input="scopeSearch"
                      placeholder="Cari scope.."
                    />
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent">
                        <a href="#" class="text-blue"
                          ><i class="fa fa-search"></i
                        ></a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div class="table-responsive no-sort w-100">
                <table class="table tabel-status-document w-100">
                  <thead>
                    <tr>
                      <th class="text-center">Nama</th>
                      <th class="text-center">RLB</th>
                      <th class="text-center">Energize</th>
                      <th class="text-center">SLO</th>
                      <th class="text-center">Aksi</th>
                    </tr>
                  </thead>
                  <tbody v-if="model.scope.length > 0 && scopeSQ == ''">
                    <tr v-for="(data, i) in model.scope" :key="i">
                      <td class="text-center">{{ data.name }}</td>
                      <td class="text-center">
                        <div class="btn-action-block">
                          <button
                            type="button"
                            @click="modalScopeRLB('add', data.sts_operasi[0])"
                            v-can="data.sts_operasi[0].permissions"
                            class="btn btn-small waves-effect waves-light"
                            :class="{
                              'btn-warning-trans': data.sts_operasi[0].dokumen,
                              'btn-success-trans': !data.sts_operasi[0].dokumen,
                            }"
                          >
                            <i
                              :class="{
                                'fa fa-pencil': data.sts_operasi[0].dokumen,
                                'fa fa-plus': !data.sts_operasi[0].dokumen,
                              }"
                            ></i>
                            {{ data.sts_operasi[0].dokumen ? "Edit" : "Add" }}
                          </button>
                        </div>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          @click="
                            modalScopeEnergize('add', data.sts_operasi[1])
                          "
                          class="btn btn-small waves-effect waves-light"
                          v-can="data.sts_operasi[1].permissions"
                          :class="{
                            'btn-warning-trans': data.sts_operasi[1].dokumen,
                            'btn-success-trans': !data.sts_operasi[1].dokumen,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-pencil': data.sts_operasi[1].dokumen,
                              'fa fa-plus': !data.sts_operasi[1].dokumen,
                            }"
                          ></i>
                          {{ data.sts_operasi[1].dokumen ? "Edit" : "Add" }}
                        </button>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          @click="modalScopeSLO('add', data.sts_operasi[2])"
                          class="btn btn-small waves-effect waves-light"
                          v-can="data.sts_operasi[2].permissions"
                          :class="{
                            'btn-warning-trans': data.sts_operasi[2].dokumen,
                            'btn-success-trans': !data.sts_operasi[2].dokumen,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-pencil': data.sts_operasi[2].dokumen,
                              'fa fa-plus': !data.sts_operasi[2].dokumen,
                            }"
                          ></i>
                          {{ data.sts_operasi[2].dokumen ? "Edit" : "Add" }}
                        </button>
                      </td>
                      <td width="230px" class="text-center">
                        <button
                          type="button"
                          @click="modalScope('edit', data.id)"
                          v-can="'kontrak/detail/scope/update'"
                          class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button
                          type="button"
                          @click="modalScopeDetail(data.id)"
                          v-can="'kontrak/detail/scope/detail'"
                          class="btn mr-1 ml-1 btn-icon-small btn-success-trans waves-effect waves-light"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          type="button"
                          @click="deleteScope(data.id)"
                          v-can="'kontrak/detail/scope/delete'"
                          class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="model.scope.length > 0 && scopeSQ != ''">
                    <tr v-for="data in scopeSQRes" :key="data.id">
                      <td class="text-center">{{ data.name }}</td>
                      <td class="text-center">
                        <div class="btn-action-block">
                          <button
                            type="button"
                            @click="modalScopeRLB('add', data.sts_operasi[0])"
                            class="btn btn-small waves-effect waves-light"
                            v-can="data.sts_operasi[0].permissions"
                            :class="{
                              'btn-warning-trans': data.sts_operasi[0].dokumen,
                              'btn-success-trans': !data.sts_operasi[0].dokumen,
                            }"
                          >
                            <i
                              :class="{
                                'fa fa-pencil': data.sts_operasi[0].dokumen,
                                'fa fa-plus': !data.sts_operasi[0].dokumen,
                              }"
                            ></i>
                            {{ data.sts_operasi[0].dokumen ? "Edit" : "Add" }}
                          </button>
                        </div>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          @click="
                            modalScopeEnergize('add', data.sts_operasi[1])
                          "
                          class="btn btn-small waves-effect waves-light"
                          v-can="data.sts_operasi[1].permissions"
                          :class="{
                            'btn-warning-trans': data.sts_operasi[1].dokumen,
                            'btn-success-trans': !data.sts_operasi[1].dokumen,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-pencil': data.sts_operasi[1].dokumen,
                              'fa fa-plus': !data.sts_operasi[1].dokumen,
                            }"
                          ></i>
                          {{ data.sts_operasi[1].dokumen ? "Edit" : "Add" }}
                        </button>
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          @click="modalScopeSLO('add', data.sts_operasi[2])"
                          class="btn btn-small waves-effect waves-light"
                          v-can="data.sts_operasi[2].permissions"
                          :class="{
                            'btn-warning-trans': data.sts_operasi[2].dokumen,
                            'btn-success-trans': !data.sts_operasi[2].dokumen,
                          }"
                        >
                          <i
                            :class="{
                              'fa fa-pencil': data.sts_operasi[2].dokumen,
                              'fa fa-plus': !data.sts_operasi[2].dokumen,
                            }"
                          ></i>
                          {{ data.sts_operasi[2].dokumen ? "Edit" : "Add" }}
                        </button>
                      </td>
                      <td width="160px" class="text-center">
                        <button
                          type="button"
                          @click="modalScope('edit', data.id)"
                          v-can="'kontrak/detail/scope/update'"
                          class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          @click="modalScopeDetail(data.id)"
                          v-can="'kontrak/detail/scope/detail'"
                          class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        &nbsp;&nbsp;
                        <button
                          type="button"
                          @click="deleteScope(data.id)"
                          v-can="'kontrak/detail/scope/delete'"
                          class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="6" class="text-center">Tidak ada data.</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr class="my-2" />
            </section>

            <DokumenKelayakan 
              @showDetail="(res) => pdfViewer(res)"/>

            <DokumenLainLain 
              @showDetail="(res) => pdfViewer(res)"/>

            <section
              class="d-block"
              name="StatusSerahTerima"
              v-can="'kontrak/detail/status_serah_terima/read'"
            >
              <div class="row align-items-center justify-content-between">
                <div class="col-12 col-sm-8 col-lg-6 mb-1">
                  <h4 class="card-title mb-0">
                    Status Serah Terima
                    <span v-html="model.col_status_serahterima_html"></span>
                  </h4>
                </div>

                <div class="col-12 col-sm-4 mb-1">
                  <div class="input-group form-search-rounded">
                    <input
                      class="form-control py-2 border-right-0 border"
                      type="search"
                      id="example-search-input"
                      placeholder="Cari status serah terima.."
                      v-model="sertrimSQ"
                      @input="sertrimSearch"
                    />
                    <span class="input-group-append">
                      <div class="input-group-text bg-transparent">
                        <a href="#" class="text-blue"
                          ><i class="fa fa-search"></i
                        ></a>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div class="table-responsive no-sort w-100">
                <table class="table tabel-status-document w-100">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Tanggal</th>
                      <th>Nama</th>
                      <th>Nomor</th>
                      <th v-can="'kontrak/detail/status_serah_terima/detail'">
                        <i class="feather icon-download-cloud fa-l"></i> File
                      </th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      model.status_serahterima.length > 0 &&
                      sertrimSQ == '' &&
                      sertrimBounce == false
                    "
                  >
                    <tr
                      v-for="(data, index) in model.status_serahterima"
                      :key="index"
                    >
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            disabled
                            :checked="data.date != null"
                            name="customCheck"
                            id="UserRoleManagement1"
                          />
                          <label
                            class="custom-control-label"
                            for="UserRoleManagement1"
                          ></label>
                        </div>
                      </td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>{{ data.name == null ? "-" : data.name }}</td>
                      <td>{{ data.nomor == null ? "-" : data.nomor }}</td>
                      <td v-can="'kontrak/detail/status_serah_terima/detail'">
                        <div v-if="data.dokumen == null">-</div>
                        <div v-else>
                          <p
                            v-for="(item, index) in data.dokumen"
                            :key="item.name"
                          >
                            <a @click="pdfViewer(item.url)" href="javascript:;"
                              >• File {{ index + 1 }} [Lihat Detail]</a
                            >
                          </p>
                        </div>
                      </td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="data.permissions"
                            @click="
                              sertrim_detail = data;
                              modalSertrim('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(data, index) in sertrimSQRes" :key="index">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            disabled
                            :checked="data.date != null"
                            name="customCheck"
                            id="UserRoleManagement1"
                          />
                          <label
                            class="custom-control-label"
                            for="UserRoleManagement1"
                          ></label>
                        </div>
                      </td>
                      <td>{{ data.date == null ? "-" : data.col_date }}</td>
                      <td>{{ data.name == null ? "-" : data.name }}</td>
                      <td>{{ data.nomor == null ? "-" : data.nomor }}</td>
                      <td v-can="'kontrak/detail/status_serah_terima/detail'">
                        <div v-if="data.dokumen == null">-</div>
                        <div v-else>
                          <p
                            v-for="(item, index) in data.dokumen"
                            :key="item.name"
                          >
                            <a @click="pdfViewer(item.url)" href="javascript:;"
                              >• File {{ index + 1 }} [Lihat Detail]</a
                            >
                          </p>
                        </div>
                      </td>
                      <td>
                        <div
                          class="btn-action-block"
                          v-if="model.no_spmk != null"
                        >
                          <button
                            type="button"
                            class="btn btn-icon-small btn-warning-trans waves-effect waves-light"
                            v-can="data.permissions"
                            @click="
                              sertrim_detail = data;
                              modalSertrim('edit');
                            "
                          >
                            <i class="fa fa-pencil"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Uang_Muka"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="uangMukaTr == 'add'">
              Tambah BG Uang Muka
            </h5>
            <h5 class="modal-title" v-if="uangMukaTr == 'edit'">
              Edit BG Uang Muka
            </h5>
            <h5 class="modal-title" v-if="uangMukaTr == 'add_perubahan'">
              Tambah BG Perubahan Uang Muka
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgUangMukaForm">
              <div class="row">
                <div
                  class="col-md-4"
                  v-if="
                    uangMukaTr !== 'add' && bg_uang_muka_form.is_origin == 0
                  "
                >
                  <div class="form-group">
                    <label for="">BG Perubahan</label>
                    <ValidationProvider
                      name="BG Perubahan"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <select
                        class="form-control"
                        v-model="bg_uang_muka_form.id_perubahan"
                      >
                        <option value="">- Pilih BG Uang Muka -</option>
                        <option
                          v-for="(lr, i) in bg_uang_muka_list.rows"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.nomor }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="No"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">No</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Seri"
                        v-model="bg_uang_muka_form.nomor"
                        :class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                      />
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Bank Penerbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">Bank Penerbit</label>
                      <select
                        class="form-control"
                        v-model="bg_uang_muka_form.bank"
                        data-show-subtext="true"
                        data-live-search="true"
                      >
                        <option value="">- Pilih Bank -</option>
                        <option
                          v-for="(lr, i) in bank_list"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.name }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Terbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Terbit</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_uang_muka_form.tanggal_terbit"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal terbit (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berlaku"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berlaku</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_uang_muka_form.tanggal_berlaku"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berlaku (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berakhir"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berakhir</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_uang_muka_form.tanggal_berakhir"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berakhir (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">IDR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.nilai"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">Rp</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">CNY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.cny"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#x5143;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">JPY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.jpy"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#xa5;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">EUR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.eur"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&euro;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">USD</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.usd"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">$</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File BG</label>
                    <vue-dropzone
                      ref="bgUangMukaDropzoneBG"
                      id="wjw"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgUangMukaBGFileHandler"
                      @vdropzone-removed-file="bgUangMukaBGDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_bg[0])"
                      @click="bgUangMukaBGRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                    <ValidationProvider
                      name="File BG"
                      :rules="uangMukaTr == 'edit' ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <input
                        type="hidden"
                        v-model="bg_uang_muka_form.file_bg"
                      />
                      <p style="color: #ea5455">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File Keabsahan</label>
                    <vue-dropzone
                      ref="bgUangMukaDropzoneAB"
                      id="wjw2"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgUangMukaABFileHandler"
                      @vdropzone-removed-file="bgUangMukaABDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_keabsahan[0])"
                      @click="bgUangMukaABRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="uangMukaTr == 'add'"
              @click="bgUangMukaSave()"
              :disabled="bg_uang_muka_ldstate"
            >
              <i v-if="bg_uang_muka_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bg_uang_muka_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="uangMukaTr == 'add_perubahan'"
              @click="bgUangMukaSave()"
              :disabled="bg_uang_muka_ldstate"
            >
              <i v-if="bg_uang_muka_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bg_uang_muka_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="uangMukaTr == 'edit'"
              @click="bgUangMukaUpdate()"
              :disabled="bg_uang_muka_ldstate"
            >
              <i v-if="bg_uang_muka_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bg_uang_muka_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pelaksanaan"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="pelaksanaanTr == 'add'">
              Tambah BG Pelaksanaan
            </h5>
            <h5 class="modal-title" v-if="pelaksanaanTr == 'edit'">
              Edit BG Pelaksanaan
            </h5>
            <h5 class="modal-title" v-if="pelaksanaanTr == 'add_perubahan'">
              Tambah BG Perubahan Pelaksanaan
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPelaksanaanForm">
              <div class="row">
                <div
                  class="col-md-4"
                  v-if="
                    pelaksanaanTr != 'add' && bg_pelaksanaan_form.is_origin == 0
                  "
                >
                  <div class="form-group">
                    <label for="">BG Perubahan</label>
                    <ValidationProvider
                      name="BG Perubahan"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <select
                        class="form-control"
                        v-model="bg_pelaksanaan_form.id_perubahan"
                      >
                        <option value="">- Pilih BG Pelaksanaan -</option>
                        <option
                          v-for="(lr, i) in bg_pelaksanaan_list.rows"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.nomor }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="No"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">No</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Seri"
                        v-model="bg_pelaksanaan_form.nomor"
                        :class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                      />
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Bank Penerbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">Bank Penerbit</label>
                      <select
                        class="form-control"
                        v-model="bg_pelaksanaan_form.bank"
                        data-show-subtext="true"
                        data-live-search="true"
                      >
                        <option value="">- Pilih Bank -</option>
                        <option
                          v-for="(lr, i) in bank_list"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.name }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Terbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Terbit</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pelaksanaan_form.tanggal_terbit"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal terbit (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berlaku"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berlaku</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pelaksanaan_form.tanggal_berlaku"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berlaku (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berakhir"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berakhir</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pelaksanaan_form.tanggal_berakhir"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berakhir (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">IDR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.nilai"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">Rp</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">CNY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.cny"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#x5143;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">JPY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.jpy"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#xa5;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">EUR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.eur"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&euro;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">USD</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.usd"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">$</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File BG</label>
                    <vue-dropzone
                      ref="bgPelaksanaanDropzoneBG"
                      id="aww1"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPelaksanaanBGFileHandler"
                      @vdropzone-removed-file="bgPelaksanaanBGDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_pelaksanaan_form.file_bg[0])"
                      @click="bgPelaksanaanBGRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                    <ValidationProvider
                      name="File BG"
                      :rules="pelaksanaanTr == 'edit' ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <input
                        type="hidden"
                        v-model="bg_pelaksanaan_form.file_bg"
                      />
                      <p style="color: #ea5455">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File Keabsahan</label>
                    <vue-dropzone
                      ref="bgPelaksanaanDropzoneAB"
                      id="aww2"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPelaksanaanABFileHandler"
                      @vdropzone-removed-file="bgPelaksanaanABDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_keabsahan[0])"
                      @click="bgPelaksanaanABRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pelaksanaanTr == 'add'"
              @click="bgPelaksanaanSave()"
              :disabled="bg_pelaksanaan_ldstate"
            >
              <i
                v-if="bg_pelaksanaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pelaksanaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pelaksanaanTr == 'add_perubahan'"
              @click="bgPelaksanaanSave()"
              :disabled="bg_pelaksanaan_ldstate"
            >
              <i
                v-if="bg_pelaksanaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pelaksanaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pelaksanaanTr == 'edit'"
              @click="bgPelaksanaanUpdate()"
              :disabled="bg_pelaksanaan_ldstate"
            >
              <i
                v-if="bg_pelaksanaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pelaksanaan_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pemeliharaan"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="pemeliharaanTr == 'add'">
              Tambah BG Pemeliharaan
            </h5>
            <h5 class="modal-title" v-if="pemeliharaanTr == 'edit'">
              Edit BG Pemeliharaan
            </h5>
            <h5 class="modal-title" v-if="pemeliharaanTr == 'add_perubahan'">
              Tambah BG Perubahan Pemeliharaan
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPemeliharaanForm">
              <div class="row">
                <div
                  class="col-md-4"
                  v-if="
                    pemeliharaanTr != 'add' &&
                    bg_pemeliharaan_form.is_origin == 0
                  "
                >
                  <div class="form-group">
                    <label for="">BG Perubahan</label>
                    <ValidationProvider
                      name="BG Perubahan"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <select
                        class="form-control"
                        v-model="bg_pemeliharaan_form.id_perubahan"
                      >
                        <option value="">- Pilih BG Pelaksanaan -</option>
                        <option
                          v-for="(lr, i) in bg_pemeliharaan_list.rows"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.nomor }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="No"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">No</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Seri"
                        v-model="bg_pemeliharaan_form.nomor"
                        :class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                      />
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Bank Penerbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">Bank Penerbit</label>
                      <select
                        class="form-control"
                        v-model="bg_pemeliharaan_form.bank"
                        data-show-subtext="true"
                        data-live-search="true"
                      >
                        <option value="">- Pilih Bank -</option>
                        <option
                          v-for="(lr, i) in bank_list"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.name }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Terbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Terbit</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pemeliharaan_form.tanggal_terbit"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal terbit (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berlaku"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berlaku</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pemeliharaan_form.tanggal_berlaku"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berlaku (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berakhir"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berakhir</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pemeliharaan_form.tanggal_berakhir"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berakhir (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">IDR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.nilai"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">Rp</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">CNY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.cny"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#x5143;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">JPY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.jpy"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#xa5;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">EUR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.eur"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&euro;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">USD</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.usd"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">$</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File BG</label>
                    <vue-dropzone
                      ref="bgPemeliharaanDropzoneBG"
                      id="aww1"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPemeliharaanBGFileHandler"
                      @vdropzone-removed-file="bgPemeliharaanBGDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_pemeliharaan_form.file_bg[0])"
                      @click="bgPemeliharaanBGRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                    <ValidationProvider
                      name="File BG"
                      :rules="pemeliharaanTr == 'edit' ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <input
                        type="hidden"
                        v-model="bg_pemeliharaan_form.file_bg"
                      />
                      <p style="color: #ea5455">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File Keabsahan</label>
                    <vue-dropzone
                      ref="bgPemeliharaanDropzoneAB"
                      id="aww2"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPemeliharaanABFileHandler"
                      @vdropzone-removed-file="bgPemeliharaanABDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_keabsahan[0])"
                      @click="bgPemeliharaanABRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pemeliharaanTr == 'add'"
              @click="bgPemeliharaanSave()"
              :disabled="bg_pemeliharaan_ldstate"
            >
              <i
                v-if="bg_pemeliharaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pemeliharaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pemeliharaanTr == 'add_perubahan'"
              @click="bgPemeliharaanSave()"
              :disabled="bg_pemeliharaan_ldstate"
            >
              <i
                v-if="bg_pemeliharaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pemeliharaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pemeliharaanTr == 'edit'"
              @click="bgPemeliharaanUpdate()"
              :disabled="bg_pemeliharaan_ldstate"
            >
              <i
                v-if="bg_pemeliharaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pemeliharaan_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_BG_Uang_Muka_Detail"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Detail BG Uang Muka</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-4" v-if="bg_uang_muka_detail.is_origin == 0">
                <div class="form-group">
                  <label for="filter_by">Perubahan BG Uang Muka</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="-"
                    v-model="bg_uang_muka_detail.id_perubahan_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">No</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Nomor Seri"
                    v-model="bg_uang_muka_detail.nomor"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">Bank Penerbit</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Bank Penerbit"
                    v-model="bg_uang_muka_detail.bank_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Terbit</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_uang_muka_detail.tgl_terbit"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berlaku</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_uang_muka_detail.tgl_berlaku"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berakhir</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_uang_muka_detail.tgl_berakhir"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">IDR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.nilai"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">Rp</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">CNY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.cny"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#x5143;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">JPY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.jpy"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#xa5;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">EUR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.eur"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">USD</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.usd"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File BG</label>
                  <vue-dropzone
                    ref="bgUangMukaDropzoneBGDetail"
                    id="akj1"
                    @vdropzone-removed-file="bgUangMukaBGDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File Keabsahan</label>
                  <vue-dropzone
                    ref="bgUangMukaDropzoneABDetail"
                    id="akj2"
                    @vdropzone-removed-file="bgUangMukaABDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_BG_Pelaksanaan_Detail"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Detail BG Pelaksanaan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-4" v-if="bg_pelaksanaan_detail.is_origin == 0">
                <div class="form-group">
                  <label for="filter_by">Perubahan BG Pelaksanaan</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="-"
                    v-model="bg_pelaksanaan_detail.id_perubahan_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">No</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Nomor Seri"
                    v-model="bg_pelaksanaan_detail.nomor"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">Bank Penerbit</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Bank Penerbit"
                    v-model="bg_pelaksanaan_detail.bank_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Terbit</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pelaksanaan_detail.tgl_terbit"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berlaku</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pelaksanaan_detail.tgl_berlaku"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berakhir</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pelaksanaan_detail.tgl_berakhir"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">IDR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.nilai"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">Rp</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">CNY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.cny"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#x5143;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">JPY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.jpy"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#xa5;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">EUR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.eur"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">USD</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.usd"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File BG</label>
                  <vue-dropzone
                    ref="bgPelaksanaanDropzoneBGDetail"
                    id="pel1"
                    @vdropzone-removed-file="bgPelaksanaanBGDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File Keabsahan</label>
                  <vue-dropzone
                    ref="bgPelaksanaanDropzoneABDetail"
                    id="pel2"
                    @vdropzone-removed-file="bgPelaksanaanABDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_BG_Pemeliharaan_Detail"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Detail BG Pemeliharaan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div
                class="col-md-4"
                v-if="bg_pemeliharaan_detail.is_origin == 0"
              >
                <div class="form-group">
                  <label for="filter_by">Perubahan BG Pemeliharaan</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="-"
                    v-model="bg_pemeliharaan_detail.id_perubahan_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">No</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Nomor Seri"
                    v-model="bg_pemeliharaan_detail.nomor"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">Bank Penerbit</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Bank Penerbit"
                    v-model="bg_pemeliharaan_detail.bank_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Terbit</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pemeliharaan_detail.tgl_terbit"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berlaku</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pemeliharaan_detail.tgl_berlaku"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berakhir</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pemeliharaan_detail.tgl_berakhir"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">IDR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.nilai"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">Rp</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">CNY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.cny"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#x5143;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">JPY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.jpy"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#xa5;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">EUR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.eur"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">USD</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.usd"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File BG</label>
                  <vue-dropzone
                    ref="bgPemeliharaanDropzoneBGDetail"
                    id="akj1"
                    @vdropzone-removed-file="bgPemeliharaanBGDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File Keabsahan</label>
                  <vue-dropzone
                    ref="bgPemeliharaanDropzoneABDetail"
                    id="akj2"
                    @vdropzone-removed-file="bgPemeliharaanABDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Uang_Muka_Force"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Ubah Warning Dokumen</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgUangMukaForceValidation">
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Pilih BG</label>

                  <select class="form-control" v-model="bg_uang_muka_force_id">
                    <option value="">- Pilih BG -</option>
                    <option
                      v-for="(lr, i) in bg_uang_muka_list_all"
                      :key="i"
                      :value="lr.id"
                    >
                      {{ lr.nomor }}
                    </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Status</label>

                  <select class="form-control" v-model="bg_uang_muka_force">
                    <option value="">- Pilih Status -</option>
                    <option value="1">Aktif</option>
                    <option value="2">Akan Berakhir</option>
                    <option value="3">Berakhir</option>
                    <option value="5">Selesai</option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bgUangMukaSubmit"
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pelaksanaan_Force"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Ubah Warning Dokumen</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPelaksanaanForceValidation">
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Pilih BG</label>

                  <select
                    class="form-control"
                    v-model="bg_pelaksanaan_force_id"
                  >
                    <option value="">- Pilih BG -</option>
                    <option
                      v-for="(lr, i) in bg_pelaksanaan_list_all"
                      :key="i"
                      :value="lr.id"
                    >
                      {{ lr.nomor }}
                    </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Status</label>

                  <select class="form-control" v-model="bg_pelaksanaan_force">
                    <option value="">- Pilih Status -</option>
                    <option value="1">Aktif</option>
                    <option value="2">Akan Berakhir</option>
                    <option value="3">Berakhir</option>
                    <option value="4">Penyesuaian Nilai</option>
                    <option value="5">Selesai </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bgPelaksanaanSubmit"
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pemeliharaan_Force"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Ubah Warning Dokumen</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPemeliharaanForceValidation">
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Pilih BG</label>

                  <select
                    class="form-control"
                    v-model="bg_pemeliharaan_force_id"
                  >
                    <option value="">- Pilih BG -</option>
                    <option
                      v-for="(lr, i) in bg_pemeliharaan_list_all"
                      :key="i"
                      :value="lr.id"
                    >
                      {{ lr.nomor }}
                    </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Status</label>

                  <select class="form-control" v-model="bg_pemeliharaan_force">
                    <option value="">- Pilih Status -</option>
                    <option value="1">Aktif</option>
                    <option value="2">Akan Berakhir</option>
                    <option value="3">Berakhir</option>
                    <!-- <option value="4">Penyesuaian Nilai</option> -->
                    <option value="5">Selesai</option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bgPemeliharaanSubmit"
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Master_AI"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Ubah Status</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="masterAIForceValidation">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <ValidationProvider
                      name="Status"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Status</label>
                      <select class="form-control" v-model="model_ai.status">
                        <option value="">- Pilih Status -</option>
                        <option value="0">Sudah Ditindaklanjuti</option>
                        <option value="1">Belum Ditindaklanjuti</option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berakhir"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berakhir</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="model_ai.tanggal_perubahan"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berakhir (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nomor Nodin"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">Nomor Nodin</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Seri"
                        v-model="model_ai.nomor_nodin"
                        :class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                      />
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="masterAiSubmit"
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahAmandemen"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="amandemenTr == 'add'">
              Tambah Amandemen
            </h5>
            <h5 class="modal-title" v-if="amandemenTr == 'edit'">
              Edit Amandemen
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="amandemenForm">
              <div class="form-group">
                <label for="">Rekomendasi</label>
                <select
                  class="form-control"
                  v-model="amandemen_form.id_rekomendasi"
                >
                  <option value="">- Pilih Rekomendasi -</option>
                  <option
                    v-for="(lr, i) in list_rekomendasi"
                    :key="i"
                    :value="lr.id"
                  >
                    {{ lr.keterangan }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Nama Amandemen"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Nama Amandemen</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nama amandemen"
                    v-model="amandemen_form.name"
                    :class="
                      Boolean(errors[0])
                        ? 'form-control is-invalid'
                        : 'form-control'
                    "
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Nomor Amandemen"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Nomor Amandemen</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukan nomor"
                    v-model="amandemen_form.nomor"
                    :class="
                      Boolean(errors[0])
                        ? 'form-control is-invalid'
                        : 'form-control'
                    "
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="filter_by">Dokumen</label>
                <vue-dropzone
                  ref="amandemenDropzone"
                  id="wjw"
                  :duplicateCheck="true"
                  @vdropzone-files-added="amandemenFileHandler"
                  @vdropzone-removed-file="amandemenDeleteFile"
                  :options="kontraktual_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(amandemen_form.dokumen[0])"
                  @click="amandemenRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider
                  name="Dokumen"
                  :rules="amandemenTr == 'add' ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input type="hidden" v-model="amandemen_form.dokumen" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Tanggal Dokumen</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="amandemen_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukan tanggal dokumen (dd/mm/yyyy)"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Perihal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Perihal</label>
                  <multiselect
                    v-model="amandemen_form.perihal"
                    :options="[
                      { id: '1', name: 'Perubahan Waktu Pelaksanaan' },
                      { id: '2', name: 'Perubahan Biaya Pemborongan' },
                      { id: '4', name: 'Perubahan Lingkup Pekerjaan' },
                      { id: '3', name: 'Lain-lain' },
                    ]"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Pilih perihal"
                    label="name"
                    track-by="id"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    :preselect-first="false"
                  >
                  </multiselect>

                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div v-if="aman_showTime">
                <div class="form-group">
                  <ValidationProvider
                    name="Tanggal Akhir Kontrak"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="">Tanggal Akhir Kontak</label>
                    <div class="position-relative has-icon-right">
                      <date-picker
                        v-model="amandemen_form.tgl_akhir"
                        format="DD/MM/YYYY"
                        type="date"
                        :input-class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid no-x'
                            : 'form-control'
                        "
                        value-type="YYYY-MM-DD"
                        placeholder="Masukan tanggal akhir kontrak"
                        style="width: 100%"
                      >
                      </date-picker>
                    </div>
                    <small>{{ errors[0] }} </small>
                  </ValidationProvider>
                </div>
              </div>

              <div
                v-if="(aman_showTime || aman_showValue) && model.curva_s == 1"
              >
                <div class="form-group">
                  <label for="filter_by">Curva S</label>
                  <p style="color: #ea5455" class="mb-0">
                    <i> *Extensi file harus format excel</i>
                  </p>
                  <vue-dropzone
                    ref="curvaSDropzone"
                    id="curva_s"
                    :duplicateCheck="true"
                    @vdropzone-files-added="curvaSFileHandler"
                    @vdropzone-removed-file="curvaSDeleteFile"
                    @vdropzone-mounted="vmounted"
                    :options="kontraktual_dropzoneOptionCurvaS"
                  >
                  </vue-dropzone>
                  <button
                    v-if="Boolean(amandemen_form.curva_s[0])"
                    @click="curvaSRemoveFile"
                    class="btn btn-danger"
                    style="margin-top: 10px"
                  >
                    Reset File
                  </button>
                  <!-- <ValidationProvider
                    name="Curva S"
                    :rules="amandemenTr == 'add' ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <input type="hidden" v-model="amandemen_form.curva_s" />
                    <p style="color: #ea5455">{{ errors[0] }}</p>
                  </ValidationProvider> -->
                </div>
              </div>

              <div v-if="aman_showValue">
                <h5>Nilai Kontrak</h5>
                <div class="row mb-2">
                  <div class="col-12 col-sm-4">
                    <div class="form-group">
                      <label for="">IDR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="amandemen_form.idr"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">Rp</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-4">
                    <div class="form-group">
                      <label for="">CNY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="amandemen_form.cny"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#x5143;</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4">
                    <div class="form-group">
                      <label for="">JPY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="amandemen_form.jpy"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#xa5;</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-4">
                    <div class="form-group">
                      <label for="">EUR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="amandemen_form.eur"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&euro;</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-4">
                    <div class="form-group">
                      <label for="">USD</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="amandemen_form.usd"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">$</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <ValidationProvider
                      name="Nilai Kontrak"
                      v-slot="{ errors }"
                    >
                      <input
                        type="hidden"
                        v-model="amandemen_form.nilai_kontrak"
                      />
                      <p style="color: #ea5455">
                        {{
                          Boolean(errors[0])
                            ? "*Nilai Kontrak tidak boleh Rp 0"
                            : ""
                        }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <p>Equivalent</p>
                <h3 class="font-bold">{{ equivalent | currency }}</h3>
              </div>

              <div v-if="aman_showOther">
                <div class="form-group">
                  <ValidationProvider
                    name="Tentang Perubahan"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="">Tentang Perubahan</label>
                    <!-- <textarea v-model="amandemen_form.other" cols="30" rows="5" class="form-control"></textarea> -->
                    <quill-editor
                      v-model="amandemen_form.other"
                      :options="editorOption"
                    />
                    <small>{{ errors[0] }} </small>
                  </ValidationProvider>
                </div>
              </div>

              <div v-if="aman_showChangeLingkup">
                <div class="form-group">
                  <ValidationProvider
                    name="Tentang Perubahan"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="">Tentang Perubahan</label>
                    <!-- <textarea v-model="amandemen_form.other" cols="30" rows="5" class="form-control"></textarea> -->
                    <quill-editor
                      v-model="amandemen_form.lingkup"
                      :options="editorOption"
                    />
                    <small>{{ errors[0] }} </small>
                  </ValidationProvider>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="amandemenTr == 'add'"
              @click="amandemenSave()"
              :disabled="amandemen_ldstate"
            >
              <i v-if="amandemen_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!amandemen_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="amandemenTr == 'edit'"
              @click="amandemenEdit()"
              :disabled="amandemen_ldstate"
            >
              <i v-if="amandemen_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!amandemen_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahScope"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">
              {{ stateScope == "add" ? "Tambah" : "Edit" }} Scope
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="scopeForm">
              <div class="form-group">
                <ValidationProvider
                  name="Nama Scope"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="nama_scope">Nama Scope</label>
                  <input
                    type="text"
                    id="nama_scope"
                    class="form-control"
                    placeholder="Nama Scope"
                    v-model="scope_form.name"
                    :class="
                      Boolean(errors[0])
                        ? 'form-control is-invalid'
                        : 'form-control'
                    "
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="scopeSave()"
              :disabled="scope_ldstate"
            >
              <i v-if="scope_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!scope_ldstate">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_ScopeDetail"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Scope Detail</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <form>
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label"
                  >Scope Name</label
                >
                <div class="col-sm-8 align-self-center">
                  {{ scopeDetail.name }}
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPassword" class="col-sm-4 col-form-label"
                  >RLB</label
                >
                <div class="col-sm-8">
                  <ul>
                    <li
                      v-for="(rlb, i) in scopeDetail.sts_operasi[0].dokumen"
                      :key="i"
                    >
                      <a @click="pdfViewer(rlb.url)" href="javascript:;"
                        >{{ rlb.name }} [Lihat Detail]</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPassword" class="col-sm-4 col-form-label"
                  >Energize</label
                >
                <div class="col-sm-8">
                  <ul>
                    <li
                      v-for="(energize, i) in scopeDetail.sts_operasi[1]
                        .dokumen"
                      :key="i"
                    >
                      <a @click="pdfViewer(energize.url)" href="javascript:;"
                        >{{ energize.name }} [Lihat Detail]</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label for="inputPassword" class="col-sm-4 col-form-label"
                  >SLO</label
                >
                <div class="col-sm-8">
                  <ul>
                    <li
                      v-for="(slo, i) in scopeDetail.sts_operasi[2].dokumen"
                      :key="i"
                    >
                      <a @click="pdfViewer(slo.url)" href="javascript:;"
                        >{{ slo.name }} [Lihat Detail]</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahRLB"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Update RLB</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="scopeRLBForm">
              <div class="form-group">
                <label for="">Nomor</label>
                <input
                  type="text"
                  required="required"
                  class="form-control"
                  v-model="scope_rlb_form.nomor"
                />
              </div>

              <div class="form-group">
                <label for="filter_by">Dokumen</label>
                <vue-dropzone
                  ref="rlbDropzone"
                  id="scprlb"
                  :duplicateCheck="true"
                  @vdropzone-files-added="scopeRLBFileHandler"
                  @vdropzone-removed-file="scopeRLBDeleteFile"
                  :options="kontraktual_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(scope_rlb_form.document[0])"
                  @click="amandemenRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider
                  name="Dokumen"
                  :rules="!scopeHasFile ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input type="hidden" v-model="scope_rlb_form.document" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Tanggal Dokumen</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="scope_rlb_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukan tanggal dokumen (dd/mm/yyyy)"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="scopeRLBSave()"
              :disabled="scoperlb_ldstate"
            >
              <i v-if="scoperlb_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!scoperlb_ldstate">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahEnergize"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Update Energize</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="scopeEnergizeForm">
              <div class="form-group">
                <label for="">Nomor</label>
                <input
                  type="text"
                  required="required"
                  class="form-control"
                  v-model="scope_energize_form.nomor"
                />
              </div>

              <div class="form-group">
                <label for="filter_by">Dokumen</label>
                <vue-dropzone
                  ref="energizeDropzone"
                  id="scpener"
                  :duplicateCheck="true"
                  @vdropzone-files-added="scopeEnergizeFileHandler"
                  @vdropzone-removed-file="scopeEnergizeDeleteFile"
                  :options="kontraktual_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(scope_energize_form.document[0])"
                  @click="amandemenRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider
                  name="Dokumen"
                  :rules="!scopeEnergizeHasFile ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input type="hidden" v-model="scope_energize_form.document" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Tanggal Dokumen</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="scope_energize_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukan tanggal dokumen (dd/mm/yyyy)"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="scopeEnergizeSave()"
              :disabled="scopeenergize_ldstate"
            >
              <i v-if="scopeenergize_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!scopeenergize_ldstate">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahSLO"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Update SLO</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="scopeSLOForm">
              <div class="form-group">
                <label for="">Nomor</label>
                <input
                  type="text"
                  required="required"
                  class="form-control"
                  v-model="scope_slo_form.nomor"
                />
              </div>

              <div class="form-group">
                <label for="filter_by">Dokumen</label>
                <vue-dropzone
                  ref="sloDropzone"
                  id="scpslo"
                  :duplicateCheck="true"
                  @vdropzone-files-added="scopeSLOFileHandler"
                  @vdropzone-removed-file="scopeSLODeleteFile"
                  :options="kontraktual_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(scope_slo_form.document[0])"
                  @click="amandemenRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider
                  name="Dokumen"
                  :rules="!scopeSLOHasFile ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input type="hidden" v-model="scope_slo_form.document" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Tanggal Dokumen</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="scope_slo_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukan tanggal dokumen (dd/mm/yyyy)"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="scopeSLOSave()"
              :disabled="scopeslo_ldstate"
            >
              <i v-if="scopeslo_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!scopeslo_ldstate">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahBAP"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="bapTr == 'add'">Tambah BAP</h5>
            <h5 class="modal-title" v-if="bapTr == 'edit'">Edit BAP</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bapForm">
              <div class="form-group">
                <ValidationProvider
                  name="Nama BAP"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>Nama BAP</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="'BAP (ke) - (%BAPP)'"
                    v-model="bap_form.name"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Nomor"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>Nomor</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukan nomor"
                    v-model="bap_form.nomor"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label>Tanggal</label>
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="bap_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukkan tanggal"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="filter_by">Dokumen</label>
                <vue-dropzone
                  ref="bapDropzone"
                  id="wjw"
                  :duplicateCheck="true"
                  @vdropzone-files-added="bapFileHandler"
                  @vdropzone-removed-file="bapDeleteFile"
                  :options="bap_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(bap_form.document[0])"
                  @click="bapRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider
                  name="Dokumen"
                  :rules="bapTr == 'add' ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input type="hidden" v-model="bap_form.document" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <h5>Nilai BAP</h5>
              <div class="row mb-2">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">IDR</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="bap_form.idr"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">Rp</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">CNY</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="bap_form.cny"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">&#x5143;</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">JPY</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="bap_form.jpy"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">&#xa5;</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">EUR</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="bap_form.eur"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">&euro;</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">USD</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="bap_form.usd"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">$</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12">
                  <ValidationProvider
                    name="Nilai Kontrak"
                    v-slot="{ errors }"
                    rules="required|min_value:1"
                  >
                    <input type="hidden" v-model="bap_form.nilai" />
                    <p style="color: #ea5455">
                      {{
                        Boolean(errors[0]) ? "*Nilai BAP tidak boleh Rp 0" : ""
                      }}
                    </p>
                  </ValidationProvider>
                </div>
              </div>

              <p>Equivalent</p>
              <h3 class="font-bold">{{ equivalentBAP | currency }}</h3>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bapSave"
              v-if="bapTr == 'add'"
              :disabled="bap_ldstate"
            >
              <i v-if="bap_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bap_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bapEdit"
              v-if="bapTr == 'edit'"
              :disabled="bap_ldstate"
            >
              <i v-if="bap_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bap_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_UpdateSPMK"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <ValidationObserver
          v-slot="{ invalid }"
          style="width: 100%"
          ref="updateSPMK"
        >
          <div class="modal-content">
            <div class="modal-header modal-primary white">
              <h5 class="modal-title" id="myModalLabel160">SPMK</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <ValidationProvider
                  name="Nomor SPMK"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Nomor SPMK</label>
                  <input
                    type="text"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    v-model="spmk.no_spmk"
                    class="form-control"
                    placeholder="Masukkan nomor SPMK"
                    aria-describedby="helpId"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="">Tanggal SPMK</label>
                <ValidationProvider
                  name="Tanggal SPMK"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="spmk.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Tanggal SPMK"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Durasi"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <label for="">Durasi</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    v-model="spmk.durasi"
                    @input="calculateDate"
                    placeholder="Durasi dalam hari"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="">Tanggal Akhir</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="tgl_akhir_text"
                  readonly
                  placeholder=""
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Tutup
              </button>
              <button
                type="button"
                :disabled="invalid"
                class="btn btn-primary"
                data-dismiss="modal"
                @click="update_spmk()"
              >
                Simpan
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_UpdateStatusKontraktual"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">
              Update Dokumen Kontraktual
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="kontraktualForm">
              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Tanggal</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="kontraktual_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukkan tanggal"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Nomor"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Nomor</label>
                  <input
                    type="text"
                    v-model="kontraktual_form.nomor"
                    class="form-control"
                    placeholder="Masukan nomor"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    aria-describedby="helpId"
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="filter_by">Upload File</label>
                <vue-dropzone
                  ref="kontraktualDropzone"
                  id="awdawd"
                  :duplicateCheck="true"
                  @vdropzone-files-added="kontraktualFileHandler"
                  @vdropzone-removed-file="kontraktualDeleteFile"
                  :options="kontraktual_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(kontraktual_form.dokumen[0])"
                  @click="kontraktualRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider name="Dokumen" v-slot="{ errors }">
                  <input type="hidden" v-model="kontraktual_form.dokumen" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <!-- <div class="form-group">
                            <ValidationProvider name="Tanggal Operasi" rules="required" v-slot="{ errors }">
                            <label for="">Tanggal Operasi</label>
                            <div class="position-relative has-icon-right">
                                <date-picker
                                    v-model="kontraktual_form.tgl_operasi"
                                    format="DD/MM/YYYY"
                                    type="date"
                                    :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                    value-type="YYYY-MM-DD"
                                    placeholder="Tanggal Operasi"
                                    style="width: 100%;"
                                    >
                                </date-picker>
                            </div>
                            <small>{{ errors[0] }} </small>
                            </ValidationProvider>
                        </div> -->
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="kontraktualEdit()"
              :disabled="kontraktual_ldstate"
            >
              <i v-if="kontraktual_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!kontraktual_ldstate">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_StatusOperasi"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">
              Update Status {{ operasi_detail.name }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="operasiForm">
              <div class="form-group">
                <ValidationProvider
                  name="Nomor"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Nomor</label>
                  <input
                    type="text"
                    v-model="operasi_form.nomor"
                    class="form-control"
                    placeholder="Masukan nomor"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    aria-describedby="helpId"
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="filter_by">Upload File</label>
                <vue-dropzone
                  ref="operasiDropzone"
                  id="awfawf"
                  :duplicateCheck="true"
                  @vdropzone-files-added="operasiFileHandler"
                  @vdropzone-removed-file="operasiDeleteFile"
                  :options="kontraktual_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(operasi_form.dokumen[0])"
                  @click="operasiRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider name="Dokumen" v-slot="{ errors }">
                  <input type="hidden" v-model="operasi_form.dokumen" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Tanggal</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="operasi_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      :placeholder="'Tanggal ' + operasi_detail.name"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="operasiEdit()"
              :disabled="operasi_ldstate"
            >
              <i v-if="operasi_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!operasi_ldstate">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Sertrim"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">
              Update Serah Terima [{{ sertrim_detail.name }}]
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="sertrimForm">
              <div class="form-group" v-if="sertrim_detail.is_numbered">
                <ValidationProvider
                  name="Nomor"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Nomor</label>
                  <input
                    type="text"
                    v-model="sertrim_form.nomor"
                    class="form-control"
                    placeholder="Masukan nomor"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    aria-describedby="helpId"
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="filter_by">Upload File</label>
                <vue-dropzone
                  ref="sertrimDropzone"
                  id="awfawfaw"
                  :duplicateCheck="true"
                  @vdropzone-files-added="sertrimFileHandler"
                  @vdropzone-removed-file="sertrimDeleteFile"
                  :options="kontraktual_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(sertrim_form.dokumen[0])"
                  @click="sertrimRemoveFile"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider name="Dokumen" v-slot="{ errors }">
                  <input type="hidden" v-model="sertrim_form.dokumen" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Tanggal</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="sertrim_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Tanggal Serah Terima"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="sertrimEdit()"
              :disabled="sertrim_ldstate"
            >
              <i v-if="sertrim_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!sertrim_ldstate">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Approve"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Approval</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="font-bold mb-2">Anda yakin menyetujui progress ini?</h4>
            <div class="row">
              <div class="col-lg-6">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-block"
                  data-dismiss="modal"
                >
                  Kembali
                </button>
              </div>
              <div class="col-lg-6">
                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  data-dismiss="modal"
                  @click="approve_progress(progress_id)"
                >
                  Ya
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_NotApprove"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Approval</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="font-bold mb-2">
              Anda yakin tidak menyetujui progress ini?
            </h4>
            <div class="row">
              <div class="col-lg-6">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-block"
                  data-dismiss="modal"
                >
                  Kembali
                </button>
              </div>
              <div class="col-lg-6">
                <button
                  type="button"
                  class="btn btn-primary btn-block"
                  data-dismiss="modal"
                  @click="not_approve_progress(progress_id)"
                >
                  Ya
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_DetailProgress"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Detail Progress</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="status waiting mb-2"
              v-if="progress_detail.approved_by == null"
            >
              Waiting For Approval
            </div>
            <div
              class="status not_approve mb-2"
              v-else-if="progress_detail.approved_by == 0"
            >
              Not Approved
            </div>
            <div class="status approve mb-2" v-else>Approved</div>
            <div class="row">
              <div class="col-9">
                <h5 class="font-600 mb-2">Periode Progress</h5>
                <h5 class="mb-2">{{ progress_detail.name }}</h5>
                <h5 class="font-600 mb-2">Uraian</h5>
                <h5
                  class="mb-2 ql-editor"
                  style="height: auto"
                  v-html="progress_detail.description"
                ></h5>
                <h5 class="font-600 mb-2" v-if="progress_detail.type != 2">
                  Cuaca
                </h5>
                <h5 class="mb-2" v-if="progress_detail.type != 2">
                  {{ progress_detail.cuaca == 1 ? "Hujan" : "Cerah" }}
                </h5>
                <div
                  v-if="
                    progress_detail.pekerja > 0 &&
                    progress_detail.jenis_progress != 0
                  "
                >
                  <h5 class="font-600">Jumlah Pekerja</h5>
                  <h5 class="mb-2">{{ progress_detail.pekerja }}</h5>
                </div>
              </div>
              <div class="col-3 text-center pr-2" v-if="progress_detail.user">
                <h5 class="font-600">
                  Input By: {{ progress_detail.user.position_name }}
                </h5>
                <vue-qrcode
                  :value="
                    progress_detail.user.name +
                    ' (' +
                    progress_detail.user.position_name +
                    ') ' +
                    progress_detail.user.phone
                  "
                />
                <h5>{{ progress_detail.user.name }}</h5>
                <h5>{{ progress_detail.user.phone }}</h5>
              </div>
            </div>

            <h5
              class="font-600 mb-2"
              v-if="progress_detail.jenis_progress != 0"
            >
              Foto
            </h5>
            <div class="row mb-2" v-if="progress_detail.jenis_progress != 0">
              <div
                class="col-lg-3 col-6"
                v-for="(data, index) in progress_detail.image"
                :key="index"
              >
                <img
                  :src="data.url"
                  class="img-fluid"
                  style="cursor: zoom-in"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#Modal_DetailFoto"
                  @click="
                    (detailFoto = progress_detail.image), (showImage = index)
                  "
                />
              </div>
            </div>
            <h5
              class="font-600"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              Progress EM
            </h5>
            <div
              class="row align-items-center mb-2"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              <div class="col-11">
                <div class="progress progress-bar-primary">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :aria-valuenow="progress_detail.progress_em"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progress_detail.progress_em + '%' }"
                  ></div>
                </div>
              </div>
              <div class="col-1">
                <h5 class="font-600">{{ progress_detail.progress_em }}%</h5>
              </div>
            </div>
            <h5
              class="font-600"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              Progress Sipil
            </h5>
            <div
              class="row align-items-center mb-2"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              <div class="col-11">
                <div class="progress progress-bar-primary">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :aria-valuenow="progress_detail.progress_sipil"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progress_detail.progress_sipil + '%' }"
                  ></div>
                </div>
              </div>
              <div class="col-1">
                <h5 class="font-600">{{ progress_detail.progress_sipil }}%</h5>
              </div>
            </div>
            <h5
              class="font-600"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              Progress Procurement
            </h5>
            <div
              class="row align-items-center mb-2"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              <div class="col-11">
                <div class="progress progress-bar-primary">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :aria-valuenow="progress_detail.progress_prequirement"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{
                      width: progress_detail.progress_prequirement + '%',
                    }"
                  ></div>
                </div>
              </div>
              <div class="col-1">
                <h5 class="font-600">
                  {{ progress_detail.progress_prequirement }}%
                </h5>
              </div>
            </div>
            <h5
              class="font-600"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              Progress Total
            </h5>
            <div
              class="row align-items-center mb-2"
              v-if="
                progress_detail.type != 1 && progress_detail.jenis_progress != 0
              "
            >
              <div class="col-11">
                <div class="progress progress-bar-primary">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :aria-valuenow="progress_detail.progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progress_detail.progress + '%' }"
                  ></div>
                </div>
              </div>
              <div class="col-1">
                <h5 class="font-600">{{ progress_detail.progress }}%</h5>
              </div>
            </div>

            <div class="row" v-if="progress_detail.approved_by != 0">
              <div class="col-lg-6">
                <button
                  type="button"
                  class="btn btn-danger col"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#Modal_NotApprove"
                  v-if="isShowApprove(progress_detail)"
                  @click="progress_id = progress_detail.id"
                  v-can="'kontrak/detail/progress/approve'"
                >
                  Not Approve
                </button>
              </div>
              <div class="col-lg-6">
                <button
                  type="button"
                  class="btn btn-primary col"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#Modal_Approve"
                  v-if="isShowApprove(progress_detail)"
                  @click="progress_id = progress_detail.id"
                  v-can="'kontrak/detail/progress/approve'"
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_DetailFoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      v-if="detailFoto != ''"
      style="overflow: auto !important"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Detail Foto</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="detailFoto = ''"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  :data-slide-to="index"
                  v-for="(data, index) in detailFoto"
                  :key="index"
                  :class="showImage == index ? 'active' : ''"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  class="carousel-item"
                  :class="showImage == index ? 'active' : ''"
                  v-for="(data, index) in detailFoto"
                  :key="index"
                >
                  <img class="d-block w-100" :src="data.url" />
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_DetailSolusi"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      v-if="solusi_detail != ''"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Detail Solusi</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="detailFoto = ''"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="font-600 mb-2">Nama Solusi</h5>
            <h5 class="mb-2">{{ solusi_detail.name }}</h5>
            <h5 class="font-600 mb-2">Nama Kendala</h5>
            <h5 class="mb-2">{{ solusi_detail.kendala.name }}</h5>
            <h5 class="font-600 mb-2">Uraian</h5>
            <h5 class="mb-2 ql-editor" v-html="solusi_detail.uraian"></h5>
            <h5 class="font-600 mb-2">Foto</h5>
            <div class="row mb-2">
              <div
                class="col-lg-3 col-6"
                v-for="(data, index) in solusi_detail.image"
                :key="index"
              >
                <img
                  :src="data.url"
                  class="img-fluid"
                  style="cursor: zoom-in"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#Modal_DetailFoto"
                  @click="
                    (detailFoto = solusi_detail.image), (showImage = index)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_DetailKendala"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      v-if="kendala_detail != ''"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Detail Kendala</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="detailFoto = ''"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5 class="font-600 mb-2">Nama Kendala</h5>
            <h5 class="mb-2">{{ kendala_detail.name }}</h5>
            <h5 class="font-600 mb-2">Periode Progress</h5>
            <h5 class="mb-2 text-capitalize">
              {{
                kendala_detail.periode_progress
                  ? kendala_detail.periode_progress.name
                  : "-"
              }}
            </h5>
            <h5 class="font-600 mb-2">Uraian</h5>
            <h5 class="mb-2 ql-editor" v-html="kendala_detail.uraian"></h5>
            <h5 class="font-600 mb-2" v-if="kendala_detail.image != null">
              Foto
            </h5>
            <div class="row mb-2">
              <div
                class="col-lg-3 col-6"
                v-for="(data, index) in kendala_detail.image"
                :key="index"
              >
                <img
                  :src="data.url"
                  class="img-fluid"
                  style="cursor: zoom-in"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#Modal_DetailFoto"
                  @click="
                    (detailFoto = kendala_detail.image), (showImage = index)
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahProgress"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="progressTr == 'add'">
              Tambah Progress
            </h5>
            <h5 class="modal-title" v-if="progressTr == 'edit'">
              Edit Progress
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="progressClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="progressForm">
              <ValidationProvider
                name="Jenis Progress"
                rules="required"
                v-slot="{ errors }"
                v-if="progress_form.type == 2"
              >
                <div class="form-group">
                  <label for="filter_by">Jenis Progress</label>
                  <select
                    v-model="progress_form.jenis_progress"
                    class="form-control"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                  >
                    <option value="1">Input Progress Baru</option>
                    <option value="0">Tidak Ada Aktifitas</option>
                  </select>
                  <small>{{ errors[0] }}</small>
                </div>
              </ValidationProvider>

              <ValidationProvider
                name="Periode Progress"
                rules="required"
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="filter_by">Periode Progress</label>
                  <input
                    type="text"
                    v-model="progress_form.name"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    class="form-control"
                    placeholder="Masukan periode progress"
                  />
                  <small>{{ errors[0] }}</small>
                </div>
              </ValidationProvider>

              <ValidationProvider
                name="Uraian"
                rules="required"
                v-slot="{ errors }"
                v-if="progress_form.type == 1 || progress_form.type == 2"
              >
                <div class="form-group">
                  <label for="filter_by">Uraian</label>
                  <quill-editor
                    v-model="progress_form.description"
                    :options="editorOption"
                  />
                  <small>{{ errors[0] }}</small>
                </div>
              </ValidationProvider>

              <label for="filter_by" v-show="progress_form.jenis_progress == 1"
                >Foto</label
              >
              <vue-dropzone
                ref="createProgressDropzone"
                id="dafavasv"
                v-show="progress_form.jenis_progress == 1"
                :duplicateCheck="true"
                @vdropzone-files-added="progressFileHandler"
                @vdropzone-removed-file="progressDeleteFile"
                :options="progress_create_dropzoneOptions"
              >
              </vue-dropzone>
              <button
                v-if="progressTotalFile > 0"
                @click="progressRemoveFile"
                class="btn btn-danger"
                style="margin-top: 10px"
              >
                Reset File
              </button>
              <ValidationProvider
                name="Foto"
                :rules="
                  progress_form.jenis_progress == 1 && progressTotalFile == 0
                    ? 'required'
                    : ''
                "
                v-slot="{ errors }"
              >
                <input type="hidden" v-model="progress_form.image" />
                <p style="color: #ea5455">{{ errors[0] }}</p>
              </ValidationProvider>

              <ValidationProvider
                name="Cuaca"
                rules="required|custom"
                v-slot="{ errors }"
                v-if="progress_form.type == 1"
              >
                <div class="form-group">
                  <label for="filter_by">Cuaca</label>
                  <select
                    v-model="progress_form.cuaca"
                    class="form-control"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                  >
                    <option value="0" selected>Pilih Cuaca</option>
                    <option value="1">Hujan</option>
                    <option value="2">Cerah</option>
                  </select>
                  <small>{{ errors[0] }}</small>
                </div>
              </ValidationProvider>

              <ValidationProvider
                name="Jumlah pekerja"
                v-if="progress_form.jenis_progress == 1"
                :rules="
                  progress_form.type == 2
                    ? 'min_value:1'
                    : 'required|min_value:1'
                "
                v-slot="{ errors }"
              >
                <div class="form-group">
                  <label for="filter_by">Jumlah pekerja</label>
                  <input
                    type="text"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    class="form-control"
                    placeholder="1"
                    v-model="progress_form.pekerja"
                  />
                  <small>{{ errors[0] }}</small>
                </div>
              </ValidationProvider>

              <div
                class="form-group"
                v-if="
                  progress_form.type == 2 && progress_form.jenis_progress == 1
                "
              >
                <ValidationProvider
                  name="Progress EM"
                  rules="required|min_value:0|max_value:100"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Progress EM</label>
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="input-group">
                        <input
                          type="text"
                          :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                          class="form-control"
                          placeholder="100"
                          v-model="progress_form.progress_em"
                        />
                        <div class="input-group-append input-group-primary">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>

                <ValidationProvider
                  name="Progress sipil"
                  rules="required|min_value:0|max_value:100"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Progress Sipil</label>
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="input-group">
                        <input
                          type="text"
                          :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                          class="form-control"
                          placeholder="100"
                          v-model="progress_form.progress_sipil"
                        />
                        <div class="input-group-append input-group-primary">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>

                <ValidationProvider
                  name="Progress procurement"
                  rules="required|min_value:0|max_value:100"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Progress Procurement</label>
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="input-group">
                        <input
                          type="text"
                          :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                          class="form-control"
                          placeholder="100"
                          v-model="progress_form.progress_prequirement"
                        />
                        <div class="input-group-append input-group-primary">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>

                <ValidationProvider
                  name="Progress total"
                  rules="required|min_value:0|max_value:100"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Progress Total</label>
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <div class="input-group">
                        <input
                          type="text"
                          :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                          class="form-control"
                          placeholder="100"
                          v-model="progress_form.progress"
                        />
                        <div class="input-group-append input-group-primary">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              :disabled="isUpload_progress"
              class="btn btn-primary"
              @click="progressSave"
              v-if="progressTr == 'add'"
            >
              <i v-if="isUpload_progress" class="fa fa-spinner fa-spin"></i>
              <span v-if="!isUpload_progress">Simpan</span>
            </button>
            <button
              type="button"
              :disabled="isUpload_progress"
              class="btn btn-primary"
              @click="progressEdit"
              v-if="progressTr == 'edit'"
            >
              <i v-if="isUpload_progress" class="fa fa-spinner fa-spin"></i>
              <span v-if="!isUpload_progress">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahKendala"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="kendalaTr == 'add'">
              Tambah Kendala
            </h5>
            <h5 class="modal-title" v-if="kendalaTr == 'edit'">Edit Kendala</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="progressClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="kendalaForm">
              <div v-for="(v, i) in kendala_form" :key="i">
                <div v-if="i > 0" class="border" style="margin: 20px 0"></div>
                <div class="row">
                  <div class="col-md-10">
                    <div class="form-group">
                      <ValidationProvider
                        :name="i > 0 ? 'Nama Kendala' + (i + 1) : ''"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label for="filter_by"
                          >Nama Kendala {{ i > 0 ? i + 1 : "" }}</label
                        >
                        <select
                          v-model="kendala_form[i].name_sel"
                          class="form-control"
                          :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                        >
                          <option
                            v-for="item in kendala_choice"
                            :value="item"
                            :key="item"
                          >
                            {{ item }}
                          </option>
                          <option value="0">Lain-lain</option>
                        </select>

                        <small>{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <button
                      class="btn-transparent"
                      @click="addMoreKendala"
                      style="margin-top: 30px"
                    >
                      <svg
                        class="img-fluid mr-2"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                      >
                        <path
                          fill="#17536A"
                          fill-rule="nonzero"
                          d="M10 .134c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zm0 18.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm3.75-9.375h-3.125V6.384a.625.625 0 0 0-1.25 0v3.125H6.25a.625.625 0 0 0 0 1.25h3.125v3.125a.625.625 0 0 0 1.25 0v-3.125h3.125a.625.625 0 0 0 0-1.25z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div class="col-md-1">
                    <button
                      class="btn-transparent"
                      @click="decreaseKendala(i)"
                      style="margin-top: 30px"
                      v-if="i > 0"
                    >
                      <svg
                        class="img-fluid mr-2"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                      >
                        <path
                          fill="#DA3E2C"
                          fill-rule="nonzero"
                          d="M17.314 3.314C13.547-.726 7.219-.946 3.18 2.82c-4.04 3.767-4.26 10.094-.494 14.134 3.767 4.039 10.095 4.26 14.134.493 4.039-3.766 4.26-10.094.494-14.133zM3.6 16.1A8.75 8.75 0 1 1 16.399 4.166 8.75 8.75 0 0 1 3.601 16.101zm9.414-3.651l-2.132-2.286 2.286-2.13a.625.625 0 0 0-.853-.915L10.031 9.25 7.9 6.965a.625.625 0 0 0-.915.852l2.132 2.286-2.286 2.13a.625.625 0 0 0 .853.915l2.285-2.131 2.131 2.285a.625.625 0 0 0 .915-.852z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="form-group" v-if="kendala_form[i].name_sel == '0'">
                  <ValidationProvider
                    :name="i > 0 ? 'Nama Kendala' + (i + 1) : 'Nama Kendala'"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="filter_by"></label>
                    <input
                      type="text"
                      v-model="kendala_form[i].name"
                      :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                      class="form-control"
                      placeholder="Silahkan tambahkan kendala"
                    />
                    <small>{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="form-group" v-if="kendala_form[i].name_sel != '0'">
                  <ValidationProvider
                    name="Progress"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="filter_by">Periode Progress</label>
                    <select
                      v-model="kendala_form[i].id_periode_progress"
                      class="form-control text-capitalize"
                      :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    >
                      <option value="">Pilih Progress</option>
                      <option
                        v-for="item in progresCurva"
                        :value="item.id"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <small>{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <ValidationProvider
                    :name="i > 0 ? 'Uraian' + (i + 1) : 'Uraian'"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="filter_by">Uraian</label>
                    <quill-editor
                      v-model="kendala_form[i].uraian"
                      :options="editorOption"
                    />
                    <small>{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>

                <label for="filter_by">Foto</label>
                <vue-dropzone
                  ref="kendalaDropzone"
                  :id="'dzk-' + i"
                  :duplicateCheck="true"
                  @vdropzone-files-added="kendalaFileHandler"
                  @vdropzone-removed-file="kendalaDeleteFile"
                  :options="progress_create_dropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="Boolean(kendala_form[i].total_file > 0)"
                  @click="kendalaRemoveFile(i)"
                  class="btn btn-danger"
                  style="margin-top: 10px"
                >
                  Reset File
                </button>
                <ValidationProvider
                  :name="i > 0 ? 'Foto' + (i + 1) : 'Foto'"
                  v-slot="{ errors }"
                >
                  <input type="hidden" v-model="kendala_form[i].image" />
                  <p style="color: #ea5455">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="kendalaSave"
              v-if="kendalaTr == 'add'"
              :disabled="kendala_ldstate"
            >
              <i v-if="kendala_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!kendala_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="kendalaEdit"
              v-if="kendalaTr == 'edit'"
              :disabled="kendala_ldstate"
            >
              <i v-if="kendala_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!kendala_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahSolusi"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="solusiTr == 'add'">Tambah Solusi</h5>
            <h5 class="modal-title" v-if="solusiTr == 'edit'">Edit Solusi</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="progressClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="solusiForm">
              <div class="form-group">
                <ValidationProvider
                  name="Nama Solusi"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Nama Solusi</label>
                  <input
                    type="text"
                    v-model="solusi_form.name"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    class="form-control"
                    placeholder="Masukan nama solusi"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Uraian"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="filter_by">Uraian</label>
                  <quill-editor
                    v-model="solusi_form.uraian"
                    :options="editorOption"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <label for="filter_by">Foto</label>
              <vue-dropzone
                ref="solusiDropzone"
                id="wjw22"
                :duplicateCheck="true"
                @vdropzone-files-added="solusiFileHandler"
                @vdropzone-removed-file="solusiDeleteFile"
                :options="progress_create_dropzoneOptions"
              >
              </vue-dropzone>
              <button
                v-if="Boolean(solusiTotalFile > 0)"
                @click="solusiRemoveFile"
                class="btn btn-danger"
                style="margin-top: 10px"
              >
                Reset File
              </button>
              <ValidationProvider
                name="Foto"
                :rules="solusiTotalFile == 0 ? 'required' : ''"
                v-slot="{ errors }"
              >
                <input type="hidden" v-model="solusi_form.image" />
                <p style="color: #ea5455">{{ errors[0] }}</p>
              </ValidationProvider>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="solusiSave"
              v-if="solusiTr == 'add'"
              :disabled="solusi_ldstate"
            >
              <i v-if="solusi_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!solusi_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="solusiEdit"
              v-if="solusiTr == 'edit'"
              :disabled="solusi_ldstate"
            >
              <i v-if="solusi_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!solusi_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_PDFViewer"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 95%; max-height: 95%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">View File</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="pdfFile" style="height: 80vh">
            <embed
              :src="pdfFile + '?#zoom=125'"
              type="application/pdf"
              width="100%"
              height="100%"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_TambahPOSE"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="poseTr == 'add'">Tambah PO SE</h5>
            <h5 class="modal-title" v-if="poseTr == 'edit'">Edit PO SE</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="poseForm">
              <div class="form-group">
                <ValidationProvider
                  name="Nomor"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>Nomor PO</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukan nomor PO"
                    v-model="pose_form.no_po"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Nomor"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>Nomor SE</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukan nomor SE"
                    v-model="pose_form.no_se"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                  />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label>Tanggal</label>
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="pose_form.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukkan tanggal"
                      style="width: 100%"
                    >
                    </date-picker>
                  </div>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="BAP"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label>Dokumen BAP</label>
                  <select
                    name=""
                    v-model="pose_form.id_bap"
                    id=""
                    class="form-control"
                    @change="choosenBAP(pose_form.id_bap)"
                  >
                    <option value="">Pilih Dokumen BAP</option>
                    <option
                      v-for="(data, index) in model.dokumen_bap"
                      :key="index"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <h5>Nilai Terbayar</h5>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">IDR</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="pose_form.idr"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">Rp</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">CNY</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="pose_form.cny"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">&#x5143;</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">JPY</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="pose_form.jpy"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">&#xa5;</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">EUR</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="pose_form.eur"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">&euro;</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label for="">USD</label>
                    <div class="position-relative has-icon-left">
                      <money
                        class="form-control"
                        v-model="pose_form.usd"
                        v-bind="money"
                      ></money>
                      <div class="form-control-position">$</div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-12">
                  <ValidationProvider
                    name="Nilai terbayar"
                    v-slot="{ errors }"
                    rules="required|min_value:1"
                  >
                    <input type="hidden" v-model="pose_form.nilai_terbayar" />
                    <p style="color: #ea5455">
                      {{
                        Boolean(errors[0])
                          ? "*Nilai terbayar tidak boleh Rp 0"
                          : ""
                      }}
                    </p>
                  </ValidationProvider>
                </div>
              </div>

              <p>Equivalent</p>
              <h3 class="font-bold">{{ equivalentPOSE | currency }}</h3>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="poseSave"
              v-if="poseTr == 'add'"
              :disabled="pose_ldstate"
            >
              <i v-if="pose_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!pose_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="poseEdit"
              v-if="poseTr == 'edit'"
              :disabled="pose_ldstate"
            >
              <i v-if="pose_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!pose_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { Money } from "v-money";
import moment from "moment";
import ld from "lodash";

import DokumenKontrak from '@/views/kontrak/components/DokumenKontrak.vue'
import DokumenKelayakan from '@/views/kontrak/components/DokumenKelayakan.vue'
import DokumenLainLain from '@/views/kontrak/components/DokumenLainLain.vue'

export default {
  components: { 
    Money,
    DokumenKontrak,
    DokumenKelayakan,
    DokumenLainLain,
  },
  data() {
    return {
      COL_MULT: 200,
      liveSeriesData: [
        {
          name: "Plan",
          data: [],
        },
        {
          name: "Actual",
          data: [],
        },
      ],
      lievCurvaOPt: {},
      categoriesChart: [],
      roleId: false,
      isSuperAdmin: false,
      filter_curva_s: "monthly",
      filter_aman_name: null,
      filter_aman_id: null,
      amandemenHistory: [],
      lineChartSeries: [
        {
          name: "Plan",
          data: [],
        },
        {
          name: "Actual",
          data: [],
        },
      ],
      lineChartOpt: {
        chart: {
          type: "line",
          height: 350,
          toolbar: {
            show: false,
          },
          events: {
            click: this.chartLineClick,
          },
        },
        grid: {
          show: true,
          strokeDashArray: 4,
        },
        markers: {
          size: 5,
        },
        colors: ["#3099AC", "#F3CA27"],
        plotOptions: {},
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          fontSize: "13px",
          fontFamily: "Myriad Pro",
          fontWeight: 400,
          height: 50,
          offsetY: 10,
          itemMargin: {
            horizontal: 24,
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            rotate: 0,
            style: {
              fontSize: "13px",
              fontFamily: "Myriad Pro",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          type: "numeric",
          tickAmount: 5,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          min: 0,
          max: 100,
          labels: {
            style: {
              fontSize: "13px",
              fontFamily: "Myriad Pro",
              fontWeight: 600,
            },
            formatter: function (val) {
              return parseInt(val);
            },
          },
        },
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return "" + val + "";
        //     },
        //   },
        // },

        tooltip: {
          marker: {
            show: true,
          },
          custom: function ({ series, dataPointIndex }) {
            if (series[1].length > 0) {
              if (
                parseFloat(Number(series[1][dataPointIndex].toFixed(2))) >= 0 &&
                parseFloat(
                  Number(
                    series[0][dataPointIndex] - series[1][dataPointIndex]
                  ).toFixed(2)
                ) > 0
              ) {
                return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                        <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                            <span class="float-right text-blue">${parseFloat(
                              series[1][dataPointIndex]
                            )}%</span>
                        </div>

                        <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-blue">${parseFloat(
                            Number(
                              series[0][dataPointIndex] -
                                series[1][dataPointIndex]
                            ).toFixed(2)
                          )}%</span>
                        </div>
                    </div>`;
              } else if (
                parseFloat(
                  Number(
                    series[0][dataPointIndex] - series[1][dataPointIndex]
                  ).toFixed(2)
                ) < 0
              ) {
                return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                        <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                            <span class="float-right text-blue">${parseFloat(
                              series[1][dataPointIndex]
                            )}%</span>
                        </div>

                        <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-danger">${parseFloat(
                            Number(
                              series[0][dataPointIndex] -
                                series[1][dataPointIndex]
                            ).toFixed(2)
                          )}%</span>
                        </div>
                    </div>`;
              } else {
                return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                      <div class="px-1 py-1">
                          <span class="float-left">Actual</span>
                          <span class="float-right text-danger">${parseFloat(
                            series[1][dataPointIndex]
                          )}%</span>
                      </div>

                      <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-blue">${parseFloat(
                            Number(
                              series[0][dataPointIndex] -
                                series[1][dataPointIndex]
                            ).toFixed(2)
                          )}%</span>
                        </div>
                  </div>`;
              }
            } else {
              return `<div class="cards">
                      <div class="px-1 pt-1">
                        <span class="float-left">Plan</span>
                        <span class="float-right">${parseFloat(
                          series[0][dataPointIndex]
                        )}%</span>
                      </div>
                      <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                      </div>
                      <div class="px-1 py-1">
                            <span class="float-left">Deviasi</span>
                      </div>
                    </div>`;
            }
          },
        },
      },
      mtu_list: [],
      amandemen_chart_list: [],
      project_lain_list: [],
      master_ai: {
        ai_thn: 0,
        aki_thn: 0,
      },
      model_ai: {},
      series: [
        {
          name: "Nominal",
          data: [],
        },
      ],
      barOPBOpt: {
        chart: {
          type: "bar",
          height: 200,
          toolbar: {
            show: false,
          },
        },

        grid: {
          show: false,
        },

        legend: {
          show: false,
        },

        colors: ["#3099AC", "#F3CA27"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "12%",
            distributed: true,
            borderRadius: 10,
            endingShape: "rounded",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },

        dataLabels: {
          enabled: false,
          offsetY: -40,
          style: {
            fontSize: "11px",
            fontWeight: "bold",
            colors: undefined,
          },
          background: {
            enabled: true,
            padding: 9,
            foreColor: "#fff",
            borderRadius: 4,
          },
        },

        xaxis: {
          categories: ["AI", "AKI"],
        },

        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      date_now: new Date().getFullYear(),
      role: localStorage.getItem("role"),
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      title: "Detail Kontrak",
      breadcumb: [],
      model: {},
      spmk: {},
      modelAmandemen: {
        perihal: "",
        tgl_akhir_kontrak: "",
        nilai: 0,
      },
      amandemenSQ: "",
      amandemenSQRes: [],
      amandemenTr: "add",

      bg_uang_muka_sq: "",
      bg_uang_muka_force: "",
      bg_uang_muka_sq_res: [],
      uangMukaTr: "add",
      bg_uang_muka_id: null,
      bg_uang_muka_ldstate: false,
      bg_uang_muka_form: {
        id_kontrak: "",
        id_perubahan: "",
        nomor: "",
        bank: "",
        tanggal_terbit: "",
        tanggal_berlaku: "",
        tanggal_berakhir: "",
        nilai: "",
        file_bg: [],
        file_keabsahan: [],
        deletedFileBg: [],
        deletedFileAb: [],
        cny: "",
        jpy: "",
        eur: "",
        usd: "",
      },

      bg_pelaksanaan_sq: "",
      bg_pelaksanaan_force: "",
      bg_pelaksanaan_sq_res: [],
      pelaksanaanTr: "add",
      bg_pelaksanaan_id: null,
      bg_pelaksanaan_ldstate: false,
      bg_pelaksanaan_form: {
        id_kontrak: "",
        id_perubahan: "",
        nomor: "",
        bank: "",
        tanggal_terbit: "",
        tanggal_berlaku: "",
        tanggal_berakhir: "",
        nilai: "",
        file_bg: [],
        file_keabsahan: [],
        deletedFileBg: [],
        deletedFileAb: [],
        cny: "",
        jpy: "",
        eur: "",
        usd: "",
      },

      bg_pemeliharaan_sq: "",
      bg_pemeliharaan_force: "",
      bg_pemeliharaan_sq_res: [],
      pemeliharaanTr: "add",
      bg_pemeliharaan_id: null,
      bg_pemeliharaan_ldstate: false,
      bg_pemeliharaan_form: {
        id_kontrak: "",
        id_perubahan: "",
        nomor: "",
        bank: "",
        tanggal_terbit: "",
        tanggal_berlaku: "",
        tanggal_berakhir: "",
        nilai: "",
        file_bg: [],
        file_keabsahan: [],
        deletedFileBg: [],
        deletedFileAb: [],
        cny: "",
        jpy: "",
        eur: "",
        usd: "",
      },

      amandemen_id: null,
      amandemen_ldstate: false,
      amandemen_detail: {},
      amandemen_form: {
        id_rekomendasi: "",
        name: "",
        nomor: "",
        dokumen: [],
        date: "",
        tgl_awal: "",
        tgl_akhir: "",
        perihal: "",
        nilai_kontrak: "",
        deletedFile: [],
        other: "",
        idr: 0,
        cny: 0,
        jpy: 0,
        eur: 0,
        usd: 0,
        curva_s: [],
        deletedFileCurvaS: [],
        lingkup: ""
      },
      progressTotalFile: 0,
      progressTr: "add",
      progress_id: null,
      progress_detail: {},
      progress_form: {
        name: "",
        description: "",
        image: [],
        cuaca: 0,
        progress: 0,
        deletedFile: [],
        type: localStorage.getItem("role_id") == 3 ? 1 : 2,
        pekerja: "",
        jenis_progress: 1,
      },
      progress_create_dropzoneOptions: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: "image/*",
        autoProcessQueue: false,
        uploadMultiple: true,
        addRemoveLinks: true,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 10000,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },
      kontraktual_dropzoneOptions: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".doc,.docx,application/msword,.pdf",
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 10000,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },

      bap_dropzoneOptions: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".doc,.docx,application/msword,.pdf",
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: false,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 10000,
        maxFiles: 1,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },

      kontraktual_dropzoneOptionCurvaS: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        maxFiles: 1,
        acceptedFiles: ".xlsx,.xls,.csv",
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: false,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 100,
        init: function () {
          // this.on("error", function(file, message) {
          //     alert(message, "hallo");
          //     this.removeFile(file);
          // });
        },
      },

      detail_dz: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".doc,.docx,application/msword,.pdf",
        autoProcessQueue: false,
        addedFile: false,
        addRemoveLinks: true,
        dictRemoveFile: "[Lihat Detail]",
        dictDefaultMessage: "Tidak Ada Dokumen",
        uploadMultiple: true,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 100,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },
      kendalaTr: "add",
      kendala_id: null,
      kendala_ldstate: false,
      kendala_detail: "",
      kendala_choice: [
        "Izin Padam",
        "Force Majeure",
        "Performa kontraktor",
        "Menunggu penyelesaian pekerjaan lain",
        "Keterlambatan material",
        "Permasalahan lahan",
        "Belum sepakat kerja tambah",
        "Material Rusak",
        "Keterlambatan desain",
      ],
      kendala_form: [
        {
          name: "",
          name_sel: "Izin Padam",
          uraian: "",
          image: [],
          deletedFile: [],
          id_periode_progress: "",
          total_file: 0,
        },
      ],
      cur: {
        jpy: 0,
        cny: 0,
        eur: 0,
        usd: 0,
      },
      solusiTotalFile: 0,
      solusiTr: "add",
      solusi_id: null,
      solusi_ldstate: false,
      solusi_detail: "",
      solusi_form: {
        name: "",
        uraian: "",
        image: [],
        deletedFile: [],
      },
      kontraktualSQ: "",
      kontraktualSQRes: [],
      kontraktualBounce: false,
      kontraktualTr: "add",
      kontraktual_id: null,
      kontraktual_ldstate: false,
      kontraktual_detail: {},
      kontraktual_form: {
        nomor: "",
        dokumen: [],
        date: "",
        tgl_operasi: "",
        deletedFile: [],
      },
      operasiSQ: "",
      operasiSQRes: [],
      operasiBounce: false,
      operasiTr: "add",
      operasi_id: null,
      operasi_ldstate: false,
      operasi_detail: {},
      operasi_form: {
        nomor: "",
        dokumen: [],
        date: "",
        deletedFile: [],
      },
      sertrimSQ: "",
      sertrimBounce: false,
      sertrimSQRes: [],
      sertrimTr: "add",
      sertrim_id: null,
      sertrim_ldstate: false,
      sertrim_detail: {
        name: "",
        deletedFile: [],
      },
      sertrim_form: {
        nomor: "",
        dokumen: [],
        date: "",
        deletedFile: [],
        is_numbered: false,
      },
      bapSQ: "",
      bapSQRes: [],
      bapTr: "add",
      bap_id: null,
      bap_ldstate: false,
      bap_detail: {},
      bap_form: {
        name: "",
        nomor: "",
        date: "",
        nilai: 0,
        idr: 0,
        cny: 0,
        jpy: 0,
        eur: 0,
        usd: 0,
        document: [],
      },
      poseSQ: "",
      poseSQRes: [],
      poseTr: "add",
      pose_id: null,
      pose_ldstate: false,
      pose_detail: {},
      pose_form: {
        id_bap: "",
        no_po: "",
        no_se: "",
        date: "",
        nilai_terbayar: 0,
        idr: 0,
        cny: 0,
        jpy: 0,
        eur: 0,
        usd: 0,
      },
      idr: 0,
      cny: 0,
      jpy: 0,
      eur: 0,
      usd: 0,
      map: "",
      moment: moment,
      tgl_akhir_text: "",
      role_id: localStorage.getItem("role_id"),
      aman_showTime: false,
      aman_showValue: false,
      aman_showOther: false,
      aman_showChangeLingkup: false,
      progress_choice: [],
      isGenerating: false,
      isUpload_progress: false,
      detailFoto: "",
      showImage: 0,
      list_rekomendasi: [],
      pdfFile: "",
      scopeSQ: "",
      scopeSQRes: [],
      loadStateChart: false,
      scope_form: {
        name: "",
      },
      chartSeries: [],
      chartOpt: {},
      stateScope: "add",
      scope_ldstate: false,
      scope_rlb_form: {
        id: null,
        nomor: null,
        date: null,
        document: [],
      },
      scopeHasFile: false,
      scoperlb_ldstate: false,
      scope_energize_form: {
        id: null,
        nomor: null,
        date: null,
        document: [],
      },
      scopeEnergizeHasFile: false,
      scopeenergize_ldstate: false,
      scope_slo_form: {
        id: null,
        nomor: null,
        date: null,
        document: [],
      },
      scopeSLOHasFile: false,
      scopeslo_ldstate: false,
      scopeDetail: {
        sts_operasi: [{ dokumen: null }, { dokumen: null }, { dokumen: null }],
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
          ],
        },
      },
      bg_uang_muka: [],
      bg_uang_muka_detail: {
        banks: {
          name: "",
        },
      },
      bg_uang_muka_list: [],
      bg_uang_muka_list_all: [],
      bg_uang_muka_force_id: null,

      bg_pelaksanaan: [],
      bg_pelaksanaan_detail: {
        banks: {
          name: "",
        },
      },
      bg_pelaksanaan_list: [],
      bg_pelaksanaan_list_all: [],
      bg_pelaksanaan_force_id: null,

      bg_pemeliharaan: [],
      bg_pemeliharaan_detail: {
        banks: {
          name: "",
        },
      },
      bg_pemeliharaan_list: [],
      bg_pemeliharaan_list_all: [],
      bg_pemeliharaan_force_id: null,

      bank_list: [],

      progresCurva: [],
    };
  },
  methods: {
    pushTodetailing(id) {
      this.$router.push("/kontrak/" + id);
      location.reload();
    },
    getMasterAI() {
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      let _ = this;
      _.loadStateChart = false;
      _.axios
        .get("v2/master-ai-aki/detail?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.master_ai = resp.data.data;
          // this.series[0].data = [];
          // this.series[0].data.push(this.master_ai.ai_thn);
          // this.series[0].data.push(this.master_ai.aki_thn);
          // this.$refs.masterAI.updateSeries([{
          //     data: this.series[0].data,
          // }]);

          this.chartSeries = [
            {
              name: "AI",
              data: [parseInt(resp.data.data.ai_thn), 0],
            },
            {
              name: "Realisasi",
              data: [0, parseInt(resp.data.data.nilai_pose_kontrak)],
            },
            {
              name: "Sisa AKI",
              data: [
                0,
                parseInt(resp.data.data.aki_thn) -
                  parseInt(resp.data.data.nilai_pose_kontrak),
              ],
            },
          ];

          this.chartOpt = {
            chart: {
              type: "bar",
              stacked: true,
              height: 250,
              toolbar: {
                show: false,
              },
            },
            grid: {
              show: true,
              strokeDashArray: 4,
            },
            colors: ["#3099AC", "#F3CA27", "#8C9092"],
            dataLabels: {
              enabled: true,
              offsetY: -40,
              offsetX: 10,
              background: {
                enabled: true,
                padding: "10",
                borderRadius: 6,
              },
              formatter: function (val, opts) {
                var warningCount = 0;

                const chart = window.$(`#apexcharts${opts.w.globals.chartID}`);
                const series = opts.w.globals.series[0];
                const index = opts.dataPointIndex;

                const dataLabels = chart.find(".apexcharts-data-labels");

                if (
                  index === 0 &&
                  series[0] < parseInt(resp.data.data.kebutuhan_ai)
                ) {
                  window.$(dataLabels[index]).addClass("chart-warning");
                } else {
                  window
                    .$(
                      chart.find(".apexcharts-data-labels:not(.chart-warning)")
                    )
                    .addClass("hide-data-labels");
                }

                setTimeout(() => {
                  const rect = chart
                    .find(".apexcharts-data-labels.chart-warning")
                    .find("rect");
                  const x = rect.attr("x");
                  const y = rect.attr("y");

                  if (
                    x !== undefined &&
                    y !== undefined &&
                    warningCount === 0
                  ) {
                    warningCount += 1;
                    window.$(".apexcharts-data-labels.chart-warning")
                      .append(`<svg x="${parseInt(x) + 14}" y="${
                      parseInt(y) + 8
                    }" cx="${x}" cy="${y}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5335 9.73665L6.90348 1.21665C6.81131 1.04685 6.67503 0.905069 6.509 0.806276C6.34296 0.707482 6.15335 0.655334 5.96014 0.655334C5.76694 0.655334 5.57732 0.707482 5.41129 0.806276C5.24526 0.905069 5.10898 1.04685 5.01681 1.21665L0.390144 9.73665C0.298652 9.90047 0.251705 10.0854 0.253986 10.273C0.256266 10.4607 0.307693 10.6444 0.40314 10.806C0.498587 10.9675 0.634717 11.1012 0.797957 11.1938C0.961196 11.2863 1.14584 11.3344 1.33348 11.3333H10.5901C10.7759 11.3331 10.9584 11.2847 11.1198 11.1929C11.2812 11.1011 11.4161 10.9689 11.5111 10.8093C11.6062 10.6498 11.6582 10.4683 11.6621 10.2826C11.6661 10.0969 11.6217 9.9134 11.5335 9.74998V9.73665ZM5.53348 3.33331C5.53348 3.20954 5.58264 3.09085 5.67016 3.00333C5.75768 2.91581 5.87638 2.86665 6.00014 2.86665C6.12391 2.86665 6.24261 2.91581 6.33013 3.00333C6.41764 3.09085 6.46681 3.20954 6.46681 3.33331V7.33331C6.46681 7.45708 6.41764 7.57578 6.33013 7.6633C6.24261 7.75081 6.12391 7.79998 6.00014 7.79998C5.87638 7.79998 5.75768 7.75081 5.67016 7.6633C5.58264 7.57578 5.53348 7.45708 5.53348 7.33331V3.33331ZM6.00014 9.94998C5.88148 9.94998 5.76547 9.91479 5.6668 9.84886C5.56813 9.78293 5.49123 9.68923 5.44582 9.57959C5.4004 9.46995 5.38852 9.34931 5.41167 9.23293C5.43482 9.11654 5.49197 9.00963 5.57588 8.92572C5.65979 8.8418 5.7667 8.78466 5.88309 8.76151C5.99948 8.73836 6.12012 8.75024 6.22975 8.79565C6.33939 8.84106 6.4331 8.91797 6.49903 9.01664C6.56496 9.11531 6.60014 9.23131 6.60014 9.34998C6.60014 9.42877 6.58462 9.50679 6.55447 9.57959C6.52432 9.65238 6.48012 9.71853 6.42441 9.77424C6.36869 9.82996 6.30255 9.87415 6.22975 9.90431C6.15696 9.93446 6.07894 9.94998 6.00014 9.94998Z" fill="#E32323"/>
                                    </svg>`);
                  }
                }, 500);

                window
                  .$(chart.find('g[seriesName="SisaxAKI"]'))
                  .find("path")
                  .css("fill", "#8C9092");

                return val;
              },
            },
            xaxis: {
              categories: ["AI", "AKI"],
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              labels: {
                rotate: 0,
              },
            },
            yaxis: {
              labels: {
                formatter: (val) => {
                  return formatter.format(val);
                },
              },
              min: 0,
              max: function (max) {
                return max + max * 0.2;
              },
              forceNiceScale: true,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              title: {
                text: "Milyar",
                rotate: 0,
                offsetX: 15,
                offsetY: -140,
                style: {
                  color: "#626262",
                  fontSize: "11px",
                  fontFamily: "Myriad Pro",
                  fontWeight: 600,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 6,
                columnWidth: "15%",
                distributed: true,
                dataLabels: {
                  position: "top",
                },
              },
            },
            legend: {
              show: false,
              itemMargin: {
                horizontal: 2,
              },
            },
            tooltip: {
              custom: ({ seriesIndex }) => {
                if (seriesIndex > 0) {
                  return `
                                        <div class="wrap-tultip">
                                        <div class="content-tultip">
                                            <div class="bulatan" style="background: #F3CA27"></div>
                                            <span>Realisasi : <strong>${formatter.format(
                                              parseInt(
                                                resp.data.data
                                                  .nilai_pose_kontrak
                                              )
                                            )}</strong></span>
                                        </div>
                                        <div class="content-tultip">
                                            <div class="bulatan" style="background: #8C9092"></div>
                                            <span>Sisa AKI : <strong>${formatter.format(
                                              parseInt(resp.data.data.aki_thn) -
                                                parseInt(
                                                  resp.data.data
                                                    .nilai_pose_kontrak
                                                ) <
                                                0
                                                ? 0
                                                : parseInt(
                                                    resp.data.data.aki_thn
                                                  ) -
                                                    parseInt(
                                                      resp.data.data
                                                        .nilai_pose_kontrak
                                                    )
                                            )}</strong></span>
                                        </div>
                                        </div>
                                    `;
                } else {
                  return `
                                    <div class="wrap-tultip">
                                    <div class="content-tultip">
                                        <div class="bulatan" style="background: #3099AC"></div>
                                        <span>AI : <strong>${formatter.format(
                                          parseInt(resp.data.data.ai_thn)
                                        )}</strong></span>
                                    </div>
                                    </div>
                                `;
                }
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 250,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
        })
        .catch((err) => {
          _.master_ai.status_text = "";
          console.log(err.response);
        })
        .finally(() => {
          this.loadStateChart = true;
        });
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    getListMTU() {
      this.axios.get(`/v2/kontrak/${this.$route.params.id}/mtu`)
        .then((resp) => {
          this.mtu_list = resp.data.data.map((e) => {
            e.name_resume = e.name;
            e.progress_fisik = e.progress_fisik + ' %';
            e.progress_bayar = e.progress_bayar > 0 ? e.progress_bayar + ' %' : '-';

            // if (e.detail.jenis_pekerjaan == 'AIS') {
              e.material = this.isJsonString(e.konfigurasi) ? JSON.parse(e.konfigurasi) : null;
              e.jumlah = this.isJsonString(e.jumlah) ? JSON.parse(e.jumlah) : null;
              e.satuan = this.isJsonString(e.satuan) ? JSON.parse(e.satuan) : null;
            // }

            return e;
          })
        })
        .catch((err) => console.log(err.response));
    },
    resetFilterAman() {
      this.filter_aman_name = null;
      this.filter_aman_id = null;
      this.getChartAmandemen();
    },
    getFilterChartAmandemen(data) {
      this.filter_aman_name = data.amandemen_name;
      this.filter_aman_id = data.id_kontrak_amandemen;
      this.getChartAmandemen();
    },
    getChartAmandemen(filter) {
      let _ = this;
      let filter_value = filter ? filter : this.filter_curva_s;
      this.filter_curva_s = filter_value;
      let url = "";
      if (this.filter_aman_id) {
        url =
          "v2/kontrak/curva-s/chart?id_kontrak=" +
          _.$route.params.id +
          "&type=" +
          filter_value +
          "&id_kontrak_amandemen=" +
          this.filter_aman_id;
      } else {
        url =
          "v2/kontrak/curva-s/chart?id_kontrak=" +
          _.$route.params.id +
          "&type=" +
          filter_value +
          "&id_kontrak_amandemen=0";
      }
      _.axios
        .get(url)
        .then((resp) => {
          _.amandemen_chart_list = resp.data.data;

          let plan = [];
          let cat = [];
          let actual = [];

          if (filter_value == "monthly") {
            _.amandemen_chart_list.forEach((element) => {
              if (element.is_show) {
                actual.push(element.actual);
              }
              plan.push(element.plan);
              cat.push(
                moment.monthsShort(element.month - 1) + "-" + element.year
              );
            });
          } else {
            _.amandemen_chart_list.forEach((element) => {
              if (element.is_show) {
                actual.push(element.actual);
              }
              plan.push(element.plan);
              cat.push(
                moment.monthsShort(element.month - 1) + "-" + element.year
              );
            });
          }

          let actual_format = [];
          if (actual.find((element) => element > 0)) {
            actual_format = actual;
          } else {
            actual_format = [];
          }

          let plan_format = [];
          if (plan.find((element) => element > 0)) {
            plan_format = plan;
          } else {
            plan = [];
          }

          if (plan_format.length > 6) {
            this.COL_MULT = 100;
          } else {
            this.COL_MULT = 200;
          }

          // this.$refs.chartAmandemen.updateSeries([
          //   {
          //     name: "Plan",
          //     data: plan_format,
          //   },
          //   {
          //     name: "Actual",
          //     data: actual_format,
          //   },
          // ]);

          // this.$refs.chartAmandemen.updateOptions({
          //   xaxis: {
          //     categories: cat,
          //   },
          // });

          this.categoriesChart = cat;
          this.lievCurvaOPt = {
            chart: {
              type: "line",
              height: 350,
              width: plan_format.length * this.COL_MULT,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            grid: {
              show: true,
              strokeDashArray: 4,
            },
            markers: {
              size: 5,
            },
            colors: ["#3099AC", "#F3CA27"],
            series: [
              {
                name: "Plan",
                data: plan_format,
              },
              {
                name: "Actual",
                data: actual_format,
              },
            ],
            annotations: {
              xaxis: this.liveSAnnotations(),
            },
            plotOptions: {},
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              fontSize: "13px",
              fontFamily: "Myriad Pro",
              fontWeight: 400,
              height: 50,
              offsetY: 10,
              itemMargin: {
                horizontal: 24,
              },
            },
            xaxis: {
              categories: cat,
              overwriteCategories: cat.map((o) => o.split("-")[0]),
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickPlacement: "between",
              labels: {
                rotate: 0,
                style: {
                  fontSize: "13px",
                  fontFamily: "Myriad Pro",
                  fontWeight: 600,
                },
              },
            },
            yaxis: {
              type: "numeric",
              tickAmount: 5,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              min: 0,
              max: 100,
              labels: {
                style: {
                  fontSize: "13px",
                  fontFamily: "Myriad Pro",
                  fontWeight: 600,
                },
              },
            },
            tooltip: {
              custom: function ({ series, dataPointIndex }) {
                if (series[1].length > 0) {
                  if (
                    parseFloat(Number(series[1][dataPointIndex].toFixed(2))) >=
                      0 &&
                    parseFloat(
                      Number(
                        series[1][dataPointIndex] - series[0][dataPointIndex]
                      ).toFixed(2)
                    ) > 0
                  ) {
                    return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                        <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                            <span class="float-right text-blue">${parseFloat(
                              series[1][dataPointIndex]
                            )}%</span>
                        </div>

                        <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-blue">${parseFloat(
                            Number(
                              series[1][dataPointIndex] -
                                series[0][dataPointIndex]
                            ).toFixed(2)
                          )}%</span>
                        </div>
                    </div>`;
                  } else if (
                    parseFloat(
                      Number(
                        series[1][dataPointIndex] - series[0][dataPointIndex]
                      ).toFixed(2)
                    ) < 0
                  ) {
                    return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                        <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                            <span class="float-right text-blue">${parseFloat(
                              series[1][dataPointIndex]
                            )}%</span>
                        </div>

                        <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-danger">${parseFloat(
                            Number(
                              series[1][dataPointIndex] -
                                series[0][dataPointIndex]
                            ).toFixed(2)
                          )}%</span>
                        </div>
                    </div>`;
                  } else {
                    return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                      <div class="px-1 py-1">
                          <span class="float-left">Actual</span>
                          <span class="float-right text-danger">${parseFloat(
                            series[1][dataPointIndex]
                          )}%</span>
                      </div>

                      <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-blue">${parseFloat(
                            Number(
                              series[1][dataPointIndex] -
                                series[0][dataPointIndex]
                            ).toFixed(2)
                          )}%</span>
                        </div>
                  </div>`;
                  }
                } else {
                  return `<div class="cards">
                      <div class="px-1 pt-1">
                        <span class="float-left">Plan</span>
                        <span class="float-right">${parseFloat(
                          series[0][dataPointIndex]
                        )}%</span>
                      </div>
                      <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                      </div>
                      <div class="px-1 py-1">
                            <span class="float-left">Deviasi</span>
                      </div>
                    </div>`;
                }
              },
            },
          };
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    liveSDragScroll() {
      const ele = document.getElementById("Chart_Live_S_Curve");
      console.log(ele);
      let pos = { top: 0, left: 0, x: 0, y: 0 };
      const mouseDownHandler = function (e) {
        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          x: e.clientX,
          y: e.clientY,
        };
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };
      const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };
      const mouseUpHandler = function () {
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };
      ele.addEventListener("mousedown", mouseDownHandler);
    },

    liveSAnnotations() {
      const list = [];
      let i = 0;
      while (i < this.categoriesChart.length - 1) {
        const cat_year = Number(this.categoriesChart[i].split("-")[1]);
        const next_year = Number(this.categoriesChart[i + 1].split("-")[1]);
        if (next_year === cat_year + 1) {
          list.push({
            x: `Jan-${next_year}`,
            strokeDashArray: 0,
            borderColor: "transparent",
            fillColor: "transparent",
            offsetX: -40,
            offsetY: 30,
            label: {
              text: next_year.toString(),
              borderWidth: 0,
              position: "bottom",
              orientation: "horizontal",
              offsetX: -40,
              offsetY: 50,
              style: {
                background: "#b6b6b6",
                color: "#329FB7",
                fontSize: "13px",
                fontFamily: "Myriad Pro",
                fontWeight: 500,
              },
            },
          });
        }
        i++;
      }
      return list;
    },

    getListProjectLain() {
      let _ = this;
      _.axios
        .get("/kontrak/keterkaitan?id=" + _.$route.params.id)
        .then((resp) => {
          _.project_lain_list = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getHistoryAmandemenCurva(param) {
      let _ = this;
      _.axios
        .get("/v2/kontrak/curva-s/amandemen?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.amandemenHistory = resp.data.data;
          console.log(param);
          // if (param == 1) {
          let dataAman = _.amandemenHistory[_.amandemenHistory.length - 1];
          _.getFilterChartAmandemen(dataAman);
          // }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    masterAiForce() {
      // let _ = this
      window.$("#Modal_Master_AI").modal("show");
    },
    masterAiSubmit() {
      let _ = this;
      var validation = _.$refs.masterAIForceValidation;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        let data_post = {
          id: _.master_ai.id,
          status: _.model_ai.status,
          tanggal_perubahan: _.model_ai.tanggal_perubahan,
          nomor_nodin: _.model_ai.nomor_nodin,
        };
        console.log(data_post);

        await _.axios
          .post("/v2/master-ai-aki/update-force", data_post)
          .then((resp) => {
            console.log(resp);
            _.$toast.success("Force Update Berhasil Disimpan");
            _.model_ai.status = "";
            _.model_ai = "";
            window.$("#Modal_Master_AI").modal("hide");
            _.getMasterAI();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
      });
    },

    bgUangukaForce() {
      let _ = this;
      _.bg_uang_muka_force = "";
      _.bg_uang_muka_force_id = "";
      window.$("#Modal_Bg_Uang_Muka_Force").modal("show");
    },
    bgUangMukaSubmit() {
      let _ = this;
      var validation = _.$refs.bgUangMukaForceValidation;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        let data_post = {
          id: _.bg_uang_muka_force_id,
          id_kontrak: _.model.id,
          status: _.bg_uang_muka_force,
        };

        await _.axios
          .post("/v2/bg_uang_muka/force-update", data_post)
          .then((resp) => {
            console.log(resp);
            _.$toast.success("Force Update Berhasil Disimpan");
            _.bg_uang_muka_force = "";
            _.bg_uang_muka_force_id = "";
            window.$("#Modal_Bg_Uang_Muka_Force").modal("hide");
            _.getUangMuka();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
      });
    },
    getUangMukaList() {
      let _ = this;
      _.axios
        .get("v2/bg_uang_muka/all-origin?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_uang_muka_list = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getUangMukaListAll() {
      let _ = this;
      _.axios
        .get("v2/bg_uang_muka/all?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_uang_muka_list_all = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getUangMuka() {
      let _ = this;
      _.axios
        .get("v2/bg_uang_muka?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_uang_muka = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getCurvaProgress() {
      let _ = this;
      _.axios
        .get("v2/kontrak/curva-s?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.progresCurva = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    bgPelaksanaanForce() {
      let _ = this;
      _.bg_pelaksanaan_force = "";
      _.bg_pelaksanaan_force_id = "";
      window.$("#Modal_Bg_Pelaksanaan_Force").modal("show");
    },
    bgPelaksanaanSubmit() {
      let _ = this;
      var validation = _.$refs.bgPelaksanaanForceValidation;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        let data_post = {
          id: _.bg_pelaksanaan_force_id,
          id_kontrak: _.model.id,
          status: _.bg_pelaksanaan_force,
        };

        await _.axios
          .post("/v2/bg_pelaksanaan/force-update", data_post)
          .then((resp) => {
            console.log(resp);
            _.$toast.success("Force Update Berhasil Disimpan");
            _.bg_pelaksanaan_force = "";
            _.bg_pelaksanaan_force_id = "";
            window.$("#Modal_Bg_Pelaksanaan_Force").modal("hide");
            _.getPelaksanaan();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
      });
    },
    getPelaksanaanList() {
      let _ = this;
      _.axios
        .get("v2/bg_pelaksanaan/all-origin?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pelaksanaan_list = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getPelaksanaanListAll() {
      let _ = this;
      _.axios
        .get("v2/bg_pelaksanaan/all?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pelaksanaan_list_all = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getPelaksanaan() {
      let _ = this;
      _.axios
        .get("v2/bg_pelaksanaan?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pelaksanaan = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    bgPemeliharaanForce() {
      let _ = this;
      _.bg_pemeliharaan_force = "";
      _.bg_pemeliharaan_force_id = "";
      window.$("#Modal_Bg_Pemeliharaan_Force").modal("show");
    },
    bgPemeliharaanSubmit() {
      let _ = this;
      var validation = _.$refs.bgPemeliharaanForceValidation;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        let data_post = {
          id: _.bg_pemeliharaan_force_id,
          id_kontrak: _.model.id,
          status: _.bg_pemeliharaan_force,
        };

        await _.axios
          .post("/v2/bg_pemeliharaan/force-update", data_post)
          .then((resp) => {
            console.log(resp);
            _.$toast.success("Force Update Berhasil Disimpan");
            _.bg_pemeliharaan_force = "";
            _.bg_pemeliharaan_force_id = "";
            window.$("#Modal_Bg_Pemeliharaan_Force").modal("hide");
            _.getPemeliharaan();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
      });
    },
    chartLineClick(event, ctx, conf) {
      let _ = this;
      const categoriesClick = conf.dataPointIndex;
      let dataPoints = this.amandemen_chart_list[categoriesClick];
      let url = "";
      if (this.filter_curva_s == "monthly") {
        url =
          "v2/kontrak/curva-s/chart/progress?id_kontrak=" +
          _.$route.params.id +
          "&year=" +
          dataPoints.year +
          "&month=" +
          dataPoints.month;
      } else {
        url =
          "v2/kontrak/curva-s/chart/progress?id_kontrak=" +
          _.$route.params.id +
          "&year=" +
          dataPoints.year +
          "&month=" +
          dataPoints.month +
          "&week=" +
          dataPoints.week;
      }
      _.axios
        .get(url)
        .then((resp) => {
          if (resp.data.data) {
            _.$router.push(
              "/kontrak/" +
                _.$route.params.id +
                "/detail-progress/" +
                resp.data.data.id
            );
          } else {
            console.log("data not Found");
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getPemeliharaan() {
      let _ = this;
      _.axios
        .get("v2/bg_pemeliharaan?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pemeliharaan = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getPemeliharaanList() {
      let _ = this;
      _.axios
        .get("v2/bg_pemeliharaan/all-origin?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pemeliharaan_list = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getPemeliharaanListAll() {
      let _ = this;
      _.axios
        .get("v2/bg_pemeliharaan/all?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pemeliharaan_list_all = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getBankList() {
      let _ = this;
      _.axios
        .get("v2/master_bank/all")
        .then((resp) => {
          _.bank_list = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    pdfViewer(file) {
      let _ = this;
      // // console.log("file");
      // this.$forceUpdate()
      _.pdfFile = file;
      // Modal_PDFViewer
      window.$("#Modal_PDFViewer").modal("show");
    },
    calculateDate: ld.debounce(function () {
      let _ = this;
      _.axios
        .get("set-date?start=" + _.spmk.date + "&durasi=" + _.spmk.durasi)
        .then((resp) => {
          _.spmk.tgl_akhir = resp.data;
          _.tgl_akhir_text = moment(_.spmk.tgl_akhir).format("DD, MMMM YYYY");
        })
        .catch((err) => {
          console.log(err.response);
        });
    }, 500),
    initMap() {
      let _ = this;
      if (_.model.lokasi == null || _.model.lokasi == "") {
        return;
      }
      var myLatlng = { lat: _.model.lokasi[0].lat, lng: _.model.lokasi[0].lng };

      _.map = new window.google.maps.Map(_.$refs["map"], {
        zoom: 12,
        center: myLatlng,
      });
      $.each(_.model.lokasi, function (index, val) {
        new window.google.maps.Marker({
          map: _.map,
          animation: window.google.maps.Animation.DROP,
          position: { lat: val.lat, lng: val.lng },
        });
      });
    },
    async get() {
      let _ = this;
      await _.axios
        .get("/kontrak?id=" + _.$route.params.id)
        .then((resp) => {
          _.model = resp.data.data.rows;
          _.breadcumb = [
            {
              name: "Monitoring Project",
              link: "",
              active: false,
            },
            {
              name: "Project List",
              link: "/kontrak",
              active: false,
            },
            {
              name: _.model.no_kontrak,
              link: "",
              active: false,
            },
            {
              name: "Detail Kontrak",
              link: "",
              active: true,
            },
          ];
          _.getProgressChoice();
          _.getChartAmandemen();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    update_spmk() {
      let _ = this;
      let data = {
        id: _.model.id,
        no_spmk: _.spmk.no_spmk,
        tgl_spmk: _.spmk.date,
        durasi: _.spmk.durasi,
        tgl_akhir: _.spmk.tgl_akhir,
      };
      _.axios
        .patch("/kontrak", data)
        .then((resp) => {
          if (resp.status) {
            this.$toast.success("Data berhasil diubah");
            _.get();
            _.spmk = {};
            _.tgl_akhir_text = "";
            _.$refs.updateSPMK.reset();
          } else {
            this.$toast.success("Data gagal diubah");
            _.$refs.updateSPMK.reset();
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    toastAlert(x) {
      if (x == 1) {
        var myDropzone = this.$refs.createProgressDropzone;
        this.progress_form = {};
        myDropzone.removeAllFiles();
        this.$toast.success("Data berhasil disimpan");
        this.get();
      } else {
        this.$toast.success("Data gagal disimpan");
      }
    },
    cetak() {
      let _ = this;
      _.isGenerating = true;
      _.axios
        .get("kontrak/export-progress?id=" + _.model.id, {
          responseType: "arraybuffer",
        })
        .then((resp) => {
          let blob = new Blob([resp.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Progress-" + _.model.no_kontrak + ".pdf";
          link.click();
          _.isGenerating = false;
        })
        .catch((err) => {
          console.log(err.response);
          _.isGenerating = false;
        });
    },
    getCurrency() {
      let _ = this;
      _.axios.get("/kontrak/currency").then((res) => {
        _.cur.jpy = res.data.data.jpy;
        _.cur.cny = res.data.data.cny;
        _.cur.eur = res.data.data.eur;
        _.cur.usd = res.data.data.usd;
      });
    },
    //
    //
    // Kendala FUNC
    //
    //
    modalKendala(type) {
      let _ = this;
      _.kendalaTr = type;
      _.kendalaRemoveFile();
      if (type == "edit") {
        _.kendala_id = _.kendala_detail.id;
        _.kendala_form = [
          {
            name: _.kendala_detail.name,
            name_sel: _.kendala_detail.id_progress
              ? _.kendala_detail.name
              : "0",
            uraian: _.kendala_detail.uraian,
            image: [],
            deletedFile: [],
            id_periode_progress: _.kendala_detail.id_periode_progress,
            total_file: 0,
          },
        ];
        if (_.kendala_choice.indexOf(_.kendala_detail.name) == -1) {
          _.kendala_form[0].name_sel = 0;
        }
        if (_.kendala_detail.image != null) {
          _.kendala_detail.image.forEach(function (el) {
            let file = { size: 10240, name: el.name, type: "image/png" };
            let url = el.url;
            _.$refs.kendalaDropzone[0].manuallyAddFile(file, url);
            _.kendala_form[0].total_file += 1;
          });
        }
      } else if (type == "add") {
        _.kendala_form = [
          {
            name: "",
            name_sel: "Izin Padam",
            uraian: "",
            image: [],
            deletedFile: [],
            id_periode_progress: "",
            total_file: 0,
          },
        ];
        console.log(_.$refs.kendalaDropzone);
      }
      window.$("#Modal_TambahKendala").modal("show");
    },
    addMoreKendala() {
      let _ = this;
      _.kendala_form.push({
        name: "",
        name_sel: "Izin Padam",
        uraian: "",
        image: [],
        deletedFile: [],
        id_periode_progress: "",
        total_file: 0,
      });
    },
    decreaseKendala(i) {
      let _ = this;
      _.kendala_form.splice(i, 1);
    },
    kendalaFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          let i = val.previewElement.parentElement.id.split("-")[1];
          val.dz_index = i;
          _.kendala_form[i].image.push(val);
          _.kendala_form[i].total_file += 1;
        });
      }
    },
    kendalaDeleteFile(file) {
      let _ = this;

      if (file.lastModified) {
        let i = file.dz_index;
        _.kendala_form[i].total_file -= 1;
        _.kendala_form[i].image = ld.filter(
          _.kendala_form[i].image,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.kendala_form[0].total_file -= 1;
        _.kendala_form[0].deletedFile.push(file.name);
      }
    },
    kendalaRemoveFile(i = -1) {
      let _ = this;
      if (i >= 0) {
        _.$refs.kendalaDropzone[i].removeAllFiles(true);
        _.kendala_form[i].image = [];
        _.kendala_form[i].total_file = 0;
      } else {
        _.$refs.kendalaDropzone[0].removeAllFiles(true);
        _.kendala_form[0].image = [];
        _.kendala_form[0].total_file = 0;
      }
    },
    kendalaDelete(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/kendala", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },
    async kendalaSave() {
      let _ = this;

      var validation = _.$refs.kendalaForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.kendala_ldstate = true;
        await $.each(_.kendala_form, async function (i, v) {
          v;
          if (_.kendala_form[i].name_sel != 0) {
            _.kendala_form[i].name = _.kendala_form[i].name_sel;
          }
          var formData = new FormData();
          formData.append("id_kontrak", _.model.id);
          $.each(_.kendala_form[i], function (index, val) {
            if (index != "image") {
              formData.append(index, val);
            } else {
              $.each(_.kendala_form[i].image, function (index, val) {
                formData.append("image[" + index + "]", val);
              });
            }
          });

          await _.axios
            .post("/kontrak/kendala", formData)
            .then((resp) => {
              console.log(resp);
              if (i == _.kendala_form.length - 1) {
                _.kendala_form = [
                  {
                    name: "",
                    name_sel: "Izin Padam",
                    uraian: "",
                    image: [],
                    deletedFile: [],
                  },
                ];
                validation.reset();
                _.kendalaRemoveFile();
                window.$("#Modal_TambahKendala").modal("hide");
                _.$toast.success("Data Berhasil Disimpan");
                _.get();
              }
            })
            .catch((err) => {
              console.log(err);
              _.$router.push("/kontrak/" + _.model.id);
              _.$toast.warning("Data Kontrak Gagal Disimpan");
            });
          _.kendala_ldstate = false;
        });
      });
    },
    async kendalaEdit() {
      let _ = this;

      var validation = _.$refs.kendalaForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.kendala_ldstate = true;
        if (_.kendala_form[0].name_sel != 0) {
          _.kendala_form[0].name = _.kendala_form[0].name_sel;
        }
        var formData = new FormData();
        formData.append("id", _.kendala_id);
        $.each(_.kendala_form[0], function (index, val) {
          if (index == "image") {
            $.each(_.kendala_form[0].image, function (index, val) {
              formData.append("image[" + index + "]", val);
            });
          } else if (index == "deletedFile") {
            $.each(_.kendala_form[0].deletedFile, function (index, val) {
              formData.append("deletedFile[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });

        await _.axios
          .post("/kontrak/kendala/update", formData)
          .then((resp) => {
            console.log(resp);
            _.kendala_form = [
              {
                name: "",
                name_sel: "Izin Padam",
                uraian: "",
                image: [],
                deletedFile: [],
                id_periode_progress: "",
              },
            ];
            validation.reset();
            _.kendalaRemoveFile();
            window.$("#Modal_TambahKendala").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
        _.kendala_ldstate = false;
      });
    },
    //
    //
    // Kendala FUNC ends
    //
    //

    //
    //
    // BAP FUNC
    //
    //

    modalBAP(type) {
      let _ = this;
      _.bapTr = type;
      _.bapRemoveFile();

      if (type == "edit") {
        _.bap_id = _.bap_detail.id;
        _.bap_form = {
          name: _.bap_detail.name,
          nomor: _.bap_detail.nomor,
          date: _.bap_detail.date,
          nilai: _.bap_detail.nilai,
          idr: _.bap_detail.idr,
          cny: _.bap_detail.cny,
          jpy: _.bap_detail.jpy,
          eur: _.bap_detail.eur,
          usd: _.bap_detail.usd,
          document: [],
        };
        // _.bap_detail.document.forEach(function (el) {
        //   let file = { size: 10240, name: el.name, type: "application/word" };
        //   let url = el.url;
        //   _.$refs.bapDropzone.manuallyAddFile(file, url);
        //   // _.$refs.curvaSDropzone.manuallyAddFile(file, url);
        // });

        if (_.bap_detail.document) {
          let file = { size: 10240, name: _.bap_detail.document, type: "application/word" };
          let url = _.bap_detail.document_url;
          _.$refs.bapDropzone.manuallyAddFile(file, url);
        }
      } else if (type == "add") {
        _.bap_form = {
          name: "",
          nomor: "",
          date: "",
          nilai: 0,
          idr: _.model.sisa_idr,
          cny: _.model.sisa_cny,
          jpy: _.model.sisa_jpy,
          eur: _.model.sisa_eur,
          usd: _.model.sisa_usd,
          document: [],
        };
      }
      
      window.$("#Modal_TambahBAP").modal("show");
      _.$refs.bapForm.reset();
    },
    async bapSave() {
      let _ = this;

      var validation = _.$refs.bapForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bap_ldstate = true;
        var formData = new FormData();
        formData.append("id_kontrak", _.model.id);
        $.each(_.bap_form, function (index, val) {
          if (index == "document" && _.bap_form.document.length > 0) {
            // $.each(_.bap_form.document, function (index, val) {
            //   formData.append("document[" + index + "]", val);
            // });
            formData.append("document", _.bap_form.document[0])
          }
          else formData.append(index, val);
        });

        await _.axios
          .post("/kontrak/bap", formData)
          .then((resp) => {
            console.log(resp);
            _.bap_form = {
              name: "",
              nomor: "",
              date: "",
              nilai: 0,
              idr: 0,
              cny: 0,
              jpy: 0,
              eur: 0,
              usd: 0,
              document: [],
            };
            validation.reset();
            window.$("#Modal_TambahBAP").modal("hide");
            _.$refs.bapDropzone.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            _.$toast.warning(err.response.data.message);
          });
        _.bap_ldstate = false;
      });
    },
    async bapEdit() {
      let _ = this;

      var validation = _.$refs.bapForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bap_ldstate = true;
        let param = {
          id: _.bap_id,
          id_kontrak: _.model.id,
          ..._.bap_form,
        };
        await _.axios
          .patch("/kontrak/bap", param)
          .then((resp) => {
            console.log(resp);
            _.bap_form = {
              name: "",
              nomor: "",
              date: "",
              nilai: 0,
              idr: 0,
              cny: 0,
              jpy: 0,
              eur: 0,
              usd: 0,
              document: [],
            };
            validation.reset();
            window.$("#Modal_TambahBAP").modal("hide");
            _.$refs.bapDropzone.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            _.$toast.warning(err.response.data.message);
          });
        _.bap_ldstate = false;
      });
    },
    delete_bap(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/bap", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    //
    //
    // BAP FUNC ends
    //
    //

    // START OF POSE FUNCTION

    modalPOSE(type) {
      let _ = this;
      _.poseTr = type;
      if (type == "edit") {
        _.pose_id = _.pose_detail.id;
        _.pose_form = {
          id_bap: _.pose_detail.id_bap,
          no_po: _.pose_detail.no_po,
          no_se: _.pose_detail.no_se,
          date: _.pose_detail.date,
          nilai_terbayar: _.pose_detail.nilai_terbayar,
          idr: _.pose_detail.idr,
          cny: _.pose_detail.cny,
          jpy: _.pose_detail.jpy,
          eur: _.pose_detail.eur,
          usd: _.pose_detail.usd,
        };
      } else if (type == "add") {
        _.pose_form = {
          id_bap: "",
          no_po: "",
          no_se: "",
          date: "",
          nilai_terbayar: 0,
          idr: 0,
          cny: 0,
          jpy: 0,
          eur: 0,
          usd: 0,
        };
      }
      window.$("#Modal_TambahPOSE").modal("show");
      _.$refs.poseForm.reset();
    },
    async poseSave() {
      let _ = this;

      var validation = _.$refs.poseForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.pose_ldstate = true;
        var formData = new FormData();
        formData.append("id_kontrak", _.model.id);
        $.each(_.pose_form, function (index, val) {
          formData.append(index, val);
        });

        await _.axios
          .post("/kontrak/pose", formData)
          .then((resp) => {
            console.log(resp);
            _.pose_form = {
              id_bap: "",
              no_po: "",
              no_se: "",
              date: "",
              nilai_terbayar: 0,
            };
            validation.reset();
            window.$("#Modal_TambahPOSE").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
            _.getMasterAI();
          })
          .catch((err) => {
            _.$toast.warning(err.response.data.message);
          });
        _.pose_ldstate = false;
      });
    },
    async poseEdit() {
      let _ = this;

      var validation = _.$refs.poseForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.pose_ldstate = true;
        let param = {
          id: _.pose_id,
          ..._.pose_form,
        };
        await _.axios
          .patch("/kontrak/pose", param)
          .then((resp) => {
            console.log(resp);
            _.pose_form = {
              id_bap: "",
              no_po: "",
              no_se: "",
              date: "",
              nilai_terbayar: 0,
            };
            validation.reset();
            window.$("#Modal_TambahPOSE").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
            _.getMasterAI();
          })
          .catch((err) => {
            _.$toast.warning(err.response.data.message);
          });
        _.pose_ldstate = false;
      });
    },
    delete_pose(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/pose", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    // END OF POSE FUNCTION

    //
    //
    // STS KONTRAKTUAL FUNC
    //
    //

    modalKontraktual(type) {
      let _ = this;
      _.kontraktualTr = type;
      _.kontraktualRemoveFile();
      if (type == "edit") {
        _.kontraktual_id = _.kontraktual_detail.id;
        _.kontraktual_form = {
          nomor: _.kontraktual_detail.nomor,
          date: _.kontraktual_detail.date,
          tgl_operasi: _.kontraktual_detail.tgl_operasi,
          dokumen: [],
          deletedFile: [],
        };
        if (_.kontraktual_detail.dokumen) {
          _.kontraktual_detail.dokumen.forEach(function (el) {
            let file = { size: 10240, name: el.name, type: "application/word" };
            let url = el.url;
            _.$refs.kontraktualDropzone.manuallyAddFile(file, url);
          });
        }
      } else if (type == "add") {
        _.kontraktual_form = {
          name: "",
          nomor: "",
          date: "",
          nilai: 0,
          deletedFile: [],
        };
      }
      window.$("#Modal_UpdateStatusKontraktual").modal("show");
    },
    kontraktualDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.kontraktual_form.dokumen = ld.filter(
          _.kontraktual_form.dokumen,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.kontraktual_form.deletedFile.push(file.name);
      }
    },
    kontraktualFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.kontraktual_form.dokumen.push(val);
        });
      }
    },
    kontraktualRemoveFile() {
      let _ = this;
      _.$refs.kontraktualDropzone.removeAllFiles(true);
      _.kontraktual_form.dokumen = [];
    },
    async kontraktualEdit() {
      let _ = this;

      var validation = _.$refs.kontraktualForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.kontraktual_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.kontraktual_id);
        $.each(_.kontraktual_form, function (index, val) {
          if (index == "dokumen") {
            $.each(_.kontraktual_form.dokumen, function (index, val) {
              formData.append("dokumen[" + index + "]", val);
            });
          } else if (index == "deletedFile") {
            $.each(_.kontraktual_form.deletedFile, function (index, val) {
              formData.append("deletedFile[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });

        await _.axios
          .post("/kontrak/kontraktual", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            console.log(resp);
            _.kontraktual_form = {
              nomor: "",
              date: "",
              tgl_operasi: "",
              dokumen: [],
            };
            _.kontraktualRemoveFile();
            validation.reset();
            window.$("#Modal_UpdateStatusKontraktual").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
            _.getUangMuka()
            _.getPelaksanaan()
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
        _.kontraktual_ldstate = false;
      });
    },
    delete_kontraktual(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/kontraktual", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    //
    //
    // STS KONTRAKTUAL FUNC ends
    //
    //

    //
    //
    // STS OPERASI FUNC
    //
    //

    modalOperasi(type) {
      let _ = this;
      _.operasiTr = type;
      _.operasiRemoveFile();
      if (type == "edit") {
        _.operasi_id = _.operasi_detail.id;
        _.operasi_form = {
          nomor: _.operasi_detail.nomor,
          date: _.operasi_detail.date,
          dokumen: [],
          deletedFile: [],
        };
        if (_.operasi_detail.dokumen) {
          _.operasi_detail.dokumen.forEach(function (el) {
            let file = { size: 10240, name: el.name, type: "application/word" };
            let url = el.url;
            _.$refs.operasiDropzone.manuallyAddFile(file, url);
          });
        }
      } else if (type == "add") {
        _.operasi_form = {
          name: "",
          nomor: "",
          date: "",
          nilai: 0,
          deletedFile: [],
        };
      }
      window.$("#Modal_StatusOperasi").modal("show");
    },
    operasiDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.operasi_form.dokumen = ld.filter(
          _.operasi_form.dokumen,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.operasi_form.deletedFile.push(file.name);
      }
    },
    operasiFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.operasi_form.dokumen.push(val);
        });
      }
    },
    operasiRemoveFile() {
      let _ = this;
      _.$refs.operasiDropzone.removeAllFiles(true);
      _.operasi_form.dokumen = [];
    },
    async operasiEdit() {
      let _ = this;

      var validation = _.$refs.operasiForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.operasi_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.operasi_id);
        $.each(_.operasi_form, function (index, val) {
          if (index == "dokumen") {
            $.each(_.operasi_form.dokumen, function (index, val) {
              formData.append("dokumen[" + index + "]", val);
            });
          } else if (index == "deletedFile") {
            $.each(_.operasi_form.deletedFile, function (index, val) {
              formData.append("deletedFile[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });

        await _.axios
          .post("/kontrak/operasi", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            console.log(resp);
            _.operasi_form = {
              nomor: "",
              date: "",
              dokumen: [],
            };
            _.operasiRemoveFile();
            validation.reset();
            window.$("#Modal_StatusOperasi").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
        _.operasi_ldstate = false;
      });
    },

    //
    //
    // STS OPERASI FUNC ends
    //
    //

    //
    //
    // serah terima FUNC
    //
    //
    sertrimSearch: ld.debounce(async function () {
      let _ = this;
      if (_.sertrimSQ != "") {
        let res = _.model.status_serahterima;
        let newRes = ld.filter(res, function (o) {
          return o.name.toLowerCase().indexOf(_.sertrimSQ.toLowerCase()) >= 0;
        });
        _.sertrimSQRes = newRes;
      } else {
        _.sertrimSQRes = [];
      }
      _.sertrimBounce = false;
    }, 300),
    amandemenSearch: ld.debounce(function () {
      let _ = this;
      if (_.amandemenSQ != "") {
        var res = _.model.dokumen_amandemen;
        let newRes = ld.filter(res, function (o) {
          return o.name.toLowerCase().indexOf(_.amandemenSQ.toLowerCase()) >= 0;
        });
        _.amandemenSQRes = newRes;
      }
    }, 100),

    bgUangMukaSearch: ld.debounce(function () {
      let _ = this;
      if (_.bg_uang_muka_sq != "") {
        var res = _.bg_uang_muka.rows;
        let newRes = ld.filter(res, function (o) {
          // console.log(o.nomor);
          return (
            o.nomor.toLowerCase().indexOf(_.bg_uang_muka_sq.toLowerCase()) >= 0
          );
        });
        _.bg_uang_muka_sq_res = newRes;
      }
    }, 100),

    bgPelaksanaanSearch: ld.debounce(function () {
      let _ = this;
      if (_.bg_pelaksanaan_sq != "") {
        var res = _.bg_pelaksanaan.rows;
        let newRes = ld.filter(res, function (o) {
          // console.log(o.nomor);
          return (
            o.nomor.toLowerCase().indexOf(_.bg_pelaksanaan_sq.toLowerCase()) >=
            0
          );
        });
        _.bg_pelaksanaan_sq_res = newRes;
      }
    }, 100),

    bgPemeliharaanSearch: ld.debounce(function () {
      let _ = this;
      if (_.bg_pemeliharaan_sq != "") {
        var res = _.bg_pemeliharaan.rows;
        let newRes = ld.filter(res, function (o) {
          // console.log(o.nomor);
          return (
            o.nomor.toLowerCase().indexOf(_.bg_pemeliharaan_sq.toLowerCase()) >=
            0
          );
        });
        _.bg_pemeliharaan_sq_res = newRes;
      }
    }, 100),

    bapSearch: ld.debounce(function () {
      let _ = this;
      if (_.bapSQ != "") {
        var res = _.model.dokumen_bap;
        let newRes = ld.filter(res, function (o) {
          return o.name.toLowerCase().indexOf(_.bapSQ.toLowerCase()) >= 0;
        });
        _.bapSQRes = newRes;
      }
    }, 100),
    poseSearch: ld.debounce(function () {
      let _ = this;
      if (_.poseSQ != "") {
        var res = _.model.pose;
        let newRes = ld.filter(res, function (o) {
          return o.no_po.toLowerCase().indexOf(_.poseSQ.toLowerCase()) >= 0;
        });
        _.poseSQRes = newRes;
      }
    }, 100),
    kontraktualSearch: ld.debounce(function () {
      let _ = this;
      if (_.kontraktualSQ != "") {
        var res = _.model.status_kontraktual;
        let newRes = ld.filter(res, function (o) {
          return (
            o.name.toLowerCase().indexOf(_.kontraktualSQ.toLowerCase()) >= 0
          );
        });
        _.kontraktualSQRes = newRes;
      }
      _.kontraktualBounce = false;
    }, 300),
    operasiSearch: ld.debounce(function () {
      let _ = this;
      if (_.operasiSQ != "") {
        var res = _.model.status_operasi;
        let newRes = ld.filter(res, function (o) {
          return o.name.toLowerCase().indexOf(_.operasiSQ.toLowerCase()) >= 0;
        });
        _.operasiSQRes = newRes;
      }
      _.operasiBounce = false;
    }, 300),
    scopeSearch: ld.debounce(function () {
      let _ = this;
      if (_.scopeSQ != "") {
        var res = _.model.scope;
        let newRes = ld.filter(res, function (o) {
          return o.name.toLowerCase().indexOf(_.scopeSQ.toLowerCase()) >= 0;
        });
        _.scopeSQRes = newRes;
      }
    }, 100),
    modalSertrim(type) {
      let _ = this;
      _.sertrimTr = type;
      _.sertrimRemoveFile();
      if (type == "edit") {
        _.sertrim_id = _.sertrim_detail.id;
        _.sertrim_form = {
          nomor: _.sertrim_detail.nomor == "-" ? "" : _.sertrim_detail.nomor,
          date: _.sertrim_detail.date,
          dokumen: [],
          deletedFile: [],
          is_numbered: _.sertrim_detail.is_numbered,
        };
        if (_.sertrim_detail.dokumen) {
          _.sertrim_detail.dokumen.forEach(function (el) {
            let file = { size: 10240, name: el.name, type: "application/word" };
            let url = el.url;
            _.$refs.sertrimDropzone.manuallyAddFile(file, url);
          });
        }
      } else if (type == "add") {
        _.sertrim_form = {
          name: "",
          nomor: "",
          date: "",
          nilai: 0,
          deletedFile: [],
        };
      }
      window.$("#Modal_Sertrim").modal("show");
    },
    sertrimFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.sertrim_form.dokumen.push(val);
        });
      }
    },
    sertrimDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.sertrim_form.dokumen = ld.filter(
          _.sertrim_form.dokumen,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.sertrim_form.deletedFile.push(file.name);
      }
    },
    sertrimRemoveFile() {
      let _ = this;
      _.$refs.sertrimDropzone.removeAllFiles(true);
      _.sertrim_form.dokumen = [];
    },
    async sertrimEdit() {
      let _ = this;

      var validation = _.$refs.sertrimForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.sertrim_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.sertrim_id);
        $.each(_.sertrim_form, function (index, val) {
          if (index == "dokumen") {
            $.each(_.sertrim_form.dokumen, function (index, val) {
              formData.append("dokumen[" + index + "]", val);
            });
          } else if (index == "deletedFile") {
            $.each(_.sertrim_form.deletedFile, function (index, val) {
              formData.append("deletedFile[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });

        await _.axios
          .post("/kontrak/serah_terima", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            console.log(resp);
            _.sertrim_form = {
              nomor: "",
              date: "",
              dokumen: [],
            };
            _.sertrimRemoveFile();
            validation.reset();
            window.$("#Modal_Sertrim").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
        _.sertrim_ldstate = false;
      });
    },
    //
    //
    // serah terima FUNC ends
    //
    //

    //
    //
    // Solusi FUNC
    //
    //
    modalSolusi(type, x) {
      let _ = this;
      _.solusiTr = type;
      _.solusiRemoveFile();
      if (type == "edit") {
        _.solusi_id = _.solusi_detail.id;
        _.solusi_form = {
          name: _.solusi_detail.name,
          uraian: _.solusi_detail.uraian,
          image: [],
          deletedFile: [],
          id_kendala: _.solusi_detail.id_kendala,
        };
        _.solusiTotalFile = 0;

        _.solusi_detail.image.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "image/png" };
          let url = el.url;
          _.$refs.solusiDropzone.manuallyAddFile(file, url);
          _.solusiTotalFile += 1;
        });
      } else if (type == "add") {
        (_.solusi_form = {
          name: "",
          uraian: "",
          image: [],
          deletedFile: [],
          id_kendala: x,
        }),
          (_.solusiTotalFile = 0);
      }
      window.$("#Modal_TambahSolusi").modal("show");
    },
    solusiFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.solusi_form.image.push(val);
          _.solusiTotalFile += 1;
        });
      }
    },
    solusiDeleteFile(file) {
      let _ = this;
      _.solusiTotalFile -= 1;
      if (file.lastModified) {
        _.solusi_form.image = ld.filter(_.solusi_form.image, function (o) {
          return o.name != file.name;
        });
      } else {
        _.solusi_form.deletedFile.push(file.name);
      }
    },
    solusiRemoveFile() {
      let _ = this;
      _.$refs.solusiDropzone.removeAllFiles(true);
      _.solusi_form.image = [];
      _.solusiTotalFile = 0;
    },
    solusiDelete(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/solusi", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },
    async solusiSave() {
      let _ = this;

      var validation = _.$refs.solusiForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.solusi_ldstate = true;

        var formData = new FormData();
        formData.append("id_kontrak", _.model.id);
        $.each(_.solusi_form, function (index, val) {
          if (index != "image") {
            formData.append(index, val);
          } else {
            $.each(_.solusi_form.image, function (index, val) {
              formData.append("image[" + index + "]", val);
            });
          }
        });

        await _.axios
          .post("/kontrak/solusi", formData)
          .then((resp) => {
            console.log(resp);
            _.solusi_form = {
              name: "",
              uraian: "",
              image: [],
              deletedFile: [],
              id_kendala: "",
            };
            validation.reset();
            _.solusiRemoveFile();
            window.$("#Modal_TambahSolusi").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
        _.solusi_ldstate = false;
      });
    },
    async solusiEdit() {
      let _ = this;

      var validation = _.$refs.solusiForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.solusi_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.solusi_id);
        $.each(_.solusi_form, function (index, val) {
          if (index == "image") {
            $.each(_.solusi_form.image, function (index, val) {
              formData.append("image[" + index + "]", val);
            });
          } else if (index == "deletedFile") {
            $.each(_.solusi_form.deletedFile, function (index, val) {
              formData.append("deletedFile[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });

        await _.axios
          .post("/kontrak/solusi/update", formData)
          .then((resp) => {
            console.log(resp);
            _.solusi_form = {
              name: "",
              uraian: "",
              image: [],
              deletedFile: [],
              id_kendala: "",
            };
            validation.reset();
            _.solusiRemoveFile();
            window.$("#Modal_TambahSolusi").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
        _.solusi_ldstate = false;
      });
    },
    //
    //
    // Solusi FUNC ends
    //
    //

    //
    //
    // amandemen FUNC
    //
    //
    vmounted() {
      this.$nextTick(function () {
        let _ = this;
        let fileCurves = {
          size: 10240,
          name: _.amandemen_detail.curva_s_file.name,
          type: ".xlsx,.xls,.csv",
        };
        let urlCurves = _.amandemen_detail.curva_s_file.url;
        _.$refs.curvaSDropzone.manuallyAddFile(fileCurves, urlCurves);
      });
    },
    modalAmandemen(type) {
      let _ = this;
      _.amandemenTr = type;
      _.amandemenRemoveFile();
      if (type == "edit") {
        _.amandemen_id = _.amandemen_detail.id;
        _.amandemen_form = {
          name: _.amandemen_detail.name,
          nomor: _.amandemen_detail.nomor,
          dokumen: [],
          date: _.amandemen_detail.date,
          tgl_akhir:
            _.amandemen_detail.tgl_akhir == null
              ? " "
              : _.amandemen_detail.tgl_akhir,
          perihal: JSON.parse(_.amandemen_detail.perihal),
          nilai_kontrak:
            _.amandemen_detail.nilai_kontrak == null
              ? " "
              : _.amandemen_detail.nilai_kontrak,
          deletedFile: [],
          other:
            _.amandemen_detail.other == null ? " " : _.amandemen_detail.other,
          idr: _.amandemen_detail.idr == null ? 0 : _.amandemen_detail.idr,
          cny: _.amandemen_detail.cny == null ? 0 : _.amandemen_detail.cny,
          jpy: _.amandemen_detail.jpy == null ? 0 : _.amandemen_detail.jpy,
          eur: _.amandemen_detail.eur == null ? 0 : _.amandemen_detail.eur,
          usd: _.amandemen_detail.usd == null ? 0 : _.amandemen_detail.usd,
          curva_s: [],
          deletedFileCurvaS: [],
          lingkup: _.amandemen_detail.lingkup,
        };
        _.amandemen_detail.dokumen.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.amandemenDropzone.manuallyAddFile(file, url);
          // _.$refs.curvaSDropzone.manuallyAddFile(file, url);
        });

        // console.log(_.amandemen_detail.curva_s_file.name);

        // _.amandemen_detail.curva_s_file.forEach(function(el) {
        // })
      } else if (type == "add") {
        _.amandemen_form = {
          name: "",
          nomor: "",
          dokumen: [],
          date: "",
          tgl_akhir: "",
          perihal: "",
          nilai_kontrak: "",
          deletedFile: [],
          other: "",
          idr: 0,
          cny: 0,
          jpy: 0,
          eur: 0,
          usd: 0,
          curva_s: [],
          deletedFileCurvaS: [],
          lingkup: ""
        };
      }
      window.$("#Modal_TambahAmandemen").modal("show");
    },

    modalBgUangMuka(type) {
      $(".dz-hidden-input").prop("disabled", false);
      let _ = this;
      _.uangMukaTr = type;
      _.bgUangMukaABRemoveFile();
      _.bgUangMukaBGRemoveFile();
      if (type == "edit") {
        _.bg_uang_muka_id = _.bg_uang_muka_detail.id;
        _.bg_uang_muka_form = {
          id_kontrak: _.bg_uang_muka_detail.id_kontrak,
          id_perubahan: _.bg_uang_muka_detail.id_perubahan,
          nomor: _.bg_uang_muka_detail.nomor,
          bank: _.bg_uang_muka_detail.bank,
          tanggal_terbit: _.bg_uang_muka_detail.tanggal_terbit,
          tanggal_berlaku: _.bg_uang_muka_detail.tanggal_berlaku,
          tanggal_berakhir: _.bg_uang_muka_detail.tanggal_berakhir,
          nilai: _.bg_uang_muka_detail.nilai,
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          cny: _.bg_uang_muka_detail.cny,
          jpy: _.bg_uang_muka_detail.jpy,
          eur: _.bg_uang_muka_detail.eur,
          usd: _.bg_uang_muka_detail.usd,
          is_origin: _.bg_uang_muka_detail.is_origin,
        };
        _.bg_uang_muka_detail.file_bg.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgUangMukaDropzoneBG.manuallyAddFile(file, url);
        });

        _.bg_uang_muka_detail.file_keabsahan.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgUangMukaDropzoneAB.manuallyAddFile(file, url);
        });
      } else if (type == "add") {
        _.bg_uang_muka_form = {
          id_kontrak: "",
          id_perubahan: "",
          nomor: "",
          bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 1,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      } else if (type == "add_perubahan") {
        _.bg_uang_muka_form = {
          id_kontrak: "",
          id_perubahan: "",
          nomor: "",
          bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 0,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      }

      window.$("#Modal_Bg_Uang_Muka").modal("show");
    },

    modalBgPelaksanaan(type) {
      $(".dz-hidden-input").prop("disabled", false);
      let _ = this;
      _.pelaksanaanTr = type;
      _.bgPelaksanaanABRemoveFile();
      _.bgPelaksanaanBGRemoveFile();
      if (type == "edit") {
        console.log(_.bg_pelaksanaan_detail);
        _.bg_pelaksanaan_id = _.bg_pelaksanaan_detail.id;
        _.bg_pelaksanaan_form = {
          id_kontrak: _.bg_pelaksanaan_detail.id_kontrak,
          id_perubahan: _.bg_pelaksanaan_detail.id_perubahan,
          nomor: _.bg_pelaksanaan_detail.nomor,
          bank: _.bg_pelaksanaan_detail.bank,
          tanggal_terbit: _.bg_pelaksanaan_detail.tanggal_terbit,
          tanggal_berlaku: _.bg_pelaksanaan_detail.tanggal_berlaku,
          tanggal_berakhir: _.bg_pelaksanaan_detail.tanggal_berakhir,
          nilai: _.bg_pelaksanaan_detail.nilai,
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          cny: _.bg_pelaksanaan_detail.cny,
          jpy: _.bg_pelaksanaan_detail.jpy,
          eur: _.bg_pelaksanaan_detail.eur,
          usd: _.bg_pelaksanaan_detail.usd,
          is_origin: _.bg_pelaksanaan_detail.is_origin,
        };
        _.bg_pelaksanaan_detail.file_bg.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPelaksanaanDropzoneBG.manuallyAddFile(file, url);
        });

        _.bg_pelaksanaan_detail.file_keabsahan.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPelaksanaanDropzoneAB.manuallyAddFile(file, url);
        });
      } else if (type == "add") {
        _.bg_pelaksanaan_form = {
          id_kontrak: "",
          id_perubahan: "",
          nomor: "",
          bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 1,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      } else if (type == "add_perubahan") {
        _.bg_pelaksanaan_form = {
          id_kontrak: "",
          id_perubahan: "",
          nomor: "",
          bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 0,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      }

      this.$forceUpdate();
      window.$("#Modal_Bg_Pelaksanaan").modal("show");
    },

    modalBgPemeliharaan(type) {
      $(".dz-hidden-input").prop("disabled", false);
      let _ = this;
      _.pemeliharaanTr = type;
      console.log(_.pemeliharaanTr);
      _.bgPemeliharaanABRemoveFile();
      _.bgPemeliharaanBGRemoveFile();
      if (type == "edit") {
        _.bg_pemeliharaan_id = _.bg_pemeliharaan_detail.id;
        _.bg_pemeliharaan_form = {
          id_kontrak: _.bg_pemeliharaan_detail.id_kontrak,
          id_perubahan: _.bg_pemeliharaan_detail.id_perubahan,
          nomor: _.bg_pemeliharaan_detail.nomor,
          bank: _.bg_pemeliharaan_detail.bank,
          tanggal_terbit: _.bg_pemeliharaan_detail.tanggal_terbit,
          tanggal_berlaku: _.bg_pemeliharaan_detail.tanggal_berlaku,
          tanggal_berakhir: _.bg_pemeliharaan_detail.tanggal_berakhir,
          nilai: _.bg_pemeliharaan_detail.nilai,
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          cny: _.bg_pemeliharaan_detail.cny,
          jpy: _.bg_pemeliharaan_detail.jpy,
          eur: _.bg_pemeliharaan_detail.eur,
          usd: _.bg_pemeliharaan_detail.usd,
          is_origin: _.bg_pemeliharaan_detail.is_origin,
        };
        _.bg_pemeliharaan_detail.file_bg.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPemeliharaanDropzoneBG.manuallyAddFile(file, url);
        });

        _.bg_pemeliharaan_detail.file_keabsahan.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPemeliharaanDropzoneAB.manuallyAddFile(file, url);
        });
      } else if (type == "add") {
        _.bg_pemeliharaan_form = {
          id_kontrak: "",
          id_perubahan: "",
          nomor: "",
          bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 1,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      } else if (type == "add_perubahan") {
        _.bg_pemeliharaan_form = {
          id_kontrak: "",
          id_perubahan: "",
          nomor: "",
          bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 0,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      }

      window.$("#Modal_Bg_Pemeliharaan").modal("show");
    },

    modalScope(type, id = null) {
      let _ = this;
      console.log(type);
      console.log(id);

      if (type == "edit") {
        let getScopeDetail = _.model.scope.filter((sc) => sc.id == id)[0];

        _.scope_form.id = getScopeDetail.id;
        _.scope_form.name = getScopeDetail.name;
      }
      _.stateScope = type;
      window.$("#Modal_TambahScope").modal("show");
    },
    modalScopeDetail(id) {
      let _ = this;
      let getScopeDetail = _.model.scope.filter((sc) => sc.id == id)[0];

      _.scopeDetail = getScopeDetail;

      window.$("#Modal_ScopeDetail").modal("show");
    },
    modalScopeRLB(type, sts_operasi) {
      console.log(type);
      console.log(sts_operasi);
      let _ = this;
      _.$refs.rlbDropzone.removeAllFiles();

      _.scope_rlb_form = {
        id: sts_operasi.id,
        nomor: sts_operasi.nomor,
        date: sts_operasi.date,
        document: [],
        deletedFile: [],
      };

      if (sts_operasi.dokumen) {
        sts_operasi.dokumen.forEach(function (el) {
          _.scopeHasFile = true;
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.rlbDropzone.manuallyAddFile(file, url);
        });
      }

      window.$("#Modal_TambahRLB").modal("show");
    },
    scopeRLBFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(
          file,
          function (index, val) {
            _.scope_rlb_form.document.push(val);
          },
          _
        );
      }
    },
    scopeRLBDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.scope_rlb_form.document = ld.filter(
          _.scope_rlb_form.document,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.scope_rlb_form.deletedFile.push(file.name);
      }
    },
    amandemenFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.amandemen_form.dokumen.push(val);
        });
      }
    },
    amandemenDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.amandemen_form.dokumen = ld.filter(
          _.amandemen_form.dokumen,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.amandemen_form.deletedFile.push(file.name);
      }
    },
    amandemenRemoveFile() {
      let _ = this;
      _.$refs.amandemenDropzone.removeAllFiles(true);
      _.amandemen_form.dokumen = [];
    },
    bapFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bap_form.document.push(val);
        });
      }
    },
    bapDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.bap_form.document = ld.filter(
          _.bap_form.document,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.bap_form.deletedFile.push(file.name);
      }
    },
    bapRemoveFile() {
      let _ = this;
      _.$refs.bapDropzone.removeAllFiles(true);
      _.bap_form.document = [];
    },

    curvaSFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.amandemen_form.curva_s.push(val);
          console.log(_.amandemen_form.curva_s);
        });
      }
    },
    curvaSDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.amandemen_form.curva_s = ld.filter(
          _.amandemen_form.curva_s,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.amandemen_form.deletedFileCurvaS.push(file.name);
      }
    },
    curvaSRemoveFile() {
      let _ = this;
      _.$refs.curvaSDropzone.removeAllFiles(true);
      _.amandemen_form.curva_s = [];
    },

    bgUangMukaBGFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_uang_muka_form.file_bg.push(val);
        });
      }
    },
    bgUangMukaBGDeleteFile(file) {
      let _ = this;
      // alert("jall");
      if (file.lastModified) {
        _.bg_uang_muka_form.file_bg = ld.filter(
          _.bg_uang_muka_form.file_bg,
          function (o) {
            console.log(o.name);
            return o.name != file.name;
          }
        );
      } else {
        console.log("hallo 2");
        _.bg_uang_muka_form.deletedFileBg.push(file.name);
      }
    },
    bgUangMukaBGRemoveFile() {
      let _ = this;
      _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true);
      _.bg_uang_muka_form.file_bg = [];
    },

    bgUangMukaBGDetail(file) {
      let _ = this;
      let detail = ld.filter(_.bg_uang_muka_detail.file_bg, function (o) {
        return o.name == file.name;
      });
      _.pdfViewer(detail[0].url);
      // _.$refs.bgUangMukaDropzoneBGDetail.removeAllFiles(true)
      // _.$refs.bgUangMukaDropzoneABDetail.removeAllFiles(true)
      window.$("#Modal_BG_Uang_Muka_Detail").modal("hide");
    },

    bgUangMukaABFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_uang_muka_form.file_keabsahan.push(val);
        });
      }
    },
    bgUangMukaABDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.bg_uang_muka_form.file_bg = ld.filter(
          _.bg_uang_muka_form.file_keabsahan,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.bg_uang_muka_form.deletedFileAb.push(file.name);
      }
    },
    bgUangMukaABRemoveFile() {
      let _ = this;
      _.$refs.bgUangMukaDropzoneAB.removeAllFiles(true);
      _.bg_uang_muka_form.file_keabsahan = [];
    },

    bgUangMukaABDetail(file) {
      let _ = this;
      let detail = ld.filter(
        _.bg_uang_muka_detail.file_keabsahan,
        function (o) {
          return o.name == file.name;
        }
      );
      _.pdfViewer(detail[0].url);
      // _.$refs.bgUangMukaDropzoneABDetail.removeAllFiles(true)
      // _.$refs.bgUangMukaDropzoneBGDetail.removeAllFiles(true)
      window.$("#Modal_BG_Uang_Muka_Detail").modal("hide");
    },

    //Batas Pelaksanaan

    bgPemeliharaanBGFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pemeliharaan_form.file_bg.push(val);
        });
      }
    },
    bgPemeliharaanBGDeleteFile(file) {
      let _ = this;
      // alert("jall");
      if (file.lastModified) {
        _.bg_pemeliharaan_form.file_bg = ld.filter(
          _.bg_pemeliharaan_form.file_bg,
          function (o) {
            console.log(o.name);
            return o.name != file.name;
          }
        );
      } else {
        console.log("hallo 2");
        _.bg_pemeliharaan_form.deletedFileBg.push(file.name);
      }
    },
    bgPemeliharaanBGRemoveFile() {
      let _ = this;
      _.$refs.bgPemeliharaanDropzoneBG.removeAllFiles(true);
      _.bg_pemeliharaan_form.file_bg = [];
    },

    bgPemeliharaanBGDetail(file) {
      let _ = this;
      let detail = ld.filter(_.bg_pemeliharaan_detail.file_bg, function (o) {
        return o.name == file.name;
      });

      _.pdfViewer(detail[0].url);
      // _.$refs.bgPemeliharaanDropzoneBGDetail.removeAllFiles(true)
      // _.$refs.bgPemeliharaanDropzoneABDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pemeliharaan_Detail").modal("hide");
    },

    bgPemeliharaanABFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pemeliharaan_form.file_keabsahan.push(val);
        });
      }
    },
    bgPemeliharaanABDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.bg_pemeliharaan_form.file_bg = ld.filter(
          _.bg_pemeliharaan_form.file_keabsahan,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.bg_pemeliharaan_form.deletedFileAb.push(file.name);
      }
    },
    bgPemeliharaanABRemoveFile() {
      let _ = this;
      _.$refs.bgPemeliharaanDropzoneAB.removeAllFiles(true);
      _.bg_pemeliharaan_form.file_keabsahan = [];
    },

    bgPemeliharaanABDetail(file) {
      let _ = this;
      let detail = ld.filter(
        _.bg_pemeliharaan_detail.file_keabsahan,
        function (o) {
          return o.name == file.name;
        }
      );

      _.pdfViewer(detail[0].url);
      // _.$refs.bgPemeliharaanDropzoneABDetail.removeAllFiles(true)
      // _.$refs.bgPemeliharaanDropzoneBGDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pemeliharaan_Detail").modal("hide");
    },

    // batas Pemeleliharan

    bgPelaksanaanBGFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pelaksanaan_form.file_bg.push(val);
        });
      }
    },
    bgPelaksanaanBGDeleteFile(file) {
      let _ = this;
      // alert("jall");
      if (file.lastModified) {
        _.bg_pelaksanaan_form.file_bg = ld.filter(
          _.bg_pelaksanaan_form.file_bg,
          function (o) {
            console.log(o.name);
            return o.name != file.name;
          }
        );
      } else {
        console.log("hallo 2");
        _.bg_pelaksanaan_form.deletedFileBg.push(file.name);
      }
    },
    bgPelaksanaanBGRemoveFile() {
      let _ = this;
      _.$refs.bgPelaksanaanDropzoneBG.removeAllFiles(true);
      _.bg_pelaksanaan_form.file_bg = [];
    },

    bgPelaksanaanBGDetail(file) {
      let _ = this;
      let detail = ld.filter(_.bg_pelaksanaan_detail.file_bg, function (o) {
        return o.name == file.name;
      });
      _.pdfViewer(detail[0].url);
      // _.$refs.bgPelaksanaanDropzoneBGDetail.removeAllFiles(true)
      // _.$refs.bgPelaksanaanDropzoneABDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pelaksanaan_Detail").modal("hide");
    },

    bgPelaksanaanABFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pelaksanaan_form.file_keabsahan.push(val);
        });
      }
    },
    bgPelaksanaanABDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.bg_pelaksanaan_form.file_bg = ld.filter(
          _.bg_pelaksanaan_form.file_keabsahan,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.bg_pelaksanaan_form.deletedFileAb.push(file.name);
      }
    },
    bgPelaksanaanABRemoveFile() {
      let _ = this;
      _.$refs.bgPelaksanaanDropzoneAB.removeAllFiles(true);
      _.bg_pelaksanaan_form.file_keabsahan = [];
    },

    bgPelaksanaanABDetail(file) {
      let _ = this;
      let detail = ld.filter(
        _.bg_pelaksanaan_detail.file_keabsahan,
        function (o) {
          return o.name == file.name;
        }
      );
      _.pdfViewer(detail[0].url);
      // _.$refs.bgPelaksanaanDropzoneABDetail.removeAllFiles(true)
      // _.$refs.bgPelaksanaanDropzoneBGDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pelaksanaan_Detail").modal("hide");
    },

    pushToMTU(id) {
      let _ = this;
      _.$router.push("/kontrak-mtu/detail/" + id);
    },

    async bgUangMukaSave() {
      let _ = this;
      var validation = _.$refs.bgUangMukaForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_uang_muka_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_uang_muka_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_uang_muka_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_uang_muka_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak", _.model.id);

        console.log(formData);

        await _.axios
          .post("v2/bg_uang_muka", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_uang_muka_form = {
              id_kontrak: "",
              id_perubahan: "",
              nomor: "",
              bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Uang_Muka").modal("hide");
            _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true);
            _.$refs.bgUangMukaDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getUangMuka();
            _.getUangMukaList();
            _.getUangMukaListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_uang_muka_ldstate = false;
      });
    },

    async bgUangMukaUpdate() {
      let _ = this;
      var validation = _.$refs.bgUangMukaForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_uang_muka_ldstate = true;

        if (_.bg_uang_muka_form.id_perubahan != null) {
          _.bg_uang_muka_form.is_origin = 0;
        } else {
          _.bg_uang_muka_form.is_origin = 1;
        }

        var formData = new FormData();
        console.log(_.bg_uang_muka_form);
        $.each(_.bg_uang_muka_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_uang_muka_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_uang_muka_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else if (index == "deletedFileBg") {
            $.each(_.bg_uang_muka_form.deletedFileBg, function (index, val) {
              formData.append("deletedFileBg[" + index + "]", val);
            });
          } else if (index == "deletedFileAb") {
            $.each(_.bg_uang_muka_form.deletedFileAb, function (index, val) {
              formData.append("deletedFileAb[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak", _.model.id);
        formData.append("id", _.bg_uang_muka_id);

        await _.axios
          .post("v2/bg_uang_muka/update", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_uang_muka_form = {
              id_kontrak: "",
              id_perubahan: "",
              nomor: "",
              bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Uang_Muka").modal("hide");
            _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true);
            _.$refs.bgUangMukaDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getUangMuka();
            _.getUangMukaList();
            _.getUangMukaListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_uang_muka_ldstate = false;
      });
    },

    modalBGUangMukaDetail(id) {
      let _ = this;
      _.bg_uang_muka_detail = [];
      _.$refs.bgUangMukaDropzoneABDetail.removeAllFiles(true);
      _.$refs.bgUangMukaDropzoneBGDetail.removeAllFiles(true);
      let get = _.bg_uang_muka.rows.filter((sc) => sc.id == id)[0];

      _.bg_uang_muka_detail = get;

      _.bg_uang_muka_detail.id_perubahan_show = _.bg_uang_muka_detail.perubahans
        ? _.bg_uang_muka_detail.perubahans.nomor
        : "-";
      _.bg_uang_muka_detail.bank_show = _.bg_uang_muka_detail.banks
        ? _.bg_uang_muka_detail.banks.name
        : "-";

      _.bg_uang_muka_detail.file_bg.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgUangMukaDropzoneBGDetail.manuallyAddFile(file, url);
      });

      _.bg_uang_muka_detail.file_keabsahan.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgUangMukaDropzoneABDetail.manuallyAddFile(file, url);
      });

      window.$("#Modal_BG_Uang_Muka_Detail").modal("show");
      $(".dz-hidden-input").prop("disabled", true);
    },

    delete_bg_uang_muka(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/v2/bg_uang_muka", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.getUangMuka();
                  _.getUangMukaList();
                  _.getUangMukaListAll();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    // Pelaksanaan

    async bgPelaksanaanSave() {
      let _ = this;
      var validation = _.$refs.bgPelaksanaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pelaksanaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pelaksanaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pelaksanaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_pelaksanaan_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak", _.model.id);

        console.log(formData);

        await _.axios
          .post("v2/bg_pelaksanaan", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pelaksanaan_form = {
              id_kontrak: "",
              id_perubahan: "",
              nomor: "",
              bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pelaksanaan").modal("hide");
            _.$refs.bgPelaksanaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPelaksanaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPelaksanaan();
            _.getPelaksanaanList();
            _.getPelaksanaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pelaksanaan_ldstate = false;
      });
    },

    async bgPelaksanaanUpdate() {
      let _ = this;
      var validation = _.$refs.bgPelaksanaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pelaksanaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pelaksanaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pelaksanaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_pelaksanaan_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else if (index == "deletedFileBg") {
            $.each(_.bg_pelaksanaan_form.deletedFileBg, function (index, val) {
              formData.append("deletedFileBg[" + index + "]", val);
            });
          } else if (index == "deletedFileAb") {
            $.each(_.bg_pelaksanaan_form.deletedFileAb, function (index, val) {
              formData.append("deletedFileAb[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak", _.model.id);
        formData.append("id", _.bg_pelaksanaan_id);

        console.log(formData);

        await _.axios
          .post("v2/bg_pelaksanaan/update", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pelaksanaan_form = {
              id_kontrak: "",
              id_perubahan: "",
              nomor: "",
              bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pelaksanaan").modal("hide");
            _.$refs.bgPelaksanaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPelaksanaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPelaksanaan();
            _.getPelaksanaanList();
            _.getPelaksanaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pelaksanaan_ldstate = false;
      });
    },

    modalBGPelaksanaanDetail(id) {
      let _ = this;
      _.bg_pelaksanaan_detail = [];
      _.$refs.bgPelaksanaanDropzoneABDetail.removeAllFiles(true);
      _.$refs.bgPelaksanaanDropzoneBGDetail.removeAllFiles(true);
      let get = _.bg_pelaksanaan.rows.filter((sc) => sc.id == id)[0];

      _.bg_pelaksanaan_detail = get;

      _.bg_pelaksanaan_detail.id_perubahan_show = _.bg_pelaksanaan_detail
        .perubahans
        ? _.bg_pelaksanaan_detail.perubahans.nomor
        : "-";
      _.bg_pelaksanaan_detail.bank_show = _.bg_pelaksanaan_detail.banks
        ? _.bg_pelaksanaan_detail.banks.name
        : "-";

      _.bg_pelaksanaan_detail.file_bg.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPelaksanaanDropzoneBGDetail.manuallyAddFile(file, url);
      });

      _.bg_pelaksanaan_detail.file_keabsahan.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPelaksanaanDropzoneABDetail.manuallyAddFile(file, url);
      });

      window.$("#Modal_BG_Pelaksanaan_Detail").modal("show");
      $(".dz-hidden-input").prop("disabled", true);
    },

    delete_bg_pelaksanaan(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/v2/bg_pelaksanaan", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.getPelaksanaan();
                  _.getPelaksanaanList();
                  _.getPelaksanaanListAll();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    // pemeliharaan

    async bgPemeliharaanSave() {
      let _ = this;
      var validation = _.$refs.bgPemeliharaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pemeliharaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pemeliharaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pemeliharaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(
              _.bg_pemeliharaan_form.file_keabsahan,
              function (index, val) {
                formData.append("file_keabsahan[" + index + "]", val);
              }
            );
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak", _.model.id);

        console.log(formData);

        await _.axios
          .post("v2/bg_pemeliharaan", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pemeliharaan_form = {
              id_kontrak: "",
              id_perubahan: "",
              nomor: "",
              bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pemeliharaan").modal("hide");
            _.$refs.bgPemeliharaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPemeliharaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPemeliharaan();
            _.getPemeliharaanList();
            _.getPemeliharaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pemeliharaan_ldstate = false;
      });
    },

    async bgPemeliharaanUpdate() {
      let _ = this;
      var validation = _.$refs.bgPemeliharaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pemeliharaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pemeliharaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pemeliharaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(
              _.bg_pemeliharaan_form.file_keabsahan,
              function (index, val) {
                formData.append("file_keabsahan[" + index + "]", val);
              }
            );
          } else if (index == "deletedFileBg") {
            $.each(_.bg_pemeliharaan_form.deletedFileBg, function (index, val) {
              formData.append("deletedFileBg[" + index + "]", val);
            });
          } else if (index == "deletedFileAb") {
            $.each(_.bg_pemeliharaan_form.deletedFileAb, function (index, val) {
              formData.append("deletedFileAb[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak", _.model.id);
        formData.append("id", _.bg_pemeliharaan_id);

        console.log(formData);

        await _.axios
          .post("v2/bg_pemeliharaan/update", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pemeliharaan_form = {
              id_kontrak: "",
              id_perubahan: "",
              nomor: "",
              bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pemeliharaan").modal("hide");
            _.$refs.bgPemeliharaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPemeliharaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPemeliharaan();
            _.getPemeliharaanList();
            _.getPemeliharaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pemeliharaan_ldstate = false;
      });
    },

    modalBGPemeliharaanDetail(id) {
      let _ = this;
      _.bg_pemeliharaan_detail = [];
      _.$refs.bgPemeliharaanDropzoneABDetail.removeAllFiles(true);
      _.$refs.bgPemeliharaanDropzoneBGDetail.removeAllFiles(true);
      let get = _.bg_pemeliharaan.rows.filter((sc) => sc.id == id)[0];

      _.bg_pemeliharaan_detail = get;

      _.bg_pemeliharaan_detail.id_perubahan_show = _.bg_pemeliharaan_detail
        .perubahans
        ? _.bg_pemeliharaan_detail.perubahans.nomor
        : "-";
      _.bg_pemeliharaan_detail.bank_show = _.bg_pemeliharaan_detail.banks
        ? _.bg_pemeliharaan_detail.banks.name
        : "-";

      _.bg_pemeliharaan_detail.file_bg.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPemeliharaanDropzoneBGDetail.manuallyAddFile(file, url);
      });

      _.bg_pemeliharaan_detail.file_keabsahan.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPemeliharaanDropzoneABDetail.manuallyAddFile(file, url);
      });

      window.$("#Modal_BG_Pemeliharaan_Detail").modal("show");
      $(".dz-hidden-input").prop("disabled", true);
    },

    delete_bg_pemeliharaan(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/v2/bg_pemeliharaan", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.getPemeliharaan();
                  _.getPemeliharaanList();
                  _.getPemeliharaanListAll();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    async amandemenSave() {
      let _ = this;
      let pr = "";

      var validation = _.$refs.amandemenForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.amandemen_ldstate = true;

        let tamps = _.amandemen_form.curva_s;

        var formData = new FormData();
        formData.append("id_kontrak", _.model.id);
        $.each(_.amandemen_form, function (index, val) {
          if (index != "dokumen" && index != "curva_s") {
            if (index == "perihal") {
              pr = JSON.stringify(val);
              formData.append(index, pr);
            } else {
              formData.append(index, val);
            }
          } else if (index == "dokumen") {
            $.each(_.amandemen_form.dokumen, function (index, val) {
              formData.append("dokumen[" + index + "]", val);
            });
          } else if (index == "curva_s") {
            $.each(_.amandemen_form.curva_s, function (index, val) {
              formData.append("curva_s", val);
            });
          }
        });
        // console.log(formData);

        await _.axios
          .post("/kontrak/amandemen", formData)
          .then(() => {
            _.amandemen_form = {
              name: "",
              nomor: "",
              dokumen: [],
              date: "",
              tgl_awal: "",
              tgl_akhir: "",
              perihal: [],
              nilai_kontrak: "",
              other: "",
              idr: 0,
              cny: 0,
              jpy: 0,
              eur: 0,
              usd: 0,
              curva_s: [],
            };
            validation.reset();
            window.$("#Modal_TambahAmandemen").modal("hide");
            _.$refs.amandemenDropzone.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
            _.getPelaksanaan();
            _.getPelaksanaanList();
            _.getPelaksanaanListAll();
            _.getMasterAI();
            _.getChartAmandemen();
            if (tamps != []) {
              _.getHistoryAmandemenCurva(1);
            } else {
              _.getHistoryAmandemenCurva();
            }
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
        _.amandemen_ldstate = false;
      });
    },
    async amandemenEdit() {
      let _ = this;
      let pr = "";

      var validation = _.$refs.amandemenForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.amandemen_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.amandemen_id);
        formData.append("id_kontrak", _.model.id);
        $.each(_.amandemen_form, function (index, val) {
          if (index == "dokumen") {
            $.each(_.amandemen_form.dokumen, function (index, val) {
              formData.append("dokumen[" + index + "]", val);
            });
          } else if (index == "deletedFile") {
            $.each(_.amandemen_form.deletedFile, function (index, val) {
              formData.append("deletedFile[" + index + "]", val);
            });
          } else {
            if (index == "perihal") {
              pr = JSON.stringify(val);
              formData.append(index, pr);
            } else {
              formData.append(index, val);
            }
          }
        });

        await _.axios
          .post("/kontrak/amandemen/update", formData)
          .then((resp) => {
            console.log(resp);
            _.amandemen_form = {
              name: "",
              nomor: "",
              dokumen: [],
              date: "",
              tgl_awal: "",
              tgl_akhir: "",
              perihal: "",
              nilai_kontrak: "",
              other: "",
              idr: 0,
              cny: 0,
              jpy: 0,
              eur: 0,
              usd: 0,
            };
            validation.reset();
            window.$("#Modal_TambahAmandemen").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
            _.getPelaksanaan();
            _.getPelaksanaanList();
            _.getPelaksanaanListAll();
            _.getMasterAI();
            _.getChartAmandemen();
            _.getHistoryAmandemenCurva();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
        _.amandemen_ldstate = false;
      });
    },
    delete_amandemen(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/amandemen", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                  _.getPelaksanaan();
                  _.getPelaksanaanList();
                  _.getPelaksanaanListAll();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    //
    //
    // amandemen FUNC ends
    //
    //

    // scope FUNC begin
    async scopeSave() {
      console.log("scopeSave clicked");
      let _ = this;
      let id_kontrak = _.$route.params.id;
      let name = _.scope_form.name;

      var validation = _.$refs.scopeForm;
      validation.validate().then(async (success) => {
        if (!success) {
          console.log("false");
          return;
        }

        if (_.stateScope == "add") {
          _.scope_ldstate = true;
          await _.axios
            .post("/kontrak/scope", { id_kontrak, name })
            .then((resp) => {
              _.scope_ldstate = false;
              console.log(resp);
              _.scope_form.name = "";
              validation.reset();
              window.$("#Modal_TambahScope").modal("hide");
              _.$toast.success("Data Berhasil Disimpan");
              _.get();
            })
            .catch((err) => {
              _.scope_ldstate = false;
              console.log(err);
            });
        } else if (_.stateScope == "edit") {
          let id = _.scope_form.id;
          _.scope_ldstate = true;
          await _.axios
            .patch("/kontrak/scope", { id, name })
            .then((resp) => {
              _.scope_ldstate = false;
              console.log(resp);
              _.scope_form.name = "";
              validation.reset();
              window.$("#Modal_TambahScope").modal("hide");
              _.$toast.success("Data Berhasil Diubah");
              _.get();
              _.stateScope = "add";
            })
            .catch((err) => {
              _.scope_ldstate = false;
              console.log(err);
            });
        }
      });
    },
    // scope FUNC end

    // scope RLB FUNC begin

    async scopeRLBSave() {
      let _ = this;
      var validation = _.$refs.scopeRLBForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.scoperlb_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.scope_rlb_form.id);
        formData.append("nomor", _.scope_rlb_form.nomor);
        formData.append("date", _.scope_rlb_form.date);

        $.each(_.scope_rlb_form.document, function (index, val) {
          formData.append("dokumen[" + index + "]", val);
        });

        $.each(_.scope_rlb_form.deletedFile, function (index, val) {
          formData.append("deletedFile[" + index + "]", val);
        });

        await _.axios
          .post("/kontrak/operasi", formData)
          .then((resp) => {
            _.scoperlb_ldstate = false;
            console.log(resp);
            _.$refs.rlbDropzone.removeAllFiles(true);
            _.scope_rlb_form = {
              id: "",
              nomor: "",
              document: [],
              deletedFile: [],
              date: "",
            };
            validation.reset();
            window.$("#Modal_TambahRLB").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            _.scoperlb_ldstate = false;
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
      });
    },

    // scope RLB FUNC end

    // scope Energize Func begin
    modalScopeEnergize(type, sts_operasi) {
      let _ = this;
      _.$refs.energizeDropzone.removeAllFiles();

      _.scope_energize_form = {
        id: sts_operasi.id,
        nomor: sts_operasi.nomor,
        date: sts_operasi.date,
        document: [],
        deletedFile: [],
      };

      if (sts_operasi.dokumen) {
        sts_operasi.dokumen.forEach(function (el) {
          _.scopeEnergizeHasFile = true;
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.energizeDropzone.manuallyAddFile(file, url);
        });
      }

      window.$("#Modal_TambahEnergize").modal("show");
    },
    scopeEnergizeFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(
          file,
          function (index, val) {
            _.scope_energize_form.document.push(val);
          },
          _
        );
      }
    },
    scopeEnergizeDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.scope_energize_form.document = ld.filter(
          _.scope_energize_form.document,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.scope_energize_form.deletedFile.push(file.name);
      }
    },

    async scopeEnergizeSave() {
      let _ = this;
      var validation = _.$refs.scopeEnergizeForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.scopeenergize_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.scope_energize_form.id);
        formData.append("nomor", _.scope_energize_form.nomor);
        formData.append("date", _.scope_energize_form.date);

        $.each(_.scope_energize_form.document, function (index, val) {
          formData.append("dokumen[" + index + "]", val);
        });

        $.each(_.scope_energize_form.deletedFile, function (index, val) {
          formData.append("deletedFile[" + index + "]", val);
        });

        await _.axios
          .post("/kontrak/operasi", formData)
          .then((resp) => {
            _.scopeenergize_ldstate = false;
            console.log(resp);
            _.$refs.energizeDropzone.removeAllFiles(true);
            _.scope_energize_form = {
              id: "",
              nomor: "",
              document: [],
              deletedFile: [],
              date: "",
            };
            validation.reset();
            window.$("#Modal_TambahEnergize").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            _.scopeenergize_ldstate = false;
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
      });
    },
    // scope Energize Func End

    // scope SLO Func Begin
    modalScopeSLO(type, sts_operasi) {
      let _ = this;
      _.$refs.sloDropzone.removeAllFiles();

      _.scope_slo_form = {
        id: sts_operasi.id,
        nomor: sts_operasi.nomor,
        date: sts_operasi.date,
        document: [],
        deletedFile: [],
      };

      if (sts_operasi.dokumen) {
        sts_operasi.dokumen.forEach(function (el) {
          _.scopeSLOHasFile = true;
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.sloDropzone.manuallyAddFile(file, url);
        });
      }

      window.$("#Modal_TambahSLO").modal("show");
    },
    scopeSLOFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(
          file,
          function (index, val) {
            _.scope_slo_form.document.push(val);
          },
          _
        );
      }
    },
    scopeSLODeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.scope_slo_form.document = ld.filter(
          _.scope_slo_form.document,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.scope_slo_form.deletedFile.push(file.name);
      }
    },

    async scopeSLOSave() {
      let _ = this;
      var validation = _.$refs.scopeSLOForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.scopeslo_ldstate = true;
        var formData = new FormData();
        formData.append("id", _.scope_slo_form.id);
        formData.append("nomor", _.scope_slo_form.nomor);
        formData.append("date", _.scope_slo_form.date);

        $.each(_.scope_slo_form.document, function (index, val) {
          formData.append("dokumen[" + index + "]", val);
        });

        $.each(_.scope_slo_form.deletedFile, function (index, val) {
          formData.append("deletedFile[" + index + "]", val);
        });

        await _.axios
          .post("/kontrak/operasi", formData)
          .then((resp) => {
            _.scopeslo_ldstate = false;
            console.log(resp);
            _.$refs.sloDropzone.removeAllFiles(true);
            _.scope_slo_form = {
              id: "",
              nomor: "",
              document: [],
              deletedFile: [],
              date: "",
            };
            validation.reset();
            window.$("#Modal_TambahSLO").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
            _.get();
          })
          .catch((err) => {
            _.scopeslo_ldstate = false;
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
      });
    },
    // scope SLO Func End

    //
    //
    // PROGRESS FUNC
    //
    //
    modalProgress(type) {
      let _ = this;
      _.progressTr = type;
      _.progressRemoveFile();
      if (type == "edit") {
        _.progress_id = _.progress_detail.id;
        _.progress_form = {
          name: _.progress_detail.name,
          description: _.progress_detail.description,
          image: [],
          cuaca: _.progress_detail.cuaca,
          progress: _.progress_detail.progress,
          deletedFile: [],
          type: _.progress_detail.type,
          approved_by: null,
          pekerja: _.progress_detail.pekerja,
          jenis_progress: _.progress_detail.jenis_progress,
          progress_em: _.progress_detail.progress_em,
          progress_sipil: _.progress_detail.progress_sipil,
          progress_prequirement: _.progress_detail.progress_prequirement,
        };
        _.progressTotalFile = 0;
        _.progress_detail.image.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "image/png" };
          let url = el.url;
          _.$refs.createProgressDropzone.manuallyAddFile(file, url);
          _.progressTotalFile += 1;
        });
      } else if (type == "add") {
        _.progress_form = {
          name: "",
          description: "",
          image: [],
          cuaca: 0,
          progress: 0,
          deletedFile: [],
          type: localStorage.getItem("role_id") == 3 ? 1 : 2,
          pekerja: "",
          jenis_progress: 1,
          progress_em: 0,
          progress_sipil: 0,
          progress_prequirement: 0,
          progressTotalFile: 0,
        };
      }
      window.$("#Modal_TambahProgress").modal("show");
    },
    approve_progress(x) {
      let _ = this;
      let data = {
        id: x,
      };
      _.axios
        .post("/kontrak/progress/approve", data)
        .then((resp) => {
          if (resp.status) {
            _.$toast.success("Data Berhasil Diubah");
            _.get();
          } else {
            _.$toast.warning("Data Gagal Diubah");
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    not_approve_progress(x) {
      let _ = this;
      let data = {
        id: x,
      };
      _.axios
        .post("/kontrak/progress/not_approve", data)
        .then((resp) => {
          if (resp.status) {
            _.$toast.success("Data Berhasil Diubah");
            _.get();
          } else {
            _.$toast.warning("Data Gagal Diubah");
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    delete_progress(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/progress", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },
    progressFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.progress_form.image.push(val);
        });
      }
      _.progressTotalFile += 1;
    },
    progressDeleteFile(file) {
      let _ = this;
      _.progressTotalFile -= 1;
      if (file.lastModified) {
        _.progress_form.image = ld.filter(_.progress_form.image, function (o) {
          return o.name != file.name;
        });
      } else {
        _.progress_form.deletedFile.push(file.name);
      }
    },
    progressRemoveFile() {
      let _ = this;
      _.$refs.createProgressDropzone.removeAllFiles(true);
      _.progress_form.image = [];
      _.progressTotalFile = 0;
    },
    async progressSave() {
      let _ = this;
      var validation = _.$refs.progressForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.isUpload_progress = true;

        var formData = new FormData();
        formData.append("id_kontrak", _.model.id);
        $.each(_.progress_form, function (index, val) {
          if (index != "image") {
            formData.append(index, val);
          } else {
            $.each(_.progress_form.image, function (index, val) {
              formData.append("image[" + index + "]", val);
            });
          }
        });

        await _.axios
          .post("/kontrak/progress", formData)
          .then((resp) => {
            _.get();
            console.log(resp);
            _.progress_form = {
              name: "",
              description: "",
              image: [],
              cuaca: "",
              progress: "",
              pekerja: "",
              jenis_progress: 1,
              image_count: 0,
            };
            validation.reset();
            _.progressRemoveFile();
            window.$("#Modal_TambahProgress").modal("hide");
            _.$toast.success("Data Berhasil Disimpan");
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Kontrak Gagal Disimpan");
          });
        _.isUpload_progress = false;
      });
    },
    async progressEdit() {
      let _ = this;

      var validation = _.$refs.progressForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.isUpload_progress = true;
        var formData = new FormData();
        formData.append("id", _.progress_id);
        $.each(_.progress_form, function (index, val) {
          if (index == "image") {
            $.each(_.progress_form.image, function (index, val) {
              formData.append("image[" + index + "]", val);
            });
          } else if (index == "deletedFile") {
            $.each(_.progress_form.deletedFile, function (index, val) {
              formData.append("deletedFile[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });

        await _.axios
          .post("/kontrak/progress/update", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            _.get();
            console.log(resp);
            _.progress_form = {
              name: "",
              description: "",
              image: [],
              cuaca: "",
              progress: "",
              pekerja: "",
              jenis_progress: 1,
              image_count: 0,
            };
            validation.reset();
            _.progressRemoveFile();
            _.$refs.progressClose.click();
            _.$toast.success("Data Berhasil Disimpan");
            window.$("#Modal_TambahProgress").modal("hide");
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak/" + _.model.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
        _.isUpload_progress = false;
      });
    },
    getProgressChoice() {
      let _ = this;
      _.axios
        .get("/kontrak/progress/all?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.progress_choice = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    //
    //
    // PROGRESS FUNC ends
    //
    //
    getRekomendasiList() {
      let _ = this;
      _.axios
        .get("/kontrak/rekomendasi?id_kontrak=" + _.$route.params.id)
        .then((resp) => {
          _.list_rekomendasi = resp.data.data.rows.filter((p) => {
            return !p.id_amandemen;
          });
        });
    },
    isShowApprove(data) {
      let _ = this;
      let position_name = localStorage.getItem("position_name");
      let isAdmin = position_name.indexOf("Super Admin") != -1;

      if (
        _.role_id == "1" &&
        data.type == "1" &&
        (data.approved_by == null || data.approved_by == 0)
      ) {
        //Role Pengawas & Progress Kontraktor
        return true;
      } else if (
        isAdmin &&
        (data.approved_by == null || data.approved_by == 0)
      ) {
        //Role Super Admin
        return true;
      } else if (
        _.role_id == "2" &&
        data.type == "2" &&
        (data.approved_by == null || data.approved_by == 0)
      ) {
        //Role Non-Pengawas & Progress Pengawas
        return true;
      } else if (
        _.role_id == "null" &&
        (data.approved_by == null || data.approved_by == 0)
      ) {
        //Role Developer
        return true;
      } else {
        return false;
      }
    },
    deleteScope(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/kontrak/scope", { data: { id: x } })
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.get();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },
    choosenBAP(x) {
      let _ = this;
      let data = {};
      data = _.model.dokumen_bap.filter(function (q) {
        return q.id == x;
      });

      _.pose_form.idr = data[0].sisa_idr;
      _.pose_form.cny = data[0].sisa_cny;
      _.pose_form.jpy = data[0].sisa_jpy;
      _.pose_form.eur = data[0].sisa_eur;
      _.pose_form.usd = data[0].sisa_usd;
    },
    getAge(startDate, dateString) {
      var today = new Date(startDate);
      var DOB = new Date(dateString);
      var totalMonths = (today.getFullYear() - DOB.getFullYear()) * 12 + today.getMonth() - DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      var years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth())
          years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
          if (DOB.getDate() > today.getDate())
              years = years - 1;

      var days;
      var months;

      if (DOB.getDate() > today.getDate()) {
          months = (totalMonths % 12);
          if (months == 0)
              months = 11;
          var x = today.getMonth();
          switch (x) {
              case 1:
              case 3:
              case 5:
              case 7:
              case 8:
              case 10:
              case 12: {
                  var a = DOB.getDate() - today.getDate();
                  days = 31 - a;
                  break;
              }
              default: {
                  var b = DOB.getDate() - today.getDate();
                  days = 30 - b;
                  break;
              }
          }

      }
      else {
          days = today.getDate() - DOB.getDate();
          if (DOB.getMonth() === today.getMonth())
              months = (totalMonths % 12);
          else
              months = (totalMonths % 12) + 1;
      }
      var age = years + ' Tahun ' + months + ' Bulan ' + days + ' Hari';
      return age;
    }

  },
  computed: {
    equivalent() {
      let _ = this;
      let equi =
        parseFloat(_.amandemen_form.idr ? _.amandemen_form.idr : 0) +
        parseFloat(
          (_.amandemen_form.cny ? _.amandemen_form.cny : 0) * _.cur.cny
        ) +
        parseFloat(
          (_.amandemen_form.jpy ? _.amandemen_form.jpy : 0) * _.cur.jpy
        ) +
        parseFloat(
          (_.amandemen_form.eur ? _.amandemen_form.eur : 0) * _.cur.eur
        ) +
        parseFloat(
          (_.amandemen_form.usd ? _.amandemen_form.usd : 0) * _.cur.usd
        );
      _.amandemen_form.nilai_kontrak = equi;
      return equi;
    },
    equivalentBAP() {
      let _ = this;
      let equi =
        parseFloat(_.bap_form.idr ? _.bap_form.idr : 0) +
        parseFloat((_.bap_form.cny ? _.bap_form.cny : 0) * _.cur.cny) +
        parseFloat((_.bap_form.jpy ? _.bap_form.jpy : 0) * _.cur.jpy) +
        parseFloat((_.bap_form.eur ? _.bap_form.eur : 0) * _.cur.eur) +
        parseFloat((_.bap_form.usd ? _.bap_form.usd : 0) * _.cur.usd);
      _.bap_form.nilai = equi;
      return equi;
    },
    equivalentPOSE() {
      let _ = this;
      let equi =
        parseFloat(_.pose_form.idr ? _.pose_form.idr : 0) +
        parseFloat((_.pose_form.cny ? _.pose_form.cny : 0) * _.cur.cny) +
        parseFloat((_.pose_form.jpy ? _.pose_form.jpy : 0) * _.cur.jpy) +
        parseFloat((_.pose_form.eur ? _.pose_form.eur : 0) * _.cur.eur) +
        parseFloat((_.pose_form.usd ? _.pose_form.usd : 0) * _.cur.usd);
      _.pose_form.nilai_terbayar = equi;
      return equi;
    },
  },
  async mounted() {
    let _ = this;
    let pos = localStorage.getItem("position_name");
    if (pos == "Super Admin") {
      this.isSuperAdmin = true;
    }

    await _.get();
    await _.getCurrency();
    await _.getRekomendasiList();
    await _.initMap();
    window.$("#Modal_PDFViewer").on("hidden.bs.modal", function () {
      // do something...
      _.pdfFile = "";
    });

    _.$root.$on("setPDFFile", (data) => {
      _.pdfViewer(data)
    });
  },
  async created() {
    let _ = this;
    await _.getUangMuka();
    await _.getPelaksanaan();
    await _.getPemeliharaan();
    await _.getUangMukaList();
    await _.getPelaksanaanList();
    await _.getPemeliharaanList();
    await _.getUangMukaListAll();
    await _.getPelaksanaanListAll();
    await _.getPemeliharaanListAll();
    await _.getBankList();
    await _.getMasterAI();
    await _.getListMTU();
    await _.getListProjectLain();
    // await _.getChartAmandemen();
    await _.getHistoryAmandemenCurva();
    await _.getCurvaProgress();

    $("[rel='tooltip']").tooltip();
  },
  watch: {
    sertrimSQ: function () {
      let _ = this;
      _.sertrimBounce = true;
    },
    kontraktualSQ: function () {
      let _ = this;
      _.kontraktualBounce = true;
    },
    operasiSQ: function () {
      let _ = this;
      _.operasiBounce = true;
    },
    "amandemen_form.perihal": function () {
      this.aman_showTime = false;
      this.aman_showValue = false;
      this.aman_showOther = false;
      this.aman_showChangeLingkup = false;

      this.amandemen_form.perihal.forEach((el) => {
        if (el.id == 1) {
          this.aman_showTime = true;
        }
        if (el.id == 2) {
          this.aman_showValue = true;
        }
        if (el.id == 3) {
          this.aman_showOther = true;
        }
        if (el.id == 4) {
          this.aman_showChangeLingkup = true;
        }
      });

      if (this.aman_showValue == false) {
        this.amandemen_form.idr = "";
        this.amandemen_form.cny = "";
        this.amandemen_form.jpy = "";
        this.amandemen_form.eur = "";
        this.amandemen_form.usd = "";
        this.amandemen_form.nilai_kontrak = "";
      }

      if (this.aman_showTime == false) {
        this.amandemen_form.tgl_akhir = "";
      }

      if (this.aman_showValue == false && this.aman_showTime == false) {
        this.amandemen_form.curva_s = [];
      }

      if (this.aman_showOther == false) {
        this.amandemen_form.other = "";
      }

      if (this.aman_showChangeLingkup == false) {
        this.amandemen_form.lingkup = "";
      }
    },
  },
};
</script>
