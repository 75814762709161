<template>
  <div v-if="!loadStatePage">
    <div class="content-header">
      <div class="content-header-left mb-2">
        <div class="breadcrumbs-top">
          <h2 class="content-header-title mb-0">Detail Kontrak</h2>
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                  <i class="feather icon-home"></i>
                </router-link>
              </li>

              <li class="breadcrumb-item">
                <router-link to="/kontrak">Monitoring Project</router-link>
              </li>
              <li class="breadcrumb-item">
                <a href="#">Project List</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">{{ detail.no_kontrak }}</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">Detail Kontrak</a>
              </li>
              <li class="breadcrumb-item active">Detail Progress</li>
            </ol>
          </div>
        </div>
        <div class="d-flex mt-2" style="gap: 10px">
          <router-link to="/approval" style="font-size: 20px">
            <i class="feather icon-arrow-left"></i>
          </router-link>
          <h2 class="content-header-title mb-0">Detail Progress</h2>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="basic-datatable">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body card-dashboard">
                  <span v-html="detail.status_text"></span>
                  <div class="row mb-2">
                    <div class="col-12">
                       <span
                        class="statusbg pl-4 pr-4 approve text-capitalize"
                        style="padding-top:10px; padding-bottom:10px;"
                        v-if="detail_progress.col_status == 'approved'"
                        >Approved</span
                      >
                      <span
                        class="statusbg pl-4 pr-4 waiting text-capitalize"
                        style="padding-top:10px; padding-bottom:10px;"
                        v-else-if="detail_progress.col_status == 'waiting for approval'"
                        >Waiting For Approval</span
                      >

                      <span
                        class="statusbg pl-4 pr-4 not_approve text-capitalize"
                        style="padding-top:10px; padding-bottom:10px;"
                        v-else-if="detail_progress.col_status == 'not approved'"
                        >Not Approved</span
                      >
                    </div>
                    <div class="col-6">
                      <h5 class="mt-2"><strong>Periode Progress</strong></h5>
                      <p class="text-capitalize">{{ detail_progress.name }}</p>

                      <h5 class="mt-2"><strong>Uraian</strong></h5>
                      <p v-html="detail_progress.description"></p>

                      <p class="mt-2">
                        <span>Catatan Kendala</span>
                        <p class="m-0" v-html="detail_progress.problem">
                      </p>

                      <h5 class="mt-2"><strong>Pekerja</strong></h5>
                      <p>{{ detail_progress.workers }}</p>

                      <h5 class="mt-2"><strong>Foto</strong></h5>
                        <div class="row">
                          <div class="col-md-3" v-for="(i, index) in detail_progress.documents" :key="index">
                            <img
                              :src="i.url"
                              width="110"
                              height="80"
                              :class="index !== 0 ? 'rounded' : 'rounded'"
                              alt=""
                              style="cursor: zoom-in"
                              data-dismiss="modal"
                              data-toggle="modal"
                              data-target="#Modal_DetailFoto"
                              @click="
                                (detailFoto = detail_progress.documents), (showImage = index)
                              "
                            />
                          </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <h5 class="mt-2"><strong>History Catatan</strong></h5>
                      <div class="table-responsive">
                        <table class="table tabel-status-document w-120">
                          <tbody>
                            <tr v-for="(i, index) in histories" :key="index">
                              <td
                                width="70px"
                                style="white-space: nowrap text-right"
                              >
                                <img
                                  :src="
                                    i.user_picture
                                      ? i.user_picture
                                      : '/assets/img/icon/profile.png'
                                  "
                                  style="border-radius:50%;"
                                  width="40px;"
                                  height="40px;"
                                  alt=""
                                />
                              </td>
                              <td width="150px">
                                <span>
                                  {{ i.user_name }} - ({{ i.user_position }})
                                </span>
                                <br />
                                <span class="font-small-3">{{
                                  i.col_updated_at
                                }}</span>
                              </td>
                              <td>
                                <span
                                  v-if="i.col_status == 'approved'"
                                  class="statusbg approve"
                                >
                                  Approved
                                </span>
                                <span v-if="i.col_status == 'waiting for approval'" class="statusbg waiting"
                                  >Waiting For Approval</span
                                >

                                <span v-if="i.col_status == 'not approved'" class="statusbg not_approve"
                                  >Not Approved</span
                                >
                              </td>
                            </tr>
                          </tbody>
                          <!-- <tbody v-else>
                            <tr>
                              <td colspan="10" class="text-center">
                                Tidak ada data.
                              </td>
                            </tr>
                          </tbody> -->
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-2">
                      <div>
                        <h5 class="mt-2 text-center">
                          <strong
                            >Input By: {{ detail_progress.input_by }}</strong
                          >
                        </h5>
                        <vue-qrcode
                          :value="
                            detail_progress.input_by +
                            ' (' +
                            detail_progress.position +
                            ') ' +
                            detail_progress.phone_number
                          "
                          width="150"
                        />
                        <h5 class="text-center">{{ detail_progress.input_by }}</h5>
                        <h5 class="text-center">{{ detail_progress.phone_number }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="detail_progress.col_status == 'waiting for approval'">
                    <div class="col col-md-6">
                      <button
                        @click="notApprove()"
                        class="btn btn-danger"
                        style="width: 100%"
                      >
                        <span class="text-white"
                          ><strong>Not Approve</strong></span
                        >
                      </button>
                    </div>
                    <div class="col col-md-6">
                      <button
                        @click="approve()"
                        class="btn btn-primary"
                        style="width: 100%"
                      >
                        <span class="text-white"><strong>Approve</strong></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
      <div
      class="modal fade text-left"
      id="Modal_DetailFoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      v-if="detailFoto != ''"
      style="overflow: auto !important"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Detail Foto</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="detailFoto = ''"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  :data-slide-to="index"
                  v-for="(data, index) in detailFoto"
                  :key="index"
                  :class="showImage == index ? 'active' : ''"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  class="carousel-item"
                  :class="showImage == index ? 'active' : ''"
                  v-for="(data, index) in detailFoto"
                  :key="index"
                >
                  <img class="d-block w-100" :src="data.url" />
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mx-auto text-center" style="width: 100%" v-else>
    <h2><i class="fa fa-spinner fa-spin"></i></h2>
  </div>
</template>

<script>
export default {
  name: "DetailProgressApprove",
  data() {
    return {
      role_id: localStorage.getItem("role_id"),
      detail: null,
      idDetail: null,
      loadStatePage: true,
      idData: null,
      detail_progress: null,
      histories: null,
      detailFoto:'',
      showImage:0,
    };
  },
  filters: {
    hhMS(val) {
      if (!val) return "-";
      return val.substring(10, val.length);
    },
  },
  mounted() {
    this.idData = this.$route.params.id;
    this.idDetail = this.$route.params.id_detail;
    this.loadDetail();
  },
  methods: {
    async loadDetail() {
      this.loadStatePage = true;
      try {
        const datas = (await this.axios.get("/kontrak?id=" + this.idData)).data;
        if (!datas.status) throw "Error";
        this.detail = datas.data.rows;
        this.loadDetailProgress();
        this.loadHistories();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadStatePage = false;
      }
    },
    async loadDetailProgress() {
      try {
        const datas = (
          await this.axios.get("v2/kontrak/curva-s/" + this.idDetail)
        ).data;
        if (!datas.status) throw "Error";
        this.detail_progress = datas.data;

        console.log(this.detail_progress);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadStatePage = false;
      }
    },
    async loadHistories() {
      try {
        const datas = (
          await this.axios.get(
            "v2/kontrak/curva-s/" + this.idDetail + "/histories"
          )
        ).data;
        if (!datas.status) throw "Error";
        this.histories = datas.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadStatePage = false;
      }
    },
    async approve() {
      try {
        const datas = (
          await this.axios.put(
            `/v2/kontrak/curva-s/${this.detail_progress.id_progress_note}/status`,
            {
              status: 1,
            }
          )
        ).data;
        if (!datas.status) throw "Error";
        this.loadDetail();
      } catch (error) {
        console.log(error);
      }
    },
    async notApprove() {
      try {
        const datas = (
          await this.axios.put(
            `/v2/kontrak/curva-s/${this.detail_progress.id_progress_note}/status`,
            {
              status: -1,
            }
          )
        ).data;
        if (!datas.status) throw "Error";
        this.loadDetail();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.wrapper-ap-gallery {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.wrapper-ap-gallery > img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.history-catatan-list {
  display: flex;
  flex-direction: column;
}
.history-row-list {
  height: 65px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.bg-row-gray {
  background: #f9f9f9;
}
.bg-row-white {
  background: #fff;
}

.avatar-40px {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.06));
}
.avatar-40px > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
</style>
