<template>
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title text-primary">Bank Garansi</h4>
      </div>
      <hr />
      <div class="card-body pt-1">
        <section class="d-block" name="BankGaransiUangMuka">
          <div class="row align-items-center justify-content-between">
            <div class="col-12 col-sm-9 col-lg-9 mb-1" v-can="'kontrak/detail/bg_uang_muka/read'">
              <h4 class="card-title mb-0">
                Jaminan Uang Muka
                <div
                  class="ml-1 badge badge-warning badge-md"
                  v-if="bg_uang_muka.rows.length > 0"
                  @click="bgUangukaForce"
                  v-can="'kontrak/detail/bg_uang_muka/force_update'"
                  style="cursor: pointer"
                >
                  {{ bg_uang_muka.status_table }} &nbsp;
                  <i class="fa fa-angle-down"></i>
                </div>
                <button
                  type="button"
                  v-can="'kontrak/detail/bg_uang_muka/create'"
                  class="btn btn-primary btn-sm btn-block-mobile"
                  @click="modalBgUangMuka('add')"
                >
                  Tambah BG Original
                </button>
                <button
                  v-if="bg_uang_muka.rows.length > 0"
                  type="button"
                  v-can="'kontrak/detail/bg_uang_muka/create'"
                  @click="modalBgUangMuka('add_perubahan')"
                  class="btn btn-primary btn-sm btn-block-mobile"
                >
                  Tambah BG Perubahan
                </button>
              </h4>
            </div>

            <div class="col-12 col-sm-3 mb-1">
              <div class="input-group form-search-rounded">
                <input
                  class="form-control py-2 border-right-0 border"
                  type="search"
                  v-model="bg_uang_muka_sq"
                  @input="bgUangMukaSearch"
                  placeholder="Cari.."
                />
                <span class="input-group-append">
                  <div class="input-group-text bg-transparent">
                    <a href="#" class="text-blue"
                      ><i class="fa fa-search"></i
                    ></a>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div v-can="'kontrak/detail/bg_uang_muka/read'" class="table-responsive no-sort tableFixHead">
            <table class="table tabel-status-document w-120">
              <thead>
                <tr>
                  <th>Nomor Seri</th>
                  <th>Tanggal Berlaku</th>
                  <th>Tanggal Berakhir</th>
                  <th>IDR</th>
                  <th>CNY</th>
                  <th>JPY</th>
                  <th>EUR</th>
                  <th>USD</th>
                  <th>Status</th>
                  <th class="w10">Aksi</th>
                </tr>
              </thead>
              <tbody
                v-if="bg_uang_muka.rows.length > 0 && bg_uang_muka_sq == ''"
              >
                <tr v-for="data in bg_uang_muka.rows" :key="data.id">
                  <td class="text-left">
                    {{ data.nomor }}
                  </td>
                  <td>{{ data.tgl_terbit }}</td>
                  <td>{{ data.tgl_berakhir }}</td>
                  <td style="white-space: nowrap">
                    {{ data.nilai | currency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#x5143;{{ data.cny | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#xa5;{{ data.jpy | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &euro;{{ data.eur | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    ${{ data.usd | globalCurrency }}
                  </td>
                  <td class="text-left" style="white-space: nowrap">
                    <span
                      class="statusbg mr-5"
                      v-if="data.status_text == '-'"
                    >
                      -
                    </span>
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Aktif'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Penyesuaian Nilai'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Akan Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg not_approve mr-1"
                      v-if="data.status_text == 'Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Selesai'"
                      >-</span
                    >
                    
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lengkapi file keabsahan"
                    >
                      <img
                        v-if="data.file_keabsahan.length == 0"
                        src="/assets/img/Vector.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                  </td>
                  <td>
                    <div class="btn-action-block">
                      <button
                        type="button"
                        @click="modalBGUangMukaDetail(data.id)"
                        class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_uang_muka/detail'"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <!-- &nbsp;&nbsp; -->
                      <button
                        type="button"
                        class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_uang_muka/update'"
                        @click="
                          bg_uang_muka_detail = data;
                          modalBgUangMuka('edit');
                        "
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <!-- &emsp;&emsp; -->
                      <button
                        type="button"
                        class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_uang_muka/delete'"
                        @click="delete_bg_uang_muka(data.id)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  bg_uang_muka.rows.length > 0 && bg_uang_muka_sq != ''
                "
              >
                <tr v-for="data in bg_uang_muka_sq_res" :key="data.id">
                  <td class="text-left">
                    {{ data.nomor }}
                  </td>
                  <td>{{ data.tgl_terbit }}</td>
                  <td>{{ data.tgl_berakhir }}</td>
                  <td style="white-space: nowrap">
                    {{ data.nilai | currency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#x5143;{{ data.cny | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#xa5;{{ data.jpy | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &euro;{{ data.eur | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    ${{ data.usd | globalCurrency }}
                  </td>
                  <td class="text-left" style="white-space: nowrap">
                    <span
                      class="statusbg mr-5"
                      v-if="data.status_text == '-'"
                    >
                      -
                    </span>
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Aktif'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Penyesuaian Nilai'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Akan Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg not_approve mr-1"
                      v-if="data.status_text == 'Berakhir'"
                      >{{ data.status_text }}</span
                    >

                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Selesai'"
                      >-</span
                    >
                    
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lengkapi file keabsahan"
                    >
                      <img
                        v-if="data.file_keabsahan.length == 0"
                        src="/assets/img/Vector.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                  </td>

                  <td>
                    <div class="btn-action-block">
                      <button
                        type="button"
                        @click="modalBGUangMukaDetail(data.id)"
                        class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_uang_muka/detail'"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <!-- &nbsp;&nbsp; -->
                      <button
                        type="button"
                        class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_uang_muka/update'"
                        @click="
                          bg_uang_muka_detail = data;
                          modalBgUangMuka('edit');
                        "
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <!-- &emsp;&emsp; -->
                      <button
                        type="button"
                        class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_uang_muka/delete'"
                        @click="delete_bg_uang_muka(data.id)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">Tidak ada data.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr v-can="'kontrak/detail/bg_uang_muka/read'" class="my-2" />
          <div class="row align-items-center justify-content-between">
            <div class="col-12 col-sm-9 col-lg-9 mb-1" v-can="'kontrak/detail/bg_pelaksanaan/read'">
              <h4 class="card-title mb-0">
                Jaminan Pelaksanaan
                <div
                  class="ml-1 badge badge-warning badge-md"
                  v-if="bg_pelaksanaan.rows.length > 0"
                  @click="bgPelaksanaanForce"
                  v-can="'kontrak/detail/bg_pelaksanaan/force_update'"
                  style="cursor: pointer"
                >
                  {{ bg_pelaksanaan.status_table }} &nbsp;
                  <i class="fa fa-angle-down"></i>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-block-mobile"
                  v-can="'kontrak/detail/bg_pelaksanaan/create'"
                  @click="modalBgPelaksanaan('add')"
                >
                  Tambah BG Original
                </button>
                <button
                  v-if="bg_pelaksanaan.rows.length > 0"
                  type="button"
                  v-can="'kontrak/detail/bg_pelaksanaan/create'"
                  @click="modalBgPelaksanaan('add_perubahan')"
                  class="btn btn-primary btn-sm btn-block-mobile"
                >
                  Tambah BG Perubahan
                </button>
              </h4>
            </div>

            <div class="col-12 col-sm-3 mb-1">
              <div class="input-group form-search-rounded">
                <input
                  class="form-control py-2 border-right-0 border"
                  type="search"
                  v-model="bg_pelaksanaan_sq"
                  @input="bgPelaksanaanSearch"
                  placeholder="Cari.."
                />
                <span class="input-group-append">
                  <div class="input-group-text bg-transparent">
                    <a href="#" class="text-blue"
                      ><i class="fa fa-search"></i
                    ></a>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div v-can="'kontrak/detail/bg_pelaksanaan/read'" class="table-responsive no-sort tableFixHead">
            <table class="table tabel-status-document w-100">
              <thead>
                <tr>
                  <th>Nomor Seri</th>
                  <th>Tanggal Berlaku</th>
                  <th>Tanggal Berakhir</th>
                  <th>IDR</th>
                  <th>CNY</th>
                  <th>JPY</th>
                  <th>EUR</th>
                  <th>USD</th>
                  <th>Status</th>
                  <th class="w10">Aksi</th>
                </tr>
              </thead>
              <tbody
                v-if="
                  bg_pelaksanaan.rows.length > 0 && bg_pelaksanaan_sq == ''
                "
              >
                <tr v-for="data in bg_pelaksanaan.rows" :key="data.id">
                  <td class="text-left">
                    {{ data.nomor }}
                  </td>
                  <td>{{ data.tgl_terbit }}</td>
                  <td>{{ data.tgl_berakhir }}</td>
                  <td style="white-space: nowrap">
                    {{ data.nilai | currency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#x5143;{{ data.cny | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#xa5;{{ data.jpy | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &euro;{{ data.eur | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    ${{ data.usd | globalCurrency }}
                  </td>
                  <td class="text-left" style="white-space: nowrap">
                    <span
                      class="statusbg mr-5"
                      v-if="data.status_text == '-'"
                    >
                      -
                    </span>
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Aktif'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Penyesuaian Nilai'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Akan Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg not_approve mr-1"
                      v-if="data.status_text == 'Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Selesai'"
                      >-</span
                    >
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lengkapi file keabsahan"
                    >
                      <img
                        v-if="data.file_keabsahan.length == 0"
                        src="/assets/img/Vector.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                  </td>

                  <td>
                    <div class="btn-action-block">
                      <button
                        type="button"
                        @click="modalBGPelaksanaanDetail(data.id)"
                        v-can="'kontrak/detail/bg_pelaksanaan/detail'"
                        class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pelaksanaan/update'"
                        @click="
                          bg_pelaksanaan_detail = data;
                          modalBgPelaksanaan('edit');
                        "
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pelaksanaan/delete'"
                        @click="delete_bg_pelaksanaan(data.id)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  bg_pelaksanaan.rows.length > 0 && bg_pelaksanaan_sq != ''
                "
              >
                <tr v-for="data in bg_pelaksanaan_sq_res" :key="data.id">
                  <td class="text-left">
                    {{ data.nomor }}
                  </td>
                  <td>{{ data.tgl_terbit }}</td>
                  <td>{{ data.tgl_berakhir }}</td>
                  <td style="white-space: nowrap">
                    {{ data.nilai | currency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#x5143;{{ data.cny | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#xa5;{{ data.jpy | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &euro;{{ data.eur | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    ${{ data.usd | globalCurrency }}
                  </td>
                  <td class="text-left" style="white-space: nowrap">
                    <span
                      class="statusbg mr-5"
                      v-if="data.status_text == '-'"
                    >
                      -
                    </span>
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Aktif'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Penyesuaian Nilai'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Akan Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg not_approve mr-1"
                      v-if="data.status_text == 'Berakhir'"
                      >{{ data.status_text }}</span
                    >

                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Selesai'"
                      >-</span
                    >
                    
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lengkapi file keabsahan"
                    >
                      <img
                        v-if="data.file_keabsahan.length == 0"
                        src="/assets/img/Vector.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                  </td>

                  <td>
                    <div class="btn-action-block">
                      <button
                        type="button"
                        @click="modalBGPelaksanaanDetail(data.id)"
                        v-can="'kontrak/detail/bg_pelaksanaan/detail'"
                        class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon-small ml-1 mr-1 btn-warning-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pelaksanaan/update'"
                        @click="
                          bg_pelaksanaan_detail = data;
                          modalBgPelaksanaan('edit');
                        "
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pelaksanaan/delete'"
                        @click="delete_bg_pelaksanaan(data.id)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">Tidak ada data.</td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr v-can="'kontrak/detail/bg_pelaksanaan/read'" class="my-2" />
          <div class="row align-items-center justify-content-between">
            <div class="col-12 col-sm-9 col-lg-9 mb-1" v-can="'kontrak/detail/bg_pemeliharaan/read'">
              <h4 class="card-title mb-0">
                Jaminan Pemeliharaan
                <div
                  class="ml-1 badge badge-warning badge-md"
                  v-if="bg_pemeliharaan.rows.length > 0"
                  @click="bgPemeliharaanForce"
                  style="cursor: pointer"
                  v-can="'kontrak/detail/bg_pemeliharaan/force_update'"
                >
                  {{ bg_pemeliharaan.status_table }} &nbsp;
                  <i class="fa fa-angle-down"></i>
                </div>
                <button
                  type="button"
                  class="btn btn-primary btn-sm btn-block-mobile"
                  @click="modalBgPemeliharaan('add')"
                  v-can="'kontrak/detail/bg_pemeliharaan/create'"
                >
                  Tambah BG Original
                </button>
                <button
                  v-if="bg_pemeliharaan.rows.length > 0"
                  type="button"
                  @click="modalBgPemeliharaan('add_perubahan')"
                  v-can="'kontrak/detail/bg_pemeliharaan/create'"
                  class="btn btn-primary btn-sm btn-block-mobile"
                >
                  Tambah BG Perubahan
                </button>
              </h4>
            </div>

            <div class="col-12 col-sm-3 mb-1">
              <div class="input-group form-search-rounded">
                <input
                  class="form-control py-2 border-right-0 border"
                  type="search"
                  v-model="bg_pemeliharaan_sq"
                  @input="bgPemeliharaanSearch"
                  placeholder="Cari.."
                />
                <span class="input-group-append">
                  <div class="input-group-text bg-transparent">
                    <a href="#" class="text-blue"
                      ><i class="fa fa-search"></i
                    ></a>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div v-can="'kontrak/detail/bg_pemeliharaan/read'" class="table-responsive no-sort tableFixHead">
            <table class="table tabel-status-document w-100">
              <thead>
                <tr>
                  <th>Nomor Seri</th>
                  <th>Tanggal Berlaku</th>
                  <th>Tanggal Berakhir</th>
                  <th>IDR</th>
                  <th>CNY</th>
                  <th>JPY</th>
                  <th>EUR</th>
                  <th>USD</th>
                  <th>Status</th>
                  <th class="w10">Aksi</th>
                </tr>
              </thead>
              <tbody
                v-if="
                  bg_pemeliharaan.rows.length > 0 &&
                  bg_pemeliharaan_sq == ''
                "
              >
                <tr v-for="data in bg_pemeliharaan.rows" :key="data.id">
                  <td class="text-left">
                    {{ data.nomor }}
                  </td>
                  <td>{{ data.tgl_terbit }}</td>
                  <td>{{ data.tgl_berakhir }}</td>
                  <td style="white-space: nowrap">
                    {{ data.nilai | currency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#x5143;{{ data.cny | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#xa5;{{ data.jpy | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &euro;{{ data.eur | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    ${{ data.usd | globalCurrency }}
                  </td>
                  <td class="text-left" style="white-space: nowrap">
                    <span
                      class="statusbg mr-5"
                      v-if="data.status_text == '-'"
                    >
                      -
                    </span>
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Aktif'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Penyesuaian Nilai'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Akan Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg not_approve mr-1"
                      v-if="data.status_text == 'Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lengkapi file keabsahan"
                    >
                      <img
                        v-if="data.file_keabsahan.length == 0"
                        src="/assets/img/Vector.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                  </td>

                  <td>
                    <div class="btn-action-block">
                      <button
                        type="button"
                        @click="modalBGPemeliharaanDetail(data.id)"
                        v-can="'kontrak/detail/bg_pemeliharaan/detail'"
                        class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <button
                        type="button"
                        class="btn ml-1 mr-1 btn-icon-small btn-warning-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pemeliharaan/update'"
                        @click="
                          bg_pemeliharaan_detail = data;
                          modalBgPemeliharaan('edit');
                        "
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pemeliharaan/delete'"
                        @click="delete_bg_pemeliharaan(data.id)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody
                v-else-if="
                  bg_pemeliharaan.rows.length > 0 &&
                  bg_pemeliharaan_sq != ''
                "
              >
                <tr v-for="data in bg_pemeliharaan_sq_res" :key="data.id">
                  <td class="text-left">
                    {{ data.nomor }}
                  </td>
                  <td>{{ data.tgl_terbit }}</td>
                  <td>{{ data.tgl_berakhir }}</td>
                  <td style="white-space: nowrap">
                    {{ data.nilai | currency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#x5143;{{ data.cny | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &#xa5;{{ data.jpy | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    &euro;{{ data.eur | globalCurrency }}
                  </td>
                  <td style="white-space: nowrap">
                    ${{ data.usd | globalCurrency }}
                  </td>
                  <td class="text-left" style="white-space: nowrap">
                    <span
                      class="statusbg mr-5"
                      v-if="data.status_text == '-'"
                    >
                      -
                    </span>
                    <span
                      class="statusbg approve mr-1"
                      v-if="data.status_text == 'Aktif'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Penyesuaian Nilai'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg waiting mr-1"
                      v-if="data.status_text == 'Akan Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      class="statusbg not_approve mr-1"
                      v-if="data.status_text == 'Berakhir'"
                      >{{ data.status_text }}</span
                    >
                    <span
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lengkapi file keabsahan"
                    >
                      <img
                        v-if="data.file_keabsahan.length == 0"
                        src="/assets/img/Vector.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </span>
                  </td>

                  <td>
                    <div class="btn-action-block">
                      <button
                        type="button"
                        @click="modalBGPemeliharaanDetail(data.id)"
                        v-can="'kontrak/detail/bg_pemeliharaan/detail'"
                        class="btn btn-icon-small btn-success-trans waves-effect waves-light"
                      >
                        <i class="fa fa-eye"></i>
                      </button>
                      <button
                        type="button"
                        class="btn ml-1 mr-1 btn-icon-small btn-warning-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pemeliharaan/update'"
                        @click="
                          bg_pemeliharaan_detail = data;
                          modalBgPemeliharaan('edit');
                        "
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                        v-can="'kontrak/detail/bg_pemeliharaan/delete'"
                        @click="delete_bg_pemeliharaan(data.id)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="10" class="text-center">Tidak ada data.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Uang_Muka"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="uangMukaTr == 'add'">
              Tambah BG Uang Muka
            </h5>
            <h5 class="modal-title" v-if="uangMukaTr == 'edit'">
              Edit BG Uang Muka
            </h5>
            <h5 class="modal-title" v-if="uangMukaTr == 'add_perubahan'">
              Tambah BG Perubahan Uang Muka
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgUangMukaForm">
              <div class="row">
                <div
                  class="col-md-4"
                  v-if="
                    uangMukaTr !== 'add' && bg_uang_muka_form.is_origin == 0
                  "
                >
                  <div class="form-group">
                    <label for="">BG Perubahan</label>
                    <ValidationProvider
                      name="BG Perubahan"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <select
                        class="form-control"
                        v-model="bg_uang_muka_form.id_perubahan"
                      >
                        <option value="">- Pilih BG Uang Muka -</option>
                        <option
                          v-for="(lr, i) in bg_uang_muka_list.rows"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.nomor }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="No"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">No</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Seri"
                        v-model="bg_uang_muka_form.nomor"
                        :class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                      />
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Bank Penerbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">Bank Penerbit</label>
                      <select
                        class="form-control"
                        v-model="bg_uang_muka_form.id_bank"
                        data-show-subtext="true"
                        data-live-search="true"
                      >
                        <option value="">- Pilih Bank -</option>
                        <option
                          v-for="(lr, i) in bank_list"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.name }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Terbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Terbit</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_uang_muka_form.tanggal_terbit"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal terbit (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berlaku"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berlaku</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_uang_muka_form.tanggal_berlaku"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berlaku (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berakhir"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berakhir</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_uang_muka_form.tanggal_berakhir"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berakhir (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">IDR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.nilai"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">Rp</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">CNY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.cny"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#x5143;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">JPY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.jpy"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#xa5;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">EUR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.eur"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&euro;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">USD</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_uang_muka_form.usd"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">$</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File BG</label>
                    <vue-dropzone
                      ref="bgUangMukaDropzoneBG"
                      id="wjw"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgUangMukaBGFileHandler"
                      @vdropzone-removed-file="bgUangMukaBGDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_bg[0])"
                      @click="bgUangMukaBGRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                    <ValidationProvider
                      name="File BG"
                      :rules="uangMukaTr == 'edit' ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <input
                        type="hidden"
                        v-model="bg_uang_muka_form.file_bg"
                      />
                      <p style="color: #ea5455">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File Keabsahan</label>
                    <vue-dropzone
                      ref="bgUangMukaDropzoneAB"
                      id="wjw2"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgUangMukaABFileHandler"
                      @vdropzone-removed-file="bgUangMukaABDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_keabsahan[0])"
                      @click="bgUangMukaABRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="uangMukaTr == 'add'"
              @click="bgUangMukaSave()"
              :disabled="bg_uang_muka_ldstate"
            >
              <i v-if="bg_uang_muka_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bg_uang_muka_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="uangMukaTr == 'add_perubahan'"
              @click="bgUangMukaSave()"
              :disabled="bg_uang_muka_ldstate"
            >
              <i v-if="bg_uang_muka_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bg_uang_muka_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="uangMukaTr == 'edit'"
              @click="bgUangMukaUpdate()"
              :disabled="bg_uang_muka_ldstate"
            >
              <i v-if="bg_uang_muka_ldstate" class="fa fa-spinner fa-spin"></i>
              <span v-if="!bg_uang_muka_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pelaksanaan"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="pelaksanaanTr == 'add'">
              Tambah BG Pelaksanaan
            </h5>
            <h5 class="modal-title" v-if="pelaksanaanTr == 'edit'">
              Edit BG Pelaksanaan
            </h5>
            <h5 class="modal-title" v-if="pelaksanaanTr == 'add_perubahan'">
              Tambah BG Perubahan Pelaksanaan
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPelaksanaanForm">
              <div class="row">
                <div
                  class="col-md-4"
                  v-if="
                    pelaksanaanTr != 'add' && bg_pelaksanaan_form.is_origin == 0
                  "
                >
                  <div class="form-group">
                    <label for="">BG Perubahan</label>
                    <ValidationProvider
                      name="BG Perubahan"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <select
                        class="form-control"
                        v-model="bg_pelaksanaan_form.id_perubahan"
                      >
                        <option value="">- Pilih BG Pelaksanaan -</option>
                        <option
                          v-for="(lr, i) in bg_pelaksanaan_list.rows"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.nomor }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="No"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">No</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Seri"
                        v-model="bg_pelaksanaan_form.nomor"
                        :class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                      />
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Bank Penerbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">Bank Penerbit</label>
                      <select
                        class="form-control"
                        v-model="bg_pelaksanaan_form.id_bank"
                        data-show-subtext="true"
                        data-live-search="true"
                      >
                        <option value="">- Pilih Bank -</option>
                        <option
                          v-for="(lr, i) in bank_list"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.name }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Terbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Terbit</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pelaksanaan_form.tanggal_terbit"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal terbit (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berlaku"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berlaku</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pelaksanaan_form.tanggal_berlaku"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berlaku (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berakhir"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berakhir</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pelaksanaan_form.tanggal_berakhir"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berakhir (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">IDR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.nilai"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">Rp</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">CNY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.cny"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#x5143;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">JPY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.jpy"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#xa5;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">EUR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.eur"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&euro;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">USD</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pelaksanaan_form.usd"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">$</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File BG</label>
                    <vue-dropzone
                      ref="bgPelaksanaanDropzoneBG"
                      id="aww1"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPelaksanaanBGFileHandler"
                      @vdropzone-removed-file="bgPelaksanaanBGDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_pelaksanaan_form.file_bg[0])"
                      @click="bgPelaksanaanBGRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                    <ValidationProvider
                      name="File BG"
                      :rules="pelaksanaanTr == 'edit' ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <input
                        type="hidden"
                        v-model="bg_pelaksanaan_form.file_bg"
                      />
                      <p style="color: #ea5455">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File Keabsahan</label>
                    <vue-dropzone
                      ref="bgPelaksanaanDropzoneAB"
                      id="aww2"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPelaksanaanABFileHandler"
                      @vdropzone-removed-file="bgPelaksanaanABDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_keabsahan[0])"
                      @click="bgPelaksanaanABRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pelaksanaanTr == 'add'"
              @click="bgPelaksanaanSave()"
              :disabled="bg_pelaksanaan_ldstate"
            >
              <i
                v-if="bg_pelaksanaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pelaksanaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pelaksanaanTr == 'add_perubahan'"
              @click="bgPelaksanaanSave()"
              :disabled="bg_pelaksanaan_ldstate"
            >
              <i
                v-if="bg_pelaksanaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pelaksanaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pelaksanaanTr == 'edit'"
              @click="bgPelaksanaanUpdate()"
              :disabled="bg_pelaksanaan_ldstate"
            >
              <i
                v-if="bg_pelaksanaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pelaksanaan_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pemeliharaan"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" v-if="pemeliharaanTr == 'add'">
              Tambah BG Pemeliharaan
            </h5>
            <h5 class="modal-title" v-if="pemeliharaanTr == 'edit'">
              Edit BG Pemeliharaan
            </h5>
            <h5 class="modal-title" v-if="pemeliharaanTr == 'add_perubahan'">
              Tambah BG Perubahan Pemeliharaan
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPemeliharaanForm">
              <div class="row">
                <div
                  class="col-md-4"
                  v-if="
                    pemeliharaanTr != 'add' &&
                    bg_pemeliharaan_form.is_origin == 0
                  "
                >
                  <div class="form-group">
                    <label for="">BG Perubahan</label>
                    <ValidationProvider
                      name="BG Perubahan"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <select
                        class="form-control"
                        v-model="bg_pemeliharaan_form.id_perubahan"
                      >
                        <option value="">- Pilih BG Pelaksanaan -</option>
                        <option
                          v-for="(lr, i) in bg_pemeliharaan_list.rows"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.nomor }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="No"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">No</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nomor Seri"
                        v-model="bg_pemeliharaan_form.nomor"
                        :class="
                          Boolean(errors[0])
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                      />
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Bank Penerbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">Bank Penerbit</label>
                      <select
                        class="form-control"
                        v-model="bg_pemeliharaan_form.id_bank"
                        data-show-subtext="true"
                        data-live-search="true"
                      >
                        <option value="">- Pilih Bank -</option>
                        <option
                          v-for="(lr, i) in bank_list"
                          :key="i"
                          :value="lr.id"
                        >
                          {{ lr.name }}
                        </option>
                      </select>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Terbit"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Terbit</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pemeliharaan_form.tanggal_terbit"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal terbit (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berlaku"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berlaku</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pemeliharaan_form.tanggal_berlaku"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berlaku (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Tanggal Berakhir"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="">Tanggal Berakhir</label>
                      <div class="position-relative has-icon-right">
                        <date-picker
                          v-model="bg_pemeliharaan_form.tanggal_berakhir"
                          format="DD/MM/YYYY"
                          type="date"
                          :input-class="
                            Boolean(errors[0])
                              ? 'form-control is-invalid no-x'
                              : 'form-control'
                          "
                          value-type="YYYY-MM-DD"
                          placeholder="Masukan tanggal berakhir (dd/mm/yyyy)"
                          style="width: 100%"
                        >
                        </date-picker>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">IDR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.nilai"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">Rp</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">CNY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.cny"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#x5143;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">JPY</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.jpy"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&#xa5;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">EUR</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.eur"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">&euro;</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <ValidationProvider
                      name="Nilai"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="filter_by">USD</label>
                      <div class="position-relative has-icon-left">
                        <money
                          class="form-control"
                          v-model="bg_pemeliharaan_form.usd"
                          v-bind="money"
                        ></money>
                        <div class="form-control-position">$</div>
                      </div>
                      <small>{{ errors[0] }} </small>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File BG</label>
                    <vue-dropzone
                      ref="bgPemeliharaanDropzoneBG"
                      id="aww1"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPemeliharaanBGFileHandler"
                      @vdropzone-removed-file="bgPemeliharaanBGDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_pemeliharaan_form.file_bg[0])"
                      @click="bgPemeliharaanBGRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                    <ValidationProvider
                      name="File BG"
                      :rules="pemeliharaanTr == 'edit' ? '' : 'required'"
                      v-slot="{ errors }"
                    >
                      <input
                        type="hidden"
                        v-model="bg_pemeliharaan_form.file_bg"
                      />
                      <p style="color: #ea5455">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="filter_by">File Keabsahan</label>
                    <vue-dropzone
                      ref="bgPemeliharaanDropzoneAB"
                      id="aww2"
                      :duplicateCheck="true"
                      @vdropzone-files-added="bgPemeliharaanABFileHandler"
                      @vdropzone-removed-file="bgPemeliharaanABDeleteFile"
                      :options="kontraktual_dropzoneOptions"
                    >
                    </vue-dropzone>
                    <button
                      v-if="Boolean(bg_uang_muka_form.file_keabsahan[0])"
                      @click="bgPemeliharaanABRemoveFile"
                      class="btn btn-danger"
                      style="margin-top: 10px"
                    >
                      Reset File
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pemeliharaanTr == 'add'"
              @click="bgPemeliharaanSave()"
              :disabled="bg_pemeliharaan_ldstate"
            >
              <i
                v-if="bg_pemeliharaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pemeliharaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pemeliharaanTr == 'add_perubahan'"
              @click="bgPemeliharaanSave()"
              :disabled="bg_pemeliharaan_ldstate"
            >
              <i
                v-if="bg_pemeliharaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pemeliharaan_ldstate">Simpan</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-if="pemeliharaanTr == 'edit'"
              @click="bgPemeliharaanUpdate()"
              :disabled="bg_pemeliharaan_ldstate"
            >
              <i
                v-if="bg_pemeliharaan_ldstate"
                class="fa fa-spinner fa-spin"
              ></i>
              <span v-if="!bg_pemeliharaan_ldstate">Edit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_BG_Uang_Muka_Detail"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Detail BG Uang Muka</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-4" v-if="bg_uang_muka_detail.is_origin == 0">
                <div class="form-group">
                  <label for="filter_by">Perubahan BG Uang Muka</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="-"
                    v-model="bg_uang_muka_detail.id_perubahan_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">No</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Nomor Seri"
                    v-model="bg_uang_muka_detail.nomor"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">Bank Penerbit</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Bank Penerbit"
                    v-model="bg_uang_muka_detail.bank_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Terbit</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_uang_muka_detail.tgl_terbit"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berlaku</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_uang_muka_detail.tgl_berlaku"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berakhir</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_uang_muka_detail.tgl_berakhir"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">IDR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.nilai"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">Rp</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">CNY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.cny"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#x5143;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">JPY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.jpy"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#xa5;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">EUR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.eur"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">USD</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_uang_muka_detail.usd"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File BG</label>
                  <vue-dropzone
                    ref="bgUangMukaDropzoneBGDetail"
                    id="akj1"
                    @vdropzone-removed-file="bgUangMukaBGDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File Keabsahan</label>
                  <vue-dropzone
                    ref="bgUangMukaDropzoneABDetail"
                    id="akj2"
                    @vdropzone-removed-file="bgUangMukaABDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_BG_Pelaksanaan_Detail"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Detail BG Pelaksanaan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-4" v-if="bg_pelaksanaan_detail.is_origin == 0">
                <div class="form-group">
                  <label for="filter_by">Perubahan BG Pelaksanaan</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="-"
                    v-model="bg_pelaksanaan_detail.id_perubahan_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">No</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Nomor Seri"
                    v-model="bg_pelaksanaan_detail.nomor"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">Bank Penerbit</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Bank Penerbit"
                    v-model="bg_pelaksanaan_detail.bank_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Terbit</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pelaksanaan_detail.tgl_terbit"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berlaku</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pelaksanaan_detail.tgl_berlaku"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berakhir</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pelaksanaan_detail.tgl_berakhir"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">IDR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.nilai"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">Rp</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">CNY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.cny"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#x5143;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">JPY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.jpy"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#xa5;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">EUR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.eur"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">USD</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pelaksanaan_detail.usd"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File BG</label>
                  <vue-dropzone
                    ref="bgPelaksanaanDropzoneBGDetail"
                    id="pel1"
                    @vdropzone-removed-file="bgPelaksanaanBGDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File Keabsahan</label>
                  <vue-dropzone
                    ref="bgPelaksanaanDropzoneABDetail"
                    id="pel2"
                    @vdropzone-removed-file="bgPelaksanaanABDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_BG_Pemeliharaan_Detail"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="max-width: 90%"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Detail BG Pemeliharaan</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div
                class="col-md-4"
                v-if="bg_pemeliharaan_detail.is_origin == 0"
              >
                <div class="form-group">
                  <label for="filter_by">Perubahan BG Pemeliharaan</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="-"
                    v-model="bg_pemeliharaan_detail.id_perubahan_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">No</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Nomor Seri"
                    v-model="bg_pemeliharaan_detail.nomor"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">Bank Penerbit</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    placeholder="Bank Penerbit"
                    v-model="bg_pemeliharaan_detail.bank_show"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Terbit</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pemeliharaan_detail.tgl_terbit"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berlaku</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pemeliharaan_detail.tgl_berlaku"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="">Tanggal Berakhir</label>
                  <div class="position-relative has-icon-right">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      placeholder="Bank Penerbit"
                      v-model="bg_pemeliharaan_detail.tgl_berakhir"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">IDR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.nilai"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">Rp</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">CNY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.cny"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#x5143;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">JPY</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.jpy"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&#xa5;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">EUR</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.eur"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">USD</label>
                  <div class="position-relative has-icon-left">
                    <money
                      disabled
                      class="form-control"
                      v-model="bg_pemeliharaan_detail.usd"
                      v-bind="money"
                    ></money>
                    <div class="form-control-position">&euro;</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File BG</label>
                  <vue-dropzone
                    ref="bgPemeliharaanDropzoneBGDetail"
                    id="akj1"
                    @vdropzone-removed-file="bgPemeliharaanBGDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="filter_by">File Keabsahan</label>
                  <vue-dropzone
                    ref="bgPemeliharaanDropzoneABDetail"
                    id="akj2"
                    @vdropzone-removed-file="bgPemeliharaanABDetail"
                    :options="detail_dz"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Uang_Muka_Force"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Ubah Warning Dokumen</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgUangMukaForceValidation">
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Pilih BG</label>

                  <select class="form-control" v-model="bg_uang_muka_force_id">
                    <option value="">- Pilih BG -</option>
                    <option
                      v-for="(lr, i) in bg_uang_muka_list_all"
                      :key="i"
                      :value="lr.id"
                    >
                      {{ lr.nomor }}
                    </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Status</label>

                  <select class="form-control" v-model="bg_uang_muka_force">
                    <option value="">- Pilih Status -</option>
                    <option value="1">Aktif</option>
                    <option value="2">Akan Berakhir</option>
                    <option value="3">Berakhir</option>
                    <option value="5">Selesai</option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bgUangMukaSubmit"
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pelaksanaan_Force"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Ubah Warning Dokumen</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPelaksanaanForceValidation">
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Pilih BG</label>

                  <select
                    class="form-control"
                    v-model="bg_pelaksanaan_force_id"
                  >
                    <option value="">- Pilih BG -</option>
                    <option
                      v-for="(lr, i) in bg_pelaksanaan_list_all"
                      :key="i"
                      :value="lr.id"
                    >
                      {{ lr.nomor }}
                    </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Status</label>

                  <select class="form-control" v-model="bg_pelaksanaan_force">
                    <option value="">- Pilih Status -</option>
                    <option value="1">Aktif</option>
                    <option value="2">Akan Berakhir</option>
                    <option value="3">Berakhir</option>
                    <option value="4">Penyesuaian Nilai</option>
                    <option value="5">Selesai </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bgPelaksanaanSubmit"
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_Bg_Pemeliharaan_Force"
      aye="mdaman"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title">Ubah Warning Dokumen</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bgPemeliharaanForceValidation">
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Pilih BG</label>

                  <select
                    class="form-control"
                    v-model="bg_pemeliharaan_force_id"
                  >
                    <option value="">- Pilih BG -</option>
                    <option
                      v-for="(lr, i) in bg_pemeliharaan_list_all"
                      :key="i"
                      :value="lr.id"
                    >
                      {{ lr.nomor }}
                    </option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="Status"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="">Status</label>

                  <select class="form-control" v-model="bg_pemeliharaan_force">
                    <option value="">- Pilih Status -</option>
                    <option value="1">Aktif</option>
                    <option value="2">Akan Berakhir</option>
                    <option value="3">Berakhir</option>
                    <!-- <option value="4">Penyesuaian Nilai</option> -->
                    <option value="5">Selesai</option>
                  </select>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal"
            >
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="bgPemeliharaanSubmit"
            >
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { Money } from "v-money";
import ld from "lodash";

export default {
  components: {
    Money
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },

      bg_uang_muka_sq: "",
      bg_uang_muka_force: "",
      bg_uang_muka_sq_res: [],
      uangMukaTr: "add",
      bg_uang_muka_id: null,
      bg_uang_muka_ldstate: false,
      bg_uang_muka_form: {
        id_kontrak_mtu: "",
        id_perubahan: "",
        nomor: "",
        id_bank: "",
        tanggal_terbit: "",
        tanggal_berlaku: "",
        tanggal_berakhir: "",
        nilai: "",
        file_bg: [],
        file_keabsahan: [],
        deletedFileBg: [],
        deletedFileAb: [],
        cny: "",
        jpy: "",
        eur: "",
        usd: "",
      },

      bg_pelaksanaan_sq: "",
      bg_pelaksanaan_force: "",
      bg_pelaksanaan_sq_res: [],
      pelaksanaanTr: "add",
      bg_pelaksanaan_id: null,
      bg_pelaksanaan_ldstate: false,
      bg_pelaksanaan_form: {
        id_kontrak_mtu: "",
        id_perubahan: "",
        nomor: "",
        id_bank: "",
        tanggal_terbit: "",
        tanggal_berlaku: "",
        tanggal_berakhir: "",
        nilai: "",
        file_bg: [],
        file_keabsahan: [],
        deletedFileBg: [],
        deletedFileAb: [],
        cny: "",
        jpy: "",
        eur: "",
        usd: "",
      },

      bg_pemeliharaan_sq: "",
      bg_pemeliharaan_force: "",
      bg_pemeliharaan_sq_res: [],
      pemeliharaanTr: "add",
      bg_pemeliharaan_id: null,
      bg_pemeliharaan_ldstate: false,
      bg_pemeliharaan_form: {
        id_kontrak_mtu: "",
        id_perubahan: "",
        nomor: "",
        id_bank: "",
        tanggal_terbit: "",
        tanggal_berlaku: "",
        tanggal_berakhir: "",
        nilai: "",
        file_bg: [],
        file_keabsahan: [],
        deletedFileBg: [],
        deletedFileAb: [],
        cny: "",
        jpy: "",
        eur: "",
        usd: "",
      },

      bg_uang_muka: {
        rows: [],
      },
      bg_uang_muka_detail: {
        banks: {
          name: "",
        },
      },
      bg_uang_muka_list: [],
      bg_uang_muka_list_all: [],
      bg_uang_muka_force_id: null,

      bg_pelaksanaan: {
        rows: [],
      },
      bg_pelaksanaan_detail: {
        banks: {
          name: "",
        },
      },
      bg_pelaksanaan_list: [],
      bg_pelaksanaan_list_all: [],
      bg_pelaksanaan_force_id: null,

      bg_pemeliharaan: {
        rows: [],
      },
      bg_pemeliharaan_detail: {
        banks: {
          name: "",
        },
      },
      bg_pemeliharaan_list: [],
      bg_pemeliharaan_list_all: [],
      bg_pemeliharaan_force_id: null,

      bank_list: [],

      
      kontraktual_dropzoneOptions: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".doc,.docx,application/msword,.pdf",
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 10000,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },

      
      detail_dz: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".doc,.docx,application/msword,.pdf",
        autoProcessQueue: false,
        addedFile: false,
        addRemoveLinks: true,
        dictRemoveFile: "[Lihat Detail]",
        dictDefaultMessage: "Tidak Ada Dokumen",
        uploadMultiple: true,
        headers: { Authorization: "Bearer " + this.$store.state.token },
        paramName: "image",
        maxFilesize: 100,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },
    }
  },
  methods: {
    bgUangukaForce() {
      let _ = this;
      _.bg_uang_muka_force = "";
      _.bg_uang_muka_force_id = "";
      window.$("#Modal_Bg_Uang_Muka_Force").modal("show");
    },
    bgUangMukaSubmit() {
      let _ = this;
      var validation = _.$refs.bgUangMukaForceValidation;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        let data_post = {
          id: _.bg_uang_muka_force_id,
          id_kontrak_mtu: _.$route.params.id,
          status: _.bg_uang_muka_force,
        };

        await _.axios
          .post("/v2/kontrak-mtu/bg-uang-muka/update-status", data_post)
          .then((resp) => {
            console.log(resp);
            _.$toast.success("Force Update Berhasil Disimpan");
            _.bg_uang_muka_force = "";
            _.bg_uang_muka_force_id = "";
            window.$("#Modal_Bg_Uang_Muka_Force").modal("hide");
            _.getUangMuka();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
      });
    },
    getUangMukaList() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-uang-muka?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_uang_muka_list = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getUangMukaListAll() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-uang-muka?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_uang_muka_list_all = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getUangMuka() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-uang-muka?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_uang_muka = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    bgPelaksanaanForce() {
      let _ = this;
      _.bg_pelaksanaan_force = "";
      _.bg_pelaksanaan_force_id = "";
      window.$("#Modal_Bg_Pelaksanaan_Force").modal("show");
    },
    bgPelaksanaanSubmit() {
      let _ = this;
      var validation = _.$refs.bgPelaksanaanForceValidation;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        let data_post = {
          id: _.bg_pelaksanaan_force_id,
          id_kontrak_mtu: _.$route.params.id,
          status: _.bg_pelaksanaan_force,
        };

        await _.axios
          .post("/v2/kontrak-mtu/bg-pelaksanaan/update-status", data_post)
          .then((resp) => {
            console.log(resp);
            _.$toast.success("Force Update Berhasil Disimpan");
            _.bg_pelaksanaan_force = "";
            _.bg_pelaksanaan_force_id = "";
            window.$("#Modal_Bg_Pelaksanaan_Force").modal("hide");
            _.getPelaksanaan();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
      });
    },
    getPelaksanaanList() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-pelaksanaan?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pelaksanaan_list = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getPelaksanaanListAll() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-pelaksanaan?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pelaksanaan_list_all = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getPelaksanaan() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-pelaksanaan?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pelaksanaan = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    bgPemeliharaanForce() {
      let _ = this;
      _.bg_pemeliharaan_force = "";
      _.bg_pemeliharaan_force_id = "";
      window.$("#Modal_Bg_Pemeliharaan_Force").modal("show");
    },
    bgPemeliharaanSubmit() {
      let _ = this;
      var validation = _.$refs.bgPemeliharaanForceValidation;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        let data_post = {
          id: _.bg_pemeliharaan_force_id,
          id_kontrak_mtu: _.$route.params.id,
          status: _.bg_pemeliharaan_force,
        };

        await _.axios
          .post("/v2/kontrak-mtu/bg-pemeliharaan/update-status", data_post)
          .then((resp) => {
            console.log(resp);
            _.$toast.success("Force Update Berhasil Disimpan");
            _.bg_pemeliharaan_force = "";
            _.bg_pemeliharaan_force_id = "";
            window.$("#Modal_Bg_Pemeliharaan_Force").modal("hide");
            _.getPemeliharaan();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data Gagal Disimpan");
          });
      });
    },
    getPemeliharaan() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-pemeliharaan?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pemeliharaan = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getPemeliharaanList() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-pemeliharaan?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pemeliharaan_list = resp.data.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    getPemeliharaanListAll() {
      let _ = this;
      _.axios
        .get("v2/kontrak-mtu/bg-pemeliharaan?id_kontrak_mtu=" + _.$route.params.id)
        .then((resp) => {
          _.bg_pemeliharaan_list_all = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    bgUangMukaSearch: ld.debounce(function () {
      let _ = this;
      if (_.bg_uang_muka_sq != "") {
        var res = _.bg_uang_muka.rows;
        let newRes = ld.filter(res, function (o) {
          // console.log(o.nomor);
          return (
            o.nomor.toLowerCase().indexOf(_.bg_uang_muka_sq.toLowerCase()) >= 0
          );
        });
        _.bg_uang_muka_sq_res = newRes;
      }
    }, 100),

    bgPelaksanaanSearch: ld.debounce(function () {
      let _ = this;
      if (_.bg_pelaksanaan_sq != "") {
        var res = _.bg_pelaksanaan.rows;
        let newRes = ld.filter(res, function (o) {
          // console.log(o.nomor);
          return (
            o.nomor.toLowerCase().indexOf(_.bg_pelaksanaan_sq.toLowerCase()) >=
            0
          );
        });
        _.bg_pelaksanaan_sq_res = newRes;
      }
    }, 100),

    bgPemeliharaanSearch: ld.debounce(function () {
      let _ = this;
      if (_.bg_pemeliharaan_sq != "") {
        var res = _.bg_pemeliharaan.rows;
        let newRes = ld.filter(res, function (o) {
          // console.log(o.nomor);
          return (
            o.nomor.toLowerCase().indexOf(_.bg_pemeliharaan_sq.toLowerCase()) >=
            0
          );
        });
        _.bg_pemeliharaan_sq_res = newRes;
      }
    }, 100),

    
    modalBgUangMuka(type) {
      $(".dz-hidden-input").prop("disabled", false);
      let _ = this;
      _.uangMukaTr = type;
      _.bgUangMukaABRemoveFile();
      _.bgUangMukaBGRemoveFile();
      if (type == "edit") {
        _.bg_uang_muka_id = _.bg_uang_muka_detail.id;
        _.bg_uang_muka_form = {
          id_kontrak_mtu: _.bg_uang_muka_detail.id_kontrak_mtu,
          id_perubahan: _.bg_uang_muka_detail.id_perubahan,
          nomor: _.bg_uang_muka_detail.nomor,
          id_bank: _.bg_uang_muka_detail.id_bank,
          tanggal_terbit: _.bg_uang_muka_detail.tanggal_terbit,
          tanggal_berlaku: _.bg_uang_muka_detail.tanggal_berlaku,
          tanggal_berakhir: _.bg_uang_muka_detail.tanggal_berakhir,
          nilai: _.bg_uang_muka_detail.nilai,
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          cny: _.bg_uang_muka_detail.cny,
          jpy: _.bg_uang_muka_detail.jpy,
          eur: _.bg_uang_muka_detail.eur,
          usd: _.bg_uang_muka_detail.usd,
          is_origin: _.bg_uang_muka_detail.is_origin,
        };
        _.bg_uang_muka_detail.file_bg.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgUangMukaDropzoneBG.manuallyAddFile(file, url);
        });

        _.bg_uang_muka_detail.file_keabsahan.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgUangMukaDropzoneAB.manuallyAddFile(file, url);
        });
      } else if (type == "add") {
        _.bg_uang_muka_form = {
          id_kontrak_mtu: "",
          id_perubahan: "",
          nomor: "",
          id_bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 1,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      } else if (type == "add_perubahan") {
        _.bg_uang_muka_form = {
          id_kontrak_mtu: "",
          id_perubahan: "",
          nomor: "",
          id_bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 0,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      }

      window.$("#Modal_Bg_Uang_Muka").modal("show");
    },

    modalBgPelaksanaan(type) {
      $(".dz-hidden-input").prop("disabled", false);
      let _ = this;
      _.pelaksanaanTr = type;
      _.bgPelaksanaanABRemoveFile();
      _.bgPelaksanaanBGRemoveFile();
      if (type == "edit") {
        console.log(_.bg_pelaksanaan_detail);
        _.bg_pelaksanaan_id = _.bg_pelaksanaan_detail.id;
        _.bg_pelaksanaan_form = {
          id_kontrak_mtu: _.bg_pelaksanaan_detail.id_kontrak_mtu,
          id_perubahan: _.bg_pelaksanaan_detail.id_perubahan,
          nomor: _.bg_pelaksanaan_detail.nomor,
          id_bank: _.bg_pelaksanaan_detail.id_bank,
          tanggal_terbit: _.bg_pelaksanaan_detail.tanggal_terbit,
          tanggal_berlaku: _.bg_pelaksanaan_detail.tanggal_berlaku,
          tanggal_berakhir: _.bg_pelaksanaan_detail.tanggal_berakhir,
          nilai: _.bg_pelaksanaan_detail.nilai,
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          cny: _.bg_pelaksanaan_detail.cny,
          jpy: _.bg_pelaksanaan_detail.jpy,
          eur: _.bg_pelaksanaan_detail.eur,
          usd: _.bg_pelaksanaan_detail.usd,
          is_origin: _.bg_pelaksanaan_detail.is_origin,
        };
        _.bg_pelaksanaan_detail.file_bg.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPelaksanaanDropzoneBG.manuallyAddFile(file, url);
        });

        _.bg_pelaksanaan_detail.file_keabsahan.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPelaksanaanDropzoneAB.manuallyAddFile(file, url);
        });
      } else if (type == "add") {
        _.bg_pelaksanaan_form = {
          id_kontrak_mtu: "",
          id_perubahan: "",
          nomor: "",
          id_bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 1,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      } else if (type == "add_perubahan") {
        _.bg_pelaksanaan_form = {
          id_kontrak_mtu: "",
          id_perubahan: "",
          nomor: "",
          id_bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 0,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      }

      this.$forceUpdate();
      window.$("#Modal_Bg_Pelaksanaan").modal("show");
    },

    modalBgPemeliharaan(type) {
      $(".dz-hidden-input").prop("disabled", false);
      let _ = this;
      _.pemeliharaanTr = type;
      console.log(_.pemeliharaanTr);
      _.bgPemeliharaanABRemoveFile();
      _.bgPemeliharaanBGRemoveFile();
      if (type == "edit") {
        _.bg_pemeliharaan_id = _.bg_pemeliharaan_detail.id;
        _.bg_pemeliharaan_form = {
          id_kontrak_mtu: _.bg_pemeliharaan_detail.id_kontrak_mtu,
          id_perubahan: _.bg_pemeliharaan_detail.id_perubahan,
          nomor: _.bg_pemeliharaan_detail.nomor,
          id_bank: _.bg_pemeliharaan_detail.id_bank,
          tanggal_terbit: _.bg_pemeliharaan_detail.tanggal_terbit,
          tanggal_berlaku: _.bg_pemeliharaan_detail.tanggal_berlaku,
          tanggal_berakhir: _.bg_pemeliharaan_detail.tanggal_berakhir,
          nilai: _.bg_pemeliharaan_detail.nilai,
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          cny: _.bg_pemeliharaan_detail.cny,
          jpy: _.bg_pemeliharaan_detail.jpy,
          eur: _.bg_pemeliharaan_detail.eur,
          usd: _.bg_pemeliharaan_detail.usd,
          is_origin: _.bg_pemeliharaan_detail.is_origin,
        };
        _.bg_pemeliharaan_detail.file_bg.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPemeliharaanDropzoneBG.manuallyAddFile(file, url);
        });

        _.bg_pemeliharaan_detail.file_keabsahan.forEach(function (el) {
          let file = { size: 10240, name: el.name, type: "application/word" };
          let url = el.url;
          _.$refs.bgPemeliharaanDropzoneAB.manuallyAddFile(file, url);
        });
      } else if (type == "add") {
        _.bg_pemeliharaan_form = {
          id_kontrak_mtu: "",
          id_perubahan: "",
          nomor: "",
          id_bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 1,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      } else if (type == "add_perubahan") {
        _.bg_pemeliharaan_form = {
          id_kontrak_mtu: "",
          id_perubahan: "",
          nomor: "",
          id_bank: "",
          tanggal_terbit: "",
          tanggal_berlaku: "",
          tanggal_berakhir: "",
          nilai: "",
          file_bg: [],
          file_keabsahan: [],
          deletedFileBg: [],
          deletedFileAb: [],
          is_origin: 0,
          cny: "",
          jpy: "",
          eur: "",
          usd: "",
        };
      }

      window.$("#Modal_Bg_Pemeliharaan").modal("show");
    },

    bgUangMukaBGFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_uang_muka_form.file_bg.push(val);
        });
      }
    },
    bgUangMukaBGDeleteFile(file) {
      let _ = this;
      // alert("jall");
      if (file.lastModified) {
        _.bg_uang_muka_form.file_bg = ld.filter(
          _.bg_uang_muka_form.file_bg,
          function (o) {
            console.log(o.name);
            return o.name != file.name;
          }
        );
      } else {
        console.log("hallo 2");
        _.bg_uang_muka_form.deletedFileBg.push(file.name);
      }
    },
    bgUangMukaBGRemoveFile() {
      let _ = this;
      _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true);
      _.bg_uang_muka_form.file_bg = [];
    },

    bgUangMukaBGDetail(file) {
      let _ = this;
      let detail = ld.filter(_.bg_uang_muka_detail.file_bg, function (o) {
        return o.name == file.name;
      });
      _.pdfViewer(detail[0].url);
      // _.$refs.bgUangMukaDropzoneBGDetail.removeAllFiles(true)
      // _.$refs.bgUangMukaDropzoneABDetail.removeAllFiles(true)
      window.$("#Modal_BG_Uang_Muka_Detail").modal("hide");
    },

    bgUangMukaABFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_uang_muka_form.file_keabsahan.push(val);
        });
      }
    },
    bgUangMukaABDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.bg_uang_muka_form.file_bg = ld.filter(
          _.bg_uang_muka_form.file_keabsahan,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.bg_uang_muka_form.deletedFileAb.push(file.name);
      }
    },
    bgUangMukaABRemoveFile() {
      let _ = this;
      _.$refs.bgUangMukaDropzoneAB.removeAllFiles(true);
      _.bg_uang_muka_form.file_keabsahan = [];
    },

    bgUangMukaABDetail(file) {
      let _ = this;
      let detail = ld.filter(
        _.bg_uang_muka_detail.file_keabsahan,
        function (o) {
          return o.name == file.name;
        }
      );
      _.pdfViewer(detail[0].url);
      // _.$refs.bgUangMukaDropzoneABDetail.removeAllFiles(true)
      // _.$refs.bgUangMukaDropzoneBGDetail.removeAllFiles(true)
      window.$("#Modal_BG_Uang_Muka_Detail").modal("hide");
    },

    //Batas Pelaksanaan

    bgPemeliharaanBGFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pemeliharaan_form.file_bg.push(val);
        });
      }
    },
    bgPemeliharaanBGDeleteFile(file) {
      let _ = this;
      // alert("jall");
      if (file.lastModified) {
        _.bg_pemeliharaan_form.file_bg = ld.filter(
          _.bg_pemeliharaan_form.file_bg,
          function (o) {
            console.log(o.name);
            return o.name != file.name;
          }
        );
      } else {
        console.log("hallo 2");
        _.bg_pemeliharaan_form.deletedFileBg.push(file.name);
      }
    },
    bgPemeliharaanBGRemoveFile() {
      let _ = this;
      _.$refs.bgPemeliharaanDropzoneBG.removeAllFiles(true);
      _.bg_pemeliharaan_form.file_bg = [];
    },

    bgPemeliharaanBGDetail(file) {
      let _ = this;
      let detail = ld.filter(_.bg_pemeliharaan_detail.file_bg, function (o) {
        return o.name == file.name;
      });

      _.pdfViewer(detail[0].url);
      // _.$refs.bgPemeliharaanDropzoneBGDetail.removeAllFiles(true)
      // _.$refs.bgPemeliharaanDropzoneABDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pemeliharaan_Detail").modal("hide");
    },

    bgPemeliharaanABFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pemeliharaan_form.file_keabsahan.push(val);
        });
      }
    },
    bgPemeliharaanABDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.bg_pemeliharaan_form.file_bg = ld.filter(
          _.bg_pemeliharaan_form.file_keabsahan,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.bg_pemeliharaan_form.deletedFileAb.push(file.name);
      }
    },
    bgPemeliharaanABRemoveFile() {
      let _ = this;
      _.$refs.bgPemeliharaanDropzoneAB.removeAllFiles(true);
      _.bg_pemeliharaan_form.file_keabsahan = [];
    },

    bgPemeliharaanABDetail(file) {
      let _ = this;
      let detail = ld.filter(
        _.bg_pemeliharaan_detail.file_keabsahan,
        function (o) {
          return o.name == file.name;
        }
      );

      _.pdfViewer(detail[0].url);
      // _.$refs.bgPemeliharaanDropzoneABDetail.removeAllFiles(true)
      // _.$refs.bgPemeliharaanDropzoneBGDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pemeliharaan_Detail").modal("hide");
    },

    // batas Pemeleliharan

    bgPelaksanaanBGFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pelaksanaan_form.file_bg.push(val);
        });
      }
    },
    bgPelaksanaanBGDeleteFile(file) {
      let _ = this;
      // alert("jall");
      if (file.lastModified) {
        _.bg_pelaksanaan_form.file_bg = ld.filter(
          _.bg_pelaksanaan_form.file_bg,
          function (o) {
            console.log(o.name);
            return o.name != file.name;
          }
        );
      } else {
        console.log("hallo 2");
        _.bg_pelaksanaan_form.deletedFileBg.push(file.name);
      }
    },
    bgPelaksanaanBGRemoveFile() {
      let _ = this;
      _.$refs.bgPelaksanaanDropzoneBG.removeAllFiles(true);
      _.bg_pelaksanaan_form.file_bg = [];
    },

    bgPelaksanaanBGDetail(file) {
      let _ = this;
      let detail = ld.filter(_.bg_pelaksanaan_detail.file_bg, function (o) {
        return o.name == file.name;
      });
      _.pdfViewer(detail[0].url);
      // _.$refs.bgPelaksanaanDropzoneBGDetail.removeAllFiles(true)
      // _.$refs.bgPelaksanaanDropzoneABDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pelaksanaan_Detail").modal("hide");
    },

    bgPelaksanaanABFileHandler(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.kontraktual_dropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.bg_pelaksanaan_form.file_keabsahan.push(val);
        });
      }
    },
    bgPelaksanaanABDeleteFile(file) {
      let _ = this;
      if (file.lastModified) {
        _.bg_pelaksanaan_form.file_bg = ld.filter(
          _.bg_pelaksanaan_form.file_keabsahan,
          function (o) {
            return o.name != file.name;
          }
        );
      } else {
        _.bg_pelaksanaan_form.deletedFileAb.push(file.name);
      }
    },
    bgPelaksanaanABRemoveFile() {
      let _ = this;
      _.$refs.bgPelaksanaanDropzoneAB.removeAllFiles(true);
      _.bg_pelaksanaan_form.file_keabsahan = [];
    },

    bgPelaksanaanABDetail(file) {
      let _ = this;
      let detail = ld.filter(
        _.bg_pelaksanaan_detail.file_keabsahan,
        function (o) {
          return o.name == file.name;
        }
      );
      _.pdfViewer(detail[0].url);
      // _.$refs.bgPelaksanaanDropzoneABDetail.removeAllFiles(true)
      // _.$refs.bgPelaksanaanDropzoneBGDetail.removeAllFiles(true)
      window.$("#Modal_BG_Pelaksanaan_Detail").modal("hide");
    },

    async bgUangMukaSave() {
      let _ = this;
      var validation = _.$refs.bgUangMukaForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_uang_muka_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_uang_muka_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_uang_muka_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_uang_muka_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak_mtu", _.$route.params.id);

        console.log(formData);

        await _.axios
          .post("v2/kontrak-mtu/bg-uang-muka", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_uang_muka_form = {
              id_kontrak_mtu: "",
              id_perubahan: "",
              nomor: "",
              id_bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Uang_Muka").modal("hide");
            _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true);
            _.$refs.bgUangMukaDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getUangMuka();
            _.getUangMukaList();
            _.getUangMukaListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_uang_muka_ldstate = false;
      });
    },

    async bgUangMukaUpdate() {
      let _ = this;
      var validation = _.$refs.bgUangMukaForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_uang_muka_ldstate = true;

        if (_.bg_uang_muka_form.id_perubahan != null) {
          _.bg_uang_muka_form.is_origin = 0;
        } else {
          _.bg_uang_muka_form.is_origin = 1;
        }

        var formData = new FormData();
        console.log(_.bg_uang_muka_form);
        $.each(_.bg_uang_muka_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_uang_muka_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_uang_muka_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else if (index == "deletedFileBg") {
            $.each(_.bg_uang_muka_form.deletedFileBg, function (index, val) {
              formData.append("deletedFileBg[" + index + "]", val);
            });
          } else if (index == "deletedFileAb") {
            $.each(_.bg_uang_muka_form.deletedFileAb, function (index, val) {
              formData.append("deletedFileAb[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak_mtu", _.$route.params.id);
        formData.append("id", _.bg_uang_muka_id);

        await _.axios
          .post("v2/kontrak-mtu/bg-uang-muka/update", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_uang_muka_form = {
              id_kontrak_mtu: "",
              id_perubahan: "",
              nomor: "",
              id_bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Uang_Muka").modal("hide");
            _.$refs.bgUangMukaDropzoneBG.removeAllFiles(true);
            _.$refs.bgUangMukaDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getUangMuka();
            _.getUangMukaList();
            _.getUangMukaListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_uang_muka_ldstate = false;
      });
    },

    modalBGUangMukaDetail(id) {
      let _ = this;
      _.bg_uang_muka_detail = [];
      _.$refs.bgUangMukaDropzoneABDetail.removeAllFiles(true);
      _.$refs.bgUangMukaDropzoneBGDetail.removeAllFiles(true);
      let get = _.bg_uang_muka.rows.filter((sc) => sc.id == id)[0];

      _.bg_uang_muka_detail = get;

      _.bg_uang_muka_detail.id_perubahan_show = _.bg_uang_muka_detail.perubahans
        ? _.bg_uang_muka_detail.perubahans.nomor
        : "-";
      _.bg_uang_muka_detail.bank_show = _.bg_uang_muka_detail.bank
        ? _.bg_uang_muka_detail.bank.name
        : "-";

      _.bg_uang_muka_detail.file_bg.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgUangMukaDropzoneBGDetail.manuallyAddFile(file, url);
      });

      _.bg_uang_muka_detail.file_keabsahan.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgUangMukaDropzoneABDetail.manuallyAddFile(file, url);
      });

      window.$("#Modal_BG_Uang_Muka_Detail").modal("show");
      $(".dz-hidden-input").prop("disabled", true);
    },

    delete_bg_uang_muka(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/v2/kontrak-mtu/bg-uang-muka/" + x)
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.getUangMuka();
                  _.getUangMukaList();
                  _.getUangMukaListAll();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    // Pelaksanaan

    async bgPelaksanaanSave() {
      let _ = this;
      var validation = _.$refs.bgPelaksanaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pelaksanaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pelaksanaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pelaksanaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_pelaksanaan_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak_mtu", _.$route.params.id);

        console.log(formData);

        await _.axios
          .post("v2/kontrak-mtu/bg-pelaksanaan", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pelaksanaan_form = {
              id_kontrak_mtu: "",
              id_perubahan: "",
              nomor: "",
              id_bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pelaksanaan").modal("hide");
            _.$refs.bgPelaksanaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPelaksanaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPelaksanaan();
            _.getPelaksanaanList();
            _.getPelaksanaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pelaksanaan_ldstate = false;
      });
    },

    async bgPelaksanaanUpdate() {
      let _ = this;
      var validation = _.$refs.bgPelaksanaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pelaksanaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pelaksanaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pelaksanaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(_.bg_pelaksanaan_form.file_keabsahan, function (index, val) {
              formData.append("file_keabsahan[" + index + "]", val);
            });
          } else if (index == "deletedFileBg") {
            $.each(_.bg_pelaksanaan_form.deletedFileBg, function (index, val) {
              formData.append("deletedFileBg[" + index + "]", val);
            });
          } else if (index == "deletedFileAb") {
            $.each(_.bg_pelaksanaan_form.deletedFileAb, function (index, val) {
              formData.append("deletedFileAb[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak_mtu", _.$route.params.id);
        formData.append("id", _.bg_pelaksanaan_id);

        console.log(formData);

        await _.axios
          .post("v2/kontrak-mtu/bg-pelaksanaan/update", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pelaksanaan_form = {
              id_kontrak_mtu: "",
              id_perubahan: "",
              nomor: "",
              id_bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pelaksanaan").modal("hide");
            _.$refs.bgPelaksanaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPelaksanaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPelaksanaan();
            _.getPelaksanaanList();
            _.getPelaksanaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pelaksanaan_ldstate = false;
      });
    },

    modalBGPelaksanaanDetail(id) {
      let _ = this;
      _.bg_pelaksanaan_detail = [];
      _.$refs.bgPelaksanaanDropzoneABDetail.removeAllFiles(true);
      _.$refs.bgPelaksanaanDropzoneBGDetail.removeAllFiles(true);
      let get = _.bg_pelaksanaan.rows.filter((sc) => sc.id == id)[0];

      _.bg_pelaksanaan_detail = get;

      _.bg_pelaksanaan_detail.id_perubahan_show = _.bg_pelaksanaan_detail
        .perubahans
        ? _.bg_pelaksanaan_detail.perubahans.nomor
        : "-";
      _.bg_pelaksanaan_detail.bank_show = _.bg_pelaksanaan_detail.bank
        ? _.bg_pelaksanaan_detail.bank.name
        : "-";

      _.bg_pelaksanaan_detail.file_bg.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPelaksanaanDropzoneBGDetail.manuallyAddFile(file, url);
      });

      _.bg_pelaksanaan_detail.file_keabsahan.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPelaksanaanDropzoneABDetail.manuallyAddFile(file, url);
      });

      window.$("#Modal_BG_Pelaksanaan_Detail").modal("show");
      $(".dz-hidden-input").prop("disabled", true);
    },

    delete_bg_pelaksanaan(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/v2/kontrak-mtu/bg-pelaksanaan/" + x)
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.getPelaksanaan();
                  _.getPelaksanaanList();
                  _.getPelaksanaanListAll();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    // pemeliharaan

    async bgPemeliharaanSave() {
      let _ = this;
      var validation = _.$refs.bgPemeliharaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pemeliharaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pemeliharaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pemeliharaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(
              _.bg_pemeliharaan_form.file_keabsahan,
              function (index, val) {
                formData.append("file_keabsahan[" + index + "]", val);
              }
            );
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak_mtu", _.$route.params.id);

        console.log(formData);

        await _.axios
          .post("v2/kontrak-mtu/bg-pemeliharaan", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pemeliharaan_form = {
              id_kontrak_mtu: "",
              id_perubahan: "",
              nomor: "",
              id_bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pemeliharaan").modal("hide");
            _.$refs.bgPemeliharaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPemeliharaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPemeliharaan();
            _.getPemeliharaanList();
            _.getPemeliharaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pemeliharaan_ldstate = false;
      });
    },

    async bgPemeliharaanUpdate() {
      let _ = this;
      var validation = _.$refs.bgPemeliharaanForm;
      validation.validate().then(async (success) => {
        if (!success) {
          return;
        }
        _.bg_pemeliharaan_ldstate = true;

        var formData = new FormData();
        $.each(_.bg_pemeliharaan_form, function (index, val) {
          if (index == "file_bg") {
            $.each(_.bg_pemeliharaan_form.file_bg, function (index, val) {
              formData.append("file_bg[" + index + "]", val);
            });
          } else if (index == "file_keabsahan") {
            $.each(
              _.bg_pemeliharaan_form.file_keabsahan,
              function (index, val) {
                formData.append("file_keabsahan[" + index + "]", val);
              }
            );
          } else if (index == "deletedFileBg") {
            $.each(_.bg_pemeliharaan_form.deletedFileBg, function (index, val) {
              formData.append("deletedFileBg[" + index + "]", val);
            });
          } else if (index == "deletedFileAb") {
            $.each(_.bg_pemeliharaan_form.deletedFileAb, function (index, val) {
              formData.append("deletedFileAb[" + index + "]", val);
            });
          } else {
            formData.append(index, val);
          }
        });
        formData.append("id_kontrak_mtu", _.$route.params.id);
        formData.append("id", _.bg_pemeliharaan_id);

        console.log(formData);

        await _.axios
          .post("v2/kontrak-mtu/bg-pemeliharaan/update", formData)
          .then((resp) => {
            console.log(resp);
            (_.bg_pemeliharaan_form = {
              id_kontrak_mtu: "",
              id_perubahan: "",
              nomor: "",
              id_bank: "",
              tanggal_terbit: "",
              tanggal_berlaku: "",
              tanggal_berakhir: "",
              nilai: "",
              file_bg: [],
              file_keabsahan: [],
              deletedFileBg: [],
              deletedFileAb: [],
              cny: "",
              jpy: "",
              eur: "",
              usd: "",
            }),
              validation.reset();
            window.$("#Modal_Bg_Pemeliharaan").modal("hide");
            _.$refs.bgPemeliharaanDropzoneBG.removeAllFiles(true);
            _.$refs.bgPemeliharaanDropzoneAB.removeAllFiles(true);
            _.$toast.success("Data Berhasil Disimpan");
            _.getPemeliharaan();
            _.getPemeliharaanList();
            _.getPemeliharaanListAll();
          })
          .catch((err) => {
            console.log(err);
            _.$router.push("/kontrak-mtu/detail/" + _.$route.params.id);
            _.$toast.warning("Data BG Gagal Disimpan");
          });
        _.bg_pemeliharaan_ldstate = false;
      });
    },

    modalBGPemeliharaanDetail(id) {
      let _ = this;
      _.bg_pemeliharaan_detail = [];
      _.$refs.bgPemeliharaanDropzoneABDetail.removeAllFiles(true);
      _.$refs.bgPemeliharaanDropzoneBGDetail.removeAllFiles(true);
      let get = _.bg_pemeliharaan.rows.filter((sc) => sc.id == id)[0];

      _.bg_pemeliharaan_detail = get;

      _.bg_pemeliharaan_detail.id_perubahan_show = _.bg_pemeliharaan_detail
        .perubahans
        ? _.bg_pemeliharaan_detail.perubahans.nomor
        : "-";
      _.bg_pemeliharaan_detail.bank_show = _.bg_pemeliharaan_detail.bank
        ? _.bg_pemeliharaan_detail.bank.name
        : "-";

      _.bg_pemeliharaan_detail.file_bg.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPemeliharaanDropzoneBGDetail.manuallyAddFile(file, url);
      });

      _.bg_pemeliharaan_detail.file_keabsahan.forEach(function (el) {
        let file = { size: 10240, name: el.name, type: "application/word" };
        let url = el.url;
        console.log(file);
        _.$refs.bgPemeliharaanDropzoneABDetail.manuallyAddFile(file, url);
      });

      window.$("#Modal_BG_Pemeliharaan_Detail").modal("show");
      $(".dz-hidden-input").prop("disabled", true);
    },

    delete_bg_pemeliharaan(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.axios
              .delete("/v2/kontrak-mtu/bg-pemeliharaan/" + x)
              .then((resp) => {
                if (resp.status) {
                  this.$swal.fire({
                    title: "Terhapus!",
                    text: "Data berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#37BA71",
                    confirmButtonText: "OK",
                  });
                  _.getPemeliharaan();
                  _.getPemeliharaanList();
                  _.getPemeliharaanListAll();
                } else {
                  console.log("Failed");
                }
              })
              .catch((err) => {
                console.log(err.response);
              });
          }
        });
    },

    
    getBankList() {
      let _ = this;
      _.axios
        .get("v2/master_bank/all")
        .then((resp) => {
          _.bank_list = resp.data.data.rows;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  async mounted() {
    let _ = this
    await _.getBankList();
    await _.getUangMuka();
    await _.getPelaksanaan();
    await _.getPemeliharaan();
    await _.getUangMukaList();
    await _.getPelaksanaanList();
    await _.getPemeliharaanList();
    await _.getUangMukaListAll();
    await _.getPelaksanaanListAll();
    await _.getPemeliharaanListAll();
  },
}
</script>