<template>
	<div class="row">
        <div class="col-12 col-lg-6 col-xl-7 d-none d-lg-block">
            <div class="full-img">
                <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
                <div class="logo-block">
                    <img src="/assets/img/logo-text-white-min.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>

        <ValidationObserver v-slot="{ invalid }" class="col-12 col-md-8 col-lg-6 col-xl-5 ml-auto mr-auto">
            <div class="form-auth-block">
                <div class="form-logo d-block d-lg-none">
                    <img src="/assets/img/logo.png" class="img-fluid" alt="">
                    <div class="sub-logo">Monitoring <span>Digital</span></div>
                </div>

                <h4 class="title-heading-form text-primary">Buat Password Baru</h4>    
                <p class="subtitle-form">Password minimal 6 karakter dengan kombinasi huruf dan angka</p>

                <ValidationProvider name="password" rules="required|alpha_num|alphaandnumeric|min:6">
                <div class="input-group form-group" :class="{ 'validation-warning': errors[0] }" slot-scope="{ errors }">
                    <input v-model="password" class="form-control py-2 border-right-0 border form-custom-bg" :type="showPassword ? 'text' : 'password'" placeholder="Password baru" required="">
                    <span class="input-group-append">
                        <div class="input-group-text form-custom-bg">
                            <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue">
                                <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                            </a>
                        </div>
                    </span>
                    <div class="invalid-feedback" style="display: block !important">
                      <small v-if="errors[0]" id="" class="text-muted">Password tidak valid</small>
                    </div>
                </div>
                </ValidationProvider>

                <ValidationProvider name="password_confirmation" rules="required|alpha_num|alphaandnumeric|min:6">
                <div class="input-group form-group" :class="{ 'validation-warning': errors[0] }" slot-scope="{ errors }">
                    <input v-model="password_confirmation" class="form-control py-2 border-right-0 border form-custom-bg" :type="showPasswordConf ? 'text' : 'password'" placeholder="Konfirmasi password baru" required="">
                    <span class="input-group-append">
                        <div class="input-group-text form-custom-bg">
                            <a href="javascript:;" @click="showPasswordConf = !showPasswordConf" class="text-blue">
                                <i :class="showPasswordConf ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                            </a>
                        </div>
                    </span>
                    <div class="invalid-feedback" style="display: block !important">
                      <small v-if="errors[0]" id="" class="text-muted">Konfirmasi password baru tidak valid</small>
                    </div>
                </div>
                </ValidationProvider>

                <button :disabled="invalid" @click="reset" class="btn btn-primary waves-effect waves-light w-100 mt-2">Reset Password</button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>

export default {
	name: 'ResetPassword',
    data() {
        return {
            password: null,
            password_confirmation: null,
            showPassword: false,
            showPasswordConf: false
        }
    },
    methods: {
        reset(){
            let _ = this
            _.isProcess = true
            _.axios.post('/auth/reset', { otp: _.$route.query.otp, password: _.password, password_confirmation: _.password_confirmation })
                .then(resp => {
                    _.isProcess = false
                    console.log(resp)
                    if ( resp.status == 200 ) {
                        _.$toast.success('Password Berhasil Diubah')
                        _.$router.push('/login')
                    }
                })
                .catch(err => {
                    _.isProcess = false
                    _.$toast.warning('Konfirmasi Password Baru Tidak Cocok')
                    console.log(err.message)
                })
        }
    }
}
</script>