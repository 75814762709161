<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
                    <button type="submit" class="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#Modal_FilterKontrak" style="margin-left: 3px;">
                        <i class="fa fa-filter fa-l" aria-hidden="true"></i> Filter Laporan
                    </button>
                    &emsp;
                    <button v-can="'laporan/bulanan/cetak'" @click="cetak(filterby.tgl_awal, filterby.tgl_akhir)" class="btn btn-outline-primary waves-effect waves-light" 
                        v-if="table.dataTable.lists.data" :disabled="table.dataTable.lists.data.length == 0">
                        <i class="fa fa-file-excel-o fa-l" aria-hidden="true"></i> Cetak Excel
                    </button>
				</div>
			</template>

            <template v-slot:search>
				<div class="input-group form-search-rounded">
                    <input class="form-control py-2 border-right-0 border" v-model.lazy="search" type="search" id="example-search-input" placeholder="Cari laporan disini..">
                    <span class="input-group-append">
                        <div class="input-group-text bg-transparent">
                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                        </div>
                    </span>
                </div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Nama</th> 
                        <th>Aksi</th>  
                    </tr>
                </thead>
			</template> 

            <template v-slot:action="{ data }">
                <div v-can="'laporan/bulanan/download'" class="btn-action-block">
                    <a @click="cetak(data.date_start,data.date_end)" class="download-file">
                        <i class="fa fa-cloud-download "></i> Download
                    </a>
                </div>
            </template>            
		</List>
        <div class="modal fade text-left show" id="Modal_FilterKontrak" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Filter Kontrak</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <!-- Filter -->
                        <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                            <div class="col-8 col-sm-10">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="filter_by">Filter Berdasarkan</label>
                                            <select class="form-control" v-model="f.by" :disabled="f.by != '-'">
                                                <option v-for="(data, index) in filterOption" :key="index" :value="data.by">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-if="f.by == 'my'">
                                        <div class="form-group">
                                            <label for="filter_by">Bulan dan tahun</label>
                                            <div class="position-relative has-icon-left">                                                
                                                <date-picker
                                                    v-model="filterby.my"
                                                    format="MMMM, YYYY"
                                                    type="month"
                                                    value-type="YYYY-M-D"
                                                    placeholder="Masukkan bulan & tahun"  
                                                    style="width: 100%;"
                                                ></date-picker>
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else-if="f.by == 'id_unit'">
                                        <div class="form-group">
                                            <label for="filter_by">UPP</label>                                                                                        
                                            <select class="form-control" v-model="filterby.id_unit">
                                                <option v-for="(unit, index) in optionsUnit.filter(unit => unit.id > 1)" :key="index" :value="unit.id">{{unit.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else>
                                        <div class="form-group">
                                            <label for="filter_by">-</label>
                                            <input type="text" class="form-control" disabled>                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 col-sm-2 d-flex align-items-center">
                                <button class="btn-transparent" @click="addFilter()" v-if="idx == filter.length - 1">
                                    <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                        <path fill="#17536A" fill-rule="nonzero" d="M10 .134c-5.523 0-10 4.476-10 10 0 5.523 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zm0 18.75a8.75 8.75 0 1 1 0-17.5 8.75 8.75 0 0 1 0 17.5zm3.75-9.375h-3.125V6.384a.625.625 0 0 0-1.25 0v3.125H6.25a.625.625 0 0 0 0 1.25h3.125v3.125a.625.625 0 0 0 1.25 0v-3.125h3.125a.625.625 0 0 0 0-1.25z"></path>
                                    </svg>
                                </button>
                                <button class="btn-transparent" @click="deleteFilter(idx)" v-if="filter.length > 1">
                                    <svg class="img-fluid mr-2" width="20" height="21" viewBox="0 0 20 21">
                                        <path fill="#DA3E2C" fill-rule="nonzero" d="M17.314 3.314C13.547-.726 7.219-.946 3.18 2.82c-4.04 3.767-4.26 10.094-.494 14.134 3.767 4.039 10.095 4.26 14.134.493 4.039-3.766 4.26-10.094.494-14.133zM3.6 16.1A8.75 8.75 0 1 1 16.399 4.166 8.75 8.75 0 0 1 3.601 16.101zm9.414-3.651l-2.132-2.286 2.286-2.13a.625.625 0 0 0-.853-.915L10.031 9.25 7.9 6.965a.625.625 0 0 0-.915.852l2.132 2.286-2.286 2.13a.625.625 0 0 0 .853.915l2.285-2.131 2.131 2.285a.625.625 0 0 0 .915-.852z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="reset()">Reset</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="getList()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
	</section>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import List from '@/components/List.vue';

export default {
	name: 'UserIndex',
	components: {
        List,
        DatePicker
	},
	data() {
		return {
			table: {
				title: "Laporan Bulanan",
				breadcumb: "Laporan",
                dataTable: {
                    lists: [],
                    columns: [
                        'name'
                    ]
                }
            },
            model: [],
            search: '',
            filterby: {
                my: '',
                tgl_awal: '',
                tgl_akhir: '',
                id_unit: ''
            },
            filter: [
                {
                    'by': '-'
                }
            ],
            filterOption: 
            [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'Bulan & Tahun',
                    'by': 'my'
                },
                {
                    'name': 'UPP',
                    'by': 'id_unit'
                }
            ],
            optionsUnit: [],
		}
	},
    mounted() {
        let _ = this
        _.getList()
        _.getUnit()
    },
    methods: {
        getList() {
            let _ = this
            _.axios.get('/kontrak/index-bulanan?' + 
                    'date=' + _.filterby.my + 
                    '&key=' + _.search + 
                    '&id_unit=' + _.filterby.id_unit
                ).then(resp=>{
                    _.table.dataTable.lists = resp.data.data

                    if (_.table.dataTable.lists.data.length > 0){
                        _.filterby.tgl_awal     = _.table.dataTable.lists.data[0].date_start
                        _.filterby.tgl_akhir    = _.table.dataTable.lists.data[_.table.dataTable.lists.data.length-1].date_end
                    } else {
                        _.filterby.tgl_awal = ''
                        _.filterby.tgl_akhir = ''
                    }                 
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        cetak(x, y) {
			let _ = this
            _.axios.get('/kontrak/export-monitoring' + '?orderby=id' + '&sort=asc'
                + '&date_start='        + x
                + '&date_end='          + y
                + '&id_unit='           + _.filterby.id_unit
                + '&id_pekerjaan='      + ''
                + '&status_pekerjaan='  + ''
                + '&status_serahterima='+ ''
                + '&status_operasi='    + ''              
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LaporanMonitoring_' + x + '-' + y + '.xlsx'
                    link.click()
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        addFilter(){
            let _ = this
            _.filter.push({
                name	: '-',
                by      : '-'
			})
        },
        deleteFilter(x) {
            let _ = this
            switch (_.filter[x].by) {
                case 'my':
                    _.filterby.my = ''
                    break;

                case 'id_unit':
                    _.filterby.id_unit = ''
                    break;             
            
                default:
                    break;
            }            
            _.filter.splice(x, 1);            
        },
        reset() {
            let _ = this 
            _.filterby.my = ''
            _.filterby.tgl_awal = ''
            _.filterby.tgl_akhir = ''   
            _.filterby.id_unit = ''
            _.filter = [
                {
                    'by': '-'
                }
            ]
        },
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp => {
                    _.optionsUnit = resp.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
    },
    watch: {
        search: function() {
            this.getList()
        },
    }
}
</script>