<template>
	<!-- Modal -->
    <div class="modal fade text-left" id="Modal_TambahUser" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel160" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">Tambah User</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="observer">
	                <div class="modal-body">
	                	<ValidationProvider name="model.email" v-slot="{ errors }" rules="required|email">
		                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
		                        <label for="">Email</label>
		                        <input type="email" v-model="model.email" class="form-control" placeholder="Masukan email user"
		                            aria-describedby="helpId">
		                        <small class="text-muted" v-if="emailExist">Email telah digunakan.</small>
		                    </div>
		                </ValidationProvider>

		                <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
		                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
		                        <label for="">Nama</label>
		                        <input type="text" v-model="model.name" class="form-control" placeholder="Masukan nama user"
		                            aria-describedby="helpId">
		                    </div>
		                </ValidationProvider>

		                <ValidationProvider name="model.role_id" v-slot="{ errors }" rules="required">
		                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
		                        <label for="">Role</label>
		                        <select v-model="model.role_id" class="form-control">
		                            <option value="">Masukan Role</option>
		                            <option v-for="r in role" :value="r.id" :key="`role-${r.id}`">{{ r.name }}</option>
		                        </select>
		                    </div>
		                </ValidationProvider>
		                <template v-if="model.role_id == 3">
							<ValidationProvider name="model.kontraktor_id" v-slot="{ errors }" rules="required">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="">Kontraktor</label>
									<select v-model="model.kontraktor_id" class="form-control">
										<option value="">Pilih Kontraktor</option>
										<option v-for="kt in kontraktor" :value="kt.id" :key="`role-${kt.id}`">{{ kt.name }}</option>
									</select>
								</div>
							</ValidationProvider>
		                </template>
		                <template v-else>
							<ValidationProvider name="model.unit_id" v-slot="{ errors }" rules="required">
								<div class="form-group" :class="{ 'validation-warning': errors[0] }">
									<label for="">Unit</label>
									<select v-model="model.unit_id" class="form-control">
										<option value="">Masukan Unit</option>
										<option v-for="u in unit.filter(u =>
											u.id > (model.role_id == 1 ? 1 : 0)
											)" :value="u.id" :key="`unit-${u.id}`">{{ u.name }}</option>
									</select>
								</div>
							</ValidationProvider>

		                    <div class="form-group">
		                        <label for="">Bidang</label>
		                        <select v-model="model.bidang_id" class="form-control" :disabled="positionDetail != null && positionDetail.id_bidang == null">
		                            <option value="">Masukan Bidang</option>
		                            <option v-for="b in bidang.filter(p => p.unit == (model.unit_id == 1 ? 1 : 2 ))" :value="b.id" :key="`role-${b.id}`">{{ b.name }}</option>
		                        </select>
		                    </div>

		                    <div class="form-group">
		                        <label for="">Sub Bidang</label>
		                        <select v-model="model.subbidang_id" class="form-control" :disabled="(positionDetail != null && positionDetail.id_subbidang == null) || model.unit_id != 1">
		                            <option value="">Masukan Sub-Bidang</option>
		                            <option v-for="sub in subbidang.filter(p => p.unit == model.unit_id)" :value="sub.id" :key="`role-${sub.id}`">{{ sub.name }}</option>
		                        </select>
		                    </div>

							<ValidationProvider name="model.jabatan_id" v-slot="{ errors }" rules="required">
			                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
			                        <label for="">Jabatan</label>
			                        <select v-model="model.jabatan_id" class="form-control">
			                            <option value="">Masukan Jabatan</option>
			                            <option v-for="p in position.filter(p => 
											p.type 			== model.role_id &&
											p.unit 			== (model.unit_id      == 1  ? 1 : 2 ) &&
											p.id_bidang 	== (model.bidang_id    == '' ? null : model.bidang_id) && 
											p.id_subbidang 	== (model.subbidang_id == '' ? null : model.subbidang_id)
										)" :value="p.id" :key="`role-${p.id}`">{{ p.name }}</option>
			                        </select>
			                    </div>
			                </ValidationProvider>

		                    <div class="form-group">
		                        <label for="">Atasan</label>
		                        <select v-model="model.atasan_id" class="form-control" :disabled="positionDetail!= null && positionDetail.index == null">
									<option value="">Pilih Atasan</option>
		                            <option v-for="at in atasan" :value="at.id" :key="`role-${at.id}`">{{ at.name }}</option>
		                        </select>
		                    </div>							
		                </template>

	                    <div class="form-group">
	                        <label for="">Status</label>
	                        <div class="custom-control custom-switch custom-control-inline mt-1">
	                            <input v-model="model.status" type="checkbox" class="custom-control-input" id="customSwitch1">
	                            <label class="custom-control-label" for="customSwitch1">
	                            </label>
	                            <span class="switch-label">{{  model.status ? 'Aktif' : 'Tidak Aktif' }}</span>
	                        </div>
	                    </div>
	                </div>
	                <div class="modal-footer">
	                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
	                    <button type="button" :disabled="invalid" @click="save()" class="btn btn-primary" data-dismiss="modal">Tambah User</button>
	                </div>
	            </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'ModalAddUser',
	data() {
		return {								
			model: {
				email: null,
				name: null,
				unit_id: "",
				role_id: "",
				jabatan_id: "",
				bidang_id: "",
				subbidang_id: "",
				atasan_id: "",
				kontraktor_id: "",
				status: false
			},
			role: [
				{ id: 1, name: 'Pengawas' },
				{ id: 2, name: 'Non Pengawas' },
				{ id: 3, name: 'Kontraktor' }
			],	
			unit: [],
			position: [],
			bidang: [],
			subbidang: [],
			atasan: [],
			kontraktor: [],
			emailExist: false,
			positionDetail: null
		}
	},
	mounted() {
		let _ = this
		_.getUnit()
		_.getPosition()
		_.getBidang()
		_.getSubbidang()
		_.getKontraktor()
	},
	methods: {
		getUnit() {
			let _ = this
			_.axios.get('/unit/all')
				.then(resp=> {
					_.unit = resp.data
				})
				.catch(err=> {
					console.log(err)
				})
		},		
		getPosition() {
			let _ = this
			_.axios.get('/position/all')
				.then(resp=> {
					_.position = resp.data.data
				})
				.catch(err=> {
					console.log(err)
				})
		},
		getBidang() {
			let _ = this
			_.axios.get('/bidang/all')
				.then(resp=> {
					_.bidang = resp.data.data
				})
				.catch(err=> {
					console.log(err)
				})
		},
		getSubbidang() {
			let _ = this
			_.axios.get('/subbidang/all')
				.then(resp=> {
					_.subbidang = resp.data.data
				})
				.catch(err=> {
					console.log(err)
				})
		},
		getKontraktor() {
			let _ = this
			_.axios.get('/kontraktor/all')
				.then(resp=> {
					_.kontraktor = resp.data.data
				})
				.catch(err=> {
					console.log(err)
				})
		},
		getUser() {
			let _ = this
			_.axios.get('/user/all?unit_id=' + _.model.unit_id + '&jabatan_id=' + _.model.jabatan_id)
				.then(resp=> {
					_.atasan = resp.data.data
					_.model.atasan_id = _.atasan[0].id
				})
				.catch(err=> {
					console.log(err)
				})
		},
		async save() {
			let _ = this
			await _.checkEmail(_.model.email)

			if ( !_.emailExist ) {
				console.log(_.emailExist)
				_.axios.post('/user/store', _.model)
					.then(resp=> {
						console.log(resp)
						_.model.email 			= null
						_.model.name 			= null
						_.model.unit_id 		= ""
						_.model.role_id 		= ""
						_.model.jabatan_id 		= ""
						_.model.bidang_id 		= ""
						_.model.subbidang_id 	= ""
						_.model.atasan_id 		= ""
						_.model.status 			= false
											
						_.$emit('success')
	                    _.$refs.observer.reset();
	                    _.$toast.success('Data Berhasil Disimpan')
					})
					.catch(err=> {
						console.log(err.response)
					})
			}
		},
		checkEmail(email) {
			let _ = this
			_.axios.get('/user/check_email?email=' + email)
					.then(resp => {
						if ( resp.data.data.count == 1 ) {
							_.model.email = ''
							_.emailExist = true
							_.$toast.error('Email sudah digunakan')
						} else {
							_.emailExist = false
						}
					})
					.catch(err=> {
						console.log(err.response)
					})
		},
		getPositionDetail(){
			let _ = this
			_.axios.get('/position?id='+_.model.jabatan_id)
				.then(resp=> {
					_.positionDetail 	 = resp.data.data.rows
					_.model.bidang_id 	 = _.positionDetail.id_bidang == null ? "" : _.positionDetail.id_bidang
					_.model.subbidang_id = _.positionDetail.id_subbidang == null ? "" : _.positionDetail.id_subbidang
					
					if(_.positionDetail.index == null){
						_.model.atasan_id = ""
					}					
				})
				.catch(err=> {
					console.log(err)
				})
		}
	},
	watch: {
		'model.jabatan_id': function(){
			let _ = this		
			if (_.model.jabatan_id == null || _.model.jabatan_id == ''){				
				_.positionDetail = null
			} else {
				_.getPositionDetail()
				_.getUser()
			}			 
		},
		'model.role_id': function(){
			let _ = this
			_.model.unit_id 		= ""
			_.model.jabatan_id 		= ""
			_.model.bidang_id 		= ""
			_.model.subbidang_id 	= ""
			_.model.atasan_id 		= ""
			_.model.status 			= false			
		},
		'model.unit_id': function(){
			let _ = this
			_.model.jabatan_id 		= ""
			_.model.bidang_id 		= ""
			_.model.subbidang_id 	= ""
			_.model.atasan_id 		= ""
			_.model.status 			= false			
		},
		'model.bidang_id': function(){
			let _ = this
			_.model.subbidang_id 	= ""
			_.model.atasan_id 		= ""
			_.model.jabatan_id 		= ""
			_.model.status 			= false			
		},
		'model.subbidang_id': function(){
			let _ = this
			_.model.atasan_id 		= ""
			_.model.status 			= false			
		}
	}
}
</script>