<template>
    <div>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{$route.query.edit ? 'Edit' : 'Tambah'}} Kontrak</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak-mtu'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body card-dashboard">
                                <form-wizard color="#329fb7" title="" ref='fwizard' subtitle="" finishButtonText="Selesai" nextButtonText="Selanjutnya" backButtonText="Sebelumnya" stepSize="sm">
                                  <tab-content title="Nama Kontrak" icon="step-icon feather icon-home">
                                    <fieldset>
                                        <ValidationObserver ref="vFirst">
                                            <div class="row mt-2">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Jenis Pekerjaan" rules="required" v-slot="{ errors }">
                                                        <label for="">Jenis Pekerjaan</label>
                                                        <select v-model="form.jenis_pekerjaan" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" :disabled="id_edit !== null">
                                                            <option value="">Pilih Jenis Pekerjaan</option>
                                                            <option v-for="(opt, idx) in jenisPekerjaan" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                                        </select>
                                                        <small>{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Nomor Kontrak" rules="required" v-slot="{ errors }">
                                                        <label for="">Nomor Kontrak </label>
                                                        <input type="text" v-model="form.no_kontrak" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" placeholder="Contoh: 362.PJ/2/UIP/JJB/2016">
                                                        <small>{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Keterangan PRK" rules="required" v-slot="{ errors }">
                                                        <label for="">Keterangan PRK</label>
                                                        <select v-model="form.keterangan_prk" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                            <option value="">Pilih Keterangan PRK</option>
                                                            <option v-for="(opt, idx) in keteranganPRK" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                                        </select>
                                                        <small>{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Nomor PRK" :rules="form.keterangan_prk === 'Gabungan' ? 'required' : ''" v-slot="{ errors }">
                                                        <label for="">Nomor PRK</label>
                                                        <input type="text" v-model="no_prk" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" placeholder="Masukan Nomor PRK" :disabled="form.keterangan_prk === 'Per Lokasi'">
                                                        <small>{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Judul Kontrak" rules="required" v-slot="{ errors }">
                                                        <label for="">Judul Kontrak</label>
                                                        <input type="text" v-model="form.judul_kontrak" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" placeholder="Masukan Judul Kontrak">
                                                        <small>{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <label for="">Tanggal Kontrak</label>
                                                    <div class="position-relative has-icon-right mb-2">
                                                        <ValidationProvider name="Tanggal Kontrak" rules="required" v-slot="{ errors }">
                                                        <date-picker
                                                            format="DD/MM/YYYY"
                                                            type="date"
                                                            placeholder="Masukan Tanggal Kontrak"
                                                            v-model="form.tanggal_kontrak"
                                                            :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                            value-type="YYYY-MM-DD"
                                                            style="width: 100%;"
                                                            >
                                                        </date-picker>
                                                        <small>{{ errors[0] }} </small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div :class="{ 'col-12 col-md-4' : true, 'col-md-7': form.keterangan_prk === 'Gabungan' }">
                                                            <label for="">Lokasi</label>
                                                        </div>
                                                        <div class="col-12 col-md-3" v-if="form.keterangan_prk === 'Per Lokasi'">
                                                            <label for="">No. PRK</label>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <label for="">Ready at Site</label>
                                                        </div>
                                                        <div class="col-12 col-md-1">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" v-for="(f,idx) in form.lokasi" :key="idx">
                                                        <div :class="{ 'col-12 col-md-4' : true, 'col-md-7': form.keterangan_prk === 'Gabungan' }">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'Lokasi '+ (idx + 1)" rules="required" v-slot="{ errors }">
                                                                <input :disabled="id_edit !== null" type="text" v-model="f.name" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" placeholder="Masukan Lokasi">
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3" v-if="form.keterangan_prk === 'Per Lokasi'">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'No PRK '+ (idx + 1) " rules="required" v-slot="{ errors }">
                                                                <input type="text" v-model="f.no_prk" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" placeholder="Masukan Nomor PRK">
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="position-relative has-icon-right mb-2">
                                                                <ValidationProvider :name="'Ready at Site '+ (idx + 1)" rules="required" v-slot="{ errors }">
                                                                <date-picker
                                                                    format="DD/MM/YYYY"
                                                                    type="date"
                                                                    :disabled="id_edit !== null"
                                                                    placeholder="Masukan Tanggal Ready at Site"
                                                                    v-model="f.ready_at"
                                                                    :input-class="(Boolean(errors[0]))? 'form-control is-invalid no-x' : 'form-control'"
                                                                    value-type="YYYY-MM-DD"
                                                                    style="width: 100%;"
                                                                    >
                                                                </date-picker>
                                                                <small>{{ errors[0] }} </small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-1">
                                                            <div style="gap: 10px; padding-top: 10px" class="d-flex align-items-center">
                                                                <button v-if="form.lokasi.length > 1 && !id_edit" @click="form.lokasi.splice(idx, 1)" class="btn p-0"><h4 class="m-0"><i class="feather icon-trash text-danger"></i></h4></button>
                                                                <button 
                                                                    @click="addLokasiv2"
                                                                    v-if="idx === form.lokasi.length - 1 && !id_edit" 
                                                                    class="btn p-0"
                                                                >
                                                                    <h4 class="m-0">
                                                                        <i class="feather icon-plus-circle text-primary"></i>
                                                                    </h4>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationObserver>
                                    </fieldset>
                                  </tab-content>
                                  <tab-content title="Informasi Kontrak" icon="step-icon feather icon-file-text">
                                    <fieldset>
                                        <ValidationObserver ref="vSecond">
                                            <div class="row mt-2">
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Kontraktor" rules="required" v-slot="{ errors }">
                                                        <label for="">Kontraktor</label>
                                                        <select class="form-control" v-model="form.id_kontraktor" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                            <option value="">Pilih Kontraktor</option>
                                                            <option v-for="(e, idx) in kontraktor" :key="idx" :value="e.id">{{ e.name }}</option>
                                                        </select>
                                                        <small>{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <ValidationProvider name="Sumber Dana" rules="required" v-slot="{ errors }">
                                                        <label for="">Sumber Dana</label>
                                                        <input type="text" v-model="form.sumber_dana" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" placeholder="Contoh : BUMN">
                                                        <small>{{ errors[0] }}</small>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }">
                                                            <label for="">Pekerjaan</label>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }">
                                                            <label for="">Keterikatan Project</label>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }">
                                                            <div class="row">
                                                                <div class="col-3">
                                                                    <label for="">Material</label>
                                                                </div>
                                                                <div class="col-3">
                                                                    <label for="">Jumlah</label>
                                                                </div>
                                                                <div class="col-3">
                                                                    <label for="">Satuan</label>
                                                                </div>
                                                                <div class="col-3"></div>
                                                            </div>
                                                        </div>
                                                        <!-- <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'GIS'">
                                                            <label for="">Konfigurasi</label>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'Trafo'">
                                                            <label for="">Kapasitas per Trafo</label>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'Tower'">
                                                            <label for="">Tipe</label>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'Trafo' || form.jenis_pekerjaan === 'Tower'">
                                                            <label for="">Jumlah</label>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'Konduktor'">
                                                            <label for="">Quantity (meter)</label>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row" v-for="(e, idx) in form.lokasi" :key="idx">   
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'Pekerjaan (Row '+(idx + 1)+')'" rules="required" v-slot="{ errors }">
                                                                <input type="text" v-model="e.name" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''" placeholder="Lokasi" disabled>
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'Keterikatan Project (Row '+(idx + 1)+')'" rules="required" v-slot="{ errors }">
                                                                <select :disabled="id_edit !== null" class="form-control" v-model="e.id_kontrak" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                    <option value="">Pilih Project</option>
                                                                    <option v-for="(e, idx) in keterikatanProject" :key="idx" :value="e.id">{{ e.judul_kontrak }}</option>
                                                                </select>
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <!-- <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="['GIS'].includes(form.jenis_pekerjaan)">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'Konfigurasi (Row '+(idx + 1)+')'" rules="required" v-slot="{ errors }">
                                                                <input type="text" v-model="e.konfigurasi.konfigurasi" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div> -->
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }">
                                                            <div class="row" v-for="(conf, idxConf) in e.konfigurasi" :key="idxConf">
                                                                <div class="col-3">
                                                                    <div class="form-group">
                                                                        <ValidationProvider :name="'Material (Lokasi '+(idx + 1)+' Row ' + (idxConf+1) +')'" rules="required" v-slot="{ errors }">
                                                                            <input type="text" v-model="conf.konfigurasi" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                            <small>{{ errors[0] }}</small>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="form-group">
                                                                        <ValidationProvider :name="'Jumlah (Lokasi '+(idx + 1)+' Row ' + (idxConf+1) +')'" rules="required" v-slot="{ errors }">
                                                                            <input type="number" v-model="conf.jumlah" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                            <small>{{ errors[0] }}</small>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="form-group">
                                                                        <ValidationProvider :name="'Satuan (Lokasi '+(idx + 1)+' Row ' + (idxConf+1) +')'" rules="required" v-slot="{ errors }">
                                                                            <select v-model="conf.satuan" class="form-control">
                                                                                <option value="set" v-if="form.jenis_pekerjaan != 'Konduktor'">Set</option>
                                                                                <option value="unit" v-if="form.jenis_pekerjaan != 'Konduktor'">Unit</option>
                                                                                <option value="meter" v-if="form.jenis_pekerjaan == 'Konduktor'">Meter</option> 
                                                                            </select>
                                                                            <small>{{ errors[0] }}</small>
                                                                        </ValidationProvider>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div style="gap: 10px; padding-top: 10px" class="d-flex align-items-center">
                                                                        <button class="btn p-0" 
                                                                            @click="e.konfigurasi.splice(idxConf, 1)"
                                                                            v-if="e.konfigurasi.length > 1 && !id_edit">
                                                                            <h4 class="m-0"><i class="feather icon-trash text-danger"></i></h4>
                                                                        </button>
                                                                        <button class="btn p-0"
                                                                            @click="addLocationConfiguration(idx)"
                                                                            v-if="idxConf === e.konfigurasi.length - 1 && !id_edit">
                                                                            <h4 class="m-0"><i class="feather icon-plus-circle text-primary"></i></h4>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'Trafo'">
                                                            <div class="form-group">
                                                                <div class="mva-input text-muted">MVA</div>
                                                                <ValidationProvider :name="'Kapasitas (Row '+(idx + 1)+')'" rules="required" v-slot="{ errors }">
                                                                <input type="text" v-model="e.konfigurasi.kapasitas" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'Tower'">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'Tipe (Row '+(idx + 1)+')'" rules="required" v-slot="{ errors }">
                                                                <input type="text" v-model="e.konfigurasi.tipe" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="['Trafo', 'Tower'].includes(form.jenis_pekerjaan)">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'Jumlah (Row '+(idx + 1)+')'" rules="required" v-slot="{ errors }">
                                                                <input type="text" v-model="e.konfigurasi.jumlah" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div>
                                                        <div :class="{ 'col-12': true, 'col-md-4': ['Konduktor', 'GIS', 'AIS', 'Trafo', 'Tower'].includes(form.jenis_pekerjaan), 'col-md-3': [].includes(form.jenis_pekerjaan) }" v-if="form.jenis_pekerjaan === 'Konduktor'">
                                                            <div class="form-group">
                                                                <ValidationProvider :name="'Panjang (Row '+(idx + 1)+')'" rules="required" v-slot="{ errors }">
                                                                <input type="text" v-model="e.konfigurasi.panjang" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                                                <small>{{ errors[0] }}</small>
                                                                </ValidationProvider>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationObserver>
                                    </fieldset>
                                  </tab-content>
                                   <tab-content title="Informasi Tambahan" icon="step-icon feather icon-layers">
                                    <fieldset>
                                        <ValidationObserver ref="vThird">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="">Foto Kontrak</label>
                                                    <vue-dropzone ref="myVueDropzone" id="dropzone" 
                                                        :duplicateCheck="true" 
                                                        :options="dropzoneOptions" 
                                                        @vdropzone-files-added="(e) => { form.file = e[0]; is_new_file = true }"
                                                        @vdropzone-removed-file="deleteFile"
                                                    ></vue-dropzone>
                                                    <button @click="removeFile" class="btn btn-danger mt-2"> Reset File </button>
                                                </div>
                                            </div>
                                        </div>
                                        <h5 class="mt-2"><strong>Nilai Kontrak</strong></h5>
                                        <div class="row">
                                            <div class="col-12">
                                                <el-collapse class="v2-ipm-accordion" accordion>
                                                    <el-collapse-item 
                                                        v-for="(lokasi, idx) in form.lokasi"
                                                        :key="idx"
                                                        class="v2-ipm-accordion-item" 
                                                        :title="lokasi.name" 
                                                        :name="idx"
                                                    >
                                                        <div class="row">
                                                            <div v-for="(e, i) in lokasi.nilai" :key="i" class="col-12">
                                                                <h5 class="mt-2 mb-2"><strong>{{ e.name }}</strong></h5>
                                                                <div class="row">
                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">IDR</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="e.idr" v-bind="money" :disabled="id_edit !== null"></money>
                                                                                <div class="form-control-position">
                                                                                    Rp
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">CNY</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="e.cny" v-bind="money" :disabled="id_edit !== null"></money>
                                                                                <div class="form-control-position">
                                                                                    &#x5143;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">JPY</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                            <money class="form-control" v-model="e.jpy" v-bind="money" :disabled="id_edit !== null"></money>
                                                                                <div class="form-control-position">
                                                                                    &#xa5;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">EURO</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="e.eur" v-bind="money" :disabled="id_edit !== null"></money>
                                                                                <div class="form-control-position">
                                                                                    &euro;
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 col-sm-2">
                                                                        <div class="form-group">
                                                                            <label for="">USD</label>
                                                                            <div style="height: 92px" class="position-relative has-icon-left">
                                                                                <money class="form-control" v-model="e.usd" v-bind="money" :disabled="id_edit !== null"></money>
                                                                                <div class="form-control-position">
                                                                                    $
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12">
                                                                        <ValidationProvider :name="'Nilai Kontrak ' + lokasi.name + ' ' + i + 1" rules="" v-slot="{ errors }">
                                                                            <input type="hidden" v-model="e.nilai">
                                                                            <p style="color : #EA5455;">{{ Boolean(errors[0])? '*Nilai Kontrak tidak boleh Rp 0' : '' }}</p>
                                                                        </ValidationProvider>
                                                                    </div>

                                                                    <div class="col-12">
                                                                        <h5>Equivalent</h5>
                                                                        <h3><strong>{{ equivalentV2(e, idx, i) | currency }}</strong></h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <hr>
                                                                <h5>Equivalent total exclude PPN</h5>
                                                                <h3><strong>{{ equivalentTotalExcludePPN(idx) | currency }}</strong></h3>
                                                            </div>

                                                            <div class="col-12 col-sm-2">
                                                                <div class="form-group">
                                                                    <label for="">PPN</label>
                                                                    <div style="height: 92px" class="position-relative has-icon-left">
                                                                        <money class="form-control" v-model="lokasi.ppn" v-bind="money" :disabled="id_edit !== null"></money>
                                                                        <div class="form-control-position">
                                                                            Rp
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-12">
                                                                <h5>Equivalent total include PPN</h5>
                                                                <h3><strong>{{ (equivalentTotalExcludePPN(idx) + lokasi.ppn) | currency }}</strong></h3>
                                                            </div>
                                                        </div>
                                                    </el-collapse-item>
                                                </el-collapse>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                    </fieldset>
                                    
                                   </tab-content>
                                    <template slot="footer" slot-scope="props">
                                        <div class="wizard-footer-right">
                                            <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()"  style="margin-right:30px;border-color : rgb(50,159,183) !important;;color : rgb(50,159,183) !important; background :#fff;min-width:90px;"><i class="fa fa-chevron-left" style="margin-right:10px;"></i> Sebelumnya</wizard-button>
                                            <wizard-button v-if="!props.isLastStep" @click.native="wizValidate()" v class="wizard-footer-right" :style="props.fillButtonStyle" style="min-width:90px;">Selanjutnya <i class="fa fa-chevron-right" style="margin-left:10px;"></i></wizard-button>
                                            <wizard-button v-if="props.isLastStep" @click.native="wizValidate()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" style="min-width:90px;">  Selesai <i class="fa fa-check" style="margin-left:10px;"></i></wizard-button>
                                        </div>
                                    </template>
                                </form-wizard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import vue2Dropzone from 'vue2-dropzone'
import $ from 'jquery'
import {Money} from 'v-money'
import ld from 'lodash'
import moment from 'moment'

export default {
    components: { DatePicker, vueDropzone: vue2Dropzone, 
    Money 
    },
    data() {
        return {
            jenisPekerjaan : [
                {
                    text: 'GIS',
                    value: 'GIS'
                },
                {
                    text: 'Trafo',
                    value: 'Trafo'
                },
                {
                    text: 'AIS',
                    value: 'AIS'
                },
                {
                    text: 'Konduktor',
                    value: 'Konduktor'
                },
                {
                    text: 'Tower',
                    value: 'Tower'
                },
            ],
            keteranganPRK: [
                {
                    text: 'Per Lokasi',
                    value: 'Per Lokasi'
                },
                {
                    text: 'Gabungan',
                    value: 'Gabungan'
                }
            ],
            kontraktor: [],
            keterikatanProject: [],
            no_prk: '',
            is_new_file: false,
            form: {
                judul_kontrak: '', // tab 1
                id_kontraktor: '',
                jenis_pekerjaan: '', // tab 1
                keterangan_prk: '', // tab 1
                no_kontrak: '', // tab 1
                tanggal_kontrak: '', // tab 1
                sumber_dana: '', // tab 2
                file: null, // tab 3
                lokasi: [
                    {
                        id_kontrak: '',
                        name: '',  // tab 1
                        no_prk: '', // tab 1
                        ready_at: '', // tab 1
                        jumlah_trafo: 0,
                        konfigurasi: '', // tab 2
                        nilai: [ // tab 3
                            {
                                name: 'Pengadaan Material',
                                idr: 0,
                                cny: 0,
                                jpy: 0,
                                eur: 0,
                                usd: 0,
                                nilai: 0
                            },
                            {
                                name: 'Inland Transport',
                                idr: 0,
                                cny: 0,
                                jpy: 0,
                                eur: 0,
                                usd: 0,
                                nilai: 0
                            },
                            {
                                name: 'Perakitan dan Testing',
                                idr: 0,
                                cny: 0,
                                jpy: 0,
                                eur: 0,
                                usd: 0,
                                nilai: 0
                            }
                        ]
                    }
                ]
            },
            id_edit: null,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false 
            },
            title: "Tambah Kontrak",
            breadcumb: [
                {
                    name: 'Monitoring Kontrak MTU', 
                    link: '/kontrak-mtu',
                    active: false
                },
                {
                    name: 'Project List', 
                    link: '/kontrak-mtu',
                    active: false
                },
                {
                    name: this.$route.query.edit ? 'Edit Kontrak' : 'Tambah Kontrak', 
                    link: '',
                    active: true
                }            
            ],
            model: {
                id_kontraktor: "",
                id_pekerjaan: "",
                id_unit: "",
                no_kontrak: null,
                priority: "",
                judul_kontrak: null,
                nama_pekerjaan: null,
                durasi: 0,
                tgl_awal: '',
                tgl_akhir: '',
                sumber_dana: null,
                gi: " ",
                kapasitas: null,
                no_spmk: null,
                tgl_spmk: null,
                lokasi: [],
                image: [],
                nilai: 0,
                penugasan: null,
                pic: null,
                deletedFile: []
            },
            lokasiInp: [{
                    name   : '',
                    latlng : '',
                    pinned : false
            }],
            optionsPenugasan : [
                { id: 1, name: 'Pegawai Satu', user_id : 0 },
            ],
            optionsPIC : [],
            optionsKontraktor: [],
            optionsPekerjaan: [],
            optionsUnit: [],
            dropzoneOptions: {
                url: this.$baseUrl + '/kontrak',
                acceptedFiles : 'image/*',
                thumbnailWidth: 150,
                autoProcessQueue: false,
                uploadMultiple: true,
                addRemoveLinks: true,
                paramName: 'image'
            },
            idr: 0,
            cny: 0,
            jpy: 0,
            eur: 0,
            usd: 0,
            map: '',
            marker : [],
            tgl_akhir_text : '',
            cur : {
                jpy : 0,
                cny : 0,
                eur : 0,
                usd : 0
            },
            role_id: localStorage.getItem('role_id')
        }
    },
    methods: {
        async submit () {
            const formData = new FormData ()
            for (const [key, val] of Object.entries(this.form)) {
                if (key === 'lokasi') {
                    this.form[key].forEach((e, idx) => {
                        for (const [key2, val2] of Object.entries(e)) {
                            if (key2 === 'nilai') {
                                if (!this.id_edit) {
                                    this.form[key][idx][key2].forEach((e2, idx2) => {
                                        for (const [key3, val3] of Object.entries(e2)) {
                                            formData.append(`${key}[${idx}][${key2}][${idx2}][${key3}]`, val3)
                                        }
                                    })
                                }
                            } else if (key2 === 'konfigurasi') {
                                for (const [key3, val3] of Object.entries(val2)) {
                                    for (const [key4, val4] of Object.entries(val3)) {
                                        formData.append(`${key}[${idx}][${key4}][${key3}]`, val4)
                                    }
                                }
                            } 
                            // else if (key2 === 'konfigurasi') {
                            //     for (const [key3, val3] of Object.entries(val2)) {
                            //         formData.append(`${key}[${idx}][${key2}][${key3}]`, val3)
                            //     }
                            // } 
                            else if (['ready_at', 'jumlah_trafo', 'name', 'id_kontrak'].includes(key2)) {
                                if (!this.id_edit) {
                                    formData.append(`${key}[${idx}][${key2}]`, val2)
                                }
                            } else {
                                formData.append(`${key}[${idx}][${key2}]`, val2)
                            }
                        }
                    })
                } else if (key === 'file') {
                    if (val && this.is_new_file) {
                        formData.append(key, val)
                    }
                } else {
                    formData.append(`${key}`, val)
                }
            }
            try {
                if (this.id_edit) {
                    const datas = (await this.axios.post('/v2/kontrak-mtu/'+this.id_edit, formData)).data
                    if (!datas.status) throw datas
                    this.$router.push('/kontrak-mtu')
                    this.$toast.success('Data Kontrak Berhasil Disimpan')
                } else {
                    const datas = (await this.axios.post('/v2/kontrak-mtu', formData)).data
                    if (!datas.status) throw datas
                    this.$router.push('/kontrak-mtu')
                    this.$toast.success('Data Kontrak Berhasil Disimpan')
                }
            } catch (error) {
                console.log(error)
                this.$toast.warning('Data Kontrak Gagal Disimpan')
            }
        },
        equivalentV2 (data, idx1, idx2) {
            const curr = ( parseFloat((data.idr ? data.idr : 0)) + parseFloat((data.cny ? data.cny : 0) * this.cur.cny) + parseFloat((data.jpy ? data.jpy : 0) * this.cur.jpy) + parseFloat((data.eur ? data.eur : 0) * this.cur.eur) + parseFloat((data.usd ? data.usd : 0) * this.cur.usd) )
            this.form.lokasi[idx1].nilai[idx2].nilai =  curr
            return curr
        },
        addLokasiv2 () {
            // const val = this.form.jenis_pekerjaan
            let no_prk = this.form.keterangan_prk === 'Per Lokasi' ? 0 : this.no_prk
            // if (val === 'Konduktor' || val === 'Tower') {
            //     let konfig = {}
            //     if (val === 'Konduktor') {
            //         konfig = {
            //             panjang: '',
            //         }
            //     }
            //     if (val === 'Tower') {
            //         konfig = {
            //             tipe: '',
            //             jumlah: ''
            //         }
            //     }
            //     this.form.lokasi.push(
            //         {
            //             id_kontrak: '',
            //             name: '',
            //             no_prk: no_prk,
            //             ready_at: '',
            //             jumlah_trafo: 0,
            //             konfigurasi: konfig,
            //             nilai: [
            //                 {name:'Nilai SPB',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0}
            //             ]
            //         }
            //     )
            // } else if (val === 'GIS' || val === 'Trafo') {
            //     let konfig = {}
            //     if (val === 'GIS') {
            //         konfig= {
            //             konfigurasi: '',
            //         }
            //     }
            //     if (val === 'Trafo') {
            //         konfig= {
            //             kapasitas: '',
            //             jumlah: ''
            //         }
            //     }
            //     this.form.lokasi.push(
            //         {
            //             id_kontrak: '',
            //             name: '',
            //             no_prk: no_prk,
            //             ready_at: '',
            //             jumlah_trafo: 0,
            //             konfigurasi: konfig,
            //             nilai: [
            //                 {name:'Nilai Pengadaan Material',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
            //                 {name:'Nilai Inland Transport',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
            //                 {name:'Nilai Perakitan & Testing',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
            //             ]
            //         }
            //     )
            // } else if (val === 'AIS') {
                this.form.lokasi.push(
                    {
                        id_kontrak: '',
                        name: '',
                        no_prk: no_prk,
                        ready_at: '',
                        jumlah_trafo: 0,
                        konfigurasi: {
                            konfigurasi: ''
                        },
                        nilai: [
                            {name:'Nilai Pengadaan Material',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                            {name:'Nilai Inland Transport',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                            {name:'Nilai Jasa Supervisi',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                            {name:'Nilai Perakitan & Testing',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                        ]
                    }
                )
            // }
        },
        calculateDate : ld.debounce(function(){
            let _ = this
             _.axios.get('set-date?start='+_.model.tgl_spmk+'&durasi='+_.model.durasi)
                .then(resp => {
                    _.model.tgl_akhir = resp.data
                    _.tgl_akhir_text = moment(_.model.tgl_akhir).format("DD, MMMM YYYY")
                })
                .catch(err => {
                    console.log(err.response)
                })
        },500),
        wizValidate() {
            let _ = this
            var tab = _.$refs.fwizard.activeTabIndex
            if(tab == 0){
                var vFirst = _.$refs.vFirst
                vFirst.validate().then(success => {
                    if(!success){
                        window.scrollTo(0,0)
                        return;
                    }
                    _.$refs.fwizard.nextTab()
                    this.fetchKontraktor ()
                    this.fetchKeterikatanProject ()

                    if (!_.$route.query.edit) {
                        this.form.lokasi.forEach((e) => {
                            e.konfigurasi = [
                                {
                                    konfigurasi: null, 
                                    jumlah: 0,
                                    satuan: 'set'
                                }
                            ]
                        })
                    }
                })
            }
            else if(tab == 1){
                var vSecond = _.$refs.vSecond
                vSecond.validate().then(success => {
                    if(!success){
                        window.scrollTo(0,0)
                        return;
                    }

                    _.$refs.fwizard.nextTab()
                })
            }
            else if(tab == 2){
                var vThird = _.$refs.vThird
                vThird.validate().then(success => {
                    if(!success){
                        return;
                    }
                // _.onComplete()
                this.submit ()
                })
            }
        },
        async fetchKontraktor () {
            try {
                const datas = (await this.axios.get('/v2/misc/kontraktor')).data
                this.kontraktor = datas.data
            } catch (error) {
                console.log(error)
            }
        },
        async fetchKeterikatanProject () {
            try {
                const datas = (await this.axios.get('/v2/misc/kontrak', { params: { jenis_kontrak: this.form.jenis_pekerjaan} })).data
                this.keterikatanProject = datas.data
            } catch (error) {
                console.log(error)
            }
        },
        fileHandler (file){
            this.form.file = file[0]
        },
        removeFile() {
            let _ = this
            _.$refs.myVueDropzone.removeAllFiles(true)
            _.model.image = []
        },
        initMap() {
            let _ = this
            var myLatlng = {lat: -6.409493537558435, lng: 106.79552258660475}

            _.map = new window.google.maps.Map(
               _.$refs["map"], {zoom: 12, center: myLatlng})
        },
        async addMarker(index) {
            let _ = this
            var mylatlng = {
                lat : parseFloat(_.lokasiInp[index].latlng.split(",")[0]), 
                lng : parseFloat(_.lokasiInp[index].latlng.split(",")[1])
            }
            if(!_.lokasiInp[index].pinned){
                var geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({
                        'latLng': mylatlng
                    }, function(results, status) {
                        if (status == window.google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                let mark = new window.google.maps.Marker({
                                                map : _.map,
                                                animation: window.google.maps.Animation.DROP,
                                                position: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                                            });
                                var infoWindow = new window.google.maps.InfoWindow({
                                    content: results[0].formatted_address,
                                });
                                _.marker['mark'+index] = mark
                                infoWindow.open(_.map, _.marker['mark'+index]);

                                _.model.lokasi.push({
                                    name: _.lokasiInp[index].name,
                                    address: results[0].formatted_address,
                                    gmap_url : 'https://www.google.co.id/maps/place/'+results[0].formatted_address.replace(/,/g,"").replace(/ /g,"+"),
                                    lat : results[0].geometry.location.lat(),
                                    lng : results[0].geometry.location.lng()
                                })
                                _.lokasiInp[index].pinned = true
                            }
                        }
                    });
           
            }
            else{
                _.cancelPin(index)
            }
            
        },
        getKontraktor() {
            let _ = this
            _.axios.get('/kontraktor/all')
                .then(resp => {
                    _.optionsKontraktor = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getPekerjaan() {
            let _ = this
            _.axios.get('/work/all')
                .then(resp => {
                    _.optionsPekerjaan = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getPenugasan(id_unit) {
            window.deleteTextEmpty()
            let _ = this
            _.axios.get('/user/all?unit_id='+id_unit+'&role=1')
                .then(resp => {
                    _.optionsPenugasan = resp.data.data.map(function(elem) {
                        return {
                            id: elem.id,
                            name: elem.name,
                            id_user: elem.id
                        };
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getPIC() {
            window.deleteTextEmpty()
            let _ = this
            _.axios.get('/user_pic?limit=200&status=1')
                .then(resp => {
                    _.optionsPIC = resp.data.data.rows.map(function(elem) {
                        return {
                            id: elem.user_id,
                            name: elem.name,
                            id_user: elem.user_id
                        };
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp => {
                    _.optionsUnit = resp.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        sendingMultiple(files, xhr, formData) {
            let _ = this
            $.each(_.model, function(index, val) {
                formData.append(index, (index == 'penugasan' ? JSON.stringify(val) : val));
            });
        },
        onSuccess() {
            let _ = this
            _.$router.push('/kontrak')
            _.$toast.success('Data Kontrak Berhasil Disimpan')
        },
        onError() {
            let _ = this
            _.$router.push('/kontrak')
            _.$toast.warning('wakwaw')
        },
        onComplete() {
            let _ = this
            // _.model.tgl_akhir = moment().format("YYYY-MM-DD")
            _.model.no_spmk = _.model.no_spmk == null ? '' : _.model.no_spmk 
            _.model.tgl_spmk = _.model.tgl_spmk == null ? '' : _.model.tgl_spmk
            _.model.lokasi = _.model.lokasi.length == 0 ? '' : _.model.lokasi

            _.model.idr = _.idr
            _.model.cny = _.cny
            _.model.jpy = _.jpy
            _.model.eur = _.eur
            _.model.usd = _.usd

            var formData = new FormData();
            $.each(_.model, function(index, val) {
                if(index == 'image'){
                    $.each(_.model.image, function(index, val) {
                       formData.append('image['+index+']',val)
                   })
                }
                else if(index == 'deletedFile'){
                    $.each(_.model.deletedFile, function(index, val) {
                        formData.append('deletedFile['+index+']',val)
                    })
                }
                else{
                    formData.append(index, (index == 'penugasan' || index == 'lokasi' || index == 'pic' ? JSON.stringify(val) : val));                   
                }
            });           
          
            let api = ''
            if (_.$route.params.id){
                api = '/kontrak/update'
            } else {
                api = '/kontrak'
            }

            _.axios.post(api, formData,{
                headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(resp=> {
                    console.log(resp)
                    _.$router.push('/kontrak')
                    _.$toast.success('Data Kontrak Berhasil Disimpan')
                })
                .catch(err=> {
                    console.log(err)
                    _.$router.push('/kontrak')
                    _.$toast.warning('Data Kontrak Gagal Disimpan')
                })
            
        },
        addLokasi() {
            let _ = this
            _.lokasiInp = [
                ..._.lokasiInp,
                { latlng : "" , pinned : false}
            ]
        },
        cancelPin(ix) {
            let _ = this
            _.model.lokasi = _.model.lokasi.filter((lk, i) => {return i != ix} )
            _.marker['mark'+ix].setMap(null)
           delete _.marker['mark'+ix]
            _.lokasiInp[ix].pinned = false
        },
        removeLokasi(ix) {
            let _ = this
            try{
                 _.lokasiInp[ix].pinned = false
                _.model.lokasi = _.model.lokasi.filter((lk, i) => {return i != ix} )
                _.marker['mark'+ix].setMap(null)
                delete _.marker['mark'+ix]
            }
            catch(err){
                console.log(err)
            }
            
            _.lokasiInp = _.lokasiInp.filter((lk, i) => {return i != ix} )
        },
        getCurrency(){
            let _ = this
            _.axios.get('/kontrak/currency')
            .then(res => {
                _.cur.jpy = res.data.data.jpy
                _.cur.cny = res.data.data.cny
                _.cur.eur = res.data.data.eur
                _.cur.usd = res.data.data.usd
            })
        },  
        get(){
            let _ = this
            _.axios.get('/kontrak?id=' + _.$route.params.id)
                .then(resp => {
                    _.model = resp.data.data.rows
                    _.tgl_akhir_text = moment(_.model.tgl_akhir).format("DD, MMMM YYYY")
                    let arr = []
                    for (let i = 0; i < _.model.penugasan.length; i++) {
                        arr.push({
                            id: _.model.penugasan[i].user.id,
                            name: _.model.penugasan[i].user.name,
                            id_user: _.model.penugasan[i].user.id
                        })
                    }
                    _.model.penugasan = arr

                    let arrPIC = []
                    for (let i = 0; i < _.model.pics.length; i++) {
                        arrPIC.push({
                            id: _.model.pics[i].user.id,
                            name: _.model.pics[i].user.name,
                            id_user: _.model.pics[i].user.id
                        })
                    }
                    _.model.pic = arrPIC
                    
                    if (_.model.lokasi == '') {
                        _.model.lokasi = []
                    } else if (_.model.lokasi != null){
                        let temp_lokasi = _.model.lokasi
                        _.model.lokasi = []
                        _.lokasiInp = []
                        for (let i = 0; i < temp_lokasi.length; i++) {
                            _.lokasiInp.push({
                                name: temp_lokasi[i].name,
                                latlng: temp_lokasi[i].lat + ',' + temp_lokasi[i].lng,
                                pinned: false
                            })
                            _.addMarker(i)
                        }
                    } else {
                        _.model.lokasi = []
                    }                                                           

                    _.model.image.forEach(function(el) {
                        let file = { 
                            size: 10240, 
                            name: el.name,
                            type: "image/png" 
                        };
                        let url = el.url;
                        _.$refs.myVueDropzone.manuallyAddFile(file, url);
                    })

                    _.model.image = []
                    _.model.deletedFile = []
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        deleteFile (file){
            let _ = this
            if(file.lastModified){
                _.model.image = ld.filter(_.model.image, function(o){
                    return o.name != file.name;
                })
            }else{
                _.model.deletedFile.push(file.name)
            }            
        },
        async loadDetail () {
            try {
                const datas = (await this.axios.get('/v2/kontrak-mtu/'+this.id_edit)).data
                const backupData = JSON.parse(JSON.stringify(datas))

                this.no_prk = parseInt(datas.data.col_prk)
                for (const [key, val] of Object.entries(datas.data)) {
                    if (Object.prototype.hasOwnProperty.call(this.form, key)) {
                        if (key === 'file' && val) {
                            let docUrl = datas.data['file_url']
                            let ext = docUrl.split('.').pop()
                            let file = { size: 10240, name: datas.data['document'], type: `application/${ext}` };
                            this.$refs.myVueDropzone.manuallyAddFile(file, docUrl);
                        } else {
                            this.form[key] = val
                        }
                    }
                }
                this.form.lokasi = datas.data.locations.map((e) => {
                    for (const [key, val] of Object.entries(e)) {
                        if (!Object.prototype.hasOwnProperty.call(this.form.lokasi[0], key)) {
                            if (key === 'values') {
                                e.nilai = e.values.map((nilai) => {
                                    for (const [keyn, valn] of Object.entries(nilai)) {
                                        if (!Object.prototype.hasOwnProperty.call(this.form.lokasi[0].nilai[0], keyn)) {
                                            delete nilai[keyn]
                                        } else {
                                            nilai[keyn] = valn
                                        }
                                    }
                                    return nilai
                                })
                            } else if (key === 'id'){
                                e['id'] = val
                            }

                            if (!['id'].includes(key)) {
                                delete e[key]
                            } 
                        } else {
                            if (key === 'konfigurasi') {
                                e[key] = JSON.parse(val)
                            }
                        }
                    }
                    return e
                })

                // if ( this.form.jenis_pekerjaan == 'AIS' ) {
                    for (let i = 0; i < backupData.data.locations.length; i++) {
                        const location = backupData.data.locations[i];

                        const material = JSON.parse(location.konfigurasi)
                        const jumlah = JSON.parse(location.jumlah)
                        const satuan = JSON.parse(location.satuan)

                        const konfigurasi = []
                        for (let z = 0; z < material.length; z++) {
                            konfigurasi.push({
                                konfigurasi: material[z],
                                jumlah: jumlah[z],
                                satuan: satuan[z]
                            })
                        }

                        this.form.lokasi[i].konfigurasi = konfigurasi
                    }
                // }


            } catch (error) {
                console.log(error)
            }
        },
        addLocationConfiguration(idxLokasi) {
            this.form.lokasi[idxLokasi].konfigurasi.push({
                konfigurasi: null, 
                jumlah: 0,
                satuan: 'set'
            })
        },
        equivalentTotalExcludePPN(idxLokasi) {
            let total = 0
            const lokasi = this.form.lokasi[idxLokasi];
            for (let i = 0; i < lokasi.nilai.length; i++) {
                const e = lokasi.nilai[i];
                total += this.equivalentV2(e, idxLokasi, i)
            }
            return total
        }
    },
    computed: {
        equivalent() {
            let _ = this
            let equi = ( parseFloat((_.idr ? _.idr : 0)) + parseFloat((_.cny ? _.cny : 0) * _.cur.cny) + parseFloat((_.jpy ? _.jpy : 0) * _.cur.jpy) + parseFloat((_.eur ? _.eur : 0) * _.cur.eur) + parseFloat((_.usd ? _.usd : 0) * _.cur.usd) )
            _.model.nilai = equi
            return equi
        }
    },
    mounted() {
        let _ = this
        _.getCurrency()
        _.getKontraktor()
        _.getPekerjaan()
        _.getUnit()
        _.getPenugasan()
        _.getPIC()
        // _.initMap()

        if (_.$route.params.id){
            _.get()
        }

        if (this.$route?.query?.edit ?? false) {
            this.id_edit = this.$route.query.edit
            this.loadDetail ()
        }

        // _.$refs.fwizard.nextTab()
        // _.$refs.fwizard.nextTab()
    },
    watch: {
        no_prk(val) {
            this.form.lokasi.forEach((e) => {
                e.no_prk = val
            })
        },
        'form.jenis_pekerjaan' () {
            if (this.id_edit) return 
            this.fetchKeterikatanProject ()
            // if (val === 'Konduktor' || val === 'Tower') {
            //     this.form.lokasi.forEach((e) => {
            //         let konfig = {}
            //         if (val === 'Konduktor') {
            //             konfig = {
            //                 panjang: '',
            //             }
            //         }
            //         if (val === 'Tower') {
            //             konfig = {
            //                 tipe: '',
            //                 jumlah: ''
            //             }
            //         }
            //         e.id_kontrak = ''
            //         e.konfigurasi = konfig
            //         e.nilai = [{name:'Nilai SPB',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0}]
            //         e.ppn = 0
            //     })
            // } else if (val === 'GIS' || val === 'Trafo') {
            //     this.form.lokasi.forEach((e) => {
            //         let konfig = {}
            //         if (val === 'GIS') {
            //             konfig= {
            //                 konfigurasi: '',
            //             }
            //         }
            //         if (val === 'Trafo') {
            //             konfig= {
            //                 kapasitas: '',
            //                 jumlah: ''
            //             }
            //         }
            //         e.id_kontrak = ''
            //         e.konfigurasi = konfig
            //         e.nilai = [
            //             {name:'Nilai Pengadaan Material',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
            //             {name:'Nilai Inland Transport',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
            //             {name:'Nilai Perakitan & Testing',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
            //         ]
            //         e.ppn = 0
            //     })
            // } else if (val === 'AIS') {
                this.form.lokasi.forEach((e) => {
                    // e.konfigurasi = [
                    //     {
                    //         konfigurasi: null, 
                    //         jumlah: 0,
                    //         satuan: 'set'
                    //     }
                    // ]
                    e.id_kontrak = ''
                    e.nilai = [
                        {name:'Nilai Pengadaan Material',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                        {name:'Nilai Inland Transport',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                        {name:'Nilai Jasa Supervisi',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                        {name:'Nilai Perakitan & Testing',idr:0,cny:0,jpy:0,eur:0,usd:0,nilai:0},
                    ]
                    e.ppn = 0
                })
            // }
        },
        'model.id_pekerjaan': function(newValue) {
            let _ = this
            if ( newValue < 5 ) {
                let lt = _.lokasiInp[0].latlng
                _.lokasiInp = [{
                    latlng : lt,
                    pinned : false
                }]
                for(let i = 0;i <= 30;i++){
                    _.marker['mark'+i].setMap(null)
                }
                _.model.lokasi = []
            } else {
                _.model.gi = ''
            }
            
        },
        'model.id_unit': function(newValue) {
            let _ = this
            _.getPenugasan(newValue)
            // _.model.penugasan = null
        }
    },
}
</script>

<style scoped>
.mva-input {
    position: absolute;
    right: 25px;
    top: 12px;
}
</style>