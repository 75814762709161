export default {
	increment(state) {
      state.count++
    },
    UPDATE_USER(state, payload) {
    	// Update state
    	state.user = payload.user
		state.token = payload.token
		state.permissions = payload.permissions

    	// Store localstorage
    	localStorage.setItem('token', payload.token)
    	localStorage.setItem('userid', payload.user.id)
    	localStorage.setItem('name', payload.user.name)
    	localStorage.setItem('email', payload.user.email)
        localStorage.setItem('has_update_profile', payload.user.has_update_profile)
        localStorage.setItem('phone', payload.user.phone)
        localStorage.setItem('address', payload.user.address)
        localStorage.setItem('position_name', payload.user.position_name)
        localStorage.setItem('profile_picture', payload.user.profile_picture)
		localStorage.setItem('permissions', payload.permissions)
		localStorage.setItem('role_id', payload.user.role_id)
    },
    LOGOUT_USER(state) {
    	state.user = null
    	state.token = null
    	localStorage.removeItem('token')
    	localStorage.removeItem('userid')
    	localStorage.removeItem('name')
		localStorage.removeItem('has_update_profile')
        localStorage.removeItem('phone')
        localStorage.removeItem('address')
        localStorage.removeItem('position_name')
        localStorage.removeItem('profile_picture')
		localStorage.removeItem('permissions')
		localStorage.removeItem('role_id')
    }
}