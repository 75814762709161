import axios from "../axios"

export default {
	login({ commit }, payload) {
		if ( payload.user.status == 1 ) {
			commit('UPDATE_USER', payload)
		}
	},
	tryAutoLogin({ commit }) {
		axios.get('auth/permissions')
			.then(resp=>{
				localStorage.setItem('permissions', resp.data.data.toString())
			})

		let updateUser = {
			user: {
				id: localStorage.getItem('userid'),
				name: localStorage.getItem('name'),
				email: localStorage.getItem('email'),
				has_update_profile: localStorage.getItem('has_update_profile'),
				phone: localStorage.getItem('phone'),
				address: localStorage.getItem('address'),
				position_name: localStorage.getItem('position_name'),
				profile_picture: localStorage.getItem('profile_picture'),
				role_id: localStorage.getItem('role_id')
			},
			token: localStorage.getItem('token'),
			permissions: localStorage.getItem('permissions')						
		}				
		commit('UPDATE_USER', updateUser)
	},
	logout({ commit }) {
		commit('LOGOUT_USER')
	}
}