<template>
    <div class="modal fade text-left" id="Modal_UserData" tabindex="-1" role="dialog" aria-labelledby="myModalLabel161"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title" id="myModalLabel160">Informasi User</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Email</label>
                                <input type="email" name="" id="email" class="form-control" readonly
                                    :value="detail.email">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Nama</label>
                                <input type="text" name="" id="name" class="form-control" :value="detail.name" readonly>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6" v-if="detail.role_id != 3">
                            <div class="form-group">
                                <label for="">Unit</label>
                                <input type="text" name="" id="unit" class="form-control" :value="detail.unit_name" readonly>
                            </div>
                        </div>                        

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Role</label>
                                <input type="text" name="" id="role" class="form-control" :value="detail.role_name" readonly>
                            </div>
                        </div>

                        <div class="col-md-6" v-if="detail.role_id == 3">
                            <div class="form-group">
                                <label for="">Kontraktor</label>
                                <input type="text" name="" class="form-control" :value="detail.kontraktor_name" readonly>
                            </div>
                        </div>
                    </div>                    

                    <div class="row" v-if="detail.role_id != 3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Jabatan</label>
                                <input type="text" name="" id="jabatan" class="form-control" :value="detail.jabatan_name" readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Bidang</label>
                                <input type="text" name="" id="bidang" class="form-control" :value="detail.bidang_name" readonly>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="detail.role_id != 3">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Sub Bidang</label>
                                <input type="text" name="" id="subbidang" class="form-control" :value="detail.subbidangs_name" readonly>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="">Atasan</label>
                                <input type="text" name="" id="atasan" class="form-control" :value="detail.atasan_name" readonly>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="">Status</label>
                        <div class="custom-control custom-switch custom-control-inline mt-1">
                            <input type="checkbox" v-model="detail.status" class="custom-control-input" id="customSwitch2" disabled>
                            <label class="custom-control-label" for="customSwitch2">
                            </label>
                            <span class="switch-label">{{ detail.status_export }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		name: 'ModalDetail',
		props: ['detail'],
		mounted() {
			let _ = this
			console.log(_.$props.detail)
		}
	}
</script>