<template>
  <div>
    <div class="content-header">
      <div class="content-header-left mb-1">
        <div class="breadcrumbs-top">
          <h2 class="content-header-title mb-0">Detail Kontrak</h2>
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="role_id == 2 ? '/' : '/kontrak-mtu'">
                  <i class="feather icon-home"></i>
                </router-link>
              </li>

              <li
                class="breadcrumb-item"
                :class="data.active == true ? 'active' : ''"
                style="text-transform: capitalize"
                v-for="(data, index) in breadcumb"
                :key="index"
              >
                <router-link :to="data.link">{{ data.name }}</router-link>
              </li>
            </ol>
          </div>
        </div>
        <div class="d-flex justify-content-between mt-1" style="gap: 10px">
          <div>
            <h2 class="content-header-title mt-1 mb-0">
              <router-link
                :to="'/kontrak/' + $route.params.id"
                style="font-size: 20px"
              >
                <i class="feather icon-arrow-left"></i>
              </router-link>
              Detail Progress
            </h2>
          </div>
          <button
            type="button"
            @click="generateProgress = 10, generatePDF()"
            class="btn btn-primary waves-effect waves-light"
            v-can="'kontrak/detail/progress/generate'"
            :disabled="generateProgress > 0">
            {{ generateProgress > 0 ? `Generating ${generateProgress}%` : 'Generate Progress' }}
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="true"
        :pdf-format="'a4'"
        :pdf-orientation="'landscape'"
        :pdf-content-width="'100%'"
        :htmlToPdfOptions="{
          filename:`LaporanProject-${model.name}`,
          html2canvas: {
            scale: 1,
            useCORS: true
          },
          jsPDF: {
            unit: 'in',
            format: 'a3',
            orientation: 'landscape'
          }
        }"
        @progress="onProgress($event)"
        ref="html2Pdf">
        <div slot="pdf-content" style="padding: 10px;">
          <section name="page-1_informasi-kontrak">
            <div class="d-flex justify-content-between align-items-center pt-3">
              <div>
                <p class="mb-0" style="font-size: 22px; color: #009ca8; font-weight: 700;">{{ model.kontrak.judul_kontrak }}</p>
                <p class="mb-0 text-capitalize" style="color: #009ca8; font-weight: 700;">Periode Progress {{ model.name }}</p>
              </div>
              <img src="/assets/img/logos.png" alt="">
            </div>
            
            <table class="w-100 table-bordered">
              <thead>
                <tr>
                  <td rowspan="2" colspan="3" class="text-center font-700 align-middle" style="padding: 5px;">DESKRIPSI PROYEK</td>
                  <td colspan="2" class="text-center font-700 align-middle" style="padding: 5px;">PROGRESS FISIK</td>
                  <td rowspan="2" class="text-center font-700 align-middle" style="padding: 5px;">KENDALA & RENCANA TINDAK LANJUT</td>
                  <td rowspan="2" class="text-center font-700 align-middle" style="padding: 5px;">STATUS APPROVAL</td>
                </tr>
                <tr>
                  <td class="text-center font-700 align-middle" style="padding: 5px;">TARGET</td>
                  <td class="text-center font-700 align-middle" style="padding: 5px;">REALISASI</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nomor Kontrak</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.no_kontrak }}</td>
                  <td class="text-center">{{ targetPlan.plan??0 }}%</td>
                  <td class="text-center">{{ model.total_progress | globalCurrency }}%</td>
                  <td class="text-center" rowspan="17" v-html="model.problem"></td>
                  <td rowspan="17" class="text-center">
                    <div v-if="model.col_status == 'approved'">
                      <h5 class="mb-0"><strong>Approved By</strong></h5>
                      <vue-qrcode
                        :value="`${model.approve_by.name} (${model.approve_by.position_name}) ${model.approve_by.phone}`"
                        width="170"/>
                      <h5 class="text-center">{{ model.approve_by.name }}</h5>
                      <h5 class="text-center">{{ model.approve_by.phone }}</h5>
                      <h5 class="text-center">History Approval Terlampir</h5>
                    </div>
                    <div v-else>
                      <h5 class="mb-0 text-capitalize"><strong>{{ model.col_status }}</strong></h5>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>No. PRK</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.no_prk }}</td>
                  <td class="text-center" rowspan="16" colspan="2" v-html="model.description"></td>
                </tr>
                <tr>
                  <td>Project Code PMO</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.project_code_pmo }}</td>
                </tr>
                <tr>
                  <td>Contract Code PMO</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.contact_code_pmo }}</td>
                </tr>
                <tr>
                  <td>SPMK</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.no_spmk }}</td>
                </tr>
                <tr>
                  <td>Akhir Kontrak</td>
                  <td class="text-center">:</td>
                  <td>{{ moment(model.kontrak.tgl_akhir).locale('id').format('DD MMMM YYYY')}}</td>
                </tr>
                <tr>
                  <td>UPP</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.col_unit }}</td>
                </tr>
                <tr>
                  <td>Kontraktor</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.col_kontraktor_name }}</td>
                </tr>
                <tr>
                  <td>Lingkup</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.lingkup }}</td>
                </tr>
                <tr>
                  <td>Kapasitas</td>
                  <td class="text-center">:</td>
                  <td>{{ model.kontrak.kapasitas }} {{ model.kontrak.id_pekerjaan &lt; 5 ? 'MVA' : 'KMS' }}</td>
                </tr>
                <tr>
                  <td>Nilai Kontrak Original</td>
                  <td class="text-center">:</td>
                  <td>{{ (model.kontrak.original_nilai || 0) | currency }}</td>
                </tr>
                <tr>
                  <td>Nilai Kontrak Terakhir</td>
                  <td class="text-center">:</td>
                  <td>{{ (model.kontrak.nilai || 0) | currency }}</td>
                </tr>
                <tr>
                  <td>Realisasi Pembayaran</td>
                  <td class="text-center">:</td>
                  <td>Rp. {{ model.kontrak.col_kontrak_progress_pembayaran_num }} ({{ model.kontrak.col_kontrak_progress_pembayaran_percentage }})</td>
                </tr>
                <tr>
                  <td>Akhir BG UM</td>
                  <td class="text-center">:</td>
                  <td v-if="model.kontrak.col_uang_muka">{{ moment(model.kontrak.col_uang_muka.tanggal_berakhir).locale('id').format('DD MMMM YYYY') }}</td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>Akhir BG Pelaksanaan</td>
                  <td class="text-center">:</td>
                  <td v-if="model.kontrak.col_pelaksanaan">{{ moment(model.kontrak.col_pelaksanaan.tanggal_berakhir).locale('id').format('DD MMMM YYYY') }}</td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>Jumlah Pekerja</td>
                  <td class="text-center">:</td>
                  <td>{{ model.workers | numFormat }}</td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mt-1"><i>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</i></p>
              </div>
              <img src="/assets/img/ipm-min.png" alt="" width="80">
            </div>
          </section>

          <section name="page-2_kurva-s-dokumentasi">
            <div class="html2pdf__page-break"></div>

            <div class="d-flex justify-content-between align-items-center pt-3">
              <div>
                <p class="mb-0" style="font-size: 22px; color: #009ca8; font-weight: 700;">{{ model.kontrak.judul_kontrak }}</p>
                <p class="mb-0 text-capitalize" style="color: #009ca8; font-weight: 700;">Periode Progress {{ model.name }}</p>
              </div>
              <img src="/assets/img/logos.png" alt="">
            </div>

            <div class="row">
              <div class="col-8">
                <div
                  class="text-center text-primary font-600 font-medium-1 py-1"
                  style="background-color: #eaf5f8">
                  Live S Curve
                </div>
                <apexchart
                  id="Chart_Live_S_Curve"
                  ref="chartAmandemen"
                  type="line"
                  height="350"
                  style="width: 1000px;"
                  :options="lievCurvaOPt"
                  :series="liveSeriesData"/>
              </div>
              <div class="col-4" style="border-left: 1px solid #000;">
                <div
                  class="text-center text-primary font-600 font-medium-1 py-1"
                  style="background-color: #eaf5f8">
                  Dokumentasi
                </div>
                <div v-for="(i, index) in model.documents" :key="index">
                  <img
                    :src="i.url"
                    width="170"
                    height="100"
                    class="rounded mt-1"
                    alt=""
                    style="object-fit: cover;"
                  />
                <!-- <div v-for="(i, index) in base64DocumentImages" :key="index">
                  <img
                    :src="i"
                    width="170"
                    height="100"
                    class="rounded mt-1"
                    alt=""
                    style="object-fit: cover;"
                  /> -->
              </div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mt-1"><i>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</i></p>
              </div>
              <img src="/assets/img/ipm-min.png" alt="" width="80">
            </div>
          </section>

          <section name="page-3_kemajuan-progress"
            v-for="(progress, iz) in chunkedProgressKemajuan" :key="iz">
            <div class="html2pdf__page-break"></div>

            <div class="d-flex justify-content-between align-items-center pt-3">
              <div>
                <p class="mb-0" style="font-size: 22px; color: #009ca8; font-weight: 700;">{{ model.kontrak.judul_kontrak }}</p>
                <p class="mb-0 text-capitalize" style="color: #009ca8; font-weight: 700;">Periode Progress {{ model.name }}</p>
              </div>
              <img src="/assets/img/logos.png" alt="">
            </div>

            <h5 class="mt-2"><strong>Kemajuan Progress</strong></h5>
            <div class="table-responsive no-sort">
              <table class="table tabel-status-document w-100">
                <thead>
                  <tr>
                    <th width="50%">Uraian Pekerjaan</th>
                    <th>Minggu Sebelumnya</th>
                    <th>Minggu Saat Ini</th>
                    <th>Kemajuan Progress</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in progress" :key="index">
                    <td>{{ data.name }}</td>
                    <td>
                      <div class="progress-circle p10">
                        <span><strong>{{Number(data.last_progress.toFixed(3))}}</strong></span>
                        <div class="left-half-clipper">
                          <div class="first50-bar"></div>
                          <div v-if="data.last_progress > 0" class="value-bar"></div>
                          <div v-if="data.last_progress < 0" class="value-bars"></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="progress-circle p10">
                        <span><strong>{{Number(data.progress.toFixed(3)) }}</strong></span>
                        <div class="left-half-clipper">
                          <div class="first50-bar"></div>
                          <div v-if="data.progress > 0" class="value-bar"></div>
                          <div v-if="data.progress < 0" class="value-bars"></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="progress-circle p10">
                        <span><strong>{{Number(data.completion_rate.toFixed(3)) }}</strong></span>
                        <div class="left-half-clipper">
                          <div class="first50-bar"></div>
                          <div v-if="data.completion_rate > 0" class="value-bargreen"></div>
                          <div v-if="data.completion_rate < 0" class="value-bars"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mt-1"><i>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</i></p>
              </div>
              <img src="/assets/img/ipm-min.png" alt="" width="80">
            </div>
          </section>

          <section name="page-4_deviasi"
            v-for="(progress, iz) in chunkedDeviasi" :key="iz">
            <div class="html2pdf__page-break"></div>
            <div class="d-flex justify-content-between align-items-center pt-3">
              <div>
                <p class="mb-0" style="font-size: 22px; color: #009ca8; font-weight: 700;">{{ model.kontrak.judul_kontrak }}</p>
                <p class="mb-0 text-capitalize" style="color: #009ca8; font-weight: 700;">Periode Progress {{ model.name }}</p>
              </div>
              <img src="/assets/img/logos.png" alt="">
            </div>

            <h5 class="mt-2"><strong>Deviasi Progress</strong></h5>
            <div class="table-responsive no-sort">
              <table class="table tabel-status-document w-100">
                <thead>
                  <tr>
                    <th width="50%">Uraian Pekerjaan</th>
                    <th>Progress Aktual</th>
                    <th>Plan Progress</th>
                    <th>Deviasi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in progress"
                    :key="index"
                  >
                    <td>{{ data.name }}</td>
                    <td>
                      <div class="progress-circle p10">
                        <span><strong>{{Number(data.progress.toFixed(3))}}</strong></span>
                        <div class="left-half-clipper">
                          <div class="first50-bar"></div>
                          
                          <div v-if="data.progress > 0" class="value-bar"></div>
                          <div v-if="data.progress < 0" class="value-bars"></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="progress-circle p10">
                        <span><strong>{{Number(data.plan.toFixed(3))}}</strong></span>
                        <div class="left-half-clipper">
                          <div class="first50-bar"></div>
                          
                          <div v-if="data.plan > 0" class="value-bar"></div>
                          <div v-if="data.plan < 0" class="value-bars"></div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="progress-circle p10">
                        <span><strong>{{Number(data.deviasi.toFixed(3))}}</strong></span>
                        <div class="left-half-clipper">
                          <div class="first50-bar"></div>
                          <div v-if="data.deviasi > 0" class="value-bargreen"></div>
                          <div v-else class="value-bars"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mt-1"><i>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</i></p>
              </div>
              <img src="/assets/img/ipm-min.png" alt="" width="80">
            </div>
          </section>

          <section name="page-5_history-catatan"
            v-for="(catatan, iz) in chunkedCatatan" :key="iz">
            <div class="html2pdf__page-break"></div>
            <div class="d-flex justify-content-between align-items-center pt-3">
              <div>
                <p class="mb-0" style="font-size: 22px; color: #009ca8; font-weight: 700;">{{ model.kontrak.judul_kontrak }}</p>
                <p class="mb-0 text-capitalize" style="color: #009ca8; font-weight: 700;">Periode Progress {{ model.name }}</p>
              </div>
              <img src="/assets/img/logos.png" alt="">
            </div>
            <h5 class="mt-2"><strong>History Catatan</strong></h5>
            <div>
              <div v-for="(i, index) in catatan" :key="index">
                <div class="row">
                  <div class="col-3">
                    <p class="mb-0"><b>{{ moment(i.created_at).locale("id").format('DD MMMM YYYY') }}</b></p>
                    <p class="mb-0"><b>{{ moment(i.created_at).locale("id").format('HH:mm:ss')}}</b></p>
                  </div>
                  <div class="col-9">
                    <p class="mb-0"><b>{{ i.user_name }} - ({{ i.user_position }})</b></p>
                    <p class="mb-0">
                      <span v-if="i.col_status == 'approved'" class="statusbg approve">Approved</span>
                      <span v-if="i.col_status == 'waiting for approval'" class="statusbg waiting">Waiting For Approval</span>
                      <span v-if="i.col_status == 'return to conceptor'" class="statusbg conceptor">Return To Conceptor</span>
                      <span v-if="i.col_status == 'not approved'" class="statusbg not_approve">Not Approved</span>
                    </p>
                    <p class="mb-0">
                      <span class="text-capitalize">
                        {{ i.remarks }}
                      </span>
                      <br v-if="i.remarks != null">
                      <span v-if="i.notes != null" class="text-capitalize">
                        Notes : {{ i.notes }}
                      </span>
                    </p>
                  </div>
                </div>
                <hr v-if="index != histories.length-1">
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="mt-1"><i>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</i></p>
              </div>
              <img src="/assets/img/ipm-min.png" alt="" width="80">
            </div>
          </section>

          <section name="page-6_detail-progress" 
            v-for="(progress, iz) in chunkedProgress" :key="iz">
            <div class="html2pdf__page-break"></div>
            <div class="d-flex justify-content-between align-items-center pt-3">
              <div>
                <p class="mb-0" style="font-size: 22px; color: #009ca8; font-weight: 700;">{{ model.kontrak.judul_kontrak }}</p>
                <p class="mb-0 text-capitalize" style="color: #009ca8; font-weight: 700;">Periode Progress {{ model.name }}</p>
              </div>
              <img src="/assets/img/logos.png" alt="">
            </div>
            <h5 class="mt-2"><strong>Detail Progress</strong></h5>
            <div class="table-responsive no-sort">
              <table
                class="table tabel-status-document w-100"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Uraian Pekerjaan</th>
                    <th>Satuan</th>
                    <th>Qty Kontrak</th>
                    <th>Bobot (%)</th>
                    <th>Kumulatif Quantity<br />Sampai Saat Ini</th>
                    <th>Progress (%)</th>
                    <th>Tingkat<br />Penyelesaian (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(data, index) in progress"
                    :key="index"
                  >
                    <td>{{ data.no }}</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.unit }}</td>
                    <td>{{ data.quantity }}</td>
                    <td>{{ Number(data.weight.toFixed(3)) || 0 }}%</td>
                    <td>
                      <input
                        v-model="
                          model.progress[index].cumulative_quantity
                        "
                        @keyup="
                          calculate(
                            index,
                            model.progress[index].cumulative_quantity
                          )
                        "
                        readonly
                        class="form-control"
                      />
                    </td>
                    <td>
                      {{ Number(data.progress.toFixed(3)) || 0 }}%
                    </td>
                    <td>
                      {{
                        Number(data.completion_rate.toFixed(3)) || 0
                      }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-between align-items-center" v-if="iz != chunkedProgress.length-1">
              <div>
                <p class="mt-1"><i>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</i></p>
              </div>
              <img src="/assets/img/ipm-min.png" alt="" width="80">
            </div>
          </section>

          <div class="d-flex justify-content-left" style="background: #eaf5f8 !important">
            <div class="col-md-9 py-2">
              <span style="font-size: 15px" class="text-blue">
                  <strong>Grand Total</strong>
              </span>
            </div>
            <div class="col-lg-3 col-md-3 py-2">
                <span style="font-size: 15px" class="text-blue">
                  <strong>{{ Number(model.total_progress.toFixed(3)) || 0 }}%</strong>
                </span>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <p class="mt-1"><i>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</i></p>
            </div>
            <img src="/assets/img/ipm-min.png" alt="" width="80">
          </div>
        </div>
      </vue-html2pdf>

      <section id="basic-datatable">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body card-dashboard">
                  <div class="row">
                    <div class="col-md-6">
                      <span
                        class="statusbg pl-4 pr-4 approve text-capitalize"
                        style="padding-top: 10px; padding-bottom: 10px"
                        v-if="model.col_status == 'approved'"
                        >Approved</span
                      >
                      <span
                        class="statusbg pl-4 pr-4 waiting text-capitalize"
                        style="padding-top: 10px; padding-bottom: 10px"
                        v-if="model.col_status == 'waiting for approval'"
                        >Waiting For Approval</span
                      >

                      <span
                        class="statusbg pl-4 pr-4 not_approve text-capitalize"
                        style="padding-top: 10px; padding-bottom: 10px"
                        v-if="model.col_status == 'not approved'"
                        >Not Approved</span
                      >
                      <span
                        class="statusbg pl-4 pr-4 conceptor text-capitalize"
                        style="padding-top: 10px; padding-bottom: 10px"
                        v-if="model.col_status == 'return to conceptor'"
                        >Return To Conceptor</span
                      >
                      <h5 class="mt-2"><strong>Periode Progress</strong></h5>
                      <p class="text-capitalize">{{ model.name }}</p>

                      <h5 class="mt-2"><strong>Uraian atau Deskripsi Singkat Pekerjaan</strong></h5>
                      <p class="m-0" v-html="model.description"></p>

                      <h5 class="mt-2"><strong>Kendala dan Rencana Tindak Lanjut</strong></h5>
                      <p class="m-0" v-html="model.problem">

                      <h5 class="mt-2"><strong>Jumlah Pekerja</strong></h5>
                      <p>{{ model.workers }}</p>

                      <h5 class="mt-2"><strong>Foto</strong></h5>
                      <div class="row">
                        <div class="col-md-4 mb-1" v-for="(i, index) in model.documents" :key="index">
                           <img
                              :src="i.url"
                              width="170"
                              height="100"
                              class="rounded"
                              alt=""
                              style="cursor: zoom-in"
                              data-dismiss="modal"
                              data-toggle="modal"
                              data-target="#Modal_DetailFoto"
                              @click="
                                (detailFoto = model.documents), (showImage = index)
                              "
                            />
                        </div>
                      </div>

                      <div class="row" v-if="model.col_status == 'approved'">
                        <div class="col-md-4 text-center">
                          <h5 class="mt-2 mb-0">
                            <strong>Approved By</strong>
                          </h5>
                          <vue-qrcode
                            :value="
                              model.approve_by.name +
                              ' (' +
                              model.approve_by.position_name +
                              ') ' +
                              model.approve_by.phone
                            "
                            width="170"
                          />
                          <h5 class="text-center">{{ model.approve_by.name }}</h5>
                          <h5 class="text-center">{{ model.approve_by.phone }}</h5>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <h5 class="mt-2"><strong>History Catatan</strong></h5>
                      <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
                        <div v-for="(i, index) in histories" :key="index">
                          <div class="row">
                            <div class="col-3">
                              <p class="mb-0"><b>{{ moment(i.created_at).locale("id").format('DD MMMM YYYY') }}</b></p>
                              <p class="mb-0"><b>{{ moment(i.created_at).locale("id").format('HH:mm:ss')}}</b></p>
                            </div>
                            <div class="col-9">
                              <p class="mb-0"><b>{{ i.user_name }} - ({{ i.user_position }})</b></p>
                              <p class="mb-0">
                                <span v-if="i.col_status == 'approved'" class="statusbg approve">Approved</span>
                                <span v-if="i.col_status == 'waiting for approval'" class="statusbg waiting">Waiting For Approval</span>
                                <span v-if="i.col_status == 'return to conceptor'" class="statusbg conceptor">Return To Conceptor</span>
                                <span v-if="i.col_status == 'not approved'" class="statusbg not_approve">Not Approved</span>
                              </p>
                              <p class="mb-0">
                                <span class="text-capitalize">
                                  {{ i.remarks }}
                                </span>
                                <br v-if="i.remarks != null">
                                <span v-if="i.notes != null" class="text-capitalize">
                                  Notes : {{ i.notes }}
                                </span>
                              </p>
                            </div>
                          </div>
                          <hr v-if="index != histories.length-1">
                        </div>
                      </div>
                    </div> 

                    <div class="col-10">
                      <h5 class="mt-2"><strong>Progress Total <span class="text-danger">*</span></strong></h5>
                      <div class="progress progress-bar-primary mb-1">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :aria-valuenow="model.total_progress"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="{ width: model.total_progress + '%' }"
                        ></div>
                      </div>
                      <p><i><b>Disclaimer: Progress Total di IPM ini merupakan progress estimasi yang merepresentasikan kondisi aktual di lapangan berdasarkan amandemen terakhir</b></i></p>
                    </div>

                    <div class="col-2">
                      <h5 class="mt-5">
                        <strong>{{
                          Number(model.total_progress.toFixed(3)) + "%"
                        }}</strong>
                      </h5>
                    </div>

                    <div class="col-12">
                      <h5 class="mt-2"><strong>Kemajuan Progress</strong></h5>
                      <div class="table-responsive no-sort tableFixHead">
                        <table class="table tabel-status-document w-100">
                          <thead>
                            <tr>
                              <th width="50%">Uraian Pekerjaan</th>
                              <th>Minggu Sebelumnya</th>
                              <th>Minggu Saat Ini</th>
                              <th>Kemajuan Progress</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in progressKemajuan"
                              :key="index"
                            >
                              <td>{{ data.name }}</td>
                              <td>
                                <vue-ellipse-progress
                                  :size="50"
                                  fontSize="0.8rem"
                                  :thickness="7"
                                  :color="data.last_progress >= 0 ? '#37AAE1' : '#E32323'"
                                  :progress="
                                    Number(data.last_progress.toFixed(3))
                                  "
                                >
                                <span><strong>{{data.last_progress}}</strong></span>
                                </vue-ellipse-progress>
                              </td>
                              <td>
                                <vue-ellipse-progress
                                  :size="50"
                                  fontSize="0.8rem"
                                  :thickness="7"
                                  :color="data.progress >= 0 ? '#37AAE1' : '#E32323'"
                                  :progress="
                                    Number(data.progress.toFixed(3))
                                  "
                                >
                                <span><strong>{{data.progress}}</strong></span>
                                </vue-ellipse-progress>
                              </td>
                              <td>
                                <vue-ellipse-progress
                                  :size="50"
                                  fontSize="0.8rem"
                                  :thickness="7"
                                  :color="data.completion_rate >= 0 ? '#6cca00' : '#E32323'"
                                  :progress="
                                    Number(data.completion_rate.toFixed(3))
                                  "
                                >
                                <span><strong>{{data.completion_rate}}</strong></span>
                                </vue-ellipse-progress>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12">
                      <h5 class="mt-2"><strong>Deviasi Progress</strong></h5>

                      <div class="table-responsive no-sort tableFixHead">
                        <table class="table tabel-status-document w-100">
                          <thead>
                            <tr>
                              <th width="50%">Uraian Pekerjaan</th>
                              <th>Progress Aktual</th>
                              <th>Plan Progress</th>
                              <th>Deviasi</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in deviasi"
                              :key="index"
                            >
                              <td>{{ data.name }}</td>
                              <td>
                                <vue-ellipse-progress
                                  :size="50"
                                  fontSize="0.8rem"
                                  :thickness="7"
                                  :color="data.completion_rate >= 0 ? '#37AAE1' : '#E32323'"
                                  :progress="
                                    Number(data.progress.toFixed(3))
                                  "
                                >
                                <span><strong>{{data.progress}}</strong></span>
                                </vue-ellipse-progress>
                              </td>
                              <td>
                                <vue-ellipse-progress
                                  :size="50"
                                  fontSize="0.8rem"
                                  :thickness="7"
                                  :color="data.plan >= 0 ? '#37AAE1' : '#E32323'"
                                  :progress="Number(data.plan.toFixed(3))"
                                >
                                <span><strong>{{data.plan}}</strong></span>
                                </vue-ellipse-progress>
                              </td>
                              <td>
                                <vue-ellipse-progress
                                  :size="50"
                                  fontSize="0.8rem"
                                  :thickness="7"
                                  :color="data.deviasi >= 0 ? '#6cca00' : '#E32323'"
                                  :progress="Number(data.deviasi.toFixed(3))"
                                >
                                <span><strong>{{data.deviasi}}</strong></span>
                                </vue-ellipse-progress>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12 mt-2">
                      <div
                        class="row align-items-center justify-content-between"
                      >
                        <div class="col-12 col-sm-8 col-lg-6 mb-1">
                          <h5 class="mt-2"><strong>Detail Progress</strong></h5>
                        </div>
                        <div class="col-12 col-sm-4">
                          <div class="input-group form-search-rounded">
                            <input
                              class="form-control border-right-0 border"
                              type="search"
                              v-model="progressSQ"
                              @input="progressSearch"
                              placeholder="Cari .."
                            />
                            <span class="input-group-append">
                              <div class="input-group-text bg-transparent">
                                <a href="#" class="text-blue"
                                  ><i class="fa fa-search"></i
                                ></a>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive no-sort tableFixHead">
                        <table
                          class="table tabel-status-document w-100"
                          v-if="model.progress.length > 0 && progressSQ == ''"
                        >
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Uraian Pekerjaan</th>
                              <th>Satuan</th>
                              <th>Qty Kontrak</th>
                              <th>Bobot (%)</th>
                              <th>Kumulatif Quantity<br />Sampai Saat Ini</th>
                              <th>Progress (%)</th>
                              <th>Tingkat<br />Penyelesaian (%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in model.progress"
                              :key="index"
                            >
                              <td>{{ data.no }}</td>
                              <td>{{ data.name }}</td>
                              <td>{{ data.unit }}</td>
                              <td>{{ data.quantity }}</td>
                              <td>{{ Number(data.weight.toFixed(3)) || 0 }}%</td>
                              <td>
                                <input
                                  v-model="
                                    model.progress[index].cumulative_quantity
                                  "
                                  @keyup="
                                    calculate(
                                      index,
                                      model.progress[index].cumulative_quantity
                                    )
                                  "
                                  readonly
                                  class="form-control"
                                />
                              </td>
                              <td>
                                {{ Number(data.progress.toFixed(3)) || 0 }}%
                              </td>
                              <td>
                                {{
                                  Number(data.completion_rate.toFixed(3)) || 0
                                }}%
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          class="table tabel-status-document w-100"
                          v-else-if="
                            progressSQRes.length > 0 && progressSQ != ''
                          "
                        >
                          <thead>
                            <tr>
                              <th>Unit Pekerjaan</th>
                              <th>Satuan</th>
                              <th>Qty Kontrak</th>
                              <th>Bobot (%)</th>
                              <th>Kumulatif Quantity<br />Sampai Saat Ini</th>
                              <th>Progress (%)</th>
                              <th>Tingkat<br />Penyelesaian (%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in progressSQRes"
                              :key="index"
                            >
                              <td>{{ data.name }}</td>
                              <td>{{ data.unit }}</td>
                              <td>{{ data.quantity }}</td>
                              <td>{{ Number(data.weight.toFixed(3)) || 0 }}%</td>
                              <td>
                                <input
                                  v-model="
                                    model.progress[index].cumulative_quantity
                                  "
                                  @keyup="
                                    calculate(
                                      index,
                                      model.progress[index].cumulative_quantity
                                    )
                                  "
                                  readonly
                                  type="number"
                                  class="form-control"
                                />
                              </td>
                              <td>
                                {{ Number(data.progress.toFixed(3)) || 0 }}%
                              </td>
                              <td>
                                {{
                                  Number(data.completion_rate.toFixed(3)) || 0
                                }}%
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table v-else class="table tabel-status-document w-100">
                          <thead>
                            <tr>
                              <th>Unit Pekerjaan</th>
                              <th>Satuan</th>
                              <th>Qty Kontrak</th>
                              <th>Bobot (%)</th>
                              <th>Kumulatif Quantity<br />Sampai Saat Ini</th>
                              <th>Progress (%)</th>
                              <th>Tingkat<br />Penyelesaian (%)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colspan="7" class="text-center">
                                Tidak ada data.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12" style="background: #eaf5f8 !important">

                      <div class="d-flex justify-content-left">
                        <div class="col-md-9 py-2">
                          <span style="font-size: 15px" class="text-blue">
                              <strong>Grand Total</strong>
                          </span>
                        </div>
                        <div class="col-lg-3 col-md-3 py-2">
                            <span style="font-size: 15px" class="text-blue">
                              <strong>{{ Number(model.total_progress.toFixed(3)) || 0 }}%</strong>
                            </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-2" v-can="'kontrak/detail/progress/approve'">
                      <div class="row" v-if="model.col_status == 'waiting for approval'">
                        <div class="col col-md-4">
                          <button
                            @click="approvalModal(-1)"
                            class="btn btn-danger"
                            style="width: 100%"
                          >
                            <span class="text-white"
                              ><strong>Not Approve</strong></span
                            >
                          </button>
                        </div>
                        <div class="col col-md-4">
                          <button
                            @click="approvalModal(-2)"
                            class="btn btn-warning"
                            style="width: 100%"
                          >
                            <span class="text-white"
                              ><strong>Return To Conceptor</strong></span
                            >
                          </button>
                        </div>
                        <div class="col col-md-4">
                          <button
                            @click="approvalModal(1)"
                            class="btn btn-primary"
                            style="width: 100%"
                          >
                            <span class="text-white"><strong>Approve</strong></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div
      class="modal fade text-left"
      id="Modal_DetailFoto"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      v-if="detailFoto != ''"
      style="overflow: auto !important"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">Detail Foto</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="detailFoto = ''"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  :data-slide-to="index"
                  v-for="(data, index) in detailFoto"
                  :key="index"
                  :class="showImage == index ? 'active' : ''"
                ></li>
              </ol>
              <div class="carousel-inner">
                <div
                  class="carousel-item"
                  :class="showImage == index ? 'active' : ''"
                  v-for="(data, index) in detailFoto"
                  :key="index"
                >
                  <img class="d-block w-100" :src="data.url" />
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div 
      class="modal fade text-left" 
      id="Modal_Approve" 
      aye="mdaman" 
      tabindex="-1" 
      role="dialog"
      aria-labelledby="myModalLabel160" 
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
              <div class="modal-header modal-primary white">
                  <h5 class="modal-title">Notes</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                  <div class="modal-body">
                      <div class="row">
                          <div class="col-12">
                              <div class="form-group">
                                  <ValidationProvider name="Notes" rules="required" v-slot="{ errors }">
                                  <label for="">Notes</label>
                                  <textarea cols="30" rows="5" v-model="dataApprove.noteApproval" class="form-control" aria-describedby="helpId"></textarea>
                                  <small>{{ errors[0] }}</small>
                                  </ValidationProvider>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="modal-footer">
                      <button v-if="true" @click="approve" type="button" :disabled="invalid" class="btn btn-primary">
                          <span>Submit</span>
                      </button>
                      <button v-else type="button" disabled class="btn btn-primary">
                          <div class="mx-auto text-center" style="width:100%">
                              <i class="fa fa-spinner fa-spin text-white"></i>
                          </div>
                      </button>
                  </div>
              </ValidationObserver>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// import TableV2 from '@/components/TableV2.vue'
import ld from "lodash";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
// import ContentToPrint from "./ProgressDetailv2Print";

export default {
  name: "TambahProgressIndex",
  components: {
    VueHtml2pdf,
    // ContentToPrint,
  },
  data() {
    return {
      newDocuments:[],
      fill: { gradient: ["red", "green", "blue"] },
      breadcumb: [],
      kontrakModel:{},
      role_id: localStorage.getItem("role_id"),
      dropzoneOptions: {
        url: this.$baseUrl + "/kontrak",
        acceptedFiles: "image/*",
        thumbnailWidth: 150,
        autoProcessQueue: false,
        uploadMultiple: true,
        addRemoveLinks: true,
        paramName: "image",
      },
      filter_curva_s: "monthly",
      filter_aman_name: null,
      filter_aman_id: null,
      COL_MULT: 200,
      liveSeriesData: [
        {
          name: "Plan",
          data: [],
        },
        {
          name: "Actual",
          data: [],
        },
      ],
      lievCurvaOPt: {},
      categoriesChart: [],
      dataApprove:{
        noteApproval:null,
        status:null,
      },
      progressSQ: "",
      progressSQRes: [],
      detailFoto: "",
      showImage: 0,
      totalProgress: 0,
      tableUpdateProgress: {
        list: [],
        columns: [
          "name_resume",
          "no_prk",
          "nilai_kontrak",
          "col_ready_at",
          "status",
          "progress_fisik",
          "progress_bayar",
        ],
      },
      model: {},
      histories:[],
      progressKemajuan:[],
      deviasi:[],
      moment: moment,
      generateProgress: null,
      targetPlan: {},
    };
  },
  methods: {
    onProgress(ev) {
      this.generateProgress = ev
    },
    generatePDF() {
      this.generateProgress = 10;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 100);
    },
    progressSearch: ld.debounce(function () {
      let _ = this;
      if (_.progressSQ != "") {
        var res = _.model.progress;
        let newRes = ld.filter(res, function (o) {
          return o.name.toLowerCase().indexOf(_.progressSQ.toLowerCase()) >= 0;
        });
        _.progressSQRes = newRes;
      }
    }, 100),
    async get() {
      let _ = this;
      await _.axios
        .get("/kontrak?id=" + _.$route.params.id)
        .then((resp) => {
          this.kontrakModel = resp.data.data.rows;
          let data_bread = resp.data.data.rows;
          _.breadcumb = [
            {
              name: "Monitoring Project",
              link: "/kontrak",
              active: false,
            },
            {
              name: "Project List",
              link: "/kontrak",
              active: false,
            },
            {
              name: data_bread.no_kontrak,
              link: "/kontrak/" + _.$route.params.id,
              active: false,
            },
            {
              name: this.$route.query.edit
                ? "Edit Progress"
                : "Tambah Progress",
              link: "",
              active: true,
            },
          ];
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    cetak_pdf() {
      let _ = this;
      _.axios
        .get("v2/kontrak/curva-s/" + _.$route.params.id_curva + "/pdf", {
          responseType: "arraybuffer",
        })
        .then((resp) => {
          let blob = new Blob([resp.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "LaporanProject-" + _.model.name + ".pdf";
          link.click();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },


    async approvalModal(params)
    {
      this.dataApprove.status = params
      if (params != 1) {
        window.$('#Modal_Approve').modal('show') 
      }else{
        this.approve()
      }
    },
    async approve() {
      try {
        const datas = (
          await this.axios.put(
            `/v2/kontrak/curva-s/${this.model.id_progress_note}/status`,
            {
              status: this.dataApprove.status,
              notes: this.dataApprove.noteApproval
            }
          )
        ).data;
        if (!datas.status) throw "Error";
        window.$('#Modal_Approve').modal('hide') 
        this.get();
        this.getCurva();
        this.loadHistories()
      } catch (error) {
        console.log(error);
      }
    },

    async loadHistories() {
      let _ = this
      try {
        const datas = (
          await this.axios.get(
            "v2/kontrak/curva-s/" + _.$route.params.id_curva + "/histories"
          )
        ).data;
        if (!datas.status) throw "Error";
        this.histories = datas.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadStatePage = false;
      }
    },

    async loadDocForPdf() {
      let _ = this
      try {
        const datas = (
          await this.axios.get(
            "v2/kontrak/curva-s/" + _.$route.params.id_curva + "/document"
          )
        ).data;
        if (!datas.status) throw "Error";
        this.newDocuments = datas.data;
        console.log(this.newDocuments);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadStatePage = false;
      }
    },

    async getCurva() {
      let _ = this;
      await _.axios
        .get("v2/kontrak/curva-s/" + _.$route.params.id_curva)
        .then((resp) => {
          _.model = resp.data.data;

          let pro = _.model.progress.filter(item => {
              return (item.completion_rate !== 0);
          });
          _.progressKemajuan = pro;

          let dev = _.model.progress.filter(item => {
              return (item.deviasi !== 0);
          });
          _.deviasi = dev;

          _.model.progress.sort(function (a, b) {
              if (a.weight > b.weight) return -1;
              if (a.weight < b.weight) return 1;
          });
          
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getChartAmandemen(filter) {
      let _ = this;
      let filter_value = filter ? filter : this.filter_curva_s;
      this.filter_curva_s = filter_value;
      let url = "";
      if (this.filter_aman_id) {
        url =
          "v2/kontrak/curva-s/chart?id_kontrak=" +
          _.$route.params.id +
          "&type=" +
          filter_value +
          "&id_kontrak_amandemen=" +
          this.filter_aman_id;
      } else {
        url =
          "v2/kontrak/curva-s/chart?id_kontrak=" +
          _.$route.params.id +
          "&type=" +
          filter_value +
          "&id_kontrak_amandemen=0";
      }
      _.axios
        .get(url)
        .then((resp) => {
          _.amandemen_chart_list = resp.data.data;

          let plan = [];
          let cat = [];
          let actual = [];

          if (filter_value == "monthly") {
            _.amandemen_chart_list.forEach((element) => {
              if (element.is_show) {
                actual.push(element.actual);
              }
              plan.push(element.plan);
              cat.push(
                moment.monthsShort(element.month - 1) + "-" + element.year
              );
            });
          } else {
            _.amandemen_chart_list.forEach((element) => {
              if (element.is_show) {
                actual.push(element.actual);
              }
              plan.push(element.plan);
              cat.push(
                moment.monthsShort(element.month - 1) + "-" + element.year
              );
            });
          }

          let actual_format = [];
          if (actual.find((element) => element > 0)) {
            actual_format = actual;
          } else {
            actual_format = [];
          }

          let plan_format = [];
          if (plan.find((element) => element > 0)) {
            plan_format = plan;
          } else {
            plan = [];
          }

          if (plan_format.length > 6) {
            this.COL_MULT = 100;
          } else {
            this.COL_MULT = 200;
          }

          this.categoriesChart = cat;
          this.lievCurvaOPt = {
            chart: {
              type: "line",
              height: 350,
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            grid: {
              show: true,
              strokeDashArray: 4,
            },
            markers: {
              size: 5,
            },
            colors: ["#3099AC", "#F3CA27"],
            series: [
              {
                name: "Plan",
                data: plan_format,
              },
              {
                name: "Actual",
                data: actual_format,
              },
            ],
            annotations: {
              xaxis: this.liveSAnnotations(),
            },
            plotOptions: {},
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: true,
              fontSize: "13px",
              fontFamily: "Myriad Pro",
              fontWeight: 400,
              height: 50,
              offsetY: 10,
              itemMargin: {
                horizontal: 24,
              },
            },
            xaxis: {
              categories: cat,
              overwriteCategories: cat.map((o) => o.split("-")[0]),
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              tickPlacement: "between",
              labels: {
                rotate: 0,
                style: {
                  fontSize: "13px",
                  fontFamily: "Myriad Pro",
                  fontWeight: 600,
                },
              },
            },
            yaxis: {
              type: "numeric",
              tickAmount: 5,
              axisBorder: {
                show: false,
              },
              axisTicks: {
                show: false,
              },
              min: 0,
              max: 100,
              labels: {
                style: {
                  fontSize: "13px",
                  fontFamily: "Myriad Pro",
                  fontWeight: 600,
                },
              },
            },
            tooltip: {
              custom: function ({ series, dataPointIndex }) {
                if (series[1].length > 0) {
                  if (
                    parseFloat(Number(series[1][dataPointIndex].toFixed(3))) >=
                      0 &&
                    parseFloat(
                      Number(
                        series[1][dataPointIndex] - series[0][dataPointIndex]
                      ).toFixed(3)
                    ) > 0
                  ) {
                    return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                        <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                            <span class="float-right text-blue">${parseFloat(
                              series[1][dataPointIndex]
                            )}%</span>
                        </div>

                        <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-blue">${parseFloat(
                            Number(
                              series[1][dataPointIndex] -
                                series[0][dataPointIndex]
                            ).toFixed(3)
                          )}%</span>
                        </div>
                    </div>`;
                  } else if (
                    parseFloat(
                      Number(
                        series[1][dataPointIndex] - series[0][dataPointIndex]
                      ).toFixed(3)
                    ) < 0
                  ) {
                    return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                        <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                            <span class="float-right text-blue">${parseFloat(
                              series[1][dataPointIndex]
                            )}%</span>
                        </div>

                        <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-danger">${parseFloat(
                            Number(
                              series[1][dataPointIndex] -
                                series[0][dataPointIndex]
                            ).toFixed(3)
                          )}%</span>
                        </div>
                    </div>`;
                  } else {
                    return `<div class="cards">
                        <div class="px-1 pt-1">
                          <span class="float-left">Plan</span>
                          <span class="float-right text-blue">${parseFloat(
                            series[0][dataPointIndex]
                          )}%</span>
                        </div>

                      <div class="px-1 py-1">
                          <span class="float-left">Actual</span>
                          <span class="float-right text-danger">${parseFloat(
                            series[1][dataPointIndex]
                          )}%</span>
                      </div>

                      <div class="px-1 py-1">
                          <span class="float-left">Deviasi</span>
                          <span class="float-right text-blue">${parseFloat(
                            Number(
                              series[1][dataPointIndex] -
                                series[0][dataPointIndex]
                            ).toFixed(3)
                          )}%</span>
                        </div>
                  </div>`;
                  }
                } else {
                  return `<div class="cards">
                      <div class="px-1 pt-1">
                        <span class="float-left">Plan</span>
                        <span class="float-right">${parseFloat(
                          series[0][dataPointIndex]
                        )}%</span>
                      </div>
                      <div class="px-1 py-1">
                            <span class="float-left">Actual</span>
                      </div>
                      <div class="px-1 py-1">
                            <span class="float-left">Deviasi</span>
                      </div>
                    </div>`;
                }
              },
            },
          };
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    liveSDragScroll() {
      const ele = document.getElementById("Chart_Live_S_Curve");
      console.log(ele);
      let pos = { top: 0, left: 0, x: 0, y: 0 };
      const mouseDownHandler = function (e) {
        pos = {
          left: ele.scrollLeft,
          top: ele.scrollTop,
          x: e.clientX,
          y: e.clientY,
        };
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };
      const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        ele.scrollTop = pos.top - dy;
        ele.scrollLeft = pos.left - dx;
      };
      const mouseUpHandler = function () {
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);
      };
      ele.addEventListener("mousedown", mouseDownHandler);
    },

    liveSAnnotations() {
      const list = [];
      let i = 0;
      while (i < this.categoriesChart.length - 1) {
        const cat_year = Number(this.categoriesChart[i].split("-")[1]);
        const next_year = Number(this.categoriesChart[i + 1].split("-")[1]);
        if (next_year === cat_year + 1) {
          list.push({
            x: `Jan-${next_year}`,
            strokeDashArray: 0,
            borderColor: "transparent",
            fillColor: "transparent",
            offsetX: -40,
            offsetY: 30,
            label: {
              text: next_year.toString(),
              borderWidth: 0,
              position: "bottom",
              orientation: "horizontal",
              offsetX: -40,
              offsetY: 50,
              style: {
                background: "#b6b6b6",
                color: "#329FB7",
                fontSize: "13px",
                fontFamily: "Myriad Pro",
                fontWeight: 500,
              },
            },
          });
        }
        i++;
      }
      return list;
    },
    chunker(inputArray, perChunk) {  
      const result = inputArray.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)

        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])

      return result;
    },
    async toDataURL(url) {
      return await fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }))
    },
    getTargetPlan() {
      let _ = this;
      _.axios.get(`v2/kontrak/curva-s/target-plan?id_kontrak=${_.$route.params.id}&type=monthly`)
        .then((resp) => {
          _.targetPlan = resp.data.data
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },

  async mounted() {
    let _ = this;
    await _.get();
    await _.getCurva();
    await _.loadHistories();
    await _.loadDocForPdf()
    await _.getTargetPlan();
    await _.getChartAmandemen();
  },

  watch: {
    generateProgress: function() {
      if (this.generateProgress === 100) {
        this.generateProgress = null;
      }
    }
  },

  computed: {
    chunkedProgress: function() {
      const perChunk = 5;
      const inputArray = this.model.progress;
      return this.chunker(inputArray, perChunk);
    },
    chunkedProgressKemajuan: function() {
      const perChunk = 3;
      const inputArray = this.progressKemajuan;
      return this.chunker(inputArray, perChunk);
    },
    chunkedDeviasi: function() {
      const perChunk = 3;
      const inputArray = this.deviasi;
      return this.chunker(inputArray, perChunk);
    },
    chunkedCatatan: function() {
      const perChunk = 5;
      const inputArray = this.histories;
      return this.chunker(inputArray, perChunk);
    },
    base64DocumentImages: function() {
      const images = [];
      this.model.documents.forEach(async document => {
        images.push(await this.toDataURL(document.url))
      });
      return images;
    }
  }
};
</script>

<style scoped>
  .class-td{
    width: 71.5%
  }

.scrollable{
  overflow-y: auto;
  max-height: 700px;
}

.progress-circle {
   font-size: 10px;
   margin: 20px;
   position: relative; /* so that children can be absolutely positioned */
   padding: 0;
   width: 5em;
   height: 5em;
   background-color: #F2E9E1; 
   border-radius: 50%;
   line-height: 5em;
}

.progress-circle:after{
    border: none;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 4.3em;
    height: 4.3em;
    background-color: white;
    content: " ";
}
/* Text inside the control */
.progress-circle span {
    position: absolute;
    line-height: 5em;
    width: 5em;
    text-align: center;
    display: block;
    color: #53777A;
    z-index: 2;
}
.left-half-clipper { 
   /* a round circle */
   border-radius: 50%;
   width: 5em;
   height: 5em;
   position: absolute; /* needed for clipping */
   clip: rect(0, 5em, 5em, 2.5em); /* clips the whole left half*/ 
}
/* when p>50, don't clip left half*/
.progress-circle.over50 .left-half-clipper {
   clip: rect(auto,auto,auto,auto);
}
.value-bar {
   /*This is an overlayed square, that is made round with the border radius,
   then it is cut to display only the left half, then rotated clockwise
   to escape the outer clipping path.*/ 
   position: absolute; /*needed for clipping*/
   clip: rect(0, 2.5em, 5em, 0);
   width: 5em;
   height: 5em;
   border-radius: 50%;
   border: 0.45em solid #37AAE1; /*The border is 0.35 but making it larger removes visual artifacts */
   /*background-color: #4D642D;*/ /* for debug */
   box-sizing: border-box;
}

.value-bars {
   /*This is an overlayed square, that is made round with the border radius,
   then it is cut to display only the left half, then rotated clockwise
   to escape the outer clipping path.*/ 
   position: absolute; /*needed for clipping*/
   clip: rect(0, 2.5em, 5em, 0);
   width: 5em;
   height: 5em;
   border-radius: 50%;
   border: 0.45em solid #E32323; /*The border is 0.35 but making it larger removes visual artifacts */
   /*background-color: #4D642D;*/ /* for debug */
   box-sizing: border-box;
}

.value-bargreen {
   /*This is an overlayed square, that is made round with the border radius,
   then it is cut to display only the left half, then rotated clockwise
   to escape the outer clipping path.*/ 
   position: absolute; /*needed for clipping*/
   clip: rect(0, 2.5em, 5em, 0);
   width: 5em;
   height: 5em;
   border-radius: 50%;
   border: 0.45em solid #6cca00; /*The border is 0.35 but making it larger removes visual artifacts */
   /*background-color: #4D642D;*/ /* for debug */
   box-sizing: border-box;
}

/* Progress bar filling the whole right half for values above 50% */
.progress-circle.over50 .first50-bar {
   /*Progress bar for the first 50%, filling the whole right half*/
   position: absolute; /*needed for clipping*/
   clip: rect(0, 5em, 5em, 2.5em);
   background-color: #53777A;
   border-radius: 50%;
   width: 5em;
   height: 5em;
}
.progress-circle:not(.over50) .first50-bar{ display: none; }

.table-bordered th, .table-bordered td {
  border: 1px solid #000 !important;
  padding: 0px 10px;
}
</style>>
