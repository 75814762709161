<template>
    <div>
        <div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <ValidationObserver v-slot="{ invalid }" class="card-content">
                                <div class="card-body card-dashboard">
                                    <h3 class="font-bold text-primary mb-2">Edit Jabatan</h3>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider name="Nama" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Nama Jabatan</label>
                                                    <input type="text" v-model="model.name" id="" class="form-control" placeholder="Masukkan nama jabatan">
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="Role" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Role</label>
                                                    <select v-model="model.type" class="custom-select form-control">
                                                        <option value="">Pilih Role</option>
                                                        <option value="1">Pengawas</option>
                                                        <option value="2" selected>Non Pengawas</option>
                                                    </select>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider name="Unit" v-slot="{ errors }" rules="required">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Unit</label>
                                                    <select v-model="model.unit" class="custom-select form-control">
                                                        <option value="">Pilih Unit</option>
                                                        <option value="1">UIP</option>
                                                        <option value="2" selected>UPP</option>
                                                    </select>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider name="Bidang" v-slot="{ errors }" rules="">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Bidang</label>
                                                    <select v-model="model.id_bidang" class="form-control" :disabled="model.unit == ''">
                                                        <option value="">Pilih Bidang</option>
                                                        <option :value="bid.id" v-for="(bid, ix) in bidang.filter(x => x.unit == model.unit)" :key="ix">{{ bid.name }}</option>
                                                    </select>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6" v-if="model.unit == 1">
                                            <ValidationProvider name="Sub Bidang" v-slot="{ errors }" rules="">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Sub Bidang</label>
                                                    <select v-model="model.id_subbidang" class="form-control" :disabled="model.unit == ''">
                                                        <option value="">Pilih Sub Bidang</option>
                                                        <option :value="subbid.id" v-for="(subbid, ix) in subbidang.filter(x => x.unit == model.unit)" :key="ix">{{ subbid.name }}</option>
                                                    </select>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6" v-if="model.type == 1">
                                            <ValidationProvider name="Atasan" v-slot="{ errors }" rules="">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Atasan</label>
                                                    <select v-if="model.unit == 1" v-model="model.index" class="form-control" :disabled="model.type == ''">
                                                        <option value="">Pilih Atasan</option>
                                                        <option :value="pos.id" v-for="(pos, ix) in 
                                                            position" :key="ix">{{ pos.name }} - {{ pos.bidang_text }} - {{ pos.subbidang_text }}
                                                        </option>
                                                    </select>
                                                    <select v-else-if="model.unit == 2" v-model="model.index" class="form-control" :disabled="model.type == ''">
                                                        <option value="">Pilih Atasan</option>
                                                        <option :value="pos.id" v-for="(pos, ix) in 
                                                            position" :key="ix">{{ pos.name }} - {{ pos.bidang_text }} - {{ pos.subbidang_text }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6" v-else-if="model.type == 2">
                                            <ValidationProvider name="Atasan" v-slot="{ errors }" rules="">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label for="">Atasan</label>
                                                    <select v-if="model.unit == 1" v-model="model.index" class="form-control" :disabled="model.type == ''">
                                                        <option value="">Pilih Atasan</option>
                                                        <option :value="pos.id" v-for="(pos, ix) in 
                                                            position" :key="ix">{{ pos.name }} - {{ pos.bidang_text }} - {{ pos.subbidang_text }}
                                                        </option>
                                                    </select>
                                                    <select v-else-if="model.unit == 2" v-model="model.index" class="form-control" :disabled="model.type == ''">
                                                        <option value="">Pilih Atasan</option>
                                                        <option :value="pos.id" v-for="(pos, ix) in 
                                                            position" :key="ix">{{ pos.name }} - {{ pos.bidang_text }} - {{ pos.subbidang_text }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </ValidationProvider>
                                        </div>                                        
                                        <div class="col-md-6">
                                            <label for="">Status</label>
                                            <ValidationProvider name="Status" rules="required">
                                                <div class="custom-control custom-switch custom-control-inline mt-1">
                                                    <input type="checkbox" v-model="model.status" class="custom-control-input"
                                                        id="customSwitch1">
                                                    <label class="custom-control-label" for="customSwitch1">
                                                    </label>
                                                    <span class="switch-label">{{ model.status ? 'Aktif' : 'Tidak Aktif' }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-wrap justify-content-end align-items-center">
                                        <router-link to="/jabatan" class="btn btn-outline-primary mr-1 btn-lg" data-dismiss="modal">Batal</router-link>
                                        <button type="button" :disabled="invalid" @click="save()" class="btn btn-primary btn-lg" data-dismiss="modal">Simpan</button>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: "Jabatan",
            breadcumb: [
                {
                    name: 'Master Data', 
                    link: '',
                    active: false
                },
                {
                    name: 'Jabatan',
                    link: '/jabatan',
                    active: false
                },   
                {
                    name: 'Edit Jabatan', 
                    link: '',
                    active: true
                }            
            ],
            model: [],
            position: [],
            bidang: [],
            subbidang: [],
            role_id: localStorage.getItem('role_id')
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/position?id=' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data.rows
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.axios.patch('/position', _.model)
                .then(resp => {
                    if (resp.status) {
                        _.$router.push('/jabatan')
                        _.$toast.success('Data Berhasil Diubah')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Diubah')
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                    _.$toast.warning('Data Jabatan Sudah Digunakan')
                })
        },
        all() {
            let _ = this
            _.axios.get('/position/all')
                .then(resp=>{
                    _.position = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_bidang() {
            let _ = this
            _.axios.get('/bidang/all')
                .then(resp=>{
                    _.bidang = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        get_subbidang() {
            let _ = this
            _.axios.get('/subbidang/all')
                .then(resp=>{
                    _.subbidang = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    },
    mounted() {
        let _ = this
        _.get()
        _.all()
        _.get_bidang()
        _.get_subbidang()
    },
    watch: {
        'model.unit': function(newValue, oldValue) {
            if (oldValue != null){
                this.model.id_bidang = ''
                this.model.id_subbidang = ''
                this.model.index = ''
            }            
        },
        'model.bidang': function(newValue, oldValue) {
            if (oldValue != null){
                this.model.index = ''
            }            
        },
        'model.subbidang': function(newValue, oldValue) {
            if (oldValue != null){
                this.model.index = ''
            }            
        }
    }
}
</script>