<template>
	<aside>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <ValidationObserver v-slot="{ invalid }" class="card-content">
                                <div class="card-content">
                                    <div class="card-body card-dashboard">
                                        <h3 class="font-bold text-primary mb-2">Edit User</h3>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <ValidationProvider name="model.email" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Email</label>
                                                        <input type="email" @change="checkEmail()" v-model="model.email" class="form-control">
                                                        <small class="text-muted" v-if="emailExist">Email telah digunakan.</small>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-6">
                                                <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Nama</label>
                                                        <input type="text" v-model="model.name" class="form-control">
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <ValidationProvider name="model.role_id" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Role</label>
                                                        <select v-model="model.role_id" class="form-control">
                                                            <option value="">Masukan Role</option>
                                                            <option v-for="r in role" :value="r.id" :key="`role-${r.id}`">{{ r.name }}</option>
                                                        </select>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="col-md-6" v-if="model.role_id != 3">
                                                <ValidationProvider name="model.unit_id" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Unit</label>
                                                        <select v-model="model.unit_id" class="form-control">
                                                            <option value="">Masukan Unit</option>                                                            
										                    <option v-for="u in unit.filter(u =>
                                                                u.id > (model.role_id == 1 ? 1 : 0)
                                                                )" :value="u.id" :key="`unit-${u.id}`">{{ u.name }}</option>
                                                        </select>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div v-if="model.role_id != 3">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="">Bidang</label>
                                                        <select v-model="model.bidang_id" class="form-control" :disabled="positionDetail != null && positionDetail.id_bidang == null">
                                                            <option value="">Masukan Bidang</option>
                                                            <option v-for="b in bidang.filter(p => p.unit == (model.unit_id == 1 ? 1 : 2 ))" :value="b.id" :key="`role-${b.id}`">{{ b.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="">Sub Bidang</label>
                                                        <select v-model="model.subbidang_id" class="form-control" :disabled="(positionDetail != null && positionDetail.id_subbidang == null) || model.unit_id != 1">
                                                            <option value="">Masukan Sub-Bidang</option>
                                                            <option v-for="sub in subbidang.filter(p => p.unit == model.unit_id)" :value="sub.id" :key="`role-${sub.id}`">{{ sub.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>                                                
                                            </div>

                                            <div class="row">                                                
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="">Jabatan</label>
                                                        <select v-model="model.jabatan_id" class="form-control">
                                                            <option value="">Masukan Jabatan</option>
                                                            <option v-for="p in position.filter(p => 
                                                                p.type 			== model.role_id &&
                                                                p.unit 			== (model.unit_id      == 1  ? 1 : 2 ) &&
                                                                p.id_bidang 	== (model.bidang_id    == '' ? null : model.bidang_id) && 
                                                                p.id_subbidang 	== (model.subbidang_id == '' ? null : model.subbidang_id)
                                                            )" :value="p.id" :key="`role-${p.id}`">{{ p.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="">Atasan</label>
                                                        <select v-model="model.atasan_id" class="form-control" :disabled="positionDetail!= null && positionDetail.index == null">
                                                            <option value="">Pilih Atasan</option>
                                                            <option v-for="at in atasan" :value="at.id" :key="`role-${at.id}`">{{ at.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>                                            
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="">Kontraktor</label>
                                                        <select v-model="model.kontraktor_id" class="form-control">
                                                            <option value="">Pilih Kontraktor</option>
                                                            <option v-for="kt in kontraktor" :value="kt.id" :key="`role-${kt.id}`">{{ kt.name }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ValidationProvider name="model.status" v-slot="{ errors }" rules="required">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label for="">Status</label>
                                                <div class="custom-control custom-switch custom-control-inline mt-1">
                                                    <input type="checkbox" v-model="model.status" class="custom-control-input" id="customSwitch1"
                                                        checked>
                                                    <label class="custom-control-label" for="customSwitch1">
                                                    </label>
                                                    <span class="switch-label">{{ model.status ? 'Aktif' : 'Tidak Aktif' }}</span>
                                                </div>
                                            </div>
                                        </ValidationProvider>


                                        <div class="d-flex flex-wrap justify-content-end align-items-center">
                                            <router-link to="/user" class="btn btn-outline-primary mr-1 btn-lg" data-dismiss="modal">Batal</router-link>
                                            <button @click="save()" :disabled="invalid" type="button" class="btn btn-primary btn-lg">Simpan</button>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</aside>
</template>

<script>
export default {
	name: 'UserForm',
    data() {
        return {
            title: "User",
            breadcumb: [
                {
                    name: 'User & Role Management', 
                    link: '',
                    active: false
                },
                {
                    name: 'User List',
                    link: '/user',
                    active: false
                },   
                {
                    name: 'Edit User', 
                    link: '',
                    active: true
                }            
            ],            
            role: [
                { id: 1, name: 'Pengawas' },
                { id: 2, name: 'Non Pengawas' },
                { id: 3, name: 'Kontraktor' }
            ],
            model: [],
            unit: [],
            position: [],
            bidang: [],
            subbidang: [],
            atasan: [],
            kontraktor: [],            
            emailExist: false,   
            positionDetail: null,
            role_id: localStorage.getItem('role_id')                  
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/user/' + _.$route.params.id + '/show')
                .then(resp=>{
                    _.model = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },        
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp=> {
                    _.unit = resp.data
                    _.unit_list = resp.data
                })
                .catch(err=> {
                    console.log(err)
                })
        },
        getPosition() {
            let _ = this
            _.axios.get('/position/all')
                .then(resp=> {
                    _.position = resp.data.data
                })
                .catch(err=> {
                    console.log(err)
                })
        },
        getBidang() {
            let _ = this
            _.axios.get('/bidang/all')
                .then(resp=> {
                    _.bidang = resp.data.data
                })
                .catch(err=> {
                    console.log(err)
                })
        },
        getSubbidang() {
            let _ = this
            _.axios.get('/subbidang/all')
                .then(resp=> {
                    _.subbidang = resp.data.data
                })
                .catch(err=> {
                    console.log(err)
                })
        },
        getKontraktor() {
            let _ = this
            _.axios.get('/kontraktor/all')
                .then(resp=> {
                    _.kontraktor = resp.data.data
                })
                .catch(err=> {
                    console.log(err)
                })
        },
        getUser() {
            let _ = this
            _.axios.get('/user/all?unit_id=' + _.model.unit_id + '&jabatan_id=' + _.model.jabatan_id)
                .then(resp=> {
                    _.atasan = resp.data.data
                    _.model.atasan_id = _.atasan[0].id
                })
                .catch(err=> {
                    console.log(err)
                })
        },
        save() {
            let _ = this

            if ( _.emailExist == false ) {
                _.axios.patch('/user/update/' + _.$route.params.id, _.model)
                    .then(resp => {
                        if (resp.status) {
                            _.$toast.success('Data Berhasil Diubah')
                            _.$router.push('/user')
                        }else{
                            console.log("Failed")
                            _.$toast.warning('Data Gagal Diubah')
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                        // _.$toast.error(err.response)
                    })
            }
        },
        checkEmail() {
            let _ = this
            let email = _.model.email
            _.axios.get('/user/check_email?email=' + email + '&id=' + _.$route.params.id)
                    .then(resp => {
                        if ( resp.data.data.count == 1 ) {
                            _.model.email = ''
                            _.emailExist = true
                            _.$toast.error('Email sudah digunakan')
                        } else {
                            _.emailExist = false
                        }
                    })
                    .catch(err=> {
                        console.log(err.response)
                    })
        },
        getPositionDetail(){
			let _ = this
			_.axios.get('/position?id='+_.model.jabatan_id)
				.then(resp=> {
                    console.log('getPositionDetail')
                    console.log(_.model.subbidang_id)
					_.positionDetail 	 = resp.data.data.rows
					_.model.bidang_id 	 = _.positionDetail.id_bidang == null ? "" : _.model.bidang_id
					_.model.subbidang_id = _.positionDetail.id_subbidang == null ? "" : _.model.subbidang_id
                    
                    if(_.positionDetail.index == null){
						_.model.atasan_id = ""
					}			
                    console.log(_.po)
                    console.log('end')
                    console.log(_.model.subbidang_id)		
				})
				.catch(err=> {
					console.log(err)
				})
		}
    },
    mounted() {
        let _ = this
        _.get()
        _.getUnit()
        _.getPosition()
        _.getBidang()
        _.getSubbidang()
        _.getKontraktor()
    },
    watch: {
        'model.jabatan_id': function(){
			let _ = this		
			if (_.model.jabatan_id == null || _.model.jabatan_id == ''){				
				_.positionDetail = null
			} else {
				_.getPositionDetail()
				_.getUser()
			}			 
        },
        'model.kontraktor_id': function(newValue, oldValue) {
            console.log(oldValue)
            this.model.kontraktor_id = newValue
        },
        'model.role_id': function(newValue, oldValue){
            let _ = this
            if (_.model.role_id != 3){
                if (oldValue != undefined){
                    _.model.unit_id 		= ""
                    _.model.jabatan_id 		= ""
                    _.model.bidang_id 		= ""
                    _.model.subbidang_id 	= ""
                    _.model.atasan_id 		= ""
                    _.model.status 			= false
                    _.atasan = ''
                }
            }			
		},
		'model.unit_id': function(newValue, oldValue){
            let _ = this
            if (_.model.role_id != 3){
                if (oldValue != undefined){
                    _.model.jabatan_id 		= ""
                    _.model.bidang_id 		= ""
                    _.model.subbidang_id 	= ""
                    _.model.atasan_id 		= ""
                    _.model.status 			= false
                    _.atasan = ''
                }
            }			
		},
		'model.bidang_id': function(newValue, oldValue){
            let _ = this
            if (_.model.role_id != 3){
                if (oldValue != undefined){
                    _.model.jabatan_id 		= ""
                    _.model.subbidang_id 	= ""
                    _.model.atasan_id 		= ""
                    _.model.status 			= false
                    _.atasan = ''
                }
            }
			
		},
		'model.subbidang_id': function(newValue, oldValue){
            let _ = this
            if (_.model.role_id != 3){
                if (oldValue != undefined){
                    _.model.atasan_id 		= ""
                    _.model.status 			= false
                    _.atasan = ''
                }
            }			
		}
    }
}
</script>