import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import Home from '../views/Home.vue'
import Home from '../views/v2//Home.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import OTP from '../views/OTP.vue'
import ResetPassword from '../views/ResetPassword.vue'

// User
import UserIndex from '../views/user/Index.vue'
import UserForm from '../views/user/Form.vue'
import EditProfile from '../views/user/EditProfile.vue'

// Role
import RoleIndex from '../views/role/Index.vue'
import RoleType from '../views/role/IndexType.vue'
import RoleAccess from '../views/role/IndexAkses.vue'
import RoleForm from '../views/role/Form.vue'

// Kontraktor
import KontraktorIndex from '../views/kontraktor/Index.vue'
import KontraktorForm from '../views/kontraktor/Form.vue'

// Bank
import BankIndex from '../views/bank/list/index.vue'

// Jenis Pekerjaan
import JenisPekerjaanIndex from '../views/jenis_pekerjaan/Index.vue'
import JenisPekerjaanForm from '../views/jenis_pekerjaan/Form.vue'

// Jabatan
import JabatanIndex from '../views/jabatan/Index.vue'
import JabatanForm from '../views/jabatan/Form.vue'

// Unit
import UnitIndex from '../views/unit/Index.vue'
import UnitForm from '../views/unit/Form.vue'

// Bidang
import BidangIndex from '../views/bidang/Index.vue'
import BidangForm from '../views/bidang/Form.vue'

// Sub-Bidang
import SubbidangIndex from '../views/subbidang/Index.vue'
import SubbidangForm from '../views/subbidang/Form.vue'

// User Pic
import UserPicIndex from '../views/user_pic/Index.vue'
import UserPicForm from '../views/user_pic/Form.vue'

// Kontrak
import KontrakIndex from '../views/kontrak/Index.vue'
import KontrakForm from '../views/kontrak/Form.vue'
import KontrakDetail from '../views/kontrak/DetailUpdateV2.vue'
import TambahProgressForm from '../views/kontrak/TambahProgress.vue'
import EditProgressForm from '../views/kontrak/EditProgress.vue'
import DetailProgress from '../views/kontrak/ProgressDetailv2.vue'
import LihatRekomendasi from '../views/kontrak/LihatRekomendasi.vue'
import LihatPermohonan from '../views/kontrak/LihatPermohonan.vue'
import UpdateRekomendasi from '../views/kontrak/UploadRekomendasi.vue'

// mtu
import KontrakMTUIndex from '../views/mtu/Index.vue'
import KontrakMTUForm from '../views/mtu/Form.vue'
import KontrakMTUDetail from '../views/mtu/Detail.vue'


// Leaderboard
import LeaderBoard from '../views/leaderboard/index.vue'
// Laporan
import LaporanMonitoringIndex from '../views/laporan/monitoring/Index.vue'
import LaporanProjectIndex from '../views/laporan/project/Index.vue'
import LaporanProjectMTUIndex from '../views/laporan/mtu/Index.vue'
import LaporanEnergizeIndex from '../views/laporan/kontrak/Energize/energize.vue'
import LaporanSLOIndex from '../views/laporan/kontrak/Energize/slo.vue'
import LaporanBayEnergizeIndex from '../views/laporan/kontrak/Bay/energize.vue'
import LaporanBaySLOIndex from '../views/laporan/kontrak/Bay/slo.vue'
import LaporanPPTPDFIndex from '../views/laporan/PPT_PDF/index.vue'
import LaporanPPPAIIndex from '../views/laporan/ai/Index.vue'
import LaporanBulananIndex from '../views/laporan_bulanan/Index.vue'
import LaporanProgressIndex from '../views/laporan/progress/Index.vue'
import LaporanKendalaIndex from '../views/laporan/kendala/Index.vue'
import LaporanSolusiIndex from '../views/laporan/solusi/Index.vue'
import LaporanAmandemenIndex from '../views/laporan/amandemen/Index.vue'
import LaporanBapIndex from '../views/laporan/bap/Index.vue'
import LaporanBGPelaksanaanIndex from '../views/laporan/bgPelaksanaan/Index.vue'
import LaporanSTPIndex from '../views/laporan/kontrak/STP/Index.vue'
import LaporanSTAPIndex from '../views/laporan/kontrak/STAP/Index.vue'
import LaporanSTOPIndex from '../views/laporan/kontrak/STOP/Index.vue'

// CCTV
import CCTVIndex from '../views/cctv/Index.vue'
import CCTVForm from '../views/cctv/Form.vue'
import CCTVDetail from '../views/cctv/Detail.vue'

// Chart Page
import ChartSemuaProjekIndex from '../views/chart_page/kontraktual/semua_projek/index.vue'
import ChartProjekKinerjaIndex from '../views/chart_page/projek_kinerja/index.vue'
import chartStatusKontrakIndex from '../views/chart_page/kontraktual/status_kontrak/index.vue'
import chartPMBKIndex from '../views/chart_page/kontraktual/pmbk/index.vue'
import chartPTPSKTETIndex from '../views/chart_page/lainya/ptp/index.vue'
import chartPGIIndex from '../views/chart_page/lainya/pgi/index.vue'
import SummaryNilaiKontrakIndex from '../views/chart_page/summary/nilai_kontrak/index.vue'

// Warning page
import WarningUMBGIndex from '../views/warning/bg/uang_muka/index.vue';
import WarningPBGIndex from '../views/warning/bg/pelaksanaan/index.vue';
import WarningPEMELIHARAANBGIndex from '../views/warning/bg/pemeliharaan/index.vue';

import SummaryKapasitasMVA from '../views/chart_page/summary/kapasitas_mva/index.vue'
import SummaryKapasitasTransmisi from '../views/chart_page/summary/transmisi/index.vue'
// ##ALL
import SummaryKapasitasMVAAll from '../views/chart_page/summary/all/kapasitas_mva/index.vue'
import SummaryKapasitasTransmisiAll from '../views/chart_page/summary/all/transmisi/index.vue'
// Approval
import ApprovalIndex from '../views/approval/index.vue'
import ApprovalDetailProgress from '../views/approval/detail-progress/index.vue'
import ApprovalDetailProgressShow from '../views/approval/detail-progress/detail.vue'

// Live monitoring
import LiveMonitoring from '../views/live_monitoring/Index.vue'

// Embed
import EmbedIndex from '../views/embed/Index.vue'
import EmbedForm from '../views/embed/Form.vue'

// Upload AI
import UploadAI from '../views/upload_ai/Index.vue'

// Amandemen Status
import AmandemenStatusIndex from '../views/amandemen_status/Index.vue'

// Bank Garansi Status
import BankGaransiStatusIndex from '../views/bank_garansi_status/Index.vue'

// Anggaran Status
import AnggaranStatusIndex from '../views/anggaran_status/Index.vue'

// Progress MTU Status
import ProgressMTUStatusIndex from '../views/progress_mtu_status/Index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: '/user/edit_profile',
                name: 'EditProfile',
                component: EditProfile
            },
            {
                path: '/user',
                name: 'UserIndex',
                component: UserIndex,
                meta: {
                    permissions: ['user/read']
                }
            },
            {
                path: '/user/edit/:id',
                name: 'UserForm',
                component: UserForm,
                meta: {
                    permissions: ['user/update']
                }
            },
            {
                path: '/role',
                name: 'RoleIndex',
                component: RoleIndex,
                meta: {
                    permissions: ['permission/read']
                }
            },
            {
                path: '/role/:type',
                name: 'RoleType',
                component: RoleType,
                meta: {
                    permissions: ['permission/read']
                }
            },
            {
                path: '/akses',
                name: 'RoleAccess',
                component: RoleAccess,
                meta: {
                    permissions: ['permission/read']
                }
            },
            {
                path: 'akses/:type',
                name: 'RoleForm',
                component: RoleForm,
                meta: {
                    permissions: ['permission/read']
                }
            },
            {
                path: '/kontraktor',
                name: 'KontraktorIndex',
                component: KontraktorIndex,
                meta: {
                    permissions: ['kontraktor/read']
                }
            },
            {
                path: '/kontraktor/edit/:id',
                name: 'KontraktorForm',
                component: KontraktorForm,
                meta: {
                    permissions: ['kontraktor/update']
                }
            },
            {
                path: '/bank',
                name: 'BankIndex',
                component: BankIndex,
            },
            {
                path: '/pekerjaan',
                name: 'JenisPekerjaanIndex',
                component: JenisPekerjaanIndex,
                meta: {
                    permissions: ['jenis_pekerjaan/read']
                }
            },
            {
                path: '/pekerjaan/edit/:id',
                name: 'JenisPekerjaanForm',
                component: JenisPekerjaanForm,
                meta: {
                    permissions: ['jenis_pekerjaan/update']
                }
            },
            {
                path: '/jabatan',
                name: 'JabatanIndex',
                component: JabatanIndex,
                meta: {
                    permissions: ['jabatan/read']
                }
            },
            {
                path: '/jabatan/edit/:id',
                name: 'JabatanForm',
                component: JabatanForm,
                meta: {
                    permissions: ['jabatan/update']
                }
            },
            {
                path: '/unit',
                name: 'UnitIndex',
                component: UnitIndex,
                meta: {
                    permissions: ['unit/read']
                }
            },
            {
                path: '/unit/edit/:id',
                name: 'UnitForm',
                component: UnitForm,
                meta: {
                    permissions: ['unit/update']
                }
            },
            {
                path: '/bidang',
                name: 'BidangIndex',
                component: BidangIndex,
                meta: {
                    permissions: ['bidang/read']
                }
            },
            {
                path: '/bidang/edit/:id',
                name: 'BidangForm',
                component: BidangForm,
                meta: {
                    permissions: ['bidang/update']
                }
            },
            {
                path: '/subbidang',
                name: 'SubbidangIndex',
                component: SubbidangIndex,
                meta: {
                    permissions: ['sub_bidang/read']
                }
            },
            {
                path: '/subbidang/edit/:id',
                name: 'SubbidangForm',
                component: SubbidangForm,
                meta: {
                    permissions: ['sub_bidang/update']
                }
            },   
            {
                path: '/leaderboard',
                name: 'LeaderBoard',
                component: LeaderBoard
            },   
            {
                path: '/kontrak',
                name: 'KontrakIndex',
                component: KontrakIndex,
                meta: {
                    permissions: ['kontrak/read']
                }
            },
            {
                path: '/kontrak/add',
                name: 'KontrakForm',
                component: KontrakForm,
                meta: {
                    permissions: ['kontrak/create']
                }
            },
            {
                path: '/kontrak/edit/:id',
                name: 'KontrakFormEdit',
                component: KontrakForm,
                meta: {
                    permissions: ['kontrak/update']
                }
            },
            {
                path: '/kontrak/:id',
                name: 'KontrakDetail',
                component: KontrakDetail,
                meta: {
                    permissions: ['kontrak/detail']
                }
            },
            {
                path: '/kontrak/:id/tambah-progress',
                name: 'TambahProgressForm',
                component: TambahProgressForm
            },
            {
                path: '/kontrak/:id/edit-progress/:id_curva',
                name: 'TambahProgressForm',
                component: EditProgressForm
            },
            {
                path: '/kontrak/:id/detail-progress/:id_curva',
                name: 'DetailProgress',
                component: DetailProgress
            },
            {
                path: '/kontrak-mtu',
                name: 'KontrakMTUIndex',
                component: KontrakMTUIndex
            },
            {
                path: '/kontrak-mtu/detail/:id',
                name: 'KontrakMTUDetail',
                component: KontrakMTUDetail
            },
            {
                path: '/kontrak-mtu/manage',
                name: 'KontrakMTUForm',
                component: KontrakMTUForm
            },
            {
                path: '/udr/:id',
                name: 'UpdateRekomendasi',
                component: UpdateRekomendasi,
                meta: {
                    permissions: ['kontrak/detail/informasi/update_dokumen']
                }
            },
            {
                path: '/rklist/:id',
                name: 'LihatRekomendasi',
                component: LihatRekomendasi,
                meta: {
                    permissions: ['kontrak/detail/informasi/read_rekomendasi']
                }
            },
            {
                path: '/pmhlist/:id',
                name: 'LihatPermohonan',
                component: LihatPermohonan,
                meta: {
                    permissions: ['kontrak/detail/informasi/read_permohonan']
                }
            },
            {
                path: '/laporan-monitoring',
                name: 'LaporanMonitoringIndex',
                component: LaporanMonitoringIndex,
                meta: {
                    permissions: ['laporan/monitoring/read']
                }
            },
            {
                path: '/laporan-project',
                name: 'LaporanProjectIndex',
                component: LaporanProjectIndex,
                meta: {
                    permissions: ['laporan/project/read']
                }
            },
            {
                path: '/laporan-project-mtu',
                name: 'LaporanProjectMTUIndex',
                component: LaporanProjectMTUIndex,
            },
            {
                path: '/laporan/kontrak/slo',
                name: 'LaporanSLOIndex',
                component: LaporanSLOIndex,
            },
            {
                path: '/laporan/kontrak/energize',
                name: 'LaporanEnergizeIndex',
                component: LaporanEnergizeIndex,
            },
            {
                path: '/laporan/kontrak/bay/slo',
                name: 'LaporanBaSLOyIndex',
                component: LaporanBaySLOIndex,
            },
            {
                path: '/laporan/kontrak/bay/energize',
                name: 'LaporanBayEnergizeIndex',
                component: LaporanBayEnergizeIndex,
            },
            {
                path: '/laporan/ppt-pdf',
                name: 'LaporanPPTPDFIndex',
                component: LaporanPPTPDFIndex,
            },
            {
                path: '/laporan-project-ai',
                name: 'LaporanPPPAIIndex',
                component: LaporanPPPAIIndex,
            },
            {
                path: '/laporan-bg-pelaksanaan',
                name: 'LaporanBGPelaksanaanIndex',
                component: LaporanBGPelaksanaanIndex,
            },
            {
                path: '/laporan-stp',
                name: 'LaporanSTPIndex',
                component: LaporanSTPIndex,
            },
            {
                path: '/laporan-stap',
                name: 'LaporanSTAPIndex',
                component: LaporanSTAPIndex,
            },
            {
                path: '/laporan-stop',
                name: 'LaporanSTOPIndex',
                component: LaporanSTOPIndex,
            },
            {
                path: '/laporan-progress',
                name: 'LaporanProgressIndex',
                component: LaporanProgressIndex,
                meta: {
                    permissions: ['laporan/progress/read']
                }
            },
            {
                path: '/laporan-kendala',
                name: 'LaporanKendalaIndex',
                component: LaporanKendalaIndex,
                meta: {
                    permissions: ['laporan/kendala/read']
                }
            },
            {
                path: '/laporan-solusi',
                name: 'LaporanSolusiIndex',
                component: LaporanSolusiIndex,
                meta: {
                    permissions: ['laporan/solusi/read']
                }
            },
            {
                path: '/laporan-amandemen',
                name: 'LaporanAmandemenIndex',
                component: LaporanAmandemenIndex,
                meta: {
                    permissions: ['laporan/amandemen/read']
                }
            },
            {
                path: '/laporan-bap',
                name: 'LaporanBapIndex',
                component: LaporanBapIndex,
                meta: {
                    permissions: ['laporan/bap/read']
                }
            },
            {
                path: '/laporan-bulanan',
                name: 'LaporanBulananIndex',
                component: LaporanBulananIndex,
                meta: {
                    permissions: ['laporan/bulanan/read']
                }
            },
            {
                path: '/chart/semua-project',
                name: 'ChartSemuaProjekIndex',
                component: ChartSemuaProjekIndex
            },
            {
                path: '/chart/projek-kinerja',
                name: 'ChartProjekKinerjaIndex',
                component: ChartProjekKinerjaIndex
            },
            {
                path: '/chart/kontraktual/status-kontrak',
                name: 'chartStatusKontrakIndex',
                component: chartStatusKontrakIndex
            },
            {
                path: '/chart/kontraktual/pmbk',
                name: 'chartPMBKIndex',
                component: chartPMBKIndex
            },
            {
                path: '/chart/lainya/ptp-sktet',
                name: 'chartPTPSKTETIndex',
                component: chartPTPSKTETIndex
            },
            {
                path: '/chart/lainya/pgi',
                name: 'chartPGIIndex',
                component: chartPGIIndex
            },
            {
                path: '/summary/nilai-kontrak',
                name: 'SummaryNilaiKontrakIndex',
                component: SummaryNilaiKontrakIndex
            },
            {
                path: '/summary/kapasitas-mva',
                name: 'SummaryKapasitasMVA',
                component: SummaryKapasitasMVA
            },
            {
                path: '/summary/kapasitas-mva/all',
                name: 'SummaryKapasitasMVAAll',
                component: SummaryKapasitasMVAAll
            },
            {
                path: '/summary/kapasitas-transmisi',
                name: 'SummaryKapasitasTransmisi',
                component: SummaryKapasitasTransmisi
            },
            {
                path: '/summary/kapasitas-transmisi/all',
                name: 'SummaryKapasitasTransmisiAll',
                component: SummaryKapasitasTransmisiAll
            },
            {
                path: '/warning/uang-muka',
                name: 'WarningUMBGIndex',
                component: WarningUMBGIndex
            },
            {
                path: '/warning/pelaksanaan',
                name: 'WarningPBGIndex',
                component: WarningPBGIndex
            },
            {
                path: '/warning/pemeliharaan',
                name: 'WarningPEMELIHARAANBGIndex',
                component: WarningPEMELIHARAANBGIndex
            },
            {
                path: '/cctv',
                name: 'CCTVIndex',
                component: CCTVIndex,
                meta: {
                    permissions: ['cctv/read']
                }
            },
            {
                path: '/cctv/edit/:id',
                name: 'CCTVForm',
                component: CCTVForm,
                meta: {
                    permissions: ['cctv/update']
                }
            },
            {
                path: '/cctv/detail/:id',
                name: 'CCTVDetail',
                component: CCTVDetail,
                meta: {
                    permissions: ['cctv/read']
                }
            },
            {
                path: '/approval',
                name: 'ApprovalIndex',
                component: ApprovalIndex,
                meta: {
                    permissions: ['kontrak/detail/progress/read']
                }
            },
            {
                path: '/approval/detail/:id',
                name: 'ApprovalDetailProgress',
                component: ApprovalDetailProgress,
            },
            {
                path: '/approval/detail/:id/show/:id_detail',
                name: 'ApprovalDetailProgressShow',
                component: ApprovalDetailProgressShow,
            },
            {
                path: '/user_pic',
                name: 'UserPicIndex',
                component: UserPicIndex
            },
            {
                path: '/user_pic/edit/:id',
                name: 'UserPicForm',
                component: UserPicForm
            },
            {
              path: '/live_monitoring',
              name: 'LiveMonitoring',
              component: LiveMonitoring
            },
            {
                path: '/embed',
                name: 'EmbedIndex',
                component: EmbedIndex,
                meta: {
                    permissions: ['embed/read']
                }
            },
            {
                path: '/embed/edit/:id',
                name: 'EmbedForm',
                component: EmbedForm,
                meta: {
                    permissions: ['embed/update']
                }
            },

            {
                path: '/upload-ai',
                name: 'AIIndex',
                component: UploadAI,
            },
            {
                path: '/about',
                name: 'About',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
            },
            {
                path: '/amandemen-status',
                name: 'AmandemenStatusIndex',
                component: AmandemenStatusIndex,
            },
            {
                path: '/bank-garansi-status',
                name: 'BankGaransiStatusIndex',
                component: BankGaransiStatusIndex,
            },
            {
                path: '/anggaran-status',
                name: 'AnggaranStatusIndex',
                component: AnggaranStatusIndex,
            },
            {
                path: '/progress-mtu-status',
                name: 'ProgressMTUStatusIndex',
                component: ProgressMTUStatusIndex,
            },
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/lupa-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/otp',
                name: 'OTP',
                component: OTP,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
                meta: {
                    isGuest: true
                }
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if ( from.name == 'Login' && ( !store.state.token && !localStorage.getItem('token') ) ) {
        Vue.$toast.warning('Silahkan Login Terlebih Dahulu')
        next({name: 'Login'})
    } else if ( localStorage.getItem('has_update_profile') == '0' && from.name == 'EditProfile' ) {
        Vue.$toast.warning('Silahkan Ganti Password dan Lengkapi Profil Anda!')
        next({name: 'EditProfile'})
    } else if (to.meta.permissions){
        let permissions = to.meta.permissions.toString().split(',')
        let isAllowed = false
        permissions.forEach(elm => {				
            if (localStorage.getItem('permissions').split(',').indexOf(elm) != -1 ) {					
                isAllowed = true
            }
        });
        if (isAllowed){
            next()
        } else{
            Vue.$toast.warning('Maaf, anda tidak memiliki akses')
            next({name: 'Home'})
        }
    } else {
        next()
    }
})

export default router
