<template>
	<div>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <ValidationObserver v-slot="{ invalid }" class="card-content">
                                <div class="card-content">
                                    <div class="card-body card-dashboard">
                                        <h3 class="font-bold text-primary mb-2">Edit User PIC</h3>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <ValidationProvider name="model.name" v-slot="{ errors }" rules="required">
                                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                        <label for="">Nama User PIC</label>
                                                        <input type="text" v-model="model.name" id="" class="form-control" disabled>
                                                    </div>
                                                </ValidationProvider>
                                            </div>

                                            <div class="col-md-6">
                                                <label for="">Status</label>
                                                <ValidationProvider name="model.status" rules="required">
                                                    <div class="custom-control custom-switch custom-control-inline mt-1">
                                                        <input type="checkbox" v-model="model.status" class="custom-control-input"
                                                            id="customSwitch1">
                                                        <label class="custom-control-label" for="customSwitch1">
                                                        </label>
                                                        <span class="switch-label">{{ model.status ? 'Aktif' : 'Tidak Aktif' }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-wrap justify-content-end align-items-center">
                                            <router-link to="/user_pic" class="btn btn-outline-primary mr-1 btn-lg" data-dismiss="modal">Batal</router-link>
                                            <button @click="save()" :disabled="invalid" type="button" class="btn btn-primary btn-lg"
                                                data-dismiss="modal">Simpan</button>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
export default {
    data() {
        return {
            title: "User PIC",
            breadcumb: [
                {
                    name: 'Master Data', 
                    link: '',
                    active: false
                },
                {
                    name: 'User PIC',
                    link: '/user_pic',
                    active: false
                },   
                {
                    name: 'Edit User PIC', 
                    link: '',
                    active: true
                }            
            ],
            model: [],
            role_id: localStorage.getItem('role_id')
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/user_pic/' + _.$route.params.id + '/show')
                .then(resp=>{
                    console.log(resp.data.data)
                    _.model = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            _.axios.patch('/user_pic/update/' + _.$route.params.id, _.model)
                .then(resp => {
                    if (resp.status) {
                        _.$router.push('/user_pic')
                        _.$toast.success('Data Berhasil Diubah')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Diubah')
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                    _.$toast.warning('Data Sub-Bidang Sudah Digunakan')
                })
        }
    },
    mounted() {
        let _ = this
        _.get()
    },
}
</script>