<template>
	<section>
		<div class="content-header">
                <div class="content-header-left mb-2">
                    <div class="breadcrumbs-top">
                        <h2 class="content-header-title mb-0">Live Monitoring</h2>
                        <div class="breadcrumb-wrapper">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                        <i class="feather icon-home"></i>
                                    </router-link>
                                </li>

                                <li class="breadcrumb-item active">Live Monitoring</li>
                            </ol>
                        </div>
                        <div class=" has-icon-right" style="margin-left:auto;">
                            <date-picker
                                v-model="start_date"
                                format="DD/MM/YYYY"
                                type="date"
                                :input-class="'form-control'"
                                value-type="YYYY-MM-DD"
                                placeholder="Tanggal Mulai"
                                style="width: 100%;"
                                >
                            </date-picker>
                        </div>
                        <div class=" has-icon-right" style="margin-left:5px;">
                            <date-picker
                                v-model="end_date"
                                format="DD/MM/YYYY"
                                type="date"
                                :input-class="'form-control'"
                                value-type="YYYY-MM-DD"
                                placeholder="Tanggal Akhir"
                                style="width: 100%;"
                                >
                            </date-picker>
                        </div>
                        <button class="btn btn-primary" style="margin-left:5px;" @click="getAllData">Filter</button>
                        <button class="btn btn-danger" style="margin-left:5px;" @click="resetFilter">Reset</button>
                    </div>
                </div>
            </div>

            <div class="content-body">
                <section id="gmaps-basic-maps">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="">
                                <!-- <iframe class="w-100" width="600" height="900" frameborder="0" style="border:0"
                                    src="https://www.google.com/maps/embed/v1/place?q=Jalan%20tubagus%20ismail%20xv%20no.%2014a%20bandung&key=AIzaSyCBkW3l4xJIvrDcLskksqmaJ8g1oZZBKLQ"
                                    allowfullscreen></iframe> -->
                                
                                <div class="w-100" id="map" ref="map" style="height:780px"></div>
                            </div>

                            <div class="card detail-pro" v-if="detailMap.judul_kontrak">
                                <div class="card-body">
                                    <div class="d-flex mb-1">
                                        <div>
                                            <img :src="detailMap.image.length > 0 ? detailMap.image[0].url : 'https://via.placeholder.com/150?text=no-image'" class="img-fluid" alt="no image">
                                        </div>
                                        <div class="ttl-dp text-primary">{{ detailMap.judul_kontrak }}</div>
                                    </div>
                                    <div class="row mb-1">
                                        <div class="col-sm-6">
                                            <p class="mb-0">Tanggal Awal Kontrak</p>
                                            <h5 class="font-bold text-primary">{{ moment(detailMap.tgl_awal).isValid() ? moment(detailMap.tgl_awal).format("DD MMMM YYYY") : '-'}}</h5>
                                        </div>
                                        <div class="col-sm-6">
                                            <p class="mb-0">Tanggal Akhir Kontrak</p>
                                            <h5 class="font-bold text-primary">{{ moment(detailMap.tgl_akhir).isValid() ? moment(detailMap.tgl_akhir).format("DD MMMM YYYY") : '-'}}</h5>
                                        </div>
                                    </div>
                                    <a :href="mapDir" class="font-bold" target="_blank">Get Direction</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card">
                                        <div class="card-body  d-flex align-items-center lm-i-pad" style="padding : 1.1rem 2.2rem !important">
                                            <div class="lm-icon">
                                                <img src="/assets/img/icon/ic-hour-glass.png" class="img-fluid"
                                                    alt="">
                                            </div>

                                            <div class="">
                                                <p class="mb-0 line-ellipsis" data-toggle="tooltip"
                                                    data-placement="bottom" data-original-title="Progress Fisik">
                                                    Progress Fisik <span class="big-font text-primary">{{ progressFisik }}%</span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-body d-flex align-items-center lm-i-pad" style="padding : 1.1rem 2.2rem !important">
                                            <div class="lm-icon">
                                                <img src="/assets/img/icon/ic-money-2.png" class="img-fluid" alt="">
                                            </div>

                                            <div class="">
                                                <p class="mb-0 line-ellipsis" data-toggle="tooltip"
                                                    data-placement="bottom" data-original-title="Progress Bayar">
                                                    Progress Bayar <span class="big-font text-primary">{{ progressBayar }}%</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic maxh-400">
                                        <div class="card-header">
                                            <h5 class="card-title">Status Kontraktual</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getKontraktualChart"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content">
                                            <div class="body-chart" v-if="kontrakState">
                                                <apexchart type="pie" height="147" :options="KontraktualOptions" :series="KontraktualSeries"></apexchart>
                                            </div>
                                            <div class="body-chart text-center" v-else>
                                                <br><br>
                                                <i class="fa fa-spinner fa-spin" style="font-size:48px;"></i> 
                                                <br><br><br>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic maxh-400">
                                        <div class="card-header">
                                            <h5 class="card-title">Lama Proses Penyelesaian</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getLamaProses"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content">
                                            <div class="body-chart" v-if="lamaState">
                                                <apexchart type="line" height="137" :options="lamaprosesOptions" :series="lamaprosesData"></apexchart>
                                            </div>
                                            <div class="body-chart text-center" v-else>
                                                <br><br>
                                                <i class="fa fa-spinner fa-spin" style="font-size:48px;"></i> 
                                                <br><br><br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic" style="min-height:215px;max-height:215px;">
                                        <div class="card-header">
                                            <h5 class="card-title" style="width: 90%;">Lokasi Tidak Ada Pekerja</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getNoWorkerLoc"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content ovSc">
                                                <ul class="list-todo">
                                                    <li v-for="item in noWorkerLoc" :key="item">{{ item }}</li>
                                                </ul>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic maxh-400">
                                        <div class="card-header">
                                            <h5 class="card-title">Lama Proses Amandemen</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getAmandemen"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content">
                                            <div class="body-chart">
                                                <apexchart type="bar" height="137" :options="AmandemenOptions" :series="AmandemenSeries"></apexchart>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic maxh-400">
                                        <div class="card-header">
                                            <h5 class="card-title">Jumlah Pekerja</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getWorker"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content">
                                            <div class="body-chart">
                                                <apexchart type="line" height="137" :options="workerOptions" :series="workerData"></apexchart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic " style="min-height:280px;max-height:280px;">
                                        <div class="card-header">
                                            <h5 class="card-title">Cuaca</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getCuacaChart"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content">
                                            <div class="body-chart">
                                               <apexchart type="pie" height="200" :options="CuacaOptions" :series="CuacaSeries"></apexchart>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic" style="min-height:280px;max-height:280px;">
                                        <div class="card-header">
                                            <h5 class="card-title">Kendala & Terselesaikan</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getKendala"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content">
                                            <div class="body-chart">
                                                <apexchart type="bar" height="200"  :options="KendalaOptions" :series="KendalaSeries"></apexchart>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="card card-statistic" style="max-height:280px">
                                        <div class="card-header">
                                            <h5 class="card-title">Klasifikasi Kendala</h5>
                                            <div class="heading-elements">
                                                <ul class="list-inline mb-0">
                                                    <li><a data-action="reload"><i
                                                                class="feather icon-rotate-cw" @click="getKlasifikasi"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="card-content ovSc">
                                            <div class="body-chart" v-if="KlasifikasiState">
                                                 <apexchart type="pie" height="450" :options="KlasifikasiOptions" :series="KlasifikasiSeries"></apexchart>
                                            </div>
                                            <div class="body-chart text-center" v-else>
                                                <br><br>
                                                <i class="fa fa-spinner fa-spin" style="font-size:48px;"></i> 
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
	</section>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import $ from 'jquery'
import ld from 'lodash'
import moment from 'moment'

export default {
	name: 'UserPicIndex',
	components: {
		DatePicker
	},
	data() {
		return {
            mapMarker : {},
            map : null,
            start_date : null,
            end_date : null,
            qs : '',
            mapDir : 0,
            moment : moment,
            rawMapData : {
                tgl_awal : "01-01-2020",
                tgl_akhir : "01-01-2020",
            },
            detailMap : {},
            lamaprosesData: [{
                name: 'Hari',
                data: [1]
            }],
            noWorkerLoc : [],
            progressBayar : 0,
            progressFisik : 0,
            lamaState : true,
            lamaprosesOptions: {
                chart: {
                    height: '300px',
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                markers: {
                    size: 4,
                    colors: ["#009ccc"],
                    strokeColors: "#fff",
                    strokeWidth: 2,
                    hover: {
                        size: 7,
                    }
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['Sep'],
                }
            },
            workerData: [{
                name: 'Orang',
                data: [1]
            }],
            workerOptions: {
                chart: {
                    height: 300,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                curve: 'smooth'
                },
                colors: ['#D65D51']
                ,
                markers: {
                size: 4,
                colors: ["#D65D51"],
                strokeColors: "#fff",
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
                },
                grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
                },
                xaxis: {
                categories: ['Sep'],
                }
            },
            kontrakState : true,
            KontraktualSeries : [1,99,99],
            KontraktualOptions : {
                    chart: {
                        height: 100,
                        type: 'pie',
                    },
                    labels: ['Test'],
                    responsive: [{
                        breakpoint: 1400,
                        options: {
                             chart: {
                                height : 160,
                                width: '100%',
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    noData: {
                        text: "Tidak ada data di periode ini",
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontFamily: undefined
                        }
                    }
            },
            CuacaSeries : [1,1],
            CuacaOptions : {
                    chart: {
                        height: 300,
                        type: 'pie',
                        redrawOnParentResize: false
                    },
                    labels: ['Cerah','Hujan'],
                    responsive: [{
                        breakpoint: 1400,
                        options: {
                             chart: {
                                height : 250,
                                width: '100%',
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }],
                    noData: {
                        text: "Tidak ada data di periode ini",
                        align: 'center',
                        verticalAlign: 'middle',
                        offsetX: 0,
                        offsetY: 0,
                        style: {
                            color: undefined,
                            fontSize: '14px',
                            fontFamily: undefined
                        }
                    }
            },
            AmandemenSeries : [{
                name : 'Hari',
                data: [0, 0, 0]
            }],
            AmandemenOptions : {
                chart: {
                    height : 100,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                colors: ["#008ffb","#00e396","#feb019"],
                plotOptions: {
                    bar: {
                        columnWidth: '21%',
                        distributed: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: [
                        'Kontraktor','UPP','UIP'
                    ],
                    labels: {
                        style: {
                        fontSize: '12px'
                    }
                }
                }
            },
            KendalaSeries : [{
                name : 'Jumlah',
                data: [0,0]
            }],
            KendalaOptions : {
                chart: {
                    height : 200,
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                },
                colors: ["#008ffb","#00e396","#feb019"],
                plotOptions: {
                    bar: {
                        columnWidth: '21%',
                        distributed: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: [
                        'Kendala','Terselesaikan',
                    ],
                    labels: {
                        style: {
                        fontSize: '12px'
                        }
                    }
                }
            },
            KlasifikasiState: true,
            KlasifikasiSeries : [0,0],
            KlasifikasiOptions : {
                chart: {
                    height: 500,
                    type: 'pie',
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'left',
                },
                noData: {
                    text: "Tidak ada data di periode ini",
                    align: 'center',
                    verticalAlign: 'top',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined
                    }
                },
                labels: ['A','B'],
                responsive: [{
                    breakpoint: 1400,
                    options: {
                        chart: {
                            height : 500,
                            width: '100%',
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800','#2E294E','#5A2A27','#3F51B5','#C5D86D','#4CAF50','#00E396','#D7263D','#1B998B','#F9CE1D','#D4526E'],   
            },
			role_id: localStorage.getItem('role_id')
		}
	},
    async mounted() {
        let _ = this
        await _.getAllData()

        _.$echo.channel(`progress_fisik`)
            .listen(`.progress_fisik`, async (e) => {
                console.log(e)
                await _.getProgressFisik()
            });

        _.$echo.channel(`proses_bayar`)
            .listen(`.proses_bayar`, async (e) => {
                console.log(e)
                await _.getProgressBayar()
            });

        _.$echo.channel(`status_kontraktual`)
            .listen(`.status_kontraktual`, async (e) => {
                console.log(e)
                await _.getKontraktualChart()
            });

        _.$echo.channel(`cuaca`)
            .listen(`.cuaca`, async (e) => {
                console.log(e)
                await _.getCuacaChart()
            });

        _.$echo.channel(`kendala_dan_terselesaikan`)
            .listen(`.kendala_dan_terselesaikan`, async (e) => {
                console.log(e)
                await _.getKendala()
                await _.getKlasifikasi()
            });

        _.$echo.channel(`lama_proses_penyelesaian`)
            .listen(`.lama_proses_penyelesaian`, async (e) => {
                console.log(e)
                await _.getLamaProses()
            });

        _.$echo.channel(`lokasi_tidak_ada_pekerja`)
            .listen(`.lokasi_tidak_ada_pekerja`, async (e) => {
                console.log(e)
                await _.getNoWorkerLoc()
                await _.getWorker()
            });

        _.$echo.channel(`lama_proses_amandemen`)
            .listen(`.lama_proses_amandemen`, async (e) => {
                console.log(e)
                await _.getAmandemen()
            });

        _.$echo.channel(`jumlah_pekerja`)
            .listen(`.jumlah_pekerja`, async (e) => {
                console.log(e)
                await _.getWorker()
                await _.getNoWorkerLoc()
            });

        _.$echo.channel(`map`)
            .listen(`.map`, async (e) => {
                console.log(e)
                await _.getMapData()
            });
    },
    methods: {
        async getAllData(){
            let _ = this
            _.qs = (_.start_date == null || _.end_date == null) ? '' : '?start_date='+_.start_date+'&end_date='+_.end_date
            await _.getKlasifikasi()
            await _.getKontraktualChart()
            await _.getCuacaChart()
            moment.locale('id')
            await _.initMap()
            await _.getMapData()
            await _.getLamaProses()
            await _.getWorker()
            await _.getKendala()
            await _.getAmandemen()
            await _.getNoWorkerLoc()
            await _.getProgressBayar()
            await _.getProgressFisik()
        },
        async resetFilter(){
            let _ = this
            _.start_date = null
            _.end_date = null
            await _.getAllData()
        },
        getLamaProses() {
            let _ = this
            _.lamaState = false
            _.axios.get('/live_monitoring/progress_penyelesaian'+_.qs)
                .then(resp=>{
                    _.lamaprosesOptions.xaxis = { categories : resp.data.data.data } 
                    
                    _.lamaprosesData = [{
                        name : 'Hari',
                        data : resp.data.data.categories
                    }]
            _.lamaState = true
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getWorker() {
            let _ = this
            _.axios.get('/live_monitoring/jumlah_pekerja'+_.qs)
                .then(resp=>{
                    var lab = []
                    $.each(resp.data.data.categories,function(i,v){
                        var det = v.split("/")
                        var det2 = det[1]+"-"+det[0]+"-"+det[2]
                        lab.push(moment(det2).format("DD MMM"))
                    })
                    _.workerOptions = {
                        xaxis: {
                            categories: lab
                        },
                    }
                    _.workerData = [{
                        data : resp.data.data.data
                    }]
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getKendala() {
            let _ = this
            _.axios.get('/live_monitoring/kendala_solusi'+_.qs)
                .then(resp=>{                    
                    _.KendalaOptions.xaxis.categories = resp.categories

                    _.KendalaSeries = [{
                        data : resp.data.data.data
                    }]
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getKlasifikasi() {
            let _ = this
            _.KlasifikasiState = false
            _.axios.get('/live_monitoring/klasifikasi_kendala'+_.qs)
                .then(resp=>{   
                    let dt = resp.data.data
                    let ser = ld.sum(dt.data) == 0 ? [] : dt.data
                    _.KlasifikasiSeries = ser
                    
                    _.KlasifikasiOptions.labels = dt.categories
                    _.KlasifikasiState = true
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getAmandemen() {
            let _ = this
            _.axios.get('/live_monitoring/progress_amandemen'+_.qs)
                .then(resp=>{                    
                    _.AmandemenOptions.xaxis.categories = resp.categories
                    _.AmandemenSeries = [{
                        data : resp.data.data.data
                    }]
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getNoWorkerLoc() {
            let _ = this
            _.axios.get('/live_monitoring/lokasi_tidak_ada_pekerja'+_.qs)
                .then(resp=>{
                    _.noWorkerLoc = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getProgressBayar() {
            let _ = this
            _.axios.get('/live_monitoring/progress_pembayaran'+_.qs)
                .then(resp=>{
                    _.progressBayar = (resp.data.data.progress_pembayaran)
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getProgressFisik() {
            let _ = this
            _.axios.get('/live_monitoring/progress_fisik'+_.qs)
                .then(resp=>{
                    _.progressFisik = (resp.data.data.progress_fisik)
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getKontraktualChart() {
            let _ = this
            _.kontrakState = false
            _.axios.get('/live_monitoring/status_kontraktual'+_.qs)
                .then(resp=>{
                    let dt = resp.data.data
                    let ser = ld.sum(dt.series) == 0 ? [] : dt.series
                    _.KontraktualSeries = ser
                    
                    _.KontraktualOptions.labels = dt.label
                    _.kontrakState = true
                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getCuacaChart() {
            let _ = this
            // _.kontrakState = false
            _.axios.get('/live_monitoring/cuaca'+_.qs)
                .then(resp=>{
                    let dt = resp.data.data
                    let ser = ld.sum(dt.series) == 0 ? [] : dt.series
                    _.CuacaSeries = ser
                    
                    _.CuacaOptions.labels = dt.label
                    // _.kontrakState = true
                    
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        getMapData(){
            let _ = this
            let loc = {}
            _.axios.get('/live_monitoring/map')
                .then(resp=>{
                    _.rawMapData = resp.data.data
                    $.each(resp.data.data,function(i,v){
                        _.mapMarker['data'+v.id] = v
                        loc['d-'+v.id+'-'+i] = {name: v.name, lat : v.lat, lng : v.lng}
                    })
                    let dif = -1
                    let dif2 = -2
                    $.each(loc,function(i,v){
                        let idx = i.split("-")[1]
                        dif = idx
                        if(dif != dif2){
                            _.mapMarker['data'+idx].loc = []
                        }
                        _.mapMarker['data'+idx].loc.push(v)
                        dif2 = idx
                    })
                    // console.log(_.mapMarker,loc)
                    _.setMarker()
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        setMarker(){
            let _ = this
            var lastMark = {}
            $.each(_.mapMarker,function(i,v){
                $.each(v.loc,function(i2,v2){
                    let size = v.jenis_pekerjaan.id > 4 ? 27 : 40;
                    let icon = {
                                url: "", 
                                scaledSize: new window.google.maps.Size(size, size),
                            }
                    switch (v.col_status_kontraktual) {
                        case "On Going":
                             icon.url= "http://maps.google.com/mapfiles/kml/paddle/grn-circle.png"
                            break;
                        case "Serah Terima I":
                             icon.url= "http://maps.google.com/mapfiles/kml/paddle/ylw-circle.png"
                            break;
                        case "Serah Terima II":
                             icon.url= "http://maps.google.com/mapfiles/kml/paddle/orange-circle.png"
                            break;
                        default:
                            icon.url= "http://maps.google.com/mapfiles/kml/paddle/red-circle.png"
                            break;
                    }
                    lastMark = {lat: v2.lat, lng: v2.lng};
                    let mark = new window.google.maps.Marker({
                            label: v2.name,
                            map : _.map,
                            animation: window.google.maps.Animation.DROP,
                            position: {lat: v2.lat, lng: v2.lng},
                            icon : icon
                        })
                    mark.addListener('click', function(event) {
                        _.map.panTo(event.latLng)
                        _.getDetailOnClick(event.latLng.lat(),event.latLng.lng())
                    });
                    if(v.jenis_pekerjaan.id > 4){
                        var path = new window.google.maps.Polyline({
                                path: v.loc,
                                geodesic: true,
                                strokeColor: "#FF0000",
                                strokeOpacity: 1.0,
                                strokeWeight: 2,
                            });
                            path.setMap(_.map);
                    }
                })
            })
            _.map.panTo(lastMark)
           
        },
        getDetailOnClick(lat,lng){
            let _ = this
            let i = ld.findIndex(_.rawMapData, function(o) { return o.lat == lat && o.lng == lng; })
            _.detailMap = _.rawMapData[i]
            _.mapDir = 'https://www.google.com/maps/search/?api=1&query='+lat+','+lng+'&query='+lat+','+lng
            console.log(_.rawMapData[i],lat,lng)
        },
        initMap() {
            let _ = this
            var myLatlng = {lat: -6.409493537558435, lng: 106.79552258660475}

            _.map = new window.google.maps.Map(
               _.$refs["map"], {zoom: 12, center: myLatlng})
        },
       
    },
    watch: {
       
    }
}
</script>