<template>
	<section>
		<List
		:title="table.title"
		:breadcumb="table.breadcumb"
        :dataTable="table.dataTable">
			<template v-slot:button>
				<div>
                    <button type="submit" class="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#Modal_FilterKontrak" style="margin-left: 3px;">
                        <i class="fa fa-filter fa-l" aria-hidden="true"></i> Filter Laporan
                    </button>
                    &emsp;
                    <button v-can="'laporan/amandemen/generate'" @click="cetak()" class="btn btn-outline-primary waves-effect waves-light" 
                        v-if="table.dataTable.lists.data" 
                        :disabled="table.dataTable.lists.data.length == 0">
                        <i class="fa fa-file-excel-o fa-l" aria-hidden="true"></i> Cetak Excel
                    </button>
				</div>
			</template>

			<template v-slot:columnHeader>
				<thead>
                    <tr>
                        <th>No.</th>
                        <th>Tanggal</th>
                        <th>No. Kontrak</th>
                        <th>Judul Kontrak</th>
                        <th>UPP</th>
                        <th>Nama Amandemen</th>
                        <th>Nomor Amandemen</th>
                        <th>Perubahan Waktu</th>
                        <th>Perubahan Biaya</th>
                        <th>Lain-lain</th>
                        <th></th>
                    </tr>
                </thead>
			</template>          
		</List>
        <div class="modal fade text-left show" id="Modal_FilterKontrak" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Filter Kontrak</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-1" v-for="(f, idx) in filter" :key="idx">
                            <div class="col-8 col-sm-10">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="filter_by">Filter Berdasarkan</label>
                                            <select class="form-control" v-model="f.by" :disabled="f.by != '-'">
                                                <option v-for="(data, index) in filterOption" :key="index" :value="data.by">{{data.name}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-if="f.by == 'id_unit'">
                                        <div class="form-group">
                                            <label for="filter_by">UPP</label>                                                                                        
                                            <select class="form-control" v-model="filterby.id_unit">
                                                <option v-for="(unit, index) in optionsUnit.filter(unit => unit.id > 1)" :key="index" :value="unit.id">{{unit.name}}</option>
                                            </select>                                                                             
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-6" v-else>
                                        <div class="form-group">
                                            <label for="filter_by">-</label>
                                            <input type="text" class="form-control" disabled>                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" @click="reset()">Reset</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="getList()">Filter</button>
                    </div>
                </div>
            </div>
        </div> 
	</section>
</template>

<script>
import List from '@/components/List.vue';

export default {
	name: 'UserIndex',
	components: {
        List
	},
	data() {
		return {
			table: {
				title: "Generate Laporan Amandemen",
				breadcumb: "Laporan",
                dataTable: {
                    lists: [],
                    columns: [
                        'col_date',
                        'no_kontrak_lap',
                        'judul_kontrak',
                        'unit_name',
                        'name',
                        'nomor',
                        'tgl_akhir',
                        'nilai_kontrak',
                        'other'
                    ]
                }
            },
            model: {},
            filterby: {
                id_unit: ''
            },
            filter: [
                {
                    'by': '-'
                }
            ],
            filterOption: 
            [
                {
                    'name': '-',
                    'by': '-'
                },
                {
                    'name': 'UPP',
                    'by': 'id_unit'
                }
            ],
            optionsUnit: [],
        }
	},
    mounted() {
        let _ = this
        _.getList()
        _.getUnit()
        _.$root.$on("paginate", (data) => {
            _.getList(data)
        });
    },
    methods: {
        getList(page) {
            let _ = this
            _.axios.get('/kontrak/amandemen/index_laporan' + '?page=' + page + '&id_unit=' + _.filterby.id_unit)
                .then(resp=>{
                    _.table.dataTable.lists = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },        
        cetak() {
			let _ = this
            _.axios.get('/kontrak/amandemen/export-data?id_unit=' + _.filterby.id_unit
                ,{responseType: 'arraybuffer'})
                .then(resp => {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'LaporanAmandemen.xlsx'
                    link.click()
                })
                .catch(err => {
                    console.log(err.response)
                })
	    },
        getUnit() {
            let _ = this
            _.axios.get('/unit/all')
                .then(resp => {
                    _.optionsUnit = resp.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        reset() {
            let _ = this 
            _.filterby.id_unit = ''
            _.filter = [
                {
                    'by': '-'
                }
            ]
        }
    }
}
</script>