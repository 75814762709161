<template>
  <div>
    <div class="content-header">
        <div class="content-header-left mb-2">
            <div class="breadcrumbs-top">
                <h2 class="content-header-title mb-0">Generate Jaminan Pemeliharaan {{ type }}</h2>
                <div class="breadcrumb-wrapper">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                <i class="feather icon-home"></i>
                            </router-link>
                        </li>

                        <li class="breadcrumb-item active">Project List</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="content-body">
        <section id="basic-datatable">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-content">
                            <div class="card-body card-dashboard">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row align-items-center justify-content-between">
                                            <div class="col mb-1">
                                                <div class="btn-responsive">
                                                   <div class="d-inline-flex align-items-center" style="gap: 15px">
                                                        <!-- <button type="button" class="btn btn-outline-primary" data-toggle="modal" data-target="#Modal_Filter">
                                                            Filter
                                                        </button> -->
                                                        <!-- <h4 class="d-inline m-0">
                                                            <div class="badge badge-rounded-md badge-warning badge-md">
                                                                10% Pencapaian
                                                            </div>
                                                        </h4> -->
                                                        <button @click="exportExcel" type="button" class="btn btn-outline-primary" style="margin-left: 4px;" data-toggle="modal" data-target="#Modal_Import">
                                                            <div v-if="!loadStateExport" class="mx-auto text-center" style="width:100%">
                                                                <i class="fa fa-spinner fa-spin"></i>
                                                            </div>
                                                            <span v-else>Cetak Excel</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-12 col-sm-4 mb-1">
                                                <div class="input-group form-search-rounded">
                                                    <input v-model="payloadList.search" class="form-control py-2 border-right-0 border" type="search" id="example-search-input" placeholder="Cari disini..">
                                                    <span class="input-group-append">
                                                        <div class="input-group-text bg-transparent">
                                                            <a href="#" class="text-blue"><i class="fa fa-search"></i></a>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div v-if="loadStateList" class="col-12">
                                                <div class="wrap-table-leaderboard">
                                                    <el-table stripe empty-text="No Data"  :data="tableData" style="width: 100%" class="table-ipm-theme">
                                                        <el-table-column width="90" fixed>
                                                            <template #header>
                                                                <span class="text-primary text-center d-block"><strong>No</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <a style="display: block; text-align: center; cursor: normal">
                                                                    {{ e.row.no }}
                                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <g filter="url(#filter0_d_939_5634)">
                                                                        <path d="M21.0711 20.0711C22.9464 18.1957 24 15.6522 24 13C24 10.3478 22.9464 7.8043 21.0711 5.92893C19.1957 4.05357 16.6522 3 14 3C11.3478 3 8.8043 4.05357 6.92893 5.92893C5.05357 7.8043 4 10.3478 4 13C4 15.6522 5.05357 18.1957 6.92893 20.0711C8.8043 21.9464 11.3478 23 14 23C16.6522 23 19.1957 21.9464 21.0711 20.0711Z" fill="white"/>
                                                                        </g>
                                                                        <path d="M14.1453 8.62457C12.8539 7.88664 11.4966 7.81762 10.1914 8.40676V8H9.60547V18H10.1914V14.3529C11.3939 13.7221 12.6568 13.7418 13.8547 14.4262C14.5731 14.8368 15.3118 15.0419 16.0508 15.0419C16.7895 15.0419 17.5285 14.8368 18.2469 14.4262L18.3945 14.3418V8.37395L17.9562 8.62457C16.71 9.33695 15.3922 9.33695 14.1453 8.62457Z" fill="#EC2F39"/>
                                                                        <defs>
                                                                        <filter id="filter0_d_939_5634" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                                        <feOffset dy="1"/>
                                                                        <feGaussianBlur stdDeviation="2"/>
                                                                        <feComposite in2="hardAlpha" operator="out"/>
                                                                        <feColorMatrix type="matrix" values="0 0 0 0 0.0117647 0 0 0 0 0.337255 0 0 0 0 0.419608 0 0 0 0.12 0"/>
                                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_939_5634"/>
                                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_939_5634" result="shape"/>
                                                                        </filter>
                                                                        </defs>
                                                                    </svg>
                                                                </a>
                                                            </template>
                                                        </el-table-column>
                                                       
                                                        <el-table-column fixed width="350">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Judul Kontrak</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <router-link :to="'/kontrak/'+e.row.id_kontrak" style="display: block;">
                                                                    <a style="display: block;" class="text-blue">{{ e.row.kontraks.judul_kontrak }}</a>
                                                                </router-link>
                                                            </template>
                                                        </el-table-column>

                                                         <el-table-column width="300">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>No. Kontrak</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <span class="no-decoration" v-html="e.row.kontraks.col_no_kontrak"></span>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="200">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Status Bank Garansi</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <button class="badge badge-default badge-md cursor-pointer border-0" 
                                                                    @click="detail = JSON.parse(JSON.stringify(e.row))"
                                                                    data-toggle="modal"
                                                                    data-target="#Modal_UpdateBankStatus"
                                                                    :style="`background-color: ${ e.row.bank_status?.color }`"
                                                                    :disabled="isDisabledUpdate(e)"
                                                                    >

                                                                    <div class="d-flex align-items-center">
                                                                        {{ e.row.bank_status_id ? e.row.bank_status.name : 'Tidak ada status' }}
                                                                        <i class="fa fa-chevron-down" style="font-size: 8px; margin-left: 5px;"></i>
                                                                    </div>
                                                                </button>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="200" v-if="type === 'Perlu Penyesuaian'">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Jaminan Pemeliharaan</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <span class="no-decoration">{{ (e.row.nilai_format || 0) }}</span>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="200" v-if="type === 'Perlu Penyesuaian'">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Jaminan Pemeliharaan<br>Seharusnya</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <span class="no-decoration">{{ (e.row.nilai_seharusnya || 0) | currency }}</span>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="200" v-if="type !== 'Perlu Penyesuaian'">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Tanggal Akhir BG</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <span class="no-decoration" v-html="e.row.tgl_berakhir"></span>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="150">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Indikator BG</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <div class="d-flex align-items-center" style="gap: 5px">
                                                                    <div class="badge badge-warning badge-md">{{ e.row.status_text }}</div>
                                                                </div>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="255">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Keterangan</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                <textarea cols="30" rows="4" maxlength="140"
                                                                    v-model.lazy="e.row.notes" 
                                                                    @change="updateInformation(e)"
                                                                    style="resize:none"
                                                                    :disabled="isDisabledUpdate(e)"/>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column width="255">
                                                            <template #header>
                                                                <span class="text-primary  d-block"><strong>Nama PIC</strong></span>
                                                            </template>
                                                            <template slot-scope="e">
                                                                {{ e.row.pic_names }}
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </div>
                                            </div>
                                            <div v-else class="col-12">
                                                <div class="mx-auto text-center" style="width:100%">
                                                    <h2><i class="fa fa-spinner fa-spin"></i></h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2" style="text-align: end">
                                        <paginate
                                            :page-count="total_page"
                                            :click-handler="paginateCb"
                                            :prev-text="'Prev'"
                                            :next-text="'Next'"
                                            container-class="pagination-v2">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div 
        class="modal fade text-left" 
        id="Modal_Filter" 
        aye="mdaman" 
        tabindex="-1" 
        role="dialog"
        aria-labelledby="myModalLabel160" 
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header modal-primary white">
                    <h5 class="modal-title">Filter</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ValidationObserver v-slot="{ invalid }" ref="dokumenStatusKontraktual">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <ValidationProvider name="UPP" rules="required" v-slot="{ errors }">
                                    <label for="">Status</label>
                                    <select v-model="payloadList.status_kontraktual" class="form-control" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                        <option :value="null">Pilih Status</option>
                                        <option v-for="(opt, idx) in optList" :key="idx" :value="opt.value">{{ opt.text }}</option>
                                    </select>
                                    <small>{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="reset" @click="resetFilter()" class="btn btn-outline-primary" data-dismiss="modal">Reset</button>
                        <button v-if="true" @click="loadList()" type="button" :disabled="invalid" class="btn btn-primary">
                            <span>Filter</span>
                        </button>
                        <button v-else type="button" disabled class="btn btn-primary">
                            <div class="mx-auto text-center" style="width:100%">
                                <i class="fa fa-spinner fa-spin text-white"></i>
                            </div>
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
    <div class="modal fade text-left" id="Modal_UpdateBankStatus" tabindex="-1" role="dialog"
        aria-hidden="true" v-if="detail">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <ValidationObserver ref="updateBankStatus">
                    <form @submit.prevent="updateBankStatus()">
                        <div class="modal-header modal-primary white">
                            <h5 class="modal-title" id="myModalLabel160">Update Status Bank Garansi</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                                <div class="form-group">
                                    <label for="">Judul Kontrak</label>
                                    <input type="text" name="" id="" class="form-control" v-model="detail.kontraks.judul_kontrak" readonly>
                                </div>
                                <div class="form-group">
                                    <label for="">No Kontrak</label>
                                    <input type="text" name="" id="" class="form-control" v-model="detail.kontraks.no_kontrak" readonly>
                                </div>
                                <div class="form-group">
                                    <label for="">Status Bank Garansi</label>
                                    <ValidationProvider name="Status Bank Garansi" rules="required" v-slot="{ errors }">
                                        <select class="form-control" v-model="detail.bank_status_id" :class="(Boolean(errors[0]))? 'is-invalid' : ''">
                                            <option :value="null">Pilih Status Bank Garansi</option>
                                            <option v-for="status in bankStatuses" :key="status.id" :value="status.id">
                                                {{ status.name }}
                                            </option>
                                        </select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </ValidationProvider>
                                </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Tutup</button>
                            <button class="btn btn-primary" type="submit">Update</button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ld from 'lodash'
import paginate from 'vuejs-paginate'

export default {
    name: 'WarningBGUM',
    components: {paginate},
    data() {
        return {
            role_id: localStorage.getItem('role_id'),
            doc_url: null,
            loadStateList: false,
            loadStateExport: true,
            optList: [
                {
                    value: 'Belum Efektif',
                    text: 'Belum Efektif'
                },
                {
                    value: 'On Going',
                    text: 'On Going',
                },
                {
                    value: 'Serah Terima I',
                    text: 'Serah Terima I',
                },
            ],
            payloadList: {
                search: '',
                page: 1,
                per_page: 10
            },
            type: null,
            total_page: 0,
            tableData: [],
            detail: null,
            bankStatuses: [],
            user_id: localStorage.getItem('userid') 
        }
    },
    watch: {
        'payloadList.search' : ld.debounce (function () {
            this.payloadList.page = 1
            this.loadList ()
        }, 300)
    },
    mounted () {
        this.getBankStatuses()
        this.type = this.$route?.query?.filter ?? null
        if (!this.$route.query.filter) {
            this.$router.push('/')
        }
        this.loadList ()
    },
    methods: {
        async loadList () {
            this.loadStateList = false
            try {
                
                if (this.type === 'Berakhir') {
                    const datas = (await this.axios.get('/v2/bg_pemeliharaan/list-berakhir', { params: this.payloadList })).data
                    if (!datas.status) throw datas
                    this.tableData = datas.data.rows.data.map((e, key, i) => {
                        e.no = ((datas.data.rows.current_page - 1) * datas.data.rows.per_page + (key > 9 ? i : key) + 1) || key + 1 
                        return e
                    })
                    this.total_page = datas.data.rows.total >= 10 ? Math.ceil(datas.data.rows.total / 10) : 1
                } else if (this.type === 'Akan Berakhir') {
                    const datas = (await this.axios.get('/v2/bg_pemeliharaan/list-akan-berakhir', { params: this.payloadList })).data
                    if (!datas.status) throw datas
                    this.tableData = datas.data.rows.data.map((e, key, i) => {
                        e.no = ((datas.data.rows.current_page - 1) * datas.data.rows.per_page + (key > 9 ? i : key) + 1) || key + 1 
                        return e
                    })
                    this.total_page = datas.data.rows.total >= 10 ? Math.ceil(datas.data.rows.total / 10) : 1
                } else if (this.type === 'Perlu Penyesuaian') {
                    const datas = (await this.axios.get('/v2/bg_pemeliharaan/list-penyesuaian', { params: this.payloadList })).data
                    if (!datas.status) throw datas
                    this.tableData = datas.data.rows.data.map((e, key, i) => {
                        e.no = ((datas.data.rows.current_page - 1) * datas.data.rows.per_page + (key > 9 ? i : key) + 1) || key + 1 
                        return e
                    })
                    this.total_page = datas.data.rows.total >= 10 ? Math.ceil(datas.data.rows.total / 10) : 1
                } else {
                    const datas = (await this.axios.get('/v2/bg_pemeliharaan/all', { params: this.payloadList })).data
                    if (!datas.status) throw datas
                    this.tableData = datas.data.rows.data.map((e, key, i) => {
                        e.no = ((datas.data.rows.current_page - 1) * datas.data.rows.per_page + (key > 9 ? i : key) + 1) || key + 1 
                        return e
                    })
                    this.total_page = datas.data.rows.total >= 10 ? Math.ceil(datas.data.rows.total / 10) : 1
                }
            } catch (error) {
                console.log(error)
            } finally {
                window.$('#Modal_Filter').modal('hide')
                this.loadStateList = true
            }
        },
        paginateCb (page) {
            this.payloadList.page = page
            this.loadList ()
        },
        resetFilter () {
            this.payloadList = { ...this.payloadList, status_kontraktual : null }
            this.loadList ()
        },
        async exportExcel () {
            this.loadStateExport = false
            try {
                const datas = (await this.axios.get(`/v2/bg_pemeliharaan/excel?status=${this.$route.query.filter == 'Perlu Penyesuaian' ? 'Penyesuaian Nilai' : this.$route.query.filter}&search=${this.payloadList.search}`, { responseType: 'blob' })).data
                const url = window.URL.createObjectURL(new Blob([datas]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `generate-jaminan-pemeliharaan-${this.type.toLowerCase().replace(' ', '-')}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error)
            } finally {
                this.loadStateExport = true
            }
        },
        getBankStatuses() {
            let _ = this
            _.axios.get('/v2/bank-status')
                .then( resp => {
                    _.bankStatuses = resp.data.data
                })
                .catch( err => {
                    console.log(err)
                })
        },
        async updateBankStatus() {
            let _ = this
            const isValid = _.$refs.updateBankStatus.validate()
            if (!isValid) return;

            _.axios.post('/v2/bg_pemeliharaan/bank_status', {
                    id: _.detail.id,
                    bank_status_id: _.detail.bank_status_id
                })
                .then(() => {
                    window.$('#Modal_UpdateBankStatus').modal('hide')
                    this.loadList ()
                })
                .catch( err => {
                    console.log(err)
                })
        },
        updateInformation(e) {
            let _ = this
            _.axios.post('/v2/bg_pemeliharaan/notes', {
                    id: e.row.id,
                    notes: e.row.notes
                })
                .then(() => {
                    _.$toast.success('Berhasil mengubah keterangan')
                    const type = this.$route.query?.list ?? null
                    if (type === 'warning') {
                        this.loadListWarning ()
                    } else {
                        this.loadList ()
                    }
                })
                .catch( err => {
                    console.log(err)
                    _.$toast.warning(err.response.data.message)
                })
        },
        isDisabledUpdate(e) {
            if (this.isAdmin) return false;
            return !e.row.pics || !(e.row.pics?.split(',').indexOf(this.user_id.toString()) != -1);
        }
    },
    computed: {
        isAdmin() {
            return localStorage.getItem('position_name')
                .indexOf("Super Admin") != -1;
        },
    }
}
</script>

<style scoped>
</style>