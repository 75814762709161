<template>
	<div class="row">
        <div class="col-12 col-lg-6 col-xl-7 d-none d-lg-block">
            <div class="full-img">
                <img src="/assets/img/gistet-tambun.jpg" class="img-fluid" alt="">
                <div class="logo-block">
                    <img src="/assets/img/logo-text-white-min.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>

        <div class="col-12 col-md-8 col-lg-6 col-xl-5 ml-auto mr-auto">
            <div class="form-auth-block">
                <div class="form-logo d-block d-lg-none">
                    <img src="/assets/img/logo.png" class="img-fluid" alt="">
                    <div class="sub-logo">Monitoring <span>Digital</span></div>
                </div>

                <h4 class="title-heading-form text-primary">Kode OTP</h4>    
                <p class="subtitle-form">Masukan Kode OTP yang sudah dikirim ke email</p>

                <!-- <div class="form-group form-otp">
                    <ValidationProvider name="satu" rules="required">
                        <div class="form">
                            <input type="number" v-model="satu" class="form-control form-custom-bg" required="">
                        </div>
                    </ValidationProvider>

                    <ValidationProvider name="dua" rules="required">
                        <div class="form">
                            <input type="number" v-model="dua" class="form-control form-custom-bg" required="">
                        </div>
                    </ValidationProvider>

                    <ValidationProvider name="tiga" rules="required">
                        <div class="form">
                            <input type="number" v-model="tiga" class="form-control form-custom-bg" required="">
                        </div>
                    </ValidationProvider>

                    <ValidationProvider name="empat" rules="required">
                        <div class="form">
                            <input type="number" v-model="empat" class="form-control form-custom-bg" required="">
                        </div>
                    </ValidationProvider>
                </div> -->
                <div class="form-group form-otp">
                    <OtpInput
                      ref="otpInput"
                      input-classes="form-control form-custom-bg otp"
                      separator="&nbsp;&nbsp;&nbsp;"
                      :num-inputs="4"
                      :should-auto-focus="true"
                      :is-input-num="true"
                      @on-change="isCompleteOtp"
                    />
                </div>

                <button :disabled="isOkOTP" @click="verifikasi" class="btn btn-primary waves-effect waves-light w-100">Verifikasi</button>

                <p class="mt-2 text-center">
                    Tidak mendapat kode? <br>
                    <a href="javascript:;" @click="resendOTP()" class="font-600 text-primary">Kirim Ulang OTP</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input"

export default {
	name: 'OTP',
    components: {
        OtpInput
    },
    data() {
        return {
            satu: '',
            dua: '',
            tiga: '',
            empat: '',
            isProcess: false,
            isOkOTP: true
        }
    },
    methods: {
        verifikasi() {
            let _ = this
            var no_otp = _.$refs.otpInput.otp.join('')
            _.isProcess = true
            _.axios.post('/auth/otp', { otp: no_otp })
                .then(resp => {
                    _.isProcess = false
                    if ( resp.status == 200 ) {
                        if ( resp.data.data.otp_valid ) {
                            console.log('OTP OKE')
                            _.$router.push('/reset-password?otp=' + no_otp)
                        } else {
                            console.log('OTP SALAH')
                            _.$toast.warning('OTP Salah, Silahkan Ulangi atau Kirim Ulang OTP')
                        }
                    }
                })
                .catch(err => {
                    _.isProcess = false
                    console.log(err.response)
                    _.$toast.warning('OTP Salah, Silahkan Ulangi atau Kirim Ulang OTP')
                })
        },
        resendOTP() {
            let _ = this
            _.axios.post('/auth/otp/resend', { email: _.$route.query.email })
                .then(resp => {
                    console.log(resp)
                    _.$toast.success('Silahkan Periksa Ulang Email Anda')
                })
                .catch(err => {
                    console.log(err.response)
                    _.$toast.warning(err.response)
                })
        },
        isCompleteOtp() {
            let _ = this
            console.log(_.$refs.otpInput)
            if ( _.$refs.otpInput === undefined ) {
                _.isOkOTP = true
            } else {
                var no_otp = _.$refs.otpInput.otp.join('')

                _.isOkOTP = no_otp.length < 4    
            }
            
        }
    },
    computed: {
        no_otp() {
            let _ = this
            return _.satu + _.dua + _.tiga + _.empat
        }
    }
}
</script>

<style>
.otp {
    height: 100% !important;
    width: 60%;
    text-align: center;
    font-size: 35px !important;
    font-weight: 700;
}
</style>