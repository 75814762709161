<template>
	<div>
		<div class="content-header">
            <div class="content-header-left mb-2">
                <div class="breadcrumbs-top">
                    <h2 class="content-header-title mb-0">{{ title }}</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="role_id == 2 ? '/' : '/kontrak'">
                                    <i class="feather icon-home"></i>
                                </router-link>
                            </li>

                            <li class="breadcrumb-item" :class="data.active == true ? 'active' : ''" style="text-transform: capitalize" v-for="(data, index) in breadcumb" :key="index">
                                <router-link :to="data.link">{{ data.name }}</router-link>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-body">
            <section id="basic-datatable">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <ValidationObserver class="card-content">
                                <div class="card-content">
                                    <div class="card-body card-dashboard">
                                        <h3 class="font-bold text-primary mb-2">Show CCTV {{ model.name }}</h3>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-8">
                                                <vplayer ref="videoPlayer" :playerOptions = "vOption"></vplayer>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="d-flex flex-wrap justify-content-end align-items-center">
                                            <router-link to="/cctv" class="btn btn-outline-primary mr-1 btn-lg" data-dismiss="modal">Kembali</router-link>
                                        </div>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </section>
        </div>
	</div>
</template>

<script>
import { vplayer } from 'vue-hls-player'
export default {
    name: "CCTVDetail",
    components: {
        vplayer
    },
    data() {
        return {
            title: "CCTV",
            breadcumb: [
                {
                    name: 'CCTV',
                    link: '/cctv',
                    active: false
                },   
                {
                    name: 'Edit CCTV', 
                    link: '',
                    active: true
                }            
            ],
            model: [],
            map: '',
            marker : [],
            lokasiInp: [{
                    latlng : '',
                    pinned : false
            }],
            role_id: localStorage.getItem('role_id'),
            vOption: {
                type: "application/x-mpegURL",
                src: this.$route.query.url,
                controls: '',
                preload: true
            }
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/cctv/' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data
                    _.vOption.src = resp.data.data.url
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        save() {
            let _ = this
            if ( _.model.lokasi.length == 0 ) {
                _.$toast.warning('Pin lokasi terlebih dahulu')
                return;
            }

            _.axios.patch('/cctv/' + _.$route.params.id, _.model)
                .then(resp => {
                    if (resp.status) {
                        _.$router.push('/cctv')
                        _.$toast.success('Data Berhasil Diubah')
                    }else{
                        console.log("Failed")
                        _.$toast.warning('Data Gagal Diubah')
                    }
                })
                .catch(err=>{
                    console.log(err.response)
                    _.$toast.warning('Data Sub-Bidang Sudah Digunakan')
                })
        },
        initMap() {
            let _ = this
            var myLatlng = {lat: _.model.lat, lng: _.model.lng}
            _.map = new window.google.maps.Map(
               _.$refs["map"], {zoom: 12, center: myLatlng})
        },
        async addMarker(index) {
            let _ = this
            var mylatlng = {lat : parseFloat(_.lokasiInp[index].latlng.split(",")[0]), lng : parseFloat(_.lokasiInp[index].latlng.split(",")[1])}
            if(!_.lokasiInp[index].pinned){
                var geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({
                        'latLng': mylatlng
                    }, function(results, status) {
                        if (status == window.google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                let mark = new window.google.maps.Marker({
                                                map : _.map,
                                                animation: window.google.maps.Animation.DROP,
                                                position: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}
                                            });
                                var infoWindow = new window.google.maps.InfoWindow({
                                    content: results[0].formatted_address,
                                });
                                _.marker['mark'+index] = mark
                                infoWindow.open(_.map, _.marker['mark'+index]);

                                _.model.lokasi[0] = {
                                    address: results[0].formatted_address,
                                    gmap_url : 'https://www.google.co.id/maps/place/'+results[0].formatted_address.replace(/,/g,"").replace(/ /g,"+"),
                                    lat : results[0].geometry.location.lat(),
                                    lng : results[0].geometry.location.lng()
                                }
                                _.lokasiInp[index].pinned = true
                            }
                        }
                    });
           
            }
            else{
                _.cancelPin(index)
            }
            
        },
        cancelPin(ix) {
            let _ = this
            _.model.lokasi = []
            _.marker['mark'+ix].setMap(null)
           delete _.marker['mark'+ix]
            _.lokasiInp[ix].latlng = ""
            _.lokasiInp[ix].pinned = false
        }
    },
    computed: {
        latlng() {
            let _ = this
            return _.model.lat + ', ' + _.model.lng
        }
    },
    beforeMount() {
        let _ = this
        _.get()
    },
    mounted() {
        
    },
    watch: {
        'model.lng': function(newValue) {
            let _ = this
            _.lokasiInp[0].latlng = _.model.lat + ', ' + newValue
            _.initMap()
            _.addMarker(0)
        }
    }
}
</script>