import axios from '../../axios'
export default {
    async getFileToken(url) {
        // let _ = this
        let urlFull = process.env.VUE_APP_BASE_URL + '/auth/token-file';
        let data = {
            url : process.env.VUE_APP_BASE_URL + url
        }

        const datas = (await await axios.post(urlFull, data)).data
        return datas;
        // return await axios.post(urlFull, data).then(resp=>{
        //     return resp
        // })
        // .catch(err=>{
        //     console.log(err.response)
        // })
    }
    
  }