<template>
  <div>
    <section class="d-block" name="DokumenKontrak">
      <div class="row align-items-center justify-content-between">
        <div class="col-12 col-sm-8 col-lg-6 mb-1">
          <h4 class="card-title mb-0">
            Dokumen Kontrak
            <button
              type="button"
              @click="showModalDokumenKontrak('add')"
              class="btn btn-primary btn-block-mobile">
              Tambah Dokumen Kontrak
            </button>
          </h4>
        </div>

        <div class="col-12 col-sm-4 mb-1">
          <div class="input-group form-search-rounded">
            <input
              class="form-control py-2 border-right-0 border"
              type="search"
              v-model="dokumenKontrakQuery"
              placeholder="Cari dokumen kontrak.."
            />
            <span class="input-group-append">
              <div class="input-group-text bg-transparent">
                <a href="#" class="text-blue"
                  ><i class="fa fa-search"></i
                ></a>
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="table-responsive no-sort w-100">
        <table class="table tabel-status-document w-100">
          <thead>
            <tr>
              <th>Nomor</th>
              <th><i class="feather icon-download-cloud fa-l"></i> File</th>
              <th>Tanggal</th>
              <th class="w10">Aksi</th>
            </tr>
          </thead>
          <tbody v-if="dokumenKontrakDatas.length > 0">
            <tr v-for="(data, index) in dokumenKontrakDatas" :key="index">
              <td>{{ data.no }}</td>
              <td>
                <div v-if="data.files == null">-</div>
                <div v-else>
                  <p
                    v-for="(item, index) in data.files"
                    :key="item.name"
                  >
                    <a @click="pdfViewer(item)" href="javascript:;"
                      >• File {{ index + 1 }} [Lihat Detail]</a
                    >
                  </p>
                </div>
              </td>
              <td>{{ moment(data.date).locale('id').format('LL') }}</td>
              <td>
                <div class="btn-action-block">
                  <button
                    type="button"
                    class="btn btn-icon-small btn-danger-trans waves-effect waves-light"
                    @click="destroyDokumenKontrak(index)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6" class="text-center">Tidak ada data.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr class="my-2" />
    </section>

    <div
      class="modal fade text-left"
      id="modalDokumenKontrak"
      role="dialog" v-if="!isSaving">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header modal-primary white">
            <h5 class="modal-title" id="myModalLabel160">
              {{ dokumenKontrakDetail.id ? 'Edit' : 'Tambah' }} Dokumen Kontrak
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="dokumenKontrakFormObserver">
              <div class="form-group">
                <ValidationProvider
                  name="Nomor"
                  rules="required"
                  v-slot="{ errors }">
                  <label for="">Nomor</label>
                  <input
                    type="text"
                    v-model="dokumenKontrakForm.no"
                    class="form-control"
                    placeholder="Masukan nomor"
                    :class="Boolean(errors[0]) ? 'is-invalid' : ''"
                    aria-describedby="helpId"
                  />
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Tanggal"
                  rules="required"
                  v-slot="{ errors }">
                  <label for="">Tanggal</label>
                  <div class="position-relative has-icon-right">
                    <date-picker
                      v-model="dokumenKontrakForm.date"
                      format="DD/MM/YYYY"
                      type="date"
                      :input-class="
                        Boolean(errors[0])
                          ? 'form-control is-invalid no-x'
                          : 'form-control'
                      "
                      value-type="YYYY-MM-DD"
                      placeholder="Masukkan tanggal"
                      style="width: 100%" />
                  </div>
                  <small>{{ errors[0] }} </small>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="filter_by">Upload File</label>
                <vue-dropzone
                  ref="dokumenKontrakDropzone"
                  id="dokumenKontrakDropzone"
                  :duplicateCheck="true"
                  @vdropzone-files-added="onDokumenKontrakFilesAdded"
                  @vdropzone-removed-file="onDokumenKontrakFilesRemoved"
                  :options="dokumenKontrakDropzoneOptions"
                >
                </vue-dropzone>
                <button
                  v-if="dokumenKontrakForm.files.length > 0"
                  @click="removeAllDokumenKontrakFile"
                  class="btn btn-danger"
                  style="margin-top: 10px">
                  Reset File
                </button>
                <ValidationProvider name="Dokumen" v-slot="{ errors }" rules="required" 
                  v-if="dokumenKontrakForm.files.length <= 0">
                  <input type="hidden" v-model="dokumenKontrakForm.files" />
                  <small>{{ errors[0] }}</small>
                </ValidationProvider>
              </div>
            </ValidationObserver>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary"
              data-dismiss="modal">
              Tutup
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDokumenKontrak()"
              :disabled="loading">
              <i v-if="loading" class="fa fa-spinner fa-spin"></i>
              <span v-if="!loading">Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import ld from "lodash";
import moment from 'moment'

export default {
  props: {
    isSaving: { type: Boolean, default: false }
  },
  data() {
    return {
      moment : moment,
      dokumenKontraks: [],
      dokumenKontrakDetail: {
        no: '',
        date: '',
        files: [],
      },
      dokumenKontrakQuery: "",
      dokumenKontrakForm: {
        no: '',
        date: '',
        files: []
      },
      dokumenKontrakDropzoneOptions: {
        url: this.$baseUrl + "/kontrak/progress",
        thumbnailWidth: 150,
        acceptedFiles: ".doc,.docx,application/msword,.pdf",
        autoProcessQueue: false,
        addRemoveLinks: true,
        uploadMultiple: true,
        headers: { 
          Authorization: "Bearer " + this.$store.state.token 
        },
        paramName: "image",
        maxFilesize: 10000,
        init: function () {
          this.on("error", function (file, message) {
            alert(message);
            this.removeFile(file);
          });
        },
      },
      loading: false,
    }
  },
  computed: {
    dokumenKontrakDatas: function() {
      let _ = this
      return _.dokumenKontraks.filter(function( x ) {
        return x.no.toLowerCase().indexOf(_.dokumenKontrakQuery.toLowerCase()) >= 0
      });
    },
  },
  methods: {
    showModalDokumenKontrak(type) {
      let _ = this;
      _.removeAllDokumenKontrakFile();
      if (type == "edit") {
        _.dokumenKontrakForm = {
          no: _.dokumenKontrakDetail.no,
          date: _.dokumenKontrakDetail.date,
          files: [],
        };
        if (_.dokumenKontrakDetail.files) {
          _.dokumenKontrakDetail.files.forEach(function (el) {
            let file = { size: 10240, name: el.name, type: "application/word" };
            let url = el.url;
            _.$refs.dokumenKontrakDropzone.manuallyAddFile(file, url);
          });
        }
      } else if (type == "add") {
        _.resetDokumenKontrakForm()
        _.dokumenKontrakDetail = {
          no: '',
          date: '',
          files: [],
        }
      }
      window.$("#modalDokumenKontrak").modal("show");
    },
    destroyDokumenKontrak(x) {
      let _ = this;
      this.$swal
        .fire({
          title: "Hapus Data Ini?",
          text: "Anda mungkin tidak akan mengembalikannya lagi!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#44A8BD",
          cancelButtonColor: "#D95E6A",
          confirmButtonText: "Ya, Hapus!",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            _.dokumenKontraks.splice(x, 1)
            _.updateDokumenKontrak()
          }
        });
    },
    onDokumenKontrakFilesAdded(file) {
      let _ = this;
      if (file[0].size / 1000000 < _.dokumenKontrakDropzoneOptions.maxFilesize) {
        $.each(file, function (index, val) {
          _.dokumenKontrakForm.files.push(val);
        });
      }
    },
    onDokumenKontrakFilesRemoved(file) {
      let _ = this;
      if (file.lastModified) {
        _.dokumenKontrakForm.files = ld.filter(
          _.dokumenKontrakForm.files,
          function (o) {
            return o.name != file.name;
          }
        );
      }
    },
    removeAllDokumenKontrakFile() {
      let _ = this;
      _.$refs.dokumenKontrakDropzone.removeAllFiles(true);
      _.dokumenKontrakForm.files = [];
    },
    resetDokumenKontrakForm() {
      this.dokumenKontrakForm = {
        no: '',
        date: '',
        files: [],
      }
    },
    saveDokumenKontrak() {
      let _ = this;
      var validation = _.$refs.dokumenKontrakFormObserver;
      validation.validate()
        .then(async (success) => {
          if (!success) {
            return;
          }

          _.dokumenKontraks.push(_.dokumenKontrakForm)
          _.$toast.success("Dokumen Kontrak Berhasil" + (_.dokumenKontrakForm.id ? ' Diubah' : ' Ditambahkan'))
          _.resetDokumenKontrakForm()
          _.removeAllDokumenKontrakFile()
          validation.reset()
          window.$("#modalDokumenKontrak").modal("hide")

          _.updateDokumenKontrak()
        });
    },
    pdfViewer(file) {
      this.$root.$emit('setPDFFile', file);
    },
    updateDokumenKontrak() {
      this.$root.$emit('updateDokumenKontrak', this.dokumenKontraks);
    },
  },
}
</script>