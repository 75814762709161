<template>
	<!-- Modal -->
    <div class="modal fade text-center with-bg" id="ModalUpdateProfile" data-backdrop="static" tabindex="-1" role="dialog"
        aria-labelledby="myModalLabel160" aria-hidden="false">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body pt-3 pb-5">
                    <img src="/assets/img/icon/img.png" class="img-fluid mb-2">
                    <p>Silahkan ganti password dan lengkapi<br> profil Anda!</p>
                    <a href="#" @click="$emit('toEditPage')" class="btn btn-primary" data-dismiss="modal">Buka Profil</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	name: 'ModalUpdateProfile'
}
</script>